import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, TextField, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData } from '../../app/firebase';
import moment from 'moment';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatientCurrentProtocol, setPatient, getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentPage } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HeightIcon from '@mui/icons-material/Height';
import { cloneDeep } from 'lodash';
import PatientProtocolNavigation from './PatientProtocolNavigation';
import PatientProtocolDiagnosis from './PatientProtocolDiagnosis';
import PatientForm from './PatientForm';
import PatientFormPrint from './PatientFormPrint';
import Spacer from '../common/Spacer';

function PatientProtocolFinishPrint(props)
{
    const user = useSelector(getUser);
    const protocol = useSelector(getPatientCurrentProtocol);
    const protocol_page = useSelector(getPatientCurrentPage);

    const [localProtocol, setLocalProtocol] = useState(protocol);
    
    const navigate = useNavigate();

    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            console.log('PRINT!');
            window.print();
        }, 3000);
        return () => clearTimeout(timer);
    });

    return (
        <Stack>
            <Button onClick={() => navigate('/patient')}>
                    <div style={{ width: '800px', height: '300px' }} />
                </Button>
            <Stack spacing={3}>
                <Typography variant="h5">Pacijent</Typography>
                <PatientFormPrint />
                {localProtocol.type === 'exam' && (
                    <div className="dontBreak">
                        <Typography variant="h5">Anamnesis</Typography>
                        <TextField
                            variant="standard"
                            fullWidth
                            multiline
                            defaultValue={localProtocol.anamnesis}
                        />
                    </div>
                )}
                <Typography variant="h5">Izveštaj</Typography>
                <PatientProtocolDiagnosis />
                
                    {localProtocol.type === 'exam' && (
                    <div className="dontBreak">
                            <Typography variant="h5">Dijagnoza</Typography>
                            <TextField
                                variant="standard"
                                fullWidth
                                multiline
                                defaultValue={localProtocol.diagnosis}
                                
                            />
                    </div>
                    )}
                <div className="dontBreak">
                        <Typography variant="h5">Zaključak</Typography>
                        <TextField
                            variant="standard"
                            fullWidth
                            multiline
                            defaultValue={localProtocol.conclusion}
                            
                        />
                    </div>
                    {localProtocol.type === 'exam' && (
                    <div className="dontBreak"><Typography variant="h5">Terapija</Typography>
                            <TextField
                                variant="standard"
                                fullWidth
                                multiline
                                defaultValue={localProtocol.therapy}
                            
                            /></div>
                )}
                <Spacer />
                <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                    <Grid item justify="flex-start" alignItems="flex-start">
                        <Typography variant="body1">Beograd,</Typography>
                        <Typography variant="body1">
                            {moment().format('DD. MM. YYYY')}
                        </Typography>
                    </Grid>
                    <Grid item justify="flex-end" alignItems="flex-end">
                        <Typography variant="body1">
                            Žarko Popović
                        </Typography>
                        <Typography variant="body2">viši fizioterapeut</Typography>
                        <Typography variant="body2">defektolog - somatoped</Typography>
                    </Grid>
                </Grid>
                </Stack>
            </Stack>
    );
}

export default withAuthentication(PatientProtocolFinishPrint);
