import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState, useEffect } from 'react';

export default function PatientPaymentOptions(props)
{
    return <FormControl sx={{ m: 1, minWidth: props.showLabel ? 200 : 100 }}>
        {props.showLabel && (<InputLabel id="paymenttype-select-label">Način plaćanja</InputLabel>)}
        <Select
        variant={props.variant}
        label="Način plaćanja"
        defaultValue={props.defaultValue}
        onChange={event => props.updateProtocol('payment_type', event.target.value)}
    >
            <MenuItem value=""> </MenuItem>
            <MenuItem value="cash">Keš</MenuItem>
            <MenuItem value="card">Kartica</MenuItem>
            <MenuItem value="transfer">Račun</MenuItem>
            <MenuItem value="dunav-osiguranje">Dunav Osiguranje</MenuItem>
            <MenuItem value="globos-osiguranje">Globos Osiguranje</MenuItem>
            <MenuItem value="wiener-osiguranje">Wiener Städtische</MenuItem>
        </Select>
        </FormControl>
}