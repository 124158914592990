import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatient, setPatientCurrentProtocol, setPatientProtocolCurrentPage } from '../patient/PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import Footer from '../appbar/Footer';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import ChartTotalsPerType from './ChartTotalsPerType';

function Statistics(props)
{
    const [data, setData] = useState([]);

    useEffect(() =>
    {
        getPatients();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const getPatients = (appendToEnd = false) =>
    {
        console.log("Loading patients...");
        getListOrderByChildEndBeforeLimit('/patients_protocols', (result) => drawData(result, appendToEnd), 'date', "2100-01-01", 99999, false);
    }

    const drawData = (dataRaw, appendToEnd = false) =>
    {
        console.log("Received patients.");
        setData(dataRaw);
    };

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer height={100} />
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Typography align="center" variant="h5">
                        Statistike
                    </Typography>
                    <Paper className="padding20">
                        <ChartTotalsPerType data={data} />
                    </Paper>
                    <Paper className="padding20">
                    </Paper>
                </Stack>
            </Container>
            <Spacer height={40} />
            <Footer />
        </div>
    );
}

export default withAuthentication(Statistics);
