import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, Grid, FormControl, InputLabel, Select, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { getDataAsync, getList, getListOrderByChild, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData, writeData } from '../../app/firebase';
import moment from 'moment';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatientCurrentProtocol, setPatient, getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentQuestion, getPatientCurrentQuestion } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import * as Components from '../components/Components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HeightIcon from '@mui/icons-material/Height';
import { cloneDeep } from 'lodash';
import PatientProtocolNavigation from './PatientProtocolNavigation';

function PatientProtocolDiagnosis(props)
{
    const protocol = useSelector(getPatientCurrentProtocol);
    
    const [pages, setPages] = useState(cloneDeep(protocol.pages));
    const [currentPage, setCurrentPage] = useState(-1);

    useEffect(() =>
    {
        loadNextPage();

        // cleanup function
        return () =>
        {
        };
    }, [currentPage]);

    const loadNextPage = () =>
    {
        let nextPage = currentPage + 1;

        if (nextPage >= pages.length)
        {
            console.log("finished diagnosis");
        }
        else
        {
            getDataAsync('/patients_protocols_pages/' + pages[nextPage].id, onPageQuestionsLoad);
        }
    }

    const onPageQuestionsLoad = (questions) =>
    {
        let newPages = cloneDeep(pages);
        newPages[currentPage + 1].questions = questions;
        setPages(newPages);
        setCurrentPage(currentPage + 1);
    };

    return (
        <Stack spacing={3}>
            {pages && pages.map(page =>
            {
                let output = [];
                if (page.questions !== undefined)
                    for (var i = 0; i < page.questions.length; i++)
                    {
                        let question = page.questions[i];
                        let component = Components.getDiagnosis(question);
                        if (component !== null)
                            output.push(<div key={page.id + i}>{component}</div>);
                    }
                return output;
            })}
        </Stack>
    );
}

export default PatientProtocolDiagnosis;
