import React from 'react';
import axios from 'axios';
import { Box, Button, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UploadButton from './UploadButton';
import * as dayjs from 'dayjs';
import { DateTimeField } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientCurrentProtocol, setPatientCurrentProtocol } from './PatientSlice';
import { cloneDeep } from 'lodash';
import { updateData } from '../../app/firebase';

let data = new FormData();

function FileUpload(props)
{
    const protocol = useSelector(getPatientCurrentProtocol);
    const dispatch = useDispatch();
    const [newUpload, setNewUpload] = React.useState({ date: dayjs().valueOf(), files: [], institution: 'default', diagnosis: '' });
    const [progress, setProgress] = React.useState(0);
    const [state, setState] = React.useState('add');

    async function onSubmit(e)
    {
        e.preventDefault();
        setState('upload');
        let res = await uploadFile();
    }

    async function onSelectFiles(e)
    {
        data = new FormData();
        data.append('protocol_id', protocol.id);
        let filesData = [];
        for (let index = 0; index < e.length; index++)
        {
            data.append('files[]', e[index]);
            filesData.push(e[index].name);
        }

        setNewUpload({ ...newUpload, files: filesData, institution: 'select_files' });
        setState('upload');

        let res = await uploadFile({ ...newUpload, files: filesData, institution: 'select_files' });
    }

    async function uploadFile(newUploadRaw)
    {
        var config = {
            onUploadProgress: function (progressEvent)
            {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
                console.log('Progress:-' + percentCompleted);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .post('https://physiopro.nika.rs/upload.php', data, config)
            .then(function (res)
            {
                console.log('UPLOAD COMPLETE');

                const newProtocol = cloneDeep(protocol);
                if (newProtocol.medicalRecords == undefined) newProtocol.medicalRecords = [];
                newProtocol.medicalRecords.push(newUploadRaw);

                updateData('/patients_protocols/' + newProtocol.id, newProtocol, () =>
                {
                    dispatch(setPatientCurrentProtocol(newProtocol));
                    setNewUpload({ date: dayjs().valueOf(), files: [], institution: 'after_update', diagnosis: '' });
                    setProgress(0);
                    setState('add');
                });
            })
            .catch(function (err)
            {
                console.log(err.message);
            });
    }

    let selectedFilesNames = '';
    for (let index = 0; index < newUpload.files.length; index++)
    {
        selectedFilesNames += newUpload.files[index] + ', ';
    }

    let uploadButtonElement =
        newUpload.files.length === 0 ? (
            <UploadButton onChange={onSelectFiles} />
        ) : (
            <Typography>
                {selectedFilesNames}
                <Button onClick={e => setNewUpload({ ...newUpload, files: [] })}>
                    <HighlightOffIcon />
                </Button>
            </Typography>
        );

    if (state === 'add')
        return (
            <Box sx={{ width: '100%' }}>
                {uploadButtonElement}
            </Box>
        );
    else
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
        );
}

export default FileUpload;