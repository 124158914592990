import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';

export default function DateTimeAdmin(props) {
  return (
    <DatePicker
      margin="normal"
      format="DD/MM/yyyy"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      {...props.config}
    />
  );
}
