import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, Grid, FormControl, InputLabel, Select, TextField, MenuItem, Checkbox, FormControlLabel, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { deleteData, getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData, writeData } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import * as Components from '../components/Components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HeightIcon from '@mui/icons-material/Height';
import { cloneDeep } from 'lodash';
import Spacer from '../common/Spacer';
import { getCurrentProtocol, getProtocolCurrentPage, getProtocolCurrentQuestion, setCurrentProtocol, setProtocolCurrentQuestion } from './ProtocolSlice';
import AdminProtocolPages from './AdminProtocolPages';
import Footer from '../appbar/Footer';
import ProtocolTypeLabelMap from '../common/ProtocolTypeLabelMap';
import ElementCopyDialog from './ElementCopyDialog';

function AdminProtocol(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const protocol = useSelector(getCurrentProtocol);
    const protocol_page = useSelector(getProtocolCurrentPage);
    let page = null;
    if (protocol.pages !== undefined)
        page = protocol.pages[protocol_page];

    const currentQuestion = useSelector(getProtocolCurrentQuestion);

    const [elements, setElements] = useState([]);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isCopyOpen, setIsCopyOpen] = useState(false);
    const [copyQuestion, setCopyQuestion] = useState({});
    const [deleteQuestionID, setDeleteQuestionID] = useState(0);

    const [listenerId, setListenerId] = useState("");

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        if(page !== null)
            getList('/protocols_pages/' + page.id, onElementsLoad);

        // cleanup function
        return () =>
        {
        };
    }, [protocol_page, protocol]);

    const onElementsLoad = (elements) =>
    {
        //setListenerId(startListeningChildrenChanged('/protocols_pages/' + page.id, onChildChange));
        console.log("onElementsLoad");

        elements.sort((a, b) => a.index - b.index);
        setElements(elements);
        //window.scrollTo(0, 0);
    };

    const selectQuestion = (questionIndex) =>
    {
        dispatch(setProtocolCurrentQuestion(questionIndex));
    }

    const onAnswer = (data) =>
    {
        console.log("Answering while editing is skipped");
        //updateData('/protocols_pages/' + page.id + '/' + data.index, { patientAnswer: data.answer }, () => console.log('Answer saved'));
    }

    const onSetProperty = (property, data) =>
    {
        let valueObj = {};
        valueObj[property] = data;
        updateData('/protocols_pages/' + page.id + '/' + currentQuestion, valueObj, () =>
        {
            console.log('Child property changed');
            getList('/protocols_pages/' + page.id, onElementsLoad);
        });
    }

    const onSetProtocolProperty = (property, data) =>
    {
        let valueObj = {};
        valueObj[property] = data;
        updateData('/protocols/' + protocol.id, valueObj, () => console.log('Protocol updated'));

        let newProtocol = cloneDeep(protocol);
        newProtocol[property] = data;
        dispatch(setCurrentProtocol(newProtocol));
    }

    const onElementCopyConfirm = (isConfirmed) =>
    {
        getList('/protocols_pages/' + page.id, onElementsLoad);
        setIsCopyOpen(false);
    }

    const onPatientDeleteConfirm = (isConfirmed) =>
    {
        console.log("is Delete Confirmed: " + isConfirmed);

        if (isConfirmed)
        {
            const data = cloneDeep(elements);
            data.splice(deleteQuestionID, 1);
            for (let index = 0; index < data.length; index++)
            {
                data[index].index = index;
            }

            writeData('/protocols_pages/' + page.id, { ...data }, () =>
            {
                console.log('Element deleted');
                getList('/protocols_pages/' + page.id, onElementsLoad);
            });
        }

        setIsDeleteOpen(false);
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex)
        {
            const data = cloneDeep(elements);
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            for (let index = 0; index < data.length; index++)
            {
                const element = data[index];
                element.index = index;
            }

            writeData('/protocols_pages/' + page.id, data, () =>
            {
                console.log('Elements order changed');
                getList('/protocols_pages/' + page.id, onElementsLoad);
            });
        },
        nodeSelector: 'span',
        handleSelector: 'a',
    };

    const onAddNewElementClick = () =>
    {
        const newPage = cloneDeep(elements);
        newPage.push({
            index: elements.length,
            question: "New Question",
            type: "shortanswer",
            answers: [{ index: 0, answer: '' }]
        });
        writeData('/protocols_pages/' + page.id, newPage, () => getList('/protocols_pages/' + page.id, onElementsLoad));
    }

    const [isShortView, setisShortView] = React.useState(false);

    const handleShortViewChange = (event) =>
    {
        setisShortView(event.target.checked);
    }

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            <ConfirmationDialog isOpen={isDeleteOpen} callback={onPatientDeleteConfirm} />
            <ElementCopyDialog isOpen={isCopyOpen} callback={onElementCopyConfirm} selectedQuestion={copyQuestion} protocol={protocol} pageIndex={protocol_page} />
            <Container maxWidth="lg">
                <Paper className="padding20">
                    <Stack direction="row" spacing={3} alignContent="center" justifyContent="space-between" alignItems="center">
                        <TextField
                            variant="outlined"
                            label="Ime protokola"
                            defaultValue={protocol.name}
                            onBlur={event => onSetProtocolProperty('name', event.target.value)}
                            fullWidth
                        />
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel id="type-select-label">Tip protokola</InputLabel>
                            <Select labelId="type-select-label" label="Type" value={protocol.type} onChange={event => onSetProtocolProperty('type', event.target.value)}>
                                {Object.keys(ProtocolTypeLabelMap).map(key =>
                                {
                                    return <MenuItem key={key} value={key}>{ProtocolTypeLabelMap[key]}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            label="AKTIVAN"
                            control={<Checkbox
                                checked={protocol.published}
                                onChange={event => onSetProtocolProperty('published', event.target.checked)}
                            />}
                        />
                    </Stack>
                </Paper>
                <Spacer />
                <Divider>Uredi strane protokola</Divider>
                <Spacer />
                <Paper className="padding20">
                    <AdminProtocolPages />
                </Paper>
                <Spacer />
                <Divider>Uredi izabranu stranu protokola</Divider>
                <FormControlLabel control={<Switch checked={isShortView}
                    onChange={handleShortViewChange} />} label="Prikaži sažeto" />
                <Spacer />
                <Typography align="center" variant="h4">
                    {page && page.name}
                </Typography>
                <Spacer />
                <ReactDragListView {...dragProps} >
                    {elements.length && elements.map(element => (
                        <span key={element.index + element.question} style={{ listStyleType: "none" }}>
                            <Paper style={{ padding: "20px", marginBottom: "20px" }}>
                                {currentQuestion != element.index && (
                                    <Question
                                        question={element}
                                        setDeleteQuestionID={setDeleteQuestionID}
                                        setIsDeleteOpen={setIsDeleteOpen}
                                        setIsCopyOpen={setIsCopyOpen}
                                        setCopyQuestion={setCopyQuestion}
                                        selectQuestion={selectQuestion}
                                        answer={onAnswer}
                                        isShortView={!isShortView}
                                    />
                                )}
                                {currentQuestion == element.index && (
                                    <AdminQuestion question={element} setProperty={onSetProperty} selectQuestion={selectQuestion} />
                                )}
                            </Paper>
                        </span>
                    ))}
                </ReactDragListView>
                <Spacer />
                <Button color="secondary" variant="contained" onClick={onAddNewElementClick}>Dodaj novi element</Button>
                <Spacer />
                <Spacer />
            </Container>
            <Footer />
        </div>
    );
}

function Question(props)
{
    const [isHover, setIsHover] = React.useState(false);

    const drawViewComponent = question =>
    {
        const config = { ...question };
        config.answer = props.answer;
        return Components.getComponentView(config);
    };

    return (
        <div
            style={{ position: 'relative' }}
            onClick={() => setIsHover(true)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <Typography variant="h6" gutterBottom>
                {props.question.question}
            </Typography>
            {props.isShortView && drawViewComponent(props.question)}
            {isHover && (
                <Paper style={{
                    backgroundColor: '#EEEEEE',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 280,
                    height: 38,
                }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <a href="#">
                            <Button>
                                <HeightIcon />
                            </Button>
                        </a>
                        <Button
                            onClick={() =>
                            {
                                props.selectQuestion(props.question.index);
                            }}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            onClick={() =>
                            {
                                props.setCopyQuestion(props.question);
                                props.setIsCopyOpen(true);
                            }}
                        >
                            <ContentCopyIcon />
                        </Button>
                        <Button
                            onClick={() =>
                            {
                                props.setDeleteQuestionID(props.question.index);
                                props.setIsDeleteOpen(true);
                            }}
                        >
                            <DeleteOutlineIcon />
                        </Button>
                    </Grid>
                </Paper>
            )}
        </div>
    );
}

function AdminQuestion(props)
{
    const drawAdminComponent = () =>
    {
        return Components.getComponentAdmin(props.question, props.setProperty);
    };

    return (
        <div style={{
            position: 'relative'
        }} >
            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                <Grid item>
                    <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <TextField
                                required
                                key={props.keyPrefix + 'text'}
                                label="Question"
                                placeholder="Enter question text"
                                variant="outlined"
                                fullWidth
                                defaultValue={props.question.question}
                                onBlur={event => props.setProperty('question', event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl variant="outlined">
                                <InputLabel id="outlined-label">Type</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="outlined-label"
                                    label="Type"
                                    defaultValue={props.question.type}
                                    onChange={event =>
                                    {
                                        props.setProperty('patientAnswer', '');
                                        props.setProperty('type', event.target.value);
                                    }}
                                >
                                    {Components.COMPONENTS_SELECT_LIST}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>{drawAdminComponent()}</Grid>
            </Grid>
            <Paper style={{
                border: 'solid',
                borderColor: '#888888',
                backgroundColor: '#CCCCCC',
                position: 'absolute',
                top: 0,
                right: 0,
                width: 65,
                height: 45,
            }}>
                <Button
                    onClick={() =>
                    {
                        props.selectQuestion(-1);
                    }}
                >
                    <HighlightOffIcon />
                </Button>
            </Paper>
        </div>
    );
}

export default withAuthentication(AdminProtocol);
