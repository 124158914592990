import { Button, FormControl, InputLabel, Select, MenuItem, Grid, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentPage } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

function PatientProtocolNavigation(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let protocol = useSelector(getPatientCurrentProtocol);
    let protocol_page = useSelector(getPatientCurrentPage);

    const handleChange = (value) =>
    {
        dispatch(setPatientProtocolCurrentPage(value));
    }   

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
        >
            <Button variant="outlined" disabled={protocol_page == -1} onClick={(event) => handleChange(protocol_page - 1)}><ArrowBackIcon /></Button>
            {props.showSelect && (
                <Select
                    labelId="select-page-label"
                    id="select-page"
                    value={protocol_page}
                    variant="standard"
                    onChange={(event) => handleChange(event.target.value)}
                    style={{ maxWidth: "70%", fontSize: '1.5rem' }}
                >
                    <MenuItem value={-1}>ZAPOČNI POSETU</MenuItem>
                    {protocol.pages && protocol.pages.map(page => (
                        <MenuItem key={page.name + page.index} value={page.index}>{page.name}</MenuItem>
                    ))}
                    {protocol.pages && <MenuItem value={protocol.pages.length}>ZAVRŠI POSETU</MenuItem>}
                </Select>)}
                {protocol.pages && <Button variant="outlined" disabled={protocol_page == protocol.pages.length} onClick={(event) => handleChange(protocol_page + 1)}><ArrowForwardIcon /></Button>}
            
        </Stack>
    );
}

export default PatientProtocolNavigation;
