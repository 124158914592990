import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, Grid, FormControl, InputLabel, Select, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData, writeData } from '../../app/firebase';
import moment from 'moment';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatientCurrentProtocol, setPatient, getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentQuestion, getPatientCurrentQuestion } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import * as Components from '../components/Components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HeightIcon from '@mui/icons-material/Height';
import { cloneDeep } from 'lodash';
import PatientProtocolNavigation from './PatientProtocolNavigation';
import Spacer from '../common/Spacer';
import Footer from '../appbar/Footer';
import PatientProtocolPage from './PatientProtocolPage';
import PatientProtocolStart from './PatientProtocolStart';
import PatientProtocolFinish from './PatientProtocolFinish';

function PatientProtocol(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const protocol = useSelector(getPatientCurrentProtocol);
    const protocol_page = useSelector(getPatientCurrentPage);

    const page = protocol.pages !== undefined ? protocol.pages[protocol_page] : undefined;
    const currentQuestion = useSelector(getPatientCurrentQuestion);

    let [content, setContent] = useState(<div>Loading...</div>);

    useEffect(() =>
    {
        if (page != undefined)
            setContent(<PatientProtocolPage />);
        else
            if (protocol_page == -1)
                setContent(<PatientProtocolStart />);
            else
                if (protocol_page == protocol.pages.length)
                    setContent(<PatientProtocolFinish />);

        // cleanup function
        return () =>
        {
            
        };
    }, [protocol_page]);

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            <Container maxWidth="lg">
                {content}
            </Container>
            <Footer />
        </div>
    );
}

export default withAuthentication(PatientProtocol);
