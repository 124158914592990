import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

export default function DropdownView(props) {
  const patientAnswer = props.patientAnswer === undefined ? '' : props.patientAnswer;

  const drawAnswers = () => {
    const answers = [];
    for (let index = 0; index < props.answers.length; index++) {
      const element = props.answers[index];
        answers.push(
            <MenuItem key={props.question + ':' + index} value={element.answer}>
          {element.answer}
        </MenuItem>,
      );
    }
    return answers;
  };

  return (
    <Select
      fullWidth
      labelId="outlined-label"
      value={patientAnswer}
      onChange={event => props.answer({ index: props.index, answer: event.target.value })}
    >
      {drawAnswers()}
    </Select>
  );
}

export function DropdownDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '')
      return <Grid container direction="row" justify="flex-start" alignItems="center" className="dontBreak">
      <Typography variant="body1">
        <b>{props.question}</b>
      </Typography>
      <Box/>
      <Typography variant="body2" display="inline">
        {props.patientAnswer}
      </Typography>
    </Grid>;
  else return null;
}
