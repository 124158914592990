import React from 'react';

export default function VideoView(props)
{
    if (props.videoURL == undefined) return <div></div>;

    const urlObj = new URL(props.videoURL);
    const params = new URLSearchParams(urlObj.search);
    const autoplay = props.autoplay ? '1' : '0';
    const controls = props.controls ? '1' : '0';
    const mute = props.mute ? '1' : '0';
    const loop = props.loop ? '1' : '0';
    const srcValue = "https://www.youtube.com/embed/" + params.get("v") + "?autoplay=" + autoplay + "&mute=" + mute + "&controls=" + controls + "&loop=" + loop;
    const height = props.height ? '800' : '500';

    return (
        <div>
            <iframe width="100%" height={height} style={{ border: "none" }}
                src={srcValue}>
            </iframe>
        </div>
    );
}

export function VideoDiagnosis(props)
{
    return null;
}
