import { Box, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';

export default function MultipleChoiceView(props) {
  const drawAnswers = () => {
      const answers = [];
      if (props.answers != undefined && props.answers.length != undefined)
    for (let index = 0; index < props.answers.length; index++) {
      const element = props.answers[index];
      answers.push(
          <FormControlLabel
              key={props.question + ':' + index}
          value={element.index}
          control={<Radio />}
          label={element.answer}
          checked={props.patientAnswer === index}
        />,
      );
    }
    return answers;
  };

  return (
    <RadioGroup aria-label="gender" name="gender1" onChange={event => props.answer({ index: props.index, answer: parseInt(event.target.value) })}>
      {drawAnswers()}
    </RadioGroup>
  );
}

export function MultipleChoiceDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '')
      return <Paper style={{ padding: 15 }} className="dontBreak">
      <Typography variant="body1">
        <b>{props.question}</b>
      </Typography>
      <Box/>
      <Typography variant="body2" display="inline">
        {props.answers[props.patientAnswer].answer}
      </Typography>
    </Paper>;
  else return null;
}
