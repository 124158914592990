import { Button, Grid, Radio, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React from 'react';

export default function MultipleChoiceAdmin(props) {
  const addAnswer = () => {
    props.answers.push({ index: props.answers.length, answer: '' });
    props.setProperty('answers', props.answers);
  };
  const deleteAnswer = index => {
    props.answers.splice(index, 1);
    for (let index = 0; index < props.answers.length; index++) {
      const element = props.answers[index];
      element.index = index;
    }
    props.setProperty('answers', props.answers);
  };
  const moveDown = index => {
    const prevItem = { ...props.answers[index] };
    const nextItem = { ...props.answers[index + 1] };
    prevItem.index += 1;
    nextItem.index -= 1;
    props.answers[index + 1] = prevItem;
    props.answers[index] = nextItem;
    props.setProperty('answers', props.answers);
  };
  const moveUp = index => {
    const prevItem = { ...props.answers[index] };
    const nextItem = { ...props.answers[index - 1] };
    prevItem.index -= 1;
    nextItem.index += 1;
    props.answers[index - 1] = prevItem;
    props.answers[index] = nextItem;
    props.setProperty('answers', props.answers);
  };
  const changeLabel = (index, value) => {
    props.answers[index].answer = value;
    props.setProperty('answers', props.answers);
  };

  const answers = () => {
    const output = [];

    if (props.answers != undefined && props.answers.length != undefined)
    for (let index = 0; index < props.answers.length; index++) {
      const answer = props.answers[index];
      output.push(
        <Grid item key={props.index + ':' + index}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item>
              <Radio />
            </Grid>
            <Grid item>
              <TextField
                required
                id="outlined-required"
                label="Answer"
                placeholder="Enter answer text"
                variant="outlined"
                fullWidth
                defaultValue={answer.answer}
                onBlur={event => changeLabel(answer.index, event.target.value)}
              />
            </Grid>
            <Grid item>
              <Toolbar answersLentgh={props.answers.length} answer={answer} delete={deleteAnswer} moveDown={moveDown} moveUp={moveUp} />
            </Grid>
          </Grid>
        </Grid>,
      );
    }
    return output;
  };

  return (
    <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
      {answers()}
      <Grid item>
        <Button onClick={() => addAnswer()}>
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
  );
}

function Toolbar(props) {
  return (
    <Grid item>
      <Button disabled={props.answer.index === 0 ? true : false} onClick={() => props.moveUp(props.answer.index)}>
        <ArrowUpwardIcon />
      </Button>
      <Button disabled={props.answer.index === props.answersLentgh - 1 ? true : false} onClick={() => props.moveDown(props.answer.index)}>
        <ArrowDownwardIcon />
      </Button>
      <Button onClick={() => props.delete(props.answer.index)}>
        <DeleteOutlineIcon />
      </Button>
    </Grid>
  );
}
