import * as React from 'react';
import { getDataAsync, getListOrderByChildEndBeforeLimit, updateData, writeData } from '../../app/firebase';
import { Button, FormControl, InputLabel, ListItemText, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientCurrentProtocol, setPatientCurrentProtocol } from './PatientSlice';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import ProtocolTypeLabelMap from '../common/ProtocolTypeLabelMap';
import { current } from '@reduxjs/toolkit';

export default function PatientProtocolPagesAdd()
{
    const protocol = useSelector(getPatientCurrentProtocol);
    const dispatch = useDispatch();

    const [protocolsList, setProtocolsList] = React.useState({});
    const [typeFilter, setTypeFilter] = React.useState(protocol.type);
    const [selectedProtocol, setSelectedProtocol] = React.useState("");
    const [selectedProgram, setSelectedProgram] = React.useState([]);

    React.useEffect(() =>
    {
        getProtocols();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const getProtocols = () =>
    {
        getListOrderByChildEndBeforeLimit('/protocols', setProtocols, 'name', 'z', 9999, false);
    }

    const setProtocols = (protocols) =>
    {
        let newProtocols = {};
        for (var i = 0; i < protocols.length; i++)
        {
            if (protocols[i].published)
                newProtocols[protocols[i].id] = protocols[i];
        }
        setProtocolsList(newProtocols);
    };

    const onAddPageClick = event =>
    {
        getDataAsync('/protocols_pages/' + protocolsList[selectedProtocol].pages[selectedProgram[0]].id, page => onProtocolPageLoaded(page, protocol, 0));
    }

    const onProtocolPageLoaded = (page, currentProtocol, currentPage) =>
    {
        let newPage = {};
        newPage.id = uuidv4();
        newPage.name = protocolsList[selectedProtocol].pages[selectedProgram[currentPage]].name;
        newPage.index = currentProtocol.pages != undefined ? currentProtocol.pages.length : 0;
        let newProtocol = cloneDeep(currentProtocol);
        
        if (newProtocol.pages == undefined)
            newProtocol.pages = [];
        
        newProtocol.pages.push(newPage);

        updateData('/patients_protocols/' + protocol.id, { pages: newProtocol.pages }, () =>
        {
            writeData('/patients_protocols_pages/' + newPage.id, page, () =>
            {
                let nextPage = currentPage + 1;
                if (nextPage < selectedProgram.length)
                {
                    getDataAsync('/protocols_pages/' + protocolsList[selectedProtocol].pages[selectedProgram[nextPage]].id, page => onProtocolPageLoaded(page, newProtocol, nextPage));
                }
                if (nextPage == selectedProgram.length)
                {
                    setSelectedProgram([]);
                    dispatch(setPatientCurrentProtocol(newProtocol));
                }
            });
        });
    }

    return (
        <Stack>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="type-select-label">Izaberi tip posete</InputLabel>
                <Select labelId="type-select-label" label="Izaberi tip posete" value={typeFilter} onChange={event =>
                {
                    setTypeFilter(event.target.value);
                    setSelectedProtocol("");
                    setSelectedProgram([]);
                }
                }>
                    {Object.keys(ProtocolTypeLabelMap).map(key =>
                    {
                        return <MenuItem key={key} value={key}>{ProtocolTypeLabelMap[key]}</MenuItem>;
                    })}
                    
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="protokol-select-label">Izaberi protokol</InputLabel>
                <Select labelId="protokol-select-label" label="Izaberi protokol" value={selectedProtocol} onChange={event =>
                {
                    setSelectedProtocol(event.target.value);
                    setSelectedProgram([]);
                }
                }>
                        {Object.keys(protocolsList).map(key =>
                        {
                            let protocol = protocolsList[key];
                            if (protocol.type !== typeFilter) return null;
                            return <MenuItem key={protocol.id} value={protocol.id}>{protocol.name}</MenuItem>;
                        })}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
            <Typography variant="caption">Izaberite programe:</Typography>
            <ToggleButtonGroup
                value={selectedProgram}
                onChange={(event, newPrograms) => setSelectedProgram(newPrograms)}
                orientation="vertical"
                color="secondary">
                {protocolsList[selectedProtocol] && protocolsList[selectedProtocol].pages.map(page =>
                {
                    return <ToggleButton sx={{justifyContent:'flex-start'}} key={page.id} value={page.index}>{page.name}</ToggleButton>;
                })}
                </ToggleButtonGroup>
            </FormControl>
            <Spacer />
            <Button variant="outlined" color="secondary" onClick={onAddPageClick} disabled={selectedProgram.length == 0}>Dodaj izabrane programe</Button>
        </Stack>
    );
}
