import { Box, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

export default function LongAnswerView(props) {
  const patientAnswer = props.patientAnswer === undefined ? '' : props.patientAnswer;
  return (
    <TextField
      variant="outlined"
      fullWidth
      multiline
      onBlur={event => props.answer({ index: props.index, answer: event.target.value })}
      defaultValue={patientAnswer}
    />
  );
}

export function LongAnswerDiagnosis(props)
{
  if (props.patientAnswer !== undefined && props.patientAnswer !== '')
      return (<Paper style={{ padding: 15 }} className="dontBreak">
          <Typography variant="body1" >
              <b>{props.question}</b>
          </Typography>
          <Box />
          {props.patientAnswer.split('\n').map(line =>
          {
              return (<Typography variant="body2" display="inline-block">
                  {line}
              </Typography>);
          })}
      </Paper>);
  else return null;
}
