import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, Grid, FormControl, InputLabel, Select, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData, writeData } from '../../app/firebase';
import moment from 'moment';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatientCurrentProtocol, setPatient, getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentQuestion, getPatientCurrentQuestion } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import * as Components from '../components/Components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HeightIcon from '@mui/icons-material/Height';
import { cloneDeep } from 'lodash';
import PatientProtocolNavigation from './PatientProtocolNavigation';
import Spacer from '../common/Spacer';
import Footer from '../appbar/Footer';

function PatientProtocolPage(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const protocol = useSelector(getPatientCurrentProtocol);
    const protocol_page = useSelector(getPatientCurrentPage);

    const page = protocol.pages[protocol_page];
    const currentQuestion = useSelector(getPatientCurrentQuestion);

    const [elements, setElements] = useState([]);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [deleteQuestionID, setDeleteQuestionID] = React.useState(0);

    var listenerId = "";

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        if (page != undefined)
        {
            setElements([]);
            getList('/patients_protocols_pages/' + page.id, onElementsLoad);
        }
        
        // cleanup function
        return () =>
        {
        };
    }, [protocol_page]);

    const onElementsLoad = (elements) =>
    {
        elements.sort((a, b) => a.index - b.index);
        setElements(elements);
    };

    const selectQuestion = (questionIndex) =>
    {
        dispatch(setPatientProtocolCurrentQuestion(questionIndex));
    }

    const onAnswer = (data) =>
    {
        updateData('/patients_protocols_pages/' + page.id + '/' + data.index, { patientAnswer: data.answer }, () => console.log('Answer saved'));

        let newElements = cloneDeep(elements);
        newElements[data.index].patientAnswer = data.answer;
        setElements(newElements);
    }

    const onSetProperty = (property, data) =>
    {
        let valueObj = {};
        valueObj[property] = data;
        updateData('/patients_protocols_pages/' + page.id + '/' + currentQuestion, valueObj, () => console.log('Answers updated'));
    }

    const onElementDeleteConfirm = (isConfirmed) =>
    {
        if (isConfirmed)
        {
            const data = cloneDeep(elements);
            data.splice(deleteQuestionID, 1);
            for (let index = 0; index < data.length; index++)
            {
                data[index].index = index;
            }

            stopListening(listenerId);
            writeData('/patients_protocols_pages/' + page.id, { ...data }, () =>
            {
                console.log('Element deleted');
                getList('/patients_protocols_pages/' + page.id, onElementsLoad);
            });
        }

        setIsDeleteOpen(false);
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex)
        {
            const data = cloneDeep(elements);
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            for (let index = 0; index < data.length; index++)
            {
                const element = data[index];
                element.index = index;
            }
            
            writeData('/patients_protocols_pages/' + page.id, data, console.log('Elements order changed'));
        },
        nodeSelector: 'span',
        handleSelector: 'a',
    };

    return (
        <Stack spacing={3}>
            <ConfirmationDialog isOpen={isDeleteOpen} callback={onElementDeleteConfirm} />
            <PatientProtocolNavigation showSelect />
            <ReactDragListView {...dragProps} >
                {page && elements && elements.map(element => (
                    <span key={page.id + element.index} style={{ listStyleType: "none"} }>
                        <Paper style={{padding:"15px", marginBottom:"20px"} }>
                            {currentQuestion != element.index && (
                                <Question
                                    key={"question-" + page.id + element.index}
                                    pageID={page.id}
                                    question={element}
                                    setDeleteQuestionID={setDeleteQuestionID}
                                    setIsDeleteOpen={setIsDeleteOpen}
                                    selectQuestion={selectQuestion}
                                    answer={onAnswer}
                                    userRole={user.role}
                                />
                            )}
                            {currentQuestion == element.index && (
                                <AdminQuestion
                                    pageID={page.id}
                                    question={element}
                                    setProperty={onSetProperty}
                                    selectQuestion={selectQuestion} />
                            )}
                        </Paper>
                    </span>
                ))}
            </ReactDragListView>
            <Spacer />
            <PatientProtocolNavigation showSelect />
            <Spacer />
            <Spacer />
        </Stack>
    );
}

function Question(props)
{
    const [isHover, setIsHover] = React.useState(false);

    const drawViewComponent = question =>
    {
        const config = { ...question };
        config.answer = props.answer;
        config.pageID = props.pageID;
        return Components.getComponentView(config);
    };

    return (
        <div
            style={{ position: 'relative'}}
            onClick={() => setIsHover(true)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <Typography variant="h6" gutterBottom>
                {props.question.question}
            </Typography>
            {drawViewComponent(props.question)}
            {props.userRole == "admin" && isHover && (
                <Paper style={{
                    backgroundColor: '#EEEEEE',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 200,
                    height: 38,
                } }>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <a href="#">
                            <Button>
                                <HeightIcon />
                            </Button>
                        </a>
                        <Button
                            onClick={() =>
                            {
                                props.selectQuestion(props.question.index);
                            }}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            onClick={() =>
                            {
                                props.setDeleteQuestionID(props.question.index);
                                props.setIsDeleteOpen(true);
                            }}
                        >
                            <DeleteOutlineIcon />
                        </Button>
                    </Grid>
                </Paper>
            )}
        </div>
    );
}

function AdminQuestion(props)
{
    const drawAdminComponent = () =>
    {
        const config = { ...props.question };
        config.pageID = props.pageID;
        return Components.getComponentAdmin(config, props.setProperty);
    };

    return (
        <div style={{
            position: 'relative'
        }} >
            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                <Grid item>
                    <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <TextField
                                required
                                key={props.keyPrefix + 'text'}
                                label="Question"
                                placeholder="Enter question text"
                                variant="outlined"
                                fullWidth
                                defaultValue={props.question.question}
                                onBlur={event => props.setProperty('question', event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl variant="outlined">
                                <InputLabel id="outlined-label">Type</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="outlined-label"
                                    label="Type"
                                    defaultValue={props.question.type}
                                    onBlur={event =>
                                    {
                                        props.setProperty('patientAnswer', '');
                                        props.setProperty('type', event.target.value);
                                    }}
                                >
                                    {Components.COMPONENTS_SELECT_LIST}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>{drawAdminComponent()}</Grid>
            </Grid>
            <Paper style={{
                border: 'solid',
                borderColor: '#888888',
                backgroundColor: '#CCCCCC',
                position: 'absolute',
                top: 0,
                right: 0,
                width: 65,
                height: 45,
            }}>
                <Button
                    onClick={() =>
                    {
                        props.selectQuestion(-1);
                    }}
                >
                    <HighlightOffIcon />
                </Button>
            </Paper>
        </div>
    );
}

export default withAuthentication(PatientProtocolPage);
