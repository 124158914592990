import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, TextField, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData } from '../../app/firebase';
import moment from 'moment';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatientCurrentProtocol, setPatient, getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentPage } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import { cloneDeep } from 'lodash';
import PatientProtocolNavigation from './PatientProtocolNavigation';
import PatientProtocolDiagnosis from './PatientProtocolDiagnosis';
import Spacer from '../common/Spacer';
import * as dayjs from 'dayjs';
import { DateTimeField } from '@mui/x-date-pickers';
import Footer from '../appbar/Footer';
import PatientProtocolPagesAdmin from './PatientProtocolPagesAdmin';
import PatientProtocolPagesAdd from './PatientProtocolPagesAdd';
import ProtocolTypeLabelMap from '../common/ProtocolTypeLabelMap';
import PatientProtocolMedicalRecords from './PatientProtocolMedicalRecords';
import FileUpload from './FileUpload';
import PatientPaymentOptions from './PatientPaymentOptions';

function PatientProtocolStart(props)
{
    const user = useSelector(getUser);
    const patient = useSelector(getPatient);
    const protocol = useSelector(getPatientCurrentProtocol);
    const protocol_page = useSelector(getPatientCurrentPage);

    const [localProtocol, setLocalProtocol] = useState(protocol);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    var listenerId = "";

    useEffect(() =>
    {
        // cleanup function
        return () =>
        {
            //stopListening(listenerId);
        };
    }, [protocol]);

    const [pageIndex, setPageIndex] = useState(protocol_page);
    const onPageChange = (newPage) =>
    {
        dispatch(setPatientProtocolCurrentPage(newPage));
        setPageIndex(newPage);
    }

    const updateProtocol = async (property, value) =>
    {
        let updateObj = {};
        updateObj[property] = value;
        updateData('/patients_protocols/' + localProtocol.id, updateObj, () => console.log('Protocol updated'));

        let newProtocol = cloneDeep(protocol);
        newProtocol[property] = value;
        dispatch(setPatientCurrentProtocol(newProtocol));
    };

    return (
            <Stack spacing={3}>
                <PatientProtocolNavigation onPageChange={onPageChange} showSelect />
                <Paper className="padding20">
                    <Stack direction="row" spacing={3} alignContent="center" justifyContent="space-between" alignItems="center">
                        <TextField
                            style={{ maxWidth: 180 }}
                            disabled
                            variant="outlined"
                            label="Tip posete"
                        defaultValue={ProtocolTypeLabelMap[localProtocol.type]}
                        />
                    <TextField
                            style={{ maxWidth: 65 }}
                            disabled
                            variant="outlined"
                            label="Serija"
                            defaultValue={localProtocol.protocol_number}    
                        />
                        <TextField
                            style={{ maxWidth: 85 }}
                            disabled
                            variant="outlined"
                            label="Dolazak"
                            defaultValue={localProtocol.protocol_round}
                        />
                        <DateTimeField
                            disabled
                            label="Datum"
                            disableFuture={true}
                            formatDensity="spacious"
                            format="DD/MM/YYYY HH:mm"
                            value={dayjs(localProtocol.date) || dayjs()}
                        />
                        <TextField
                        style={{ maxWidth: 150 }}
                        variant="outlined"
                        label="Plaćeno (RSD)"
                        defaultValue={localProtocol.paid_price}
                        onBlur={event => updateProtocol('paid_price', event.target.value)}
                        />
                    <PatientPaymentOptions defaultValue={localProtocol.payment_type} updateProtocol={updateProtocol} showLabel />
                    </Stack>
                </Paper>
                {localProtocol.type === 'exam' && (
                <Paper className="padding20">
                    <Typography variant="h5">Anamnesis</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        defaultValue={localProtocol.anamnesis}
                        onBlur={event => updateProtocol('anamnesis', event.target.value)}
                    />
                </Paper>)}
                {localProtocol.type === 'exam' && (<Paper className="padding20">
                <Typography variant="h5">Medicinska dokumentacija</Typography>
                <PatientProtocolMedicalRecords />
                {user.role == "admin" && (<FileUpload />)}
                </Paper>)}
                {user.role == "admin" && (
                <Paper className="padding20">
                    <Typography variant="h5">Dodaj programe za ovu posetu</Typography>
                    <Spacer />
                    <PatientProtocolPagesAdd />
                </Paper>)}
                {user.role == "admin" && (
                <Paper className="padding20">
                    <Typography variant="h5">Programi posete</Typography>
                    <Spacer />
                    <PatientProtocolPagesAdmin />
                </Paper>)}
            <Button variant="contained" color="secondary" size="large" disabled={protocol.pages == undefined} onClick={() => onPageChange(0)}>Započni posetu</Button>
                <Spacer />
                <Spacer />
                <Spacer />
                <Spacer />
                <Spacer />
            </Stack>
    );
}

export default withAuthentication(PatientProtocolStart);
