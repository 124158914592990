const icd = [
    {
        "icd": "G00",
        "rs": "Bakterijski meningitis, neklasifikovan na drugom mestu",
        "la": "Meningitis bacterialis, non alibi classificatis"
    },
    {
        "icd": "G000",
        "rs": "Hemofilusni meningitis",
        "la": "Meningitis haemophilosa"
    },
    {
        "icd": "G001",
        "rs": "Pneumokokni meningitis",
        "la": "Meningitis pneumococcica"
    },
    {
        "icd": "G002",
        "rs": "Streptokokni meningitis",
        "la": "Meningitis streptococcica"
    },
    {
        "icd": "G003",
        "rs": "Stafilokokni meningitis",
        "la": "Meningitis staphylococcica"
    },
    {
        "icd": "G008",
        "rs": "Ostali bakterijski meningitisi",
        "la": "Meningitis bacterialis alia"
    },
    {
        "icd": "G009",
        "rs": "Bakterijski meningitis, neoznačen",
        "la": "Meningitis bacterialis, non specificata"
    },
    {
        "icd": "G01",
        "rs": "Meningitis u bakterijskim bolestima, klasifikovanim na drugom mestu",
        "la": "Meningitis in morbis bacterialibus alibi classificatis"
    },
    {
        "icd": "G02",
        "rs": "Meningitis u drugim zaraznim i parazitarnim bolestima, klasifikovanim na drugom mestu",
        "la": "Meningitis in morbis infectivis et morbis parasitariis aliis, alibi classificatis"
    },
    {
        "icd": "G020",
        "rs": "Meningitis u virusnim bolestima, klasifikovanim na drugom mestu",
        "la": "Meningitis in morbis viralibus, alibi classificatis"
    },
    {
        "icd": "G021",
        "rs": "Meningitis u mikozama",
        "la": "Meningitis mycotica"
    },
    {
        "icd": "G028",
        "rs": "Meningitis u drugim označenim zaraznim i parazitarnim bolestima, klasifikovanim na drugom mestu",
        "la": "Meningitis in morbis infectivis et parasitariis aliis specificatis, alibi classificatis"
    },
    {
        "icd": "G03",
        "rs": "Meningitis zbog ostalih i neoznačenih uzroka",
        "la": "Meningitis propter causas alias et non specificatas"
    },
    {
        "icd": "G030",
        "rs": "Nepiogeni meningitis",
        "la": "Meningitis non pyogenes"
    },
    {
        "icd": "G031",
        "rs": "Hronični meningitis",
        "la": "Meningitis chronica"
    },
    {
        "icd": "G032",
        "rs": "Benigni rekurentni meningitis [Mollaret]",
        "la": "Meningitis benigna recidivans [Mollaret]"
    },
    {
        "icd": "G038",
        "rs": "Meningitis zbog drugih označenih uzroka",
        "la": "Meningitis propter causas specificatas alias"
    },
    {
        "icd": "G039",
        "rs": "Meningitis, neoznačen",
        "la": "Meningitis, non specificata"
    },
    {
        "icd": "G04",
        "rs": "Encefalitis, mijelitis i encefalomijelitis",
        "la": "Encephalitis, myelitis et encephalomyelitis"
    },
    {
        "icd": "G040",
        "rs": "Akutni diseminovani encefalitis",
        "la": "Encephalitis disseminata acuta"
    },
    {
        "icd": "G041",
        "rs": "Spastička tropska oduzetost nogu",
        "la": "Paraplegia spastica tropica"
    },
    {
        "icd": "G042",
        "rs": "Bakterijski meningoencefalitis i meningomijelitis, neklasifikovani na drugom mestu",
        "la": "Meningoencephalitis et meningomyelitis bacterialis, non alibi classificatis"
    },
    {
        "icd": "G048",
        "rs": "Drugi encefalitis, mijelitis i encefalomijelitis",
        "la": "Encephalitis, myelitis et encephalomyelitis alia"
    },
    {
        "icd": "G049",
        "rs": "Encefalitis, mijelitis i encefalomijelitis, neoznačen",
        "la": "Encephalitis, myelitis et encephalomyelitis, non specificata"
    },
    {
        "icd": "G05",
        "rs": "Encefalitis, mijelitis i encefalomijelitis u bolestima klasifikovanim na drugom mestu",
        "la": "Encephalitis, myelitis et encephalomyelitis in morbis alibi classificatis"
    },
    {
        "icd": "G050",
        "rs": "Encefalitis, mijelitis i encefalomijelitis u bakterijskim bolestima klasifikovanim na drugom mestu",
        "la": "Encephalitis, myelitis et encephalomyelitis in morbis bacterialibus alibi classificatis"
    },
    {
        "icd": "G051",
        "rs": "Encefalitis, mijelitis i encefalomijelitis u virusnim bolestima klasifikovanim na drugom mestu",
        "la": "Encephalitis, myelitis et encephalomyelitis in morbis viralibus alibi classificatis"
    },
    {
        "icd": "G052",
        "rs": "Encefalitis, mijelitis i encefalomijelitis u drugim zaraznim i parazitarnim bolestima klasifikovanim na drugom mestu",
        "la": "Encephalitis, myelitis et encephalomyelitis in morbus infectivis et parasitariis aliis alibi classificatis"
    },
    {
        "icd": "G058",
        "rs": "Encefalitis, mijelitis i encefalomijelitis u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Encephalitis, myelitis et encephalomyelitis in aliis morbis alibi classificatis"
    },
    {
        "icd": "G06",
        "rs": "Apsces i granulom u lobanji i kičmi",
        "la": "Abscessus intracranialis et intraspinalis et granuloma intracraniale et intraspinale"
    },
    {
        "icd": "G060",
        "rs": "Apsces i granulom u lobanji",
        "la": "Abscessus intracranialis et granuloma intracraniale"
    },
    {
        "icd": "G061",
        "rs": "Intraspinalni apsces i granulom",
        "la": "Abscesus intraspinalis et granuloma intraspinale"
    },
    {
        "icd": "G062",
        "rs": "Ekstraduralni i subduralni apsces, neoznačen",
        "la": "Abscessus extraduralis et abscessus subduralis, non specificatus"
    },
    {
        "icd": "G07",
        "rs": "Apsces i granulom u lobanji i kičmi u bolestima klasifikovanim na drugom mestu",
        "la": "Abscessus et granuloma intracraniale et intraspinale in morbis alibi classificatis"
    },
    {
        "icd": "G08",
        "rs": "Flebitis i tromboflebitis u lobanji i kičmi",
        "la": "Phlebitis et thrombophlebitis intracranialis et intraspinalis"
    },
    {
        "icd": "G09",
        "rs": "Posledice zapaljenjskih bolesti CNS-a",
        "la": "Sequelae morbi inflammatorii systematis nervosi centralis"
    },
    {
        "icd": "G10",
        "rs": "Huntingtonova bolest",
        "la": "Morbus Huntington"
    },
    {
        "icd": "G11",
        "rs": "Ataksija – nasledni poremećaj koordinacije pokreta",
        "la": "Ataxia hereditaria"
    },
    {
        "icd": "G110",
        "rs": "Urođena neprogresivna ataksija",
        "la": "Ataxia non progressiva congenita"
    },
    {
        "icd": "G111",
        "rs": "Cerebralna ataksija sa ranim početkom",
        "la": "Ataxia cerebellaris initio praecoccae"
    },
    {
        "icd": "G112",
        "rs": "Cerebralna ataksija sa kasnim početkom",
        "la": "Ataxia cerebellaris tardiva"
    },
    {
        "icd": "G113",
        "rs": "Malomoždana ataksija sa defektom DNK",
        "la": "Ataxia cerebellaris cum defectu DNA"
    },
    {
        "icd": "G114",
        "rs": "Nasledna spastička oduzetost obe noge",
        "la": "Paraplegia spastica hereditaria"
    },
    {
        "icd": "G118",
        "rs": "Druge nasledne ataksije",
        "la": "Ataxiae hereditariae aliae"
    },
    {
        "icd": "G119",
        "rs": "Nasledna ataksija, neoznačena",
        "la": "Ataxia hereditaria, non specificata"
    },
    {
        "icd": "G12",
        "rs": "Spinalna mišićina atrofija i srodni sindromi",
        "la": "Atrophia musculorum spinalis et syndromata affinia"
    },
    {
        "icd": "G120",
        "rs": "Werding-Hofmanova spinalna mišićina atrofija, tip 1",
        "la": "Atrophia musculorum spinalis infantilis, typus I (Werdnig-Hoffman]"
    },
    {
        "icd": "G121",
        "rs": "Druga nasledna spinalna mišićina atrofija",
        "la": "Atrophia musculorum spinalis hereditaria alia"
    },
    {
        "icd": "G122",
        "rs": "Bolest motornog neurona",
        "la": "Morbus neuroni motorii"
    },
    {
        "icd": "G128",
        "rs": "Druge spinalne mišićine atrofije i srodni sindromi",
        "la": "Atrophiae musculorum spinales aliae et syndromata affinia spinalia alia"
    },
    {
        "icd": "G129",
        "rs": "Spinalna mišićina atrofija, neoznačena",
        "la": "Atrophia musculorum spinalis, non specificata"
    },
    {
        "icd": "G13",
        "rs": "Sistemske atrofije koje pre svega zahvataju centralni nervni sistem u bolestima klasifikovanim na drugom mestu",
        "la": "Atrophiae systemicae praecipue systema nervosum centrale afficientes in morbis alibi classificatis"
    },
    {
        "icd": "G130",
        "rs": "Oboljenja živca i živca sa mišićima kod zloćudnih tumora",
        "la": "Neuropathia et neuromyopathia paraneoplastica"
    },
    {
        "icd": "G131",
        "rs": "Druge sistemske atrofije koje pre svega zahvataju centralni nervni sistem u neoplastičnim bolestima",
        "la": "Atrophiae systemicae praecipue systema nervosum centrale afficientes in morbis neoplastici aliae"
    },
    {
        "icd": "G132",
        "rs": "Sistemska atrofija koja pre svega zahvata centralni nervni sistem kod miksedema",
        "la": "Atrophia systemica praecipue systema nervosum centrale afficientes in myxoedema"
    },
    {
        "icd": "G138",
        "rs": "Sistemska atrofija koja pre svega zahvata centralni nervni sistem u drugim bolestima koje su klasifikovane na drugom mestu",
        "la": "Atrophia systemica praecipue systema nervosum centrale afficientes in morbis aliis alibi classificatis"
    },
    {
        "icd": "G14",
        "rs": "Sindrom posle infekcije polio virusom",
        "la": "Postpolio syndroma"
    },
    {
        "icd": "G20",
        "rs": "Parkinsonova bolest",
        "la": "Morbus Parkinson"
    },
    {
        "icd": "G21",
        "rs": "Sekundarni parkinsonizam",
        "la": "Parkinsonismus secundarius"
    },
    {
        "icd": "G210",
        "rs": "Težak neuroleptički sindrom",
        "la": "Syndroma neurolepticum malignum"
    },
    {
        "icd": "G211",
        "rs": "Drugi sekundarni parkinsonizam uzrokovan lekom",
        "la": "Parkinsonismus secundarius medicamentosus, alius"
    },
    {
        "icd": "G212",
        "rs": "Sekundarni parkinsonizam uzrokovan drugim spoljnim faktorima",
        "la": "Parkinsonismus secundarius propter factores externo, alios"
    },
    {
        "icd": "G213",
        "rs": "Parkinsonizam posle zapaljenja mozga",
        "la": "Parkinsonismus postencephaliticus"
    },
    {
        "icd": "G214",
        "rs": "Parkinsonizam uzrokovan oštećenjem krvnih sudova",
        "la": "Parkinsonismus vascularis"
    },
    {
        "icd": "G218",
        "rs": "Drugi sekundarni parkinsonizam",
        "la": "Parkinsonismus secundarius alius"
    },
    {
        "icd": "G219",
        "rs": "Sekundarni parkinsonizam, neoznačen",
        "la": "Parkinsonismus secundarius, non specificatus"
    },
    {
        "icd": "G22",
        "rs": "Parkinsonizam u bolestima klasifikovanim na drugom mestu",
        "la": "Parkinsonismus in morbis alibi classificatis"
    },
    {
        "icd": "G23",
        "rs": "Druga degenerativna oboljenja bazalnih ganglija",
        "la": "Degenerationes gangliorum basalium aliae"
    },
    {
        "icd": "G230",
        "rs": "Hallervorden-Spatzova bolest",
        "la": "Morbus Hallervorden-Spatz"
    },
    {
        "icd": "G231",
        "rs": "Steele-Richardson-Olszewskiova progresivna paraliza očnih mišića",
        "la": "Ophthalmoplegia supranuclearis progressiva [Steelo-Richardson-Olszewski]"
    },
    {
        "icd": "G232",
        "rs": "Degeneracija strijatonigralne ganglije",
        "la": "Degeneratio ganglii striatonigralis"
    },
    {
        "icd": "G238",
        "rs": "Druge specifične degeneracije bazalnih ganglija",
        "la": "Degenerationes gangliorum basalium specificatae, aliae"
    },
    {
        "icd": "G239",
        "rs": "Degeneracija bazalne ganglije, neoznačena",
        "la": "Degeneratio ganglii basalis, non specificata"
    },
    {
        "icd": "G24",
        "rs": "Distonija-poremećaj mišićnog tonusa",
        "la": "Dystonia"
    },
    {
        "icd": "G240",
        "rs": "Distonija uzrokovana lekom",
        "la": "Dystonia medicamentosa"
    },
    {
        "icd": "G241",
        "rs": "Idiopatska porodična distonija",
        "la": "Dystonia idiopathica familiaris"
    },
    {
        "icd": "G242",
        "rs": "Idiopatska neporodična distonija",
        "la": "Dystonia idiopathica non familiaris"
    },
    {
        "icd": "G243",
        "rs": "Grčevita krivošija",
        "la": "Torticollis spasmodica"
    },
    {
        "icd": "G244",
        "rs": "Idiopatska usno-lična distonija",
        "la": "Dystonia orofacialis idiopathica"
    },
    {
        "icd": "G245",
        "rs": "Grč očnih kapaka",
        "la": "Blepharospasmus"
    },
    {
        "icd": "G248",
        "rs": "Druga distonija",
        "la": "Dystonia alia"
    },
    {
        "icd": "G249",
        "rs": "Distonija, neoznačena",
        "la": "Dystonia, non specificata"
    },
    {
        "icd": "G25",
        "rs": "Druga vanpiramidna oboljenja i poremećaji kretanja",
        "la": "Affectiones extrapyramidales et disordines motionum alie"
    },
    {
        "icd": "G250",
        "rs": "Tremor – spontani ritmični nevoljni pokreti delova tela",
        "la": "Tremor essentiallis"
    },
    {
        "icd": "G251",
        "rs": "Tremor uzrokovan lekom",
        "la": "Tremor medicamentosus"
    },
    {
        "icd": "G252",
        "rs": "Drugi oblici specifičnog tremora",
        "la": "Formae tremoris specifici ailae"
    },
    {
        "icd": "G253",
        "rs": "Grč mišića",
        "la": "Myoclonus"
    },
    {
        "icd": "G254",
        "rs": "Horeja – nevoljni pokreti mišića uzrokovani lekom",
        "la": "Chorea medicamentosa"
    },
    {
        "icd": "G255",
        "rs": "Druga horeja",
        "la": "Chorea alia"
    },
    {
        "icd": "G256",
        "rs": "Tikovi - nevoljni pokreti, uzrokovani lekom i ostali tikovi organskog porekla",
        "la": "Tics medicamentosi et organici"
    },
    {
        "icd": "G258",
        "rs": "Druga označena vanpiramidna oboljenja i poremećaji kretanja",
        "la": "Affectiones extrapyramidales et disordines motionum specificatae aliae"
    },
    {
        "icd": "G259",
        "rs": "Vanpiramidno oboljenje i poremećaj kretanja, neoznačen",
        "la": "Affectio extrapyramidales et disordo motionum, non specificatus"
    },
    {
        "icd": "G26",
        "rs": "Vanpiramidna oboljenja i poremećaji kretanja u bolestima klasifikovanim na drugom mestu",
        "la": "Affectiones extrapyramidales et disordines motionum in morbis alibi classificatis"
    },
    {
        "icd": "G30",
        "rs": "Alzheimerova bolest",
        "la": "Morbus Alzheimer"
    },
    {
        "icd": "G300",
        "rs": "Rana Alzheimerova bolest",
        "la": "Morbus Alzheimer praecox"
    },
    {
        "icd": "G301",
        "rs": "Kasna Alzheimerova bolest",
        "la": "Morbus Alzheimer tardivus"
    },
    {
        "icd": "G308",
        "rs": "Druga Alzheimerova bolest",
        "la": "Morbus Alzheimer alius"
    },
    {
        "icd": "G309",
        "rs": "Alzheimerova bolest, neoznačena",
        "la": "Morbus Alzheimer, non specificatus"
    },
    {
        "icd": "G31",
        "rs": "Druge degenerativne bolesti nervnog sistema, neklasifikovane na drugom mestu",
        "la": "Morbi degenerativi systematis nervosi alii, non alibi clssificati"
    },
    {
        "icd": "G310",
        "rs": "Ograničeno isčezavanje tkiva mozga",
        "la": "Atrophia cerebri circumscripta"
    },
    {
        "icd": "G311",
        "rs": "Staračka degeneracija mozga, neklasifikovana na drugom mestu",
        "la": "Degeneratio cerebri senilis non alibi clssificatis"
    },
    {
        "icd": "G312",
        "rs": "Degeneracija nervnog sistema uzrokovana alkoholom",
        "la": "Degeneratio systematis nervosi alcoholica"
    },
    {
        "icd": "G318",
        "rs": "Druge označene degenerativne bolesti nervnog sistema",
        "la": "Morbi systematis nervosi degenerativi specificati, alii"
    },
    {
        "icd": "G319",
        "rs": "Degenerativna bolest nervnog sistema, neoznačena",
        "la": "Morbus systematis nervosi dagenerativus, non specificatus"
    },
    {
        "icd": "G32",
        "rs": "Druge degenerativne bolesti nervnog sistema u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi systematis nervosi degenerativi alii in morbis alibi classificatis"
    },
    {
        "icd": "G320",
        "rs": "Subakutna kombinovana degeneracija kičmene moždine u bolestima klasifikovanim na drugom mestu",
        "la": "Degeneratio medullae spinalis subacuta combinata in morbis alibi classificatis"
    },
    {
        "icd": "G328",
        "rs": "Druge označene bolesti nervnog sistema u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi systematis nervosi degenerativi specificati alii in morbis alibi classificatis"
    },
    {
        "icd": "G35",
        "rs": "Multipla skleroza",
        "la": "Sclerosis multiplex"
    },
    {
        "icd": "G36",
        "rs": "Druga akutna rasejana demijelinizacija",
        "la": "Demyelinisatio disseminata acuta alia"
    },
    {
        "icd": "G360",
        "rs": "Zapaljenje očnog živca i kičmene moždine [Devic]",
        "la": "Neuromyelitis optica [Devic]"
    },
    {
        "icd": "G361",
        "rs": "Akutno i subakutno hemoragičko zapaljenje bele mase mozga [Hurst]",
        "la": "Leucoencephalitis haemorrhagica acuta et subacuta [Hurst]"
    },
    {
        "icd": "G368",
        "rs": "Druga označena akutna rasejana demijelinizacija",
        "la": "Demyelinisatio disseminata acuta specificata alia"
    },
    {
        "icd": "G369",
        "rs": "Akutna rasejana demijelinizacija, neoznačena",
        "la": "Demyelinisatio disseminata acuta non specificata"
    },
    {
        "icd": "G37",
        "rs": "Druge demijelinizujuće bolesti CNS-a",
        "la": "Morbi demyelinantes systematis nervosi centralis, alii"
    },
    {
        "icd": "G370",
        "rs": "Difuzna skleroza mozga",
        "la": "Sclerosis cerebri diffusa"
    },
    {
        "icd": "G371",
        "rs": "Centralna demijelinizacija žuljevitog tela",
        "la": "Demyelinisatio corporis callosi centralis"
    },
    {
        "icd": "G372",
        "rs": "Centralna mijelinoliza ponsa",
        "la": "Myelinolysis pontis centralis"
    },
    {
        "icd": "G373",
        "rs": "Akutno poprečno zapaljenje kičmene moždine u demijelinizujućoj bolesti CNS-a",
        "la": "Myelitis transversa acuta in morbo demyelinisato sistematis nervosi centralis"
    },
    {
        "icd": "G374",
        "rs": "Subakutni nekrotizujući mijelitis",
        "la": "Myelitis necroticans subacuta"
    },
    {
        "icd": "G375",
        "rs": "Koncentrična skleroza mozga [Balo]",
        "la": "Sclerosis cerebri concentrica [Balo]"
    },
    {
        "icd": "G378",
        "rs": "Druge označene demijelinizujuće bolesti CNS-a",
        "la": "Morbi demyelinantes systematis nervosi centralis specificati, alii"
    },
    {
        "icd": "G379",
        "rs": "Demijelinizujuća bolest CNS-a, neoznačena",
        "la": "Morbus demyelinans systematis nervosi centralis, non specificatus"
    },
    {
        "icd": "G40",
        "rs": "Epilepsija – Padavica",
        "la": "Epilepsia"
    },
    {
        "icd": "G400",
        "rs": "Idiopatska žarišna epilepsija i epilepsijski sindromi sa žarišnim napadom",
        "la": "Epilepsia idiopathica (focalis) (partialis) et syndromata epileptica cum impetu focali"
    },
    {
        "icd": "G401",
        "rs": "Simptomatska žarišna epilepsija i epilepsijski sindromi sa jednostavnim parcijalnim napadom",
        "la": "Epilepsia idiopathica (focalis) (partialis) et syndromata epileptica cum impetu simplici partiali"
    },
    {
        "icd": "G402",
        "rs": "Simptomatska žarišna epilepsija i epilepsijski sindromi sa kompleksnim parcijalnim napadom",
        "la": "Epilepsia idiopathica (focalis) (partialis) et syndromata epileptica cum impetu complexo partiali"
    },
    {
        "icd": "G403",
        "rs": "Generalizovana idiopatska epilepsija i epilepsijski sindromi",
        "la": "Epilepsia idiopathica generalisata et syndromata epileptica"
    },
    {
        "icd": "G404",
        "rs": "Druga generalizovana epilepsija i epilepsijski sindromi",
        "la": "Epilepsia generalisata alia et syndromata epileptica"
    },
    {
        "icd": "G405",
        "rs": "Specifični epilepsijski sindromi",
        "la": "Syndromata epileptica specifica"
    },
    {
        "icd": "G406",
        "rs": "Veliki epileptični napad, neoznačen (sa malim napadom ili bez njega)",
        "la": "Epilepsia Grand mal, non specificata (cum Petit mal sive sine Petit mal)"
    },
    {
        "icd": "G407",
        "rs": "Mali epileptični napad, neoznačen, bez velikog napada",
        "la": "Petit mal, non specificatus, sine Grand mal"
    },
    {
        "icd": "G408",
        "rs": "Druga epilepsija",
        "la": "Epilepsia alia"
    },
    {
        "icd": "G409",
        "rs": "Epilepsija, neoznačena",
        "la": "Epilepsia, non specificata"
    },
    {
        "icd": "G41",
        "rs": "Epilepsijski status - neprekidan niz napada padavice",
        "la": "Status epilepticus"
    },
    {
        "icd": "G410",
        "rs": "Epilepsijski status, sa velikim napadima",
        "la": "Status epilepticus, Grand mal"
    },
    {
        "icd": "G411",
        "rs": "Epilepsijski status, sa malim napadima",
        "la": "Status epilepticus, Petit mal"
    },
    {
        "icd": "G412",
        "rs": "Epilepsijski status složenih delimičnih napada",
        "la": "Status epilepticus impetuum complexorum partialium"
    },
    {
        "icd": "G418",
        "rs": "Drugi epilepsijski status",
        "la": "Status epileptici alii"
    },
    {
        "icd": "G419",
        "rs": "Epilepsijski status, neoznačen",
        "la": "Status epilepticus, non specificatus"
    },
    {
        "icd": "G43",
        "rs": "Migrena – jednostrana glavobolja u napadima",
        "la": "Migraena"
    },
    {
        "icd": "G430",
        "rs": "Obična migrena, bez aure",
        "la": "Migraena simplex [sine aura]"
    },
    {
        "icd": "G431",
        "rs": "Klasična migrena, sa aurom",
        "la": "Migraena classica [cum aura]"
    },
    {
        "icd": "G432",
        "rs": "Migrenozni status",
        "la": "Status migraenosus"
    },
    {
        "icd": "G433",
        "rs": "Komplikovana migrena",
        "la": "Migraena complicata"
    },
    {
        "icd": "G438",
        "rs": "Druga migrena",
        "la": "Migraena alia"
    },
    {
        "icd": "G439",
        "rs": "Migrena, neoznačena",
        "la": "Migraena, non specificata"
    },
    {
        "icd": "G44",
        "rs": "Druge glavobolje",
        "la": "Syndromata cephalalgica alia"
    },
    {
        "icd": "G440",
        "rs": "Klaster glavobolja",
        "la": "Syndroma cephalalgica „cluster”"
    },
    {
        "icd": "G441",
        "rs": "Vaskularna glavobolja, neklasifikovana na drugom mestu",
        "la": "Cephalalgia vascularis, non alibi classificata"
    },
    {
        "icd": "G442",
        "rs": "Tenziona glavobolja",
        "la": "Cephalalgia tensionalis"
    },
    {
        "icd": "G443",
        "rs": "Hronična posttraumatska glavobolja",
        "la": "Cephalalgia posttraumatica chronica"
    },
    {
        "icd": "G444",
        "rs": "Glavobolja uzrokovana lekom, neklasifikovana na drugom mestu",
        "la": "Cephalalgia medicamentosa, non alibi classificata"
    },
    {
        "icd": "G448",
        "rs": "Druge označene glavobolje",
        "la": "Syndromata cephalalgica specificata, alia"
    },
    {
        "icd": "G45",
        "rs": "Prolazni cerebralni ishemijski napadi i srodni sindromi",
        "la": "Paroxysmi cerebrales ischaemici transitorii et syndromata affinia"
    },
    {
        "icd": "G450",
        "rs": "Sindrom vertebro-bazilarne arterije",
        "la": "Syndroma arteriae vertebrobasalis"
    },
    {
        "icd": "G451",
        "rs": "Sindrom glavne arterije vrata (hemisferični)",
        "la": "Syndroma arteriae carotidis (hemisphaericae)"
    },
    {
        "icd": "G452",
        "rs": "Obostrani i višestruki sindromi premoždanih arterija",
        "la": "Syndromata arteriarum praecerebralium bilateralia et multiplicia"
    },
    {
        "icd": "G453",
        "rs": "Kratkotrajno slepilo",
        "la": "Amaurosis fugax"
    },
    {
        "icd": "G454",
        "rs": "Prolazni potpuni gubitak pamćenja",
        "la": "Amnesia globalis transitiva"
    },
    {
        "icd": "G458",
        "rs": "Drugi prolazni cerebralni ishemijski napadi i srodni sindromi",
        "la": "Paroxysmi cerebrales ischaemici transitorii et syndromata affinia, alii"
    },
    {
        "icd": "G459",
        "rs": "Prolazni cerebralni ishemijski napadi, neoznačeni",
        "la": "Paroxysmi cerebrales ischaemici transitorii non specificati"
    },
    {
        "icd": "G46",
        "rs": "Sindromi krvnih sudova mozga kod cerebrovaskularnih bolesti",
        "la": "Syndromata vascularia cerebri in morbis cerebrovascularibus"
    },
    {
        "icd": "G460",
        "rs": "Sindrom srednje arterije mozga",
        "la": "Syndroma arteriae cerebri mediae"
    },
    {
        "icd": "G461",
        "rs": "Sindrom prednje arterije mozga",
        "la": "Syndroma arteriae cerebri anterioris"
    },
    {
        "icd": "G462",
        "rs": "Sindrom zadnje arterije mozga",
        "la": "Syndroma arteriae cerebri posterioris"
    },
    {
        "icd": "G463",
        "rs": "Sindrom apopleksije moždanog stabla",
        "la": "Syndroma apoplexiae trunci cerebri"
    },
    {
        "icd": "G464",
        "rs": "Sindrom apopleksije malog mozga",
        "la": "Syndroma apoplexiae cerebellaris"
    },
    {
        "icd": "G465",
        "rs": "Čisto lakunarni motorni sindrom",
        "la": "Syndroma lacunaris motoria purum"
    },
    {
        "icd": "G466",
        "rs": "Čisto lakunarni osetni sindrom",
        "la": "Syndroma lacunaris sensualis purum"
    },
    {
        "icd": "G467",
        "rs": "Drugi lakunarni sindromi",
        "la": "Syndromata lacunaria alia"
    },
    {
        "icd": "G468",
        "rs": "Drugi vaskularni sindromi mozga u bolestima krvnih sudova mozga",
        "la": "Syndromata vascularia cerebri alia in morbis cerebrovascularibus"
    },
    {
        "icd": "G47",
        "rs": "Poremećaji spavanja",
        "la": "Disordines somni"
    },
    {
        "icd": "G470",
        "rs": "Poremećaji zaspivanja i trajanja sna – nesanica",
        "la": "Insomnia"
    },
    {
        "icd": "G471",
        "rs": "Predugo spavanje",
        "la": "Hypersomnia"
    },
    {
        "icd": "G472",
        "rs": "Poremećaji ritma spavanja i budnosti",
        "la": "Disordines rhythmi somnii et virgiliae"
    },
    {
        "icd": "G473",
        "rs": "Apneja-povremeni prekid disanja pri spavanju",
        "la": "Apnoea ad somnum"
    },
    {
        "icd": "G474",
        "rs": "Iznenadni nastupi spavanja i gubitak tonusa mišića",
        "la": "Narcolepsia et cataplexia"
    },
    {
        "icd": "G478",
        "rs": "Drugi poremećaji spavanja",
        "la": "Disordines somni alii"
    },
    {
        "icd": "G479",
        "rs": "Poremećaj spavanja, neoznačen",
        "la": "Disordo somni, non specificatus"
    },
    {
        "icd": "G50",
        "rs": "Poremećaji trigeminalnog živca",
        "la": "Disordines nervi trigemini"
    },
    {
        "icd": "G500",
        "rs": "Neuralgija (bol u napadima) trigeminalnog živca",
        "la": "Neuralgia nervi trigemini"
    },
    {
        "icd": "G501",
        "rs": "Atipičan bol lica",
        "la": "Dolor facialis atypicus"
    },
    {
        "icd": "G508",
        "rs": "Drugi poremećaji trigeminalnog živca",
        "la": "Disordines nervi trigemini alii"
    },
    {
        "icd": "G509",
        "rs": "Poremećaj trigeminalnog živca, neoznačen",
        "la": "Disordo nervi trigemini, non specificatus"
    },
    {
        "icd": "G51",
        "rs": "Poremećaji facijalnog živca",
        "la": "Disordines nervi facialis"
    },
    {
        "icd": "G510",
        "rs": "Oduzetost facijalnog živca",
        "la": "Paralysis nervi facialis"
    },
    {
        "icd": "G511",
        "rs": "Zapaljenje kolenaste ganglije živca",
        "la": "Ganglionitis geniculata"
    },
    {
        "icd": "G512",
        "rs": "Melkerssonov sindrom",
        "la": "Syndroma Melkersson"
    },
    {
        "icd": "G513",
        "rs": "Trzajni grč polovine lica",
        "la": "Spasmus hemifacialis cionicus"
    },
    {
        "icd": "G514",
        "rs": "Talasasto grčenje lica",
        "la": "Myokymia facialis"
    },
    {
        "icd": "G518",
        "rs": "Drugi poremećaji živca lica",
        "la": "Disordines nervi facialis alii"
    },
    {
        "icd": "G519",
        "rs": "Poremećaj živca lica, neoznačen",
        "la": "Disordo nervi facialis, non specificatus"
    },
    {
        "icd": "G52",
        "rs": "Poremećaji drugih lobanjskih živaca",
        "la": "Disordines nervorum cranialium aliorum"
    },
    {
        "icd": "G520",
        "rs": "Poremećaji olfaktornog živca",
        "la": "Disordines nervi olfactorii"
    },
    {
        "icd": "G521",
        "rs": "Poremećaji glosofaringealnog živca",
        "la": "Disordines nervi glossopharyngici"
    },
    {
        "icd": "G522",
        "rs": "Poremećaji živca lutalice",
        "la": "Disordines nervi vagi"
    },
    {
        "icd": "G523",
        "rs": "Poremećaji hipoglosealnog živca",
        "la": "Disordines nervi hypoglossi"
    },
    {
        "icd": "G527",
        "rs": "Poremećaji više lobanjskih živaca",
        "la": "Disordines nervorum cranialium multiplices"
    },
    {
        "icd": "G528",
        "rs": "Poremećaji drugih označenih lobanjskih živaca",
        "la": "Disordines nervorum cranialium specificatum aliorum"
    },
    {
        "icd": "G529",
        "rs": "Poremećaj lobanjskog živca, neoznačen",
        "la": "Disordo nervi cranialis, non specificatus"
    },
    {
        "icd": "G53",
        "rs": "Poremećaji lobanjskih živaca u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines nervorum cranialium in morbis alibi classificatis"
    },
    {
        "icd": "G530",
        "rs": "Neuralgija - bol u napadima - posle herpesa zostera",
        "la": "Neuralgia postherpetica"
    },
    {
        "icd": "G531",
        "rs": "Mnogostruke oduzetosti lobanjskih živaca u zaraznim i parazitarnim bolestima klasifikovanim na drugom mestu",
        "la": "Paralyses nervorum cranialium multiplices in morbis infectivis et parasitariis in morbis alibi classificatis"
    },
    {
        "icd": "G532",
        "rs": "Mnogostruke oduzetosti lobanjskih živaca kod sarkoidoze",
        "la": "Paralyses nervorum cranialium multiplices ad sarcoidosim"
    },
    {
        "icd": "G533",
        "rs": "Mnogostruke oduzetosti lobanjskih živaca kod tumora",
        "la": "Paralyses nervorum cranialium multiploces ad neoplasma"
    },
    {
        "icd": "G538",
        "rs": "Drugi poremećaji lobanjskih živaca u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines nervorum cranialium alii in morbis alibi classificatis"
    },
    {
        "icd": "G54",
        "rs": "Oboljenja korenova i spletova živaca",
        "la": "Affectiones radicum et plexuum nervorum"
    },
    {
        "icd": "G540",
        "rs": "Oboljenje spleta živaca ruke",
        "la": "Plexopathia brachialis"
    },
    {
        "icd": "G541",
        "rs": "Oboljenje spleta živaca slabine i trtice",
        "la": "Plexopathia lumbosacralis"
    },
    {
        "icd": "G542",
        "rs": "Oboljenje korenova živaca vrata, neklasifikovano na drugom mestu",
        "la": "Radiculopathia cervicalis, non alibi classificata"
    },
    {
        "icd": "G543",
        "rs": "Oboljenje korenova živaca grudnog koša, neklasifikovano na drugom mestu",
        "la": "Radiculopathia thoracalis, non alibi classificata"
    },
    {
        "icd": "G544",
        "rs": "Oboljenje korenova živaca slabine i trtice, neklasifikovano na drugom mestu",
        "la": "Radiculopathia lumbosacralis, non alibi classificata"
    },
    {
        "icd": "G545",
        "rs": "Bolna atrofija mišića",
        "la": "Amyotrophia neuralgica"
    },
    {
        "icd": "G546",
        "rs": "Sindrom fantomskog uda sa bolom",
        "la": "Syndroma phantomatis membri cum dolore"
    },
    {
        "icd": "G547",
        "rs": "Sindrom fantomskog uda bez bola",
        "la": "Syndroma phantomatis membri sine dolore"
    },
    {
        "icd": "G548",
        "rs": "Druga oboljenja korenova i spletova živaca",
        "la": "Affectiones radicum et plexuum nervorum aliae"
    },
    {
        "icd": "G549",
        "rs": "Oboljenje korena i spleta živaca, neoznačeno",
        "la": "Affectio radicis et plexus nervi, non specificatus"
    },
    {
        "icd": "G55",
        "rs": "Pritisak na korenove i spletove živaca u bolestima klasifikovanim na drugom mestu",
        "la": "Compressio radicum et plexuum nervorum in morbis alibi classificatis"
    },
    {
        "icd": "G550",
        "rs": "Pritisak na korenove i spletove živaca kod tumora",
        "la": "Compressio radicum et plexuum nervorum ad neoplasma"
    },
    {
        "icd": "G551",
        "rs": "Pritisak na korene i spletove živaca kod poremećaja međupršljenskog diska",
        "la": "Compressio radicum et plexuum nervorum ad disordines disci intervertebralis"
    },
    {
        "icd": "G552",
        "rs": "Pritisak na korenove i spletove živaca kod degenerativnog oboljenja pršljenova",
        "la": "Compressio radicum et plexuum nervorum ad spondylosim"
    },
    {
        "icd": "G553",
        "rs": "Pritisci na korene i spletove živaca kod drugih oboljenja leđa",
        "la": "Compressiones radicum et plexuum nervorum ad dorsopathias alias"
    },
    {
        "icd": "G558",
        "rs": "Pritisci na korenove i spletove živaca u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Compressiones radicum et plexuum nervorum in morbis aliis alibi classificatis"
    },
    {
        "icd": "G56",
        "rs": "Oboljenja jednog živca ruke",
        "la": "Mononeuropathiae extremitatis superioris"
    },
    {
        "icd": "G560",
        "rs": "Sindrom karpalnog tunela",
        "la": "Syndroma canalis carpalis"
    },
    {
        "icd": "G561",
        "rs": "Druga oštećenja središnjeg živca",
        "la": "Laesiones nervi mediani aliae"
    },
    {
        "icd": "G562",
        "rs": "Oštećenje živca lakta",
        "la": "Laesio nervi ulnaris"
    },
    {
        "icd": "G563",
        "rs": "Oštećenje živca žbice",
        "la": "Laesio nervi radialis"
    },
    {
        "icd": "G564",
        "rs": "Osećaj pečenja",
        "la": "Causalgia"
    },
    {
        "icd": "G568",
        "rs": "Druga oboljenja jednog živca ruke",
        "la": "Mononeuropahtiae extremitatis superioris aliae"
    },
    {
        "icd": "G569",
        "rs": "Oboljenje jednog živca ruke, neoznačeno",
        "la": "Mononeuropathia extremitatis superioris, non specificata"
    },
    {
        "icd": "G57",
        "rs": "Oboljenja jednog živca noge",
        "la": "Mononeuropathiae extremitatis inferioris"
    },
    {
        "icd": "G570",
        "rs": "Oštećenje sedalnog živca",
        "la": "Laesio nervi ischiadici"
    },
    {
        "icd": "G571",
        "rs": "Parestezijski bolovi noge",
        "la": "Meralgia paraesthetica"
    },
    {
        "icd": "G572",
        "rs": "Oštećenje živca buta",
        "la": "Laesio nervi femoralis"
    },
    {
        "icd": "G573",
        "rs": "oštećenje spoljnjeg potkolenog živca",
        "la": "Laesio nervi peronaei"
    },
    {
        "icd": "G574",
        "rs": "Oštećenje srednjeg potkolenog živca",
        "la": "Laesio nervi sapheni"
    },
    {
        "icd": "G575",
        "rs": "Sindrom tunela noge",
        "la": "Syndroma canalis tarsalis"
    },
    {
        "icd": "G576",
        "rs": "Oštećenje živca tabana",
        "la": "Laesio nervi plantaris"
    },
    {
        "icd": "G578",
        "rs": "Druga oboljenje jednog živca noge",
        "la": "Mononeuropathiae extremitatis inferioris aliae"
    },
    {
        "icd": "G579",
        "rs": "Oboljenje jednog živca noge, neoznačeno",
        "la": "Mononeuropathia extremitatis inferioris, non specificata"
    },
    {
        "icd": "G58",
        "rs": "Druga pojedinačna oboljenja živaca",
        "la": "Mononeuropathiae aliae"
    },
    {
        "icd": "G580",
        "rs": "Oboljenje međurebarnog živca",
        "la": "Neuropathia intercostalis"
    },
    {
        "icd": "G587",
        "rs": "Zapaljenje više pojedinačnih živaca",
        "la": "Mononeuritis multiplex"
    },
    {
        "icd": "G588",
        "rs": "Druga označena pojedinačna oboljenja živaca",
        "la": "Mononeuropathiae, aliae specificatae"
    },
    {
        "icd": "G589",
        "rs": "Oboljenje jednog živca, neoznačeno",
        "la": "Mononeuropathia, non specificata"
    },
    {
        "icd": "G59",
        "rs": "Pojedinačno oboljenje živca u bolestima klasifikovanim na drugom mestu",
        "la": "Mononeuropathia in morbis alibi classificatis"
    },
    {
        "icd": "G590",
        "rs": "Oboljenje jednog živca kod šećerne bolesti",
        "la": "Mononeuropathia diabetica"
    },
    {
        "icd": "G598",
        "rs": "Druga oboljenja jednog živca u bolestima klasifikovanim na drugom mestu",
        "la": "Mononeuropathiae aliae in morbi alibi classificati"
    },
    {
        "icd": "G60",
        "rs": "Nasledno i idiopatsko oboljenje živaca",
        "la": "Neuropathia hereditaria et neuropathia idiopathica"
    },
    {
        "icd": "G600",
        "rs": "Nasledno oboljenje mišićnog i osetnog živca",
        "la": "Neurophatia motoria et sensualis hereditaria"
    },
    {
        "icd": "G601",
        "rs": "Refsumova bolest",
        "la": "Morbus Refsum"
    },
    {
        "icd": "G602",
        "rs": "Oboljenje živca sa naslednom diskordinacijom pokreta",
        "la": "Neuropathia cum ataxia hereditaria"
    },
    {
        "icd": "G603",
        "rs": "Idiopatsko progresivno oboljenje živca",
        "la": "Neuropathia idiopathica progressiva"
    },
    {
        "icd": "G608",
        "rs": "Druga nasledna i idiopatska oboljenja živaca",
        "la": "Neuropathiae hereditariae et idiopathicae aliae"
    },
    {
        "icd": "G609",
        "rs": "Nasledno i idiopatsko oboljenje živca, neoznačeno",
        "la": "Neuropathia hereditaria et idiopathica, non specificata"
    },
    {
        "icd": "G61",
        "rs": "Zapaljensko oboljenje više živaca",
        "la": "Polyneuropathia inflammatoria"
    },
    {
        "icd": "G610",
        "rs": "Guillan-Barreov sindrom",
        "la": "Syndroma Guillain-Barre"
    },
    {
        "icd": "G611",
        "rs": "Oboljenje živca uzrokovano serumom",
        "la": "Neuropathia serumica"
    },
    {
        "icd": "G618",
        "rs": "Druga zapaljenjska oboljenja živaca",
        "la": "Polyneuropathiae inflammatoriae aliae"
    },
    {
        "icd": "G619",
        "rs": "Zapaljensko oboljenje živaca, neoznačeno",
        "la": "Polyneuropathia inflammatoria, non specificata"
    },
    {
        "icd": "G62",
        "rs": "Druga oboljenja živaca",
        "la": "Polyneuropathiae aliae"
    },
    {
        "icd": "G620",
        "rs": "Oboljenje živaca uzrokovano lekom",
        "la": "Polyneuropathia medicamentosa"
    },
    {
        "icd": "G621",
        "rs": "Oboljenje živaca uzrokovano alkoholom",
        "la": "Polyneuropathia alcoholica"
    },
    {
        "icd": "G622",
        "rs": "Oboljenje živaca uzrokovano drugim toksičnim uzročnicima",
        "la": "Polyneuropathia propter factores toxicos alios"
    },
    {
        "icd": "G628",
        "rs": "Druga označena oboljenja živaca",
        "la": "Polyneuropathiae specificatae, aliae"
    },
    {
        "icd": "G629",
        "rs": "Oboljenje živaca, neoznačeno",
        "la": "Polyneuropthia, non specificata"
    },
    {
        "icd": "G63",
        "rs": "Oboljenje živaca u bolestima klasifikovanim na drugom mestu",
        "la": "Polyneuropathia in morbis alibi classificatis"
    },
    {
        "icd": "G630",
        "rs": "Oboljenje više živaca u zaraznim i parazitarnim bolestima klasifikovanim na drugom mestu",
        "la": "Polyneuropathia in morbis infectivis et parasitariis alibi classificatis"
    },
    {
        "icd": "G631",
        "rs": "Oboljenje živaca kod tumora",
        "la": "Polyneuroropathia ad neoplasma"
    },
    {
        "icd": "G632",
        "rs": "Oboljenje živaca kod šećerne bolesti",
        "la": "Polyneuropathia diabetica"
    },
    {
        "icd": "G633",
        "rs": "Oboljenje živaca u drugim bolestima žlezda sa unutrašnjim lučenjem i metaboličkim bolestima",
        "la": "Polyneuropathia in morbis endocrinis et metabolicis aliis"
    },
    {
        "icd": "G634",
        "rs": "Oboljenje živaca kod nedovoljne ishrane",
        "la": "Polyneuropathia ad defficientiam nutritionalem"
    },
    {
        "icd": "G635",
        "rs": "Oboljenje živaca u sistemskim poremećajima vezivnog tkiva",
        "la": "Polyneuropathia in disordinibus textus connexivi systemicis"
    },
    {
        "icd": "G636",
        "rs": "Oboljenje živaca u drugim mišićno-koštanim poremećajima",
        "la": "Polyneuropathia in disordinibus muscularis et osseis aliis"
    },
    {
        "icd": "G638",
        "rs": "Oboljenje živaca u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Polyneuropathia in morbis aliis alibi classificatis"
    },
    {
        "icd": "G64",
        "rs": "Drugi poremećaji perifernog nervnog sistema",
        "la": "Disordines sistematis nervosi peripherici alii"
    },
    {
        "icd": "G70",
        "rs": "Miastenija – teška slabost mišića i drugi mišićno-živčani poremećaji",
        "la": "Myasthenia gravis et disordines myoneurales alii"
    },
    {
        "icd": "G700",
        "rs": "Teška miastenija",
        "la": "Myasthenia gravis"
    },
    {
        "icd": "G701",
        "rs": "Toksički mišićno-živčani poremećaji",
        "la": "Disordines myoneurales toxici"
    },
    {
        "icd": "G702",
        "rs": "Nasledna progresivna miastenija",
        "la": "Myasthenia congenita evolutionalis"
    },
    {
        "icd": "G708",
        "rs": "Drugi označeni mišićno-živčani poremećaji",
        "la": "Disordines myoneurales specificati, alii"
    },
    {
        "icd": "G709",
        "rs": "Mišićno-živčani poremećaj, neoznačen",
        "la": "Disordo myoneuralis, non specificatus"
    },
    {
        "icd": "G71",
        "rs": "Primarni poremećaji mišića",
        "la": "Disordines musculorum primarii"
    },
    {
        "icd": "G710",
        "rs": "Distrofija mišića",
        "la": "Dystrophia musculorum"
    },
    {
        "icd": "G711",
        "rs": "Poremećaji izraženi sporim dekontrahovanjem mišića",
        "la": "Disordines myotonici"
    },
    {
        "icd": "G712",
        "rs": "Nasledno oboljenje mišića",
        "la": "Myopathia congenita"
    },
    {
        "icd": "G713",
        "rs": "Mitohondrijalno oboljenje mišića neklasifikovano na drugom mestu",
        "la": "Myopathia mitochoodrialis, non alibi classificata"
    },
    {
        "icd": "G718",
        "rs": "Drugi primarni poremećaji mišića",
        "la": "Disordines musculorum primarii alii"
    },
    {
        "icd": "G719",
        "rs": "Primarni poremećaj mišića, neoznačen",
        "la": "Disordo musculorum primarius, non specificatus"
    },
    {
        "icd": "G72",
        "rs": "Druge miopatije - oboljenja mišića",
        "la": "Myopathiae aliae"
    },
    {
        "icd": "G720",
        "rs": "Miopatija uzrokovana lekom",
        "la": "Myopathia medicamentosa"
    },
    {
        "icd": "G721",
        "rs": "Miopatija uzrokovana alkoholom",
        "la": "Myopathia alcoholica"
    },
    {
        "icd": "G722",
        "rs": "Druga miopatija uzrokovana toksinima",
        "la": "Myopathia propter factores toxicos alia"
    },
    {
        "icd": "G723",
        "rs": "Povremena oduzetost",
        "la": "Paralysis periodica"
    },
    {
        "icd": "G724",
        "rs": "Zapaljenjska miopatija neklasifikovana na drugom mestu",
        "la": "Myopathia inflammatoria non alibi classificata"
    },
    {
        "icd": "G728",
        "rs": "Druge označene miopatije",
        "la": "Myopathiae specificatae, aliae"
    },
    {
        "icd": "G729",
        "rs": "Miopatija, neoznačena",
        "la": "Myopathia, non specificata"
    },
    {
        "icd": "G73",
        "rs": "Bolesti mišićno-nervne spojnice i mišića u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines connexio myoneurales et musculorum in morbis alibi classificatis"
    },
    {
        "icd": "G730",
        "rs": "Sindromi miastenije u endokrinim bolestima",
        "la": "Syndromata myasthenicum in morbis endocrinis"
    },
    {
        "icd": "G731",
        "rs": "Eaton-Lambertov sindrom",
        "la": "Syndroma Lambert-Eaton"
    },
    {
        "icd": "G732",
        "rs": "Sindromi miastenije kod tumora",
        "la": "Syndromata myasthenicum ad neoplasma"
    },
    {
        "icd": "G733",
        "rs": "Sindromi miastenije u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Syndromata myasthenicum in morbis aliis alibi classificatis"
    },
    {
        "icd": "G734",
        "rs": "Miopatija u zaraznim i parazitarnim bolestima klasifikovanim na drugom mestu",
        "la": "Myopathia in morbis infectivis et parasitaris alibi classificatis"
    },
    {
        "icd": "G735",
        "rs": "Miopatija u endokrinim bolestima",
        "la": "Myopathia in morbis endocrinis"
    },
    {
        "icd": "G736",
        "rs": "Miopatija u bolestima metabolizma",
        "la": "Myopathia in morbis metabolicis"
    },
    {
        "icd": "G737",
        "rs": "Miopatija u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Myopathia in morbis aliis alibi classificatis"
    },
    {
        "icd": "G80",
        "rs": "Cerebralna paraliza",
        "la": "Paralysis cerebralis"
    },
    {
        "icd": "G800",
        "rs": "Spastička kvadriplegična cerebralna paraliza",
        "la": "Paralysis cerebralis spastica quadriplegica"
    },
    {
        "icd": "G801",
        "rs": "Spastička diplegična cerebralna paraliza",
        "la": "Paralysis cerebralis spastica diaplegica"
    },
    {
        "icd": "G802",
        "rs": "Spastička hemiplegična cerebralna paraliza",
        "la": "Paralysis cerebralis spastica hemiplegica"
    },
    {
        "icd": "G803",
        "rs": "Diskinetička cerebralna paraliza",
        "la": "Paralysis cerebralis dyskinetica"
    },
    {
        "icd": "G804",
        "rs": "Ataksična cerebralna paraliza",
        "la": "Paralysis cerebralis ataxica"
    },
    {
        "icd": "G808",
        "rs": "Druga cerebralna paraliza",
        "la": "Paralysis cerebralis alia"
    },
    {
        "icd": "G809",
        "rs": "Cerebralna paraliza, neoznačena",
        "la": "Paralysis cerebralis, non specificata"
    },
    {
        "icd": "G81",
        "rs": "Jednostrana paraliza - oduzetost",
        "la": "Hemiplegia"
    },
    {
        "icd": "G810",
        "rs": "Mlitava jednostrana paraliza",
        "la": "Hemiplegia flaccida"
    },
    {
        "icd": "G811",
        "rs": "Spastička (grčevita) jednostrana paraliza",
        "la": "Hemiplegia spastica"
    },
    {
        "icd": "G819",
        "rs": "Jednostrana paraliza, neoznačena",
        "la": "Hemiplegia, non specificata"
    },
    {
        "icd": "G82",
        "rs": "Simetrična paraliza - oduzetost obe ruke ili obe noge i svih udova",
        "la": "Paraplegia et tetraplegia"
    },
    {
        "icd": "G820",
        "rs": "Mlitava paraliza dva simetrična uda",
        "la": "Paraplegia flaccida"
    },
    {
        "icd": "G821",
        "rs": "Spastička (grčevita) paraliza dva simetrična uda",
        "la": "Paraplegia spastica"
    },
    {
        "icd": "G822",
        "rs": "Simetrična paraliza udova, neoznačena",
        "la": "Paraplegia, non specificata"
    },
    {
        "icd": "G823",
        "rs": "Mlitava paraliza svih udova",
        "la": "Tetraplegia flaccida"
    },
    {
        "icd": "G824",
        "rs": "Spastička (grčevita) paraliza svih udova",
        "la": "Tetraplegia spastica"
    },
    {
        "icd": "G825",
        "rs": "Paraliza svih udova, neoznačena",
        "la": "Tetraplegia, non specificata"
    },
    {
        "icd": "G83",
        "rs": "Druge paralize",
        "la": "Paralyses aliae"
    },
    {
        "icd": "G830",
        "rs": "Paraliza obe ruke",
        "la": "Diplegia extremitatum superiorum"
    },
    {
        "icd": "G831",
        "rs": "Paraliza jedne noge",
        "la": "Monoplegia extremitatis inferioris"
    },
    {
        "icd": "G832",
        "rs": "Paraliza jedne ruke",
        "la": "Monoplegia extremitatis superioris"
    },
    {
        "icd": "G833",
        "rs": "Paraliza jednog uda, neoznačena",
        "la": "Monoplegia, non specificata"
    },
    {
        "icd": "G834",
        "rs": "Sindrom \"konjskog repa\"",
        "la": "Syndroma caudae equinae"
    },
    {
        "icd": "G838",
        "rs": "Drugi označeni paralitički sindromi",
        "la": "Syndromata paralytica specificata, alia"
    },
    {
        "icd": "G839",
        "rs": "Paralitički sindrom, neoznačen",
        "la": "Syndroma paralyticum, non specificatum"
    },
    {
        "icd": "G90",
        "rs": "Poremećaji autonomnog nervnog sistema",
        "la": "Disordines systematis nervosi autonomi"
    },
    {
        "icd": "G900",
        "rs": "Idiopatsko periferno autonomno oboljenje živca",
        "la": "Neuropathia autonoma peripherica idiopathica"
    },
    {
        "icd": "G901",
        "rs": "Riley-Dayeva porodična disfunkcija autonomnog nervnog sistema",
        "la": "Dysautonomia familiaris [Riley-Day]"
    },
    {
        "icd": "G902",
        "rs": "Hornerov sindrom",
        "la": "Syndroma Horner"
    },
    {
        "icd": "G903",
        "rs": "Višesistemska degeneracija",
        "la": "Degeneratio multisystemica"
    },
    {
        "icd": "G904",
        "rs": "Autonomna disrefleksija",
        "la": "Dysreflexia autonomica"
    },
    {
        "icd": "G908",
        "rs": "Drugi poremećaji autonomnog nervnog sistema",
        "la": "Disordines systematis nervosi autonomi alii"
    },
    {
        "icd": "G909",
        "rs": "Poremećaj autonomnog nervnog sistema, neoznačen",
        "la": "Disordo systematis nervosi autonomi, non specificatus"
    },
    {
        "icd": "G91",
        "rs": "Hidrocefalus – patološki povećana količina moždane tečnosti",
        "la": "Hydrocephalus"
    },
    {
        "icd": "G910",
        "rs": "Komunicirajući hidrocefalus",
        "la": "Hydrocephalus communicans"
    },
    {
        "icd": "G911",
        "rs": "Opstruktivni hidrocefalus",
        "la": "Hydrocephalus obstructivus"
    },
    {
        "icd": "G912",
        "rs": "Hidrocefalus sa normalnim pritiskom",
        "la": "Hydrocephalus cum tensione normali"
    },
    {
        "icd": "G913",
        "rs": "Posttraumatski hidrocefalus, neoznačen",
        "la": "Hydrocephalus posttraumaticus, non specificatus"
    },
    {
        "icd": "G918",
        "rs": "Drugi hidrocefalus",
        "la": "Hydrocephalus alius"
    },
    {
        "icd": "G919",
        "rs": "Hidrocefalus, neoznačen",
        "la": "Hydrocephalus, non specificatus"
    },
    {
        "icd": "G92",
        "rs": "Toksičko oboljenje mozga",
        "la": "Encephalopathia toxica"
    },
    {
        "icd": "G93",
        "rs": "Drugi poremećaji mozga",
        "la": "Disordines cerebri alii"
    },
    {
        "icd": "G930",
        "rs": "Cista mozga",
        "la": "Cystis cerebri"
    },
    {
        "icd": "G931",
        "rs": "Oštećenje mozga uzrokovano nedostatkom kiseonika neklasifikovano na drugom mestu",
        "la": "Laesio cerebri anoxica, non alibi classificata"
    },
    {
        "icd": "G932",
        "rs": "Benigna interkranijalna hipertenzija",
        "la": "Hypertensio intracranialis benigna"
    },
    {
        "icd": "G933",
        "rs": "Sindrom zamora posle infekcije virusima",
        "la": "Syndroma fatigationis postviralis"
    },
    {
        "icd": "G934",
        "rs": "Oboljenje mozga, neoznačeno",
        "la": "Encephalopathia, non specificata"
    },
    {
        "icd": "G935",
        "rs": "Kompresija mozga",
        "la": "Compressio cerebri"
    },
    {
        "icd": "G936",
        "rs": "Edem mozga",
        "la": "Oedema cerebri"
    },
    {
        "icd": "G937",
        "rs": "Reyeov sindrom",
        "la": "Syndroma Reye"
    },
    {
        "icd": "G938",
        "rs": "Drugi označeni poremećaji mozga",
        "la": "Disordines cerebri specificati, alii"
    },
    {
        "icd": "G939",
        "rs": "Poremećaj mozga, neoznačen",
        "la": "Disordo cerebri, non specificatus"
    },
    {
        "icd": "G94",
        "rs": "Drugi poremećaji mozga u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines cerebri alii in morbis alibi classificati"
    },
    {
        "icd": "G940",
        "rs": "Hidrocefalus u zaraznim i parazitarnim bolestima klasifikovanim na drugom mestu",
        "la": "Hydrocephalus in morbis infectivis et parazitariis alibi classificatis"
    },
    {
        "icd": "G941",
        "rs": "Hidrocefalus kod tumora",
        "la": "Hydrocephalus ad neoplasma"
    },
    {
        "icd": "G942",
        "rs": "Hidrocefalus u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Hydrocephalus in morbis aliis alibi classificatis"
    },
    {
        "icd": "G948",
        "rs": "Drugi označeni poremećaji mozga u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines cerebri specificati alii in morbis alibi classificatis"
    },
    {
        "icd": "G95",
        "rs": "Drugi poremećaji kičmene moždine",
        "la": "Disorders medullae spinalis alii"
    },
    {
        "icd": "G950",
        "rs": "Oboljenje centralnog kanala kičmene i produžene moždine",
        "la": "Syringomyelia et syringobulbia"
    },
    {
        "icd": "G951",
        "rs": "Vaskularno oboljenje kičmene moždine",
        "la": "Myelopathia vascularis"
    },
    {
        "icd": "G952",
        "rs": "Kompresija kičmene moždine, neoznačena",
        "la": "Compressio medullae spinalis, non specificata"
    },
    {
        "icd": "G958",
        "rs": "Druga označena bolest kičmene moždine",
        "la": "Morbus medullae spinalis specificatus, alius"
    },
    {
        "icd": "G959",
        "rs": "Bolest kičmene moždine, neoznačena",
        "la": "Morbus medullae spinalis, non specificatus"
    },
    {
        "icd": "G96",
        "rs": "Drugi poremećaji CNS-a",
        "la": "Disordines systematis nervosi centralis alii"
    },
    {
        "icd": "G960",
        "rs": "Curenje kičmenomoždinske tečnosti",
        "la": "Liquorrhoea"
    },
    {
        "icd": "G961",
        "rs": "Bolesti moždanica neklasifikovane na drugom mestu",
        "la": "Disordines meningium, non alibi classificati"
    },
    {
        "icd": "G968",
        "rs": "Drugi označeni poremećaji CNS-a",
        "la": "Disordines systematis nervosi centralis specificati, alii"
    },
    {
        "icd": "G969",
        "rs": "Poremećaj CNS-a, neoznačen",
        "la": "Disordo systematis nervosi centralis, non specificatus"
    },
    {
        "icd": "G97",
        "rs": "Poremećaji nervnog sistema posle medicinskih intervencija, neklasifikovani na drugom mestu",
        "la": "Disordines systematis nervosi postprocedurales, non alibi classificatae"
    },
    {
        "icd": "G970",
        "rs": "Curenje kičmenomoždinske tečnosti kod punkcije",
        "la": "Liquorrhoea cerebrospinalis puncturalis"
    },
    {
        "icd": "G971",
        "rs": "Druga reakcija na kičmeno-krsnu punkciju",
        "la": "Reactio ad punctionem spinolumbalem alia"
    },
    {
        "icd": "G972",
        "rs": "Smanjen unutarlobanjski pritisak kod drenaže komora mozga",
        "la": "Hypotensio intracranialis ad shunting ventricularem"
    },
    {
        "icd": "G978",
        "rs": "Drugi poremećaji nervnog sistema posle medicinskih intervencija",
        "la": "Disordines systematis nervosi postprocedurales alii"
    },
    {
        "icd": "G979",
        "rs": "Poremećaj nervnog sistema posle medicinskih intervencija, neoznačen",
        "la": "Disordo systematis nervosi postproceduralis, non specificatus"
    },
    {
        "icd": "G98",
        "rs": "Druge poremećaji nervnog sistema neklasifikovani na drugom mestu",
        "la": "Disordines systematis nervosi alii non alibi classificati"
    },
    {
        "icd": "G99",
        "rs": "Drugi poremećaji nervnog sistema u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines systematis nervosi alii in morbis alibi classificatis"
    },
    {
        "icd": "G990",
        "rs": "Autonomna neuropatija u bolestima žlezda sa unutrašnjim lučenjem i bolestima metabolizma",
        "la": "Neuropathia autonomica in morbis endocrinis et metabolicis"
    },
    {
        "icd": "G991",
        "rs": "Drugi poremećaji autonomnog nervnog sistema u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Disordines systematis nervosi autonomi alii in morbis aliis alibi classificatis"
    },
    {
        "icd": "G992",
        "rs": "Oboljenje kičmene moždine u bolestima klasifikovanim na drugom mestu",
        "la": "Myelopathia in morbis alibi classificatis"
    },
    {
        "icd": "G998",
        "rs": "Drugi označeni poremećaji nervnog sistema u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines systematis nervosi specificati alii in morbis alibi classificatis"
    },
    {
        "icd": "J00",
        "rs": "Akutno zapaljenje nosnog dela ždrela [obična prehlada]",
        "la": "Nasopharyngitis acuta"
    },
    {
        "icd": "J01",
        "rs": "Akutno zapaljenje sinusa",
        "la": "Sinusitis acuta"
    },
    {
        "icd": "J010",
        "rs": "Akutno zapaljenje sinusa gornje vilice",
        "la": "Sinusitis maxillaris acuta"
    },
    {
        "icd": "J011",
        "rs": "Akutno zapaljenje sinusa čela",
        "la": "Sinusitis frontalis acuta"
    },
    {
        "icd": "J012",
        "rs": "Akutno zapaljenje sinusa sitaste kosti",
        "la": "Sinusitis ehmoidalis acuta"
    },
    {
        "icd": "J013",
        "rs": "Akutno zapaljenje sinusa klinaste kosti",
        "la": "Sinusitis sphenoidalis acuta"
    },
    {
        "icd": "J014",
        "rs": "Akutno zapaljenje svih sinusa",
        "la": "Pansinusitis acuta"
    },
    {
        "icd": "J018",
        "rs": "Drugo akutno zapaljenje sinusa",
        "la": "Sinusitis acuta alia"
    },
    {
        "icd": "J019",
        "rs": "Akutno zapaljenje sinusa, neoznačeno",
        "la": "Sinusitis acuta, non specificata"
    },
    {
        "icd": "J02",
        "rs": "Akutno zapaljenje ždrela",
        "la": "Pharyngitis acuta"
    },
    {
        "icd": "J020",
        "rs": "Zapaljenje ždrela uzrokovano streptokokom",
        "la": "Pharyngitis streptococcica"
    },
    {
        "icd": "J028",
        "rs": "Akutno zapaljenje ždrela uzrokovano drugim označenim mikroorganizmima",
        "la": "Pharyngitis acuta propter microorganismos specificatos alios"
    },
    {
        "icd": "J029",
        "rs": "Akutno zapaljenje ždrela, neoznačeno",
        "la": "Pharyngitis acuta, non specificata"
    },
    {
        "icd": "J03",
        "rs": "Akutno zapaljenje krajnika",
        "la": "Tonsillitis acuta"
    },
    {
        "icd": "J030",
        "rs": "Zapaljenje krajnika uzrokovano streptokokom",
        "la": "Tonsillitis streptococcica"
    },
    {
        "icd": "J038",
        "rs": "Akutno zapaljenje krajnika uzrokovano drugim označenim mikroorganizmima",
        "la": "Tonsillitis acuta propter organismos specificatos alios"
    },
    {
        "icd": "J039",
        "rs": "Akutno zapaljenje krajnika, neoznačeno",
        "la": "Tonsillitis acuta, non specificata"
    },
    {
        "icd": "J04",
        "rs": "Akutno zapaljenje grkljana i akutno zapaljenje dušnika",
        "la": "Laryngitis acuta et tracheitis acuta"
    },
    {
        "icd": "J040",
        "rs": "Akutno zapaljenje grkljana",
        "la": "Laryngitis acuta"
    },
    {
        "icd": "J041",
        "rs": "Akutno zapaljenje dušnika",
        "la": "Tracheitis acuta"
    },
    {
        "icd": "J042",
        "rs": "Akutno zapaljenje grkljana i dušnika",
        "la": "Laryngotracheitis acuta"
    },
    {
        "icd": "J05",
        "rs": "Akutno opstruktivno zapaljenje grkljana [krup] i zapaljenje poklopca grkljana",
        "la": "Laryngitis obstructiva acuta [croup] et epiglottitis acuta"
    },
    {
        "icd": "J050",
        "rs": "Akutno opstruktivno zapaljenje grkljana [krup]",
        "la": "Laryngitis obstructiva acuta [croup]"
    },
    {
        "icd": "J051",
        "rs": "Akutno zapaljenje poklopca grkljana",
        "la": "Epiglottitis acuta"
    },
    {
        "icd": "J06",
        "rs": "Akutne infekcije gornjeg dela sistema za disanje multiplih i neoznačenih lokalizacija",
        "la": "Infectiones tractus respiratorii superioris acutae, locis multis et non specificatis"
    },
    {
        "icd": "J060",
        "rs": "Akutno zapaljenje grkljana i ždrela",
        "la": "Laryngopharyngitis acuta"
    },
    {
        "icd": "J068",
        "rs": "Druge akutne infekcije gornjeg dela sistema za disanje multiplih lokalizacija",
        "la": "Infectiones tractus respiratorii superioris acutae locis multis, alii"
    },
    {
        "icd": "J069",
        "rs": "Akutne infekcije gornjeg dela sistema za disanje, neoznačene",
        "la": "Infectiones tractus respiratorii superioris acutae, non specificatae"
    },
    {
        "icd": "J09",
        "rs": "Grip, virus dokazan",
        "la": "Influenza, virus identificatum"
    },
    {
        "icd": "J10",
        "rs": "Grip, izazvan dugim identifikovanim virusom gripa",
        "la": "Influenza, virus influencae aliud identificatum"
    },
    {
        "icd": "J100",
        "rs": "Grip sa zapaljenjem pluća, uzrokovan drugim identifikovanim virusom gripa",
        "la": "Influenza cum pneumonia, virus influenca aliud identificatum"
    },
    {
        "icd": "J101",
        "rs": "Grip sa drugim disajnim manifestacijama, uzrokovan drugim dokazanim virusom gripa",
        "la": "Influenza cum symptomatis respiratoriis allis, virus influenzae aliud identificatum"
    },
    {
        "icd": "J108",
        "rs": "Grip sa drugim smetnjama, uzrokovan drugim dokazanim virusom gripa",
        "la": "Influenza cum symptomatis allis, virus influenzae aliud identificatum"
    },
    {
        "icd": "J11",
        "rs": "Grip, virus nedokazan",
        "la": "Influenza, virus non identificatum"
    },
    {
        "icd": "J110",
        "rs": "Grip sa zapaljenjem pluća, virus nedokazan",
        "la": "Influenza cum pneumonia, virus non identificatum"
    },
    {
        "icd": "J111",
        "rs": "Grip sa drugim respiratornim smetnjama, virus neidentifikovan",
        "la": "Influenza cum symptomatis respiratoriis aliis, virus non identificatum"
    },
    {
        "icd": "J118",
        "rs": "Grip sa drugim smetnjama, virus neidentifikovan",
        "la": "Influenza cum symptomatis aliis, virus non identificatum"
    },
    {
        "icd": "J12",
        "rs": "Virusno zapaljenje pluća, neklasifikovano na drugom mestu",
        "la": "Pneumonia viralis, non alibi classificata"
    },
    {
        "icd": "J120",
        "rs": "Zapaljenje pluća uzrokovano adenovirusima",
        "la": "Pneumonia adenoviralis"
    },
    {
        "icd": "J121",
        "rs": "Zapaljenje pluća uzrokovano respiratornim sincicijalnim virusima",
        "la": "Pneumonia viralis syncytialis"
    },
    {
        "icd": "J122",
        "rs": "Zapaljenje pluća uzrokovano virusima parainfluenze",
        "la": "Pneumonia viralis parainfluenzae"
    },
    {
        "icd": "J123",
        "rs": "Virusno zapaljenje pluća uzrokovano humanim meta pneumovirusom",
        "la": "Pneumonia viralis hMPV"
    },
    {
        "icd": "J128",
        "rs": "Zapaljenje pluća uzrokovano drugim virusima",
        "la": "Pneumonia viralis, alia"
    },
    {
        "icd": "J129",
        "rs": "Zapaljenje pluća uzrokovano virusom, neoznačeno",
        "la": "Pneumonia viralis, non specificata"
    },
    {
        "icd": "J13",
        "rs": "Zapaljenje pluća, uzrokovano Streptococcus-om pneumoniae (Pneumococcus)",
        "la": "Pneumonia per Streptococcum pneumoniae"
    },
    {
        "icd": "J14",
        "rs": "Zapaljenje pluća uzrokovano Haemophilusom influence",
        "la": "Pneumonia per Haemophilum influenzae"
    },
    {
        "icd": "J15",
        "rs": "Bakterijsko zapaljenje pluća, neklasifikovano na drugom mestu",
        "la": "Pneumonia bacterialis, non alibi classificata"
    },
    {
        "icd": "J150",
        "rs": "Zapaljenje pluća, uzrokovano Klebsielom pneumonije",
        "la": "Pneumonia per Klebsiellam pneumoniae"
    },
    {
        "icd": "J151",
        "rs": "Zapaljenje pluća uzrokovano Pseudomonasom",
        "la": "Pneumonia per Pseudomonadem"
    },
    {
        "icd": "J152",
        "rs": "Stafilokokno zapaljenje pluća",
        "la": "Pneumonia staphylococcica"
    },
    {
        "icd": "J153",
        "rs": "Streptokokno zapaljenje pluća, uzrokovano Streptokokusom grupe B",
        "la": "Pneumonia streptococcica B"
    },
    {
        "icd": "J154",
        "rs": "Zapaljenja pluća uzrokovana drugim streptokokama",
        "la": "Pneumonia streptococcica alia"
    },
    {
        "icd": "J155",
        "rs": "Zapaljenje pluća, uzrokovano Ešerihijom koli",
        "la": "Pneumonia per Escherichiam coli"
    },
    {
        "icd": "J156",
        "rs": "Zapaljenje pluća uzrokovano aerobnim Gram-negativnim bakterijama",
        "la": "Pneumonia bacterialis, gram-negativa-aerobica"
    },
    {
        "icd": "J157",
        "rs": "Zapaljenje pluća uzrokovano Mikoplazmom pneumonije",
        "la": "Pneumonia per Mycoplasmam pneumoniae"
    },
    {
        "icd": "J158",
        "rs": "Drugo bakterisko zapaljenje pluća",
        "la": "Pneumonia bacterialis alia"
    },
    {
        "icd": "J159",
        "rs": "Bakterijsko zapaljenje pluća, neoznačeno",
        "la": "Pneumonia bacterialis, non specificata"
    },
    {
        "icd": "J16",
        "rs": "Zapaljenje pluća uzrokovano drugim zaraznim organizmima, neklasifikovano na drugom mestu",
        "la": "Pneumonia propter organismos infectivos alios, non alibi classificata"
    },
    {
        "icd": "J160",
        "rs": "Zapaljenje pluća uzrokovano hlamidijama",
        "la": "Pneumonia chlamydialis"
    },
    {
        "icd": "J168",
        "rs": "Zapaljenje pluća uzrokovano drugim označenim zaraznim organizmima",
        "la": "Pneumonia propter organismos infectivos alios specificatos"
    },
    {
        "icd": "J17",
        "rs": "Zapaljenje pluća u bolestima klasifikovanim na drugom mestu",
        "la": "Pneumonia in morbis alibi classificatis"
    },
    {
        "icd": "J170",
        "rs": "Zapaljenje pluća u bakterijskim bolestima klasifikovanim na drugom mestu",
        "la": "Pneumonia in morbis bacterialibus alibi classificatis"
    },
    {
        "icd": "J171",
        "rs": "Zapaljenje pluca u virusnim bolestima klasifikovanim na drugom mestu",
        "la": "Pneumonia in morbis viralibus alibi classificatis"
    },
    {
        "icd": "J172",
        "rs": "Zapaljenje pluća u gljivičnim bolestima",
        "la": "Pneumonia in morbis mycoticis"
    },
    {
        "icd": "J173",
        "rs": "Zapaljenje pluća u parazitarnim bolestima",
        "la": "Pneumonia in morbis parasitariis"
    },
    {
        "icd": "J178",
        "rs": "Zapaljenje pluća u drugim bolestima, koje su klasifikovane na drugom mestu",
        "la": "Pneumonia in morbis aliis alibi classificatis"
    },
    {
        "icd": "J18",
        "rs": "Zapaljenje pluća uzrokovano neoznačenim mikroorganizom",
        "la": "Pneumonia per microorganismum non specificatum"
    },
    {
        "icd": "J180",
        "rs": "Zapaljenje bronhija i pluća, neoznačeno",
        "la": "Bronchopneumonia, non specificata"
    },
    {
        "icd": "J181",
        "rs": "Lobarna pneumonija, neoznačena",
        "la": "Pneumonia lobaris, non specificata"
    },
    {
        "icd": "J182",
        "rs": "Zapaljenje pluća usled staze, neoznačeno",
        "la": "Pneumonia hypostatica, non specificata"
    },
    {
        "icd": "J188",
        "rs": "Druge vrste zapaljenja pluća uzrokovane neoznačenim mikroorganizmom",
        "la": "Pneumonia alia per microorganismum non specificatum"
    },
    {
        "icd": "J189",
        "rs": "Zapaljenje pluća, neoznačeno",
        "la": "Pneumonia, non specificata"
    },
    {
        "icd": "J20",
        "rs": "Akutni bronhitis",
        "la": "Bronchitis acuta"
    },
    {
        "icd": "J200",
        "rs": "Akutni bronhitis, uzročnik Mycoplasma pneumoniae",
        "la": "Bronchitis per Mycoplasma pneumoniae acuta"
    },
    {
        "icd": "J201",
        "rs": "Akutni bronhitis, uzročnik Haemophillus influenzae",
        "la": "Bronchitis per Haemophillum Influenzae acuta"
    },
    {
        "icd": "J202",
        "rs": "Akutni bronhitis uzrokovan streptokokama",
        "la": "Bronchitis streptococcica acuta"
    },
    {
        "icd": "J203",
        "rs": "Akutni bronhitis, uzročnik Coxsackie virus",
        "la": "Bronchitis coxsackieviralis acuta"
    },
    {
        "icd": "J204",
        "rs": "Akutni bronhitis uzrokovan virusom parainfluence",
        "la": "Bronchitis parainfluenzoviralis acuta"
    },
    {
        "icd": "J205",
        "rs": "Akutni bronhitis uzrokovan respiratornim sincicijalnim virusom",
        "la": "Bronchitis syncytioviralis acuta"
    },
    {
        "icd": "J206",
        "rs": "Akutni bronhitis uzrokovan rinovirusom",
        "la": "Bronchitis rhinoviralis acuta"
    },
    {
        "icd": "J207",
        "rs": "Akutni bronhitis uzrokovan ehovirusom",
        "la": "Bronchitis echoviralis acuta"
    },
    {
        "icd": "J208",
        "rs": "Akutni bronhitis uzrokovan drugim označenim mikroorganizmima",
        "la": "Bronchitis acuta propter microorganismos alios, specificatos"
    },
    {
        "icd": "J209",
        "rs": "Akutni bronhitis, neoznačen",
        "la": "Bronchitis acuta, non specificata"
    },
    {
        "icd": "J21",
        "rs": "Akutni bronhiolitis",
        "la": "Bronchiolitis acuta"
    },
    {
        "icd": "J210",
        "rs": "Akutni bronhiolitis uzrokovan respiratornim sincicijalnim virusom",
        "la": "Bronchiolitis syncytioviralis acuta"
    },
    {
        "icd": "J211",
        "rs": "Akutni bronhiolitis uzrokovan humanim metapneumovirusom",
        "la": "Bronchiolitis hMPV acuta"
    },
    {
        "icd": "J218",
        "rs": "Akutni bronhiolitis uzrokovan drugim označenim mikroorganizmima",
        "la": "Bronchiolitis acuta propter microorganismos alios, specificatos"
    },
    {
        "icd": "J219",
        "rs": "Akutni bronhiolitis, neoznačen",
        "la": "Bronchiolitis acuta, non specificata"
    },
    {
        "icd": "J22",
        "rs": "Akutna infekcija donjih respiratornih puteva, neoznačena",
        "la": "Infectio respiratoria systematis inferioris acuta, non specificata"
    },
    {
        "icd": "J30",
        "rs": "Vazomotorna kijavica i alergijska kijavica",
        "la": "Rhinitis vasomotoria et rhinitis allergica"
    },
    {
        "icd": "J300",
        "rs": "Vazomotorna kijavica",
        "la": "Rhinitis vasomotoria"
    },
    {
        "icd": "J301",
        "rs": "Alergijska kijavica uzrokovana polenom",
        "la": "Rhinitis allergica pollinosa"
    },
    {
        "icd": "J302",
        "rs": "Druga sezonska alergijska kijavica",
        "la": "Rhinitis allergica saesonalis alia"
    },
    {
        "icd": "J303",
        "rs": "Druga alergijska kijavica",
        "la": "Rhinitis allergica alia"
    },
    {
        "icd": "J304",
        "rs": "Alergijska kijavica, neoznačena",
        "la": "Rhinitis allergica, non specificata"
    },
    {
        "icd": "J31",
        "rs": "Hronična kijavica, zapaljenje nosnog dela ždrela i ždrela",
        "la": "Rhinitis, nasopharingitis et pharyngitis chronica"
    },
    {
        "icd": "J310",
        "rs": "Hronična kijavica",
        "la": "Rhinitis chronica"
    },
    {
        "icd": "J311",
        "rs": "Hronično zapaljenje nosnog dela ždrela",
        "la": "Nasopharyngitis chronica"
    },
    {
        "icd": "J312",
        "rs": "Hronično zapaljenje ždrela",
        "la": "Pharyngitis chronica"
    },
    {
        "icd": "J32",
        "rs": "Hronično zapaljenje sinusa",
        "la": "Sinusitis chronica"
    },
    {
        "icd": "J320",
        "rs": "Hronično zapaljenje sinusa gornje vilice",
        "la": "Sinusitis maxillaris chronica"
    },
    {
        "icd": "J321",
        "rs": "Hronično zapaljenje čeonih sinusa",
        "la": "Sinusitis frontalis chronica"
    },
    {
        "icd": "J322",
        "rs": "Hronično zapaljenje sinusa sitaste kosti",
        "la": "Sinusitis ethmoidalis chronica"
    },
    {
        "icd": "J323",
        "rs": "Hronično zapaljenje sinusa klinaste kosti",
        "la": "Sinusitis sphenoidalis chronica"
    },
    {
        "icd": "J324",
        "rs": "Hronično zapaljenje svih sinusa",
        "la": "Pansinusitis chronica"
    },
    {
        "icd": "J328",
        "rs": "Drugo hronično zapaljenje sinusa",
        "la": "Sinusitis chronica alia"
    },
    {
        "icd": "J329",
        "rs": "Hronično zapaljenje sinusa, neoznačeno",
        "la": "Sinusitis chronica, non specificata"
    },
    {
        "icd": "J33",
        "rs": "Polip nosa – peteljkast izraštaj sluznice nosa",
        "la": "Polypus nasalis"
    },
    {
        "icd": "J330",
        "rs": "Polip nosne šupljine",
        "la": "Polypus cavi nasi"
    },
    {
        "icd": "J331",
        "rs": "Polipoidna degeneracija sinusa",
        "la": "Degeneratio sinus nasi polypoidea"
    },
    {
        "icd": "J338",
        "rs": "Drugi vid sinusnih polipa",
        "la": "Polypi sinus alius"
    },
    {
        "icd": "J339",
        "rs": "Polip nosa, neoznačen",
        "la": "Polypus nasalis non specificatus"
    },
    {
        "icd": "J34",
        "rs": "Drugi poremećaji nosa i sinusa nosa",
        "la": "Disordines nasi et sinuum nasi alii"
    },
    {
        "icd": "J340",
        "rs": "Apsces, čir i grupa čireva nosa",
        "la": "Abscessus, furunculus et carbunculus nasi"
    },
    {
        "icd": "J341",
        "rs": "Cista i sluzna cista sinusa nosa",
        "la": "Cystis et mucocele sinuum nasi"
    },
    {
        "icd": "J342",
        "rs": "Iskrivljenost pregrade nosa",
        "la": "Deviatio septi nasi"
    },
    {
        "icd": "J343",
        "rs": "Povecanje školjki nosa",
        "la": "Hypertrophia concharum nasalium"
    },
    {
        "icd": "J348",
        "rs": "Drugi označeni poremećaji nosa i sinusa nosa",
        "la": "Disordines nasi et sinuum nasi alii, specificati"
    },
    {
        "icd": "J35",
        "rs": "Hronične bolesti krajnika i trećeg krajnika",
        "la": "Morbi tonsillarum et vegetationum adenoidium chronici"
    },
    {
        "icd": "J350",
        "rs": "Hronično zapaljenje krajnika",
        "la": "Tonsillitis chronica"
    },
    {
        "icd": "J351",
        "rs": "Uvecanje krajnika",
        "la": "Hypertrophio tonsillarum"
    },
    {
        "icd": "J352",
        "rs": "Treći krajnik",
        "la": "Hyperthrophio adenoides"
    },
    {
        "icd": "J353",
        "rs": "Uvećanje krajnika sa uvećanjem trećeg krajnika",
        "la": "Hypertrophio tonsillarum cum vegetatione adenoidium"
    },
    {
        "icd": "J358",
        "rs": "Druge hronične bolesti krajnika i trećeg krajnika",
        "la": "Morbi tonsillarum et vegetationum adenoidium chronici alii"
    },
    {
        "icd": "J359",
        "rs": "Hronična bolest krajnika i trećeg krajnika, neoznačena",
        "la": "Morbus tonsillarum et vegetationum adenoidium chronicus, non specificatus"
    },
    {
        "icd": "J36",
        "rs": "Apsces oko krajnika",
        "la": "Abscessus peritonsillaris"
    },
    {
        "icd": "J37",
        "rs": "Hronično zapaljenje grkljana i grkljana sa dušnikom",
        "la": "Laryngitis et laryngotracheitis chronica"
    },
    {
        "icd": "J370",
        "rs": "Hronično zapaljenje grkljana",
        "la": "Laryngitis chronica"
    },
    {
        "icd": "J371",
        "rs": "Hronično zapaljenje grkljana i dušnika",
        "la": "Laryngotracheitis chronica"
    },
    {
        "icd": "J38",
        "rs": "Bolesti glasnica i grkljana, neklasifikovane na drugom mestu",
        "la": "Morbi plicarum vocalium et laryngis, non alibi classificati"
    },
    {
        "icd": "J380",
        "rs": "Paraliza glasnica i grkljana",
        "la": "Paralysis plicarum vocalium et laryngis"
    },
    {
        "icd": "J381",
        "rs": "Polip glasnice i grkljana",
        "la": "Polypus plicae vocalis et laryngis"
    },
    {
        "icd": "J382",
        "rs": "Čvorici glasnica",
        "la": "Noduli plicarum vocalium"
    },
    {
        "icd": "J383",
        "rs": "Druge bolesti glasnica",
        "la": "Morbi plicarum vocalium alii"
    },
    {
        "icd": "J384",
        "rs": "Edem grkljana",
        "la": "Oedema laryngis"
    },
    {
        "icd": "J385",
        "rs": "Grč grkljana",
        "la": "Spasmus laryngis"
    },
    {
        "icd": "J386",
        "rs": "Suženje grkljana",
        "la": "Stenosis laryngis"
    },
    {
        "icd": "J387",
        "rs": "Druge bolesti grkljana",
        "la": "Morbi laryngis alii"
    },
    {
        "icd": "J39",
        "rs": "Druge bolesti gornjeg dela sistema za disanje",
        "la": "Morbi tractus respiratorii superioris alii"
    },
    {
        "icd": "J390",
        "rs": "Apsces iza ždrela i oko ždrela",
        "la": "Abscessus retropharyngicus et parapharyngicus"
    },
    {
        "icd": "J391",
        "rs": "Drugi apsces ždrela",
        "la": "Abscessus pharyngis alius"
    },
    {
        "icd": "J392",
        "rs": "Druge bolesti ždrela",
        "la": "Morbi pharyngis alii"
    },
    {
        "icd": "J393",
        "rs": "Hipersenzitivna reakcija gornjeg dela sistema za disanje, neoznačene lokalizacije",
        "la": "Reactio tractus respiratorii superioris hypersensitiva, loci non specificati"
    },
    {
        "icd": "J398",
        "rs": "Druge označene bolesti gornjeg dela sistema za disanje",
        "la": "Morbi tractus respiratorii superioris alii, specificati"
    },
    {
        "icd": "J399",
        "rs": "Bolest gornjeg dela sistema za disanje, neoznačena",
        "la": "Morbus tractus respiratorii superioris, non specificatus"
    },
    {
        "icd": "J40",
        "rs": "Bronhitis, neoznačen kao akutni ili hronični",
        "la": "Bronchitis non specificata ut acuta sive chronica"
    },
    {
        "icd": "J41",
        "rs": "Obični hronični i sluzno-gnojni bronhitis",
        "la": "Bronchitis simplex et mucopurulenta chronica"
    },
    {
        "icd": "J410",
        "rs": "Obični hronični bronhitis",
        "la": "Bronchitis simplex chronica"
    },
    {
        "icd": "J411",
        "rs": "Sluzno-gnojni hronični bronhitis",
        "la": "Bronchitis mucopurulenta chronica"
    },
    {
        "icd": "J418",
        "rs": "Mešani obični i sluzno-gnojni hronični bronhitis",
        "la": "Bronchitis simplex et mucopurulenta mixta"
    },
    {
        "icd": "J42",
        "rs": "Hronični bronhitis, neoznačen",
        "la": "Bronchitis chronica, non specificata"
    },
    {
        "icd": "J43",
        "rs": "Emfizem pluća",
        "la": "Emphysema pulmonum"
    },
    {
        "icd": "J430",
        "rs": "MacLeodov sindrom",
        "la": "Syndroma MacLeod"
    },
    {
        "icd": "J431",
        "rs": "Panlobularni emfizem",
        "la": "Emphysema panlobulare"
    },
    {
        "icd": "J432",
        "rs": "Centrololobularni emfizem",
        "la": "Emphysema controlobulare"
    },
    {
        "icd": "J438",
        "rs": "Drugi emfizem",
        "la": "Emphysema alium"
    },
    {
        "icd": "J439",
        "rs": "Emfizem, neoznačen",
        "la": "Emphysema, non specificatum"
    },
    {
        "icd": "J44",
        "rs": "Druga hronična opstruktivna bolest pluća",
        "la": "Morbus pulmonis obstructivus chronicus alius"
    },
    {
        "icd": "J440",
        "rs": "Hronična opstruktivna bolest pluća sa akutnom infekcijom donjih disajnih puteva",
        "la": "Morbus pulmonum obstructivus chronicus cum infectione partis tractus respiratorii inferioris acuta"
    },
    {
        "icd": "J441",
        "rs": "Hronična opstruktivna bolest pluća sa akutnim pogoršanjem, neoznačena",
        "la": "Morbus pulmonum obstructivus chronicus cum exacerbatione acutus, non specificatus"
    },
    {
        "icd": "J448",
        "rs": "Druga označena hronična opstruktivna bolest pluća",
        "la": "Morbus pulmonum obstructivus chronicus alius, specificatus"
    },
    {
        "icd": "J449",
        "rs": "Hronična opstruktivna bolest pluća, neoznačena",
        "la": "Morbus pulmonum obstructivus chronicus, non specificatus"
    },
    {
        "icd": "J45",
        "rs": "Astma",
        "la": "Asthma"
    },
    {
        "icd": "J450",
        "rs": "Prtežno alergijska astma",
        "la": "Asthma praedominanter allergicum"
    },
    {
        "icd": "J451",
        "rs": "Nealergijska astma",
        "la": "Asthma non allergicum"
    },
    {
        "icd": "J458",
        "rs": "Mešovita astma",
        "la": "Asthma mixtum"
    },
    {
        "icd": "J459",
        "rs": "Neoznačena astma",
        "la": "Asthma, non specificatum"
    },
    {
        "icd": "J46",
        "rs": "Astmatični status",
        "la": "Status asthmaticus"
    },
    {
        "icd": "J47",
        "rs": "Bronhiektazije",
        "la": "Bronchiectasia"
    },
    {
        "icd": "J60",
        "rs": "Pneumokonioza - Profesionalna (kopača uglja) bolest pluća uzrokovana udisanjem čestica prašine",
        "la": "Pneumoconiosis"
    },
    {
        "icd": "J61",
        "rs": "Pneumokonioza uzrokovana udisanjem azbestnih čestica i drugih mineralnih vlakana",
        "la": "Pneumoconiosis propter asbestum et fibras minerales alias"
    },
    {
        "icd": "J62",
        "rs": "Silikoza pluća - Profesionalna bolest uzrokovana udisanjem silikatnih čestica",
        "la": "Pneumoconiosis silicotica"
    },
    {
        "icd": "J620",
        "rs": "Pneumokonioza uzrokovana prahom talka",
        "la": "Pneumoconiosis pulverogenes"
    },
    {
        "icd": "J628",
        "rs": "Druga pneumokonioza uzrokovana prašinom silicijuma",
        "la": "Pneumoconiosis silicotica alia"
    },
    {
        "icd": "J63",
        "rs": "Pneumokonioza uzrokovana drugom neorganskom prašinom",
        "la": "Pneumoconiosis propter pulverem inorganicum"
    },
    {
        "icd": "J630",
        "rs": "Pneumokonioza uzrokovana prasinom aluminijuma",
        "la": "Aluminiosis pulmonum"
    },
    {
        "icd": "J631",
        "rs": "Fibroza pluća uzrokovana prašinom boksita",
        "la": "Fibrosis pulmonum boxitogenes"
    },
    {
        "icd": "J632",
        "rs": "Pneumokonioza uzrokovana prašinom berilijuma",
        "la": "Berylliosis"
    },
    {
        "icd": "J633",
        "rs": "Pneumokonioza uzrokovana prašinom grafita",
        "la": "Fibrosis pulmonum graphitogenes"
    },
    {
        "icd": "J634",
        "rs": "Pneumokoniza uzrokovana prašinom gvožđa",
        "la": "Siderosis"
    },
    {
        "icd": "J635",
        "rs": "Pneumokonioza uzrokovana prašinom kalaja",
        "la": "Stannosis"
    },
    {
        "icd": "J638",
        "rs": "Pneumokonioza uzrokovana drugom označenom neorganskom prašinom",
        "la": "Pneumoconiosis propter pulverem inorganicum alium specificatum"
    },
    {
        "icd": "J64",
        "rs": "Pneumokonioza, neoznačena",
        "la": "Pneumoconiosis, non specificata"
    },
    {
        "icd": "J65",
        "rs": "Pneumokonioza sa tuberkulozom",
        "la": "Pneumoconiosis cum tuberculosi"
    },
    {
        "icd": "J66",
        "rs": "Profesionalna bolest puteva za disanje uzrokovana označenom organskom prašinom",
        "la": "Morbus tractuum respiratoriorum propter pulverem organicum specificatum"
    },
    {
        "icd": "J660",
        "rs": "Bisinoza - bolest uzrokovana prasinom pamuka",
        "la": "Byssinosis"
    },
    {
        "icd": "J661",
        "rs": "Bolest prerađivača lana",
        "la": "Morbus fabricatoris lini"
    },
    {
        "icd": "J662",
        "rs": "Kanabinoza - bolest puteva za disanje uzrokovana udisanjem prašine konoplje",
        "la": "Cannabinosis"
    },
    {
        "icd": "J668",
        "rs": "Bolest puteva za disanje uzrokovana drugom označenom organskom prašinom",
        "la": "Morbus tractuum respiratoriorum propter pulverem organicum alium specificatum"
    },
    {
        "icd": "J67",
        "rs": "Hipersenzitivni pneumonitis uzrokovan organskom prašinom",
        "la": "Pneumonitis hypersensitiva propter pulvarem organicum"
    },
    {
        "icd": "J670",
        "rs": "Farmerska pluća",
        "la": "Pulmones agricultoris"
    },
    {
        "icd": "J671",
        "rs": "Bagasoza-profesionalna bolest pluća uzrokovana udisanjem plesnive prašine",
        "la": "Bagassosis"
    },
    {
        "icd": "J672",
        "rs": "Pluća uzgaivača ptica",
        "la": "Pulmones amatoris avium"
    },
    {
        "icd": "J673",
        "rs": "Bolest pluća uzrokovana plutom",
        "la": "Suberosis"
    },
    {
        "icd": "J674",
        "rs": "Pluća proizvođača slada",
        "la": "Pulmones braseum producentis"
    },
    {
        "icd": "J675",
        "rs": "Pluća radnika sa gljivama",
        "la": "Pulmones operarii fungis"
    },
    {
        "icd": "J676",
        "rs": "Pluća strugača kore javora",
        "la": "Pulmones radentis Cryptostromatis corticalis"
    },
    {
        "icd": "J677",
        "rs": "Ventilacijsko zapaljenje mehurića pluća",
        "la": "Alveolitis ventilationalis"
    },
    {
        "icd": "J678",
        "rs": "Hipersenzitivni pneumonitis uzrokovan drugim organskim prašinama",
        "la": "Pneumonitis hypersensitiva propter pulvares organicos alios"
    },
    {
        "icd": "J679",
        "rs": "Alergijsko zapaljenje mehurića pluća, neoznačeno",
        "la": "Alveolitis allergica hypersensitiva, non specificata"
    },
    {
        "icd": "J68",
        "rs": "Respiratorna stanja uzrokovana udisanjem hemikalija, gasova, dimova i para",
        "la": "Condiciones respiratoriae propter inhalationem chemicalium, gasorum, fumorum et vaporum"
    },
    {
        "icd": "J680",
        "rs": "Bronhitis i pneumonitis uzrokovani hemikalijama, gasovima, dimovima i parama",
        "la": "Bronchitis et pneumonitis chemicalis, gasogenes, fumogenes et vaporogenes"
    },
    {
        "icd": "J681",
        "rs": "Plućni edem uzrokovan hemikalijama, gasovima, dimovima i parama",
        "la": "Oedema pulmonis chemicale, gasogenes, fumogenes et vaporogenes"
    },
    {
        "icd": "J682",
        "rs": "Zapaljenje gornjeg dela sistema za disanje uzrokovano hemikalijama, gasovima, dimovima i parama, neklasifikovano na drugom mestu",
        "la": "Inflammatio tractus respiratorii superioris chemicalis, gasogenes, fumogenes et vaporogenes non alibi classificatus"
    },
    {
        "icd": "J683",
        "rs": "Druga akutna i subakutna stanja disajnog sistema uzrokovana hemikalijama, gasovima, dimovima i parama",
        "la": "Condiciones respiratoriae chemicales, gasogenes, fumogenes et vaporogenes acuti et subacuti aliae"
    },
    {
        "icd": "J684",
        "rs": "Hronična respiratorna stanja uzrokovana hemikalijama, gasovima, dimovima i parama",
        "la": "Condiciones respiratoriae chemicales, gasogenes, fumogenes et vaporogenes chronicae"
    },
    {
        "icd": "J688",
        "rs": "Druga respiratorna stanja uzrokovane hemikalijama, gasovima, dimovima i parama",
        "la": "Condiciones respiratoriae chemicales, gasogenes, fumogenes et vaporogenes, aliae"
    },
    {
        "icd": "J689",
        "rs": "Neoznačena respiratorna stanja uzrokovana hemikalijama, gasovima, dimovima i parama",
        "la": "Condiciones respiratoriae chemicales, gasogenes, fumogenes et vaporogenes, non specificatae"
    },
    {
        "icd": "J69",
        "rs": "Zapaljenje pluća uzrokovano čvrstim telima i tečnostima",
        "la": "Pneumonia propter corpora dura et liquores"
    },
    {
        "icd": "J690",
        "rs": "Zapaljenje pluća uzrokovano hranom i povraćenim sadržajem",
        "la": "Pneumonia alimentaria et vomitegenes"
    },
    {
        "icd": "J691",
        "rs": "Zapaljenje pluća uzrokovano uljima i esencijama",
        "la": "Pneumonia oleogenes et essentiogenes"
    },
    {
        "icd": "J698",
        "rs": "Zapaljenje pluća uzrokovano drugim čvrstim telima i tečnostima",
        "la": "Pneumonia propter materias solidas et liquores alios"
    },
    {
        "icd": "J70",
        "rs": "Respiratorna stanja uzrokovana drugim spoljašnjim uzročnicima",
        "la": "Condiciones respiratoriae per agentes externos alios"
    },
    {
        "icd": "J700",
        "rs": "Akutne plućne smetnje uzrokovane zračenjem",
        "la": "Manifestationes pulmonales radiationales acutae"
    },
    {
        "icd": "J701",
        "rs": "Hronične i druge plućne smetnje uzrokovane zračenjem",
        "la": "Manifestationes pulmonales radiationales chronicae et aliae"
    },
    {
        "icd": "J702",
        "rs": "Akutni poremećaji plućnog intersticijuma uzrokovani lekovima",
        "la": "Disordines intersticialis pneumonis medicamentosae acuti"
    },
    {
        "icd": "J703",
        "rs": "Hronični poremećaji plućnog intersticijuma uzrokovani lekovima",
        "la": "Disordines intersticialis pneumonis medicamentosae chronici"
    },
    {
        "icd": "J704",
        "rs": "Poremećaji plućnog intersticijuma uzrokovani lekovima, neoznačeni",
        "la": "Disordines intersticialis pneumonis medicamentosi, non specificati"
    },
    {
        "icd": "J708",
        "rs": "Respiratorna stanja uzrokovana drugim označenim spoljašnjim agensima",
        "la": "Condiciones pulmonum per alios agentes externos specificatis"
    },
    {
        "icd": "J709",
        "rs": "Respiratorna stanja uzrokovana neoznačenim spoljašnjim agensima",
        "la": "Condiciones pulmonum per agentes externos non specificates"
    },
    {
        "icd": "J80",
        "rs": "Sindrom poremećenog disanja kod odraslih osoba",
        "la": "Syndroma distress respiratorium ad adultum"
    },
    {
        "icd": "J81",
        "rs": "Edem pluća",
        "la": "Oedema pulmonum"
    },
    {
        "icd": "J82",
        "rs": "Plućna eozinofilija, neklasifikovana na drugom mestu",
        "la": "Eosinophilia pulmonum non alibi classificata"
    },
    {
        "icd": "J84",
        "rs": "Druge intersticijske bolesti pluća",
        "la": "Morbi interstitiales pulmonum, alii"
    },
    {
        "icd": "J840",
        "rs": "Alveolarni i parietalveolarni poremećaji",
        "la": "Disordines alveolares et parietoalveolares"
    },
    {
        "icd": "J841",
        "rs": "Druge intersticijske bolesti pluća s fibrozom",
        "la": "Morbi interstitiales pulmonum alii, cum fibrosi"
    },
    {
        "icd": "J848",
        "rs": "Druge označene intersticijske bolesti pluća",
        "la": "Morbi interstitiales pulmonum, alii specificati"
    },
    {
        "icd": "J849",
        "rs": "Neoznačene intersticijske bolesti pluća",
        "la": "Morbi interstitiales pulmonum, non specificati"
    },
    {
        "icd": "J85",
        "rs": "Apsces pluća i medijastinuma",
        "la": "Abscessus pulmonum et mediastini"
    },
    {
        "icd": "J850",
        "rs": "Gangrena i nekroza pluća",
        "la": "Gangrena et necrosis pulmonum"
    },
    {
        "icd": "J851",
        "rs": "Plućni apsces sa zapaljenjem pluća",
        "la": "Abscessus pulmonum cum pneumonia"
    },
    {
        "icd": "J852",
        "rs": "Apsces pluća bez pneumonije",
        "la": "Abscessus pulmonum sine pneumoniam"
    },
    {
        "icd": "J853",
        "rs": "Apsces medijastinuma",
        "la": "Abscessus mediastini"
    },
    {
        "icd": "J86",
        "rs": "Gnojni proces u grudnom košu",
        "la": "Pyothorax"
    },
    {
        "icd": "J860",
        "rs": "Gnojenje u grudnom košu sa fistulom",
        "la": "Pyothorax cum fistula"
    },
    {
        "icd": "J869",
        "rs": "Gnojenje u grudnom košu bez fistule",
        "la": "Pyothorax sine fistula"
    },
    {
        "icd": "J90",
        "rs": "Pleuralni izliv, neklasifikovan na drugom mestu",
        "la": "Effusio pleurae non alibi classificata"
    },
    {
        "icd": "J91",
        "rs": "Pleuralni izliv u stanjima klasifikovanim na drugom mestu",
        "la": "Effusio pleurae in condicionibus alibi classificatis"
    },
    {
        "icd": "J92",
        "rs": "Pleuralne naslage („plak”)",
        "la": "Lamella pleuralis"
    },
    {
        "icd": "J920",
        "rs": "Pleuralni plak sa azbestozom",
        "la": "Lamella pleuralis cum asbesto"
    },
    {
        "icd": "J929",
        "rs": "Pleuralni plak bez azbestoze",
        "la": "Lamella pleuralis sine asbesto"
    },
    {
        "icd": "J93",
        "rs": "Pneumotoraks – vazduh u slobodnom pleuralnom prostoru",
        "la": "Pneumothorax"
    },
    {
        "icd": "J930",
        "rs": "Spontani tenzioni pneumotoraks",
        "la": "Pneumothorax spontaneus tensionis"
    },
    {
        "icd": "J931",
        "rs": "Drugi spontani pneumotoraks",
        "la": "Pneumothorax spontaneus alius"
    },
    {
        "icd": "J938",
        "rs": "Drugi pneumotoraks",
        "la": "Pneumothorax alius"
    },
    {
        "icd": "J939",
        "rs": "Pneumotoraks, neoznačen",
        "la": "Pneumotorax, non specificatus"
    },
    {
        "icd": "J94",
        "rs": "Druga stanja pleure",
        "la": "Condiciones pleurae, aliae"
    },
    {
        "icd": "J940",
        "rs": "Hilozna tečnost u slobodnom pleuralnom prostoru",
        "la": "Chylothorax"
    },
    {
        "icd": "J941",
        "rs": "Fibroza pleuralnog prostora",
        "la": "Fibrothorax"
    },
    {
        "icd": "J942",
        "rs": "Krv u slobodnom pleuralnom prostoru",
        "la": "Haemothorax"
    },
    {
        "icd": "J948",
        "rs": "Druga označena stanja pleure",
        "la": "Condiciones pleurae, aliae specificatae"
    },
    {
        "icd": "J949",
        "rs": "Neoznačeno stanje pleure",
        "la": "Condicio pleurae, non specificatae"
    },
    {
        "icd": "J95",
        "rs": "Respiratorni poremećaji posle medicinskih intervencija, neklasifikovani na drugom mestu",
        "la": "Disordines respiratorii postprocedurales non alibi classificati"
    },
    {
        "icd": "J950",
        "rs": "Poremećaj funkcije veštačkog otvora dušnika",
        "la": "Dysfunctio tracheostomatis"
    },
    {
        "icd": "J951",
        "rs": "Akutno popuštanje funkcije pluća posle operacije grudnog koša",
        "la": "Insufficientia pulmonum acuta post operationem thoracalem"
    },
    {
        "icd": "J952",
        "rs": "Akutno popuštanje funkcije pluća posle operacije van grudnog koša",
        "la": "Insufficientia pulmonum acuta post operationem extrathoracalem"
    },
    {
        "icd": "J953",
        "rs": "Hronično popuštanje funkcije pluća posle operacije",
        "la": "Insufficientia pulmonum postoperativa chronica"
    },
    {
        "icd": "J954",
        "rs": "Mendelsonov sindrom",
        "la": "Syndroma Mendelson"
    },
    {
        "icd": "J955",
        "rs": "Suženje ispod glasnica posle medicinske intervencije",
        "la": "Stenosis subglottica post interventiones"
    },
    {
        "icd": "J958",
        "rs": "Drugi respiratorni poremećaji posle medicinskih intervencija",
        "la": "Disordines respiratoriii postprocedurales, alii"
    },
    {
        "icd": "J959",
        "rs": "Respiratorni poremećaj posle medicinskih intervencija, neoznačen",
        "la": "Disordo respiratorium postprocedurales, non specificatum"
    },
    {
        "icd": "J96",
        "rs": "Respiratorna insuficijencija, neklasifikovana na drugom mestu",
        "la": "Insufficientia respiratoria, non alibi classificata"
    },
    {
        "icd": "J960",
        "rs": "Akutna respiratorna insuficiencija",
        "la": "Insufficientia respiratoria acuta"
    },
    {
        "icd": "J961",
        "rs": "Hronična respiratorna insuficiencija",
        "la": "Insufficientia respiratoria chronica"
    },
    {
        "icd": "J969",
        "rs": "Respiratorna insuficiencija, neoznačena",
        "la": "Insufficientia respiratoria, non specificata"
    },
    {
        "icd": "J98",
        "rs": "Drugi respiratorni poremećaji",
        "la": "Disordines respiratorii alii"
    },
    {
        "icd": "J980",
        "rs": "Bolesti dušnika, neklasifikovane na drugom mestu",
        "la": "Morbi bronchi, non alibi classificati"
    },
    {
        "icd": "J981",
        "rs": "Nepotpuno širenje mehurića pluća – kolaps pluća",
        "la": "Collapsus pulmonum"
    },
    {
        "icd": "J982",
        "rs": "Intersticijski emfizem",
        "la": "Emphysema interstitiale"
    },
    {
        "icd": "J983",
        "rs": "Kompezatorni emfizem",
        "la": "Emphysema compensatorium"
    },
    {
        "icd": "J984",
        "rs": "Drugi poremećaji pluća",
        "la": "Disordines pulmonum, alii"
    },
    {
        "icd": "J985",
        "rs": "Bolesti medijastinuma, neklasifikovane",
        "la": "Morbi mediastini, non alibi classificati"
    },
    {
        "icd": "J986",
        "rs": "Poremećaji dijafragme",
        "la": "Disordines diaphragmatis"
    },
    {
        "icd": "J988",
        "rs": "Drugi označeni respiratorni poremećaji",
        "la": "Disordines respiratoriii alii, specificati"
    },
    {
        "icd": "J989",
        "rs": "Respiratorni poremećaj neoznačen",
        "la": "Disordo respiratorius, non specificatus"
    },
    {
        "icd": "J99",
        "rs": "Respiratorni poremećaji u bolestima klasifikovanim na drugom mestu",
        "la": "Disordines respiratorii in morbis alibi classificatis"
    },
    {
        "icd": "J990",
        "rs": "Reumatoidna bolest pluća",
        "la": "Morbus pulmonum rheumatoides"
    },
    {
        "icd": "J991",
        "rs": "Respiratorni pormećaji u drugim difuznim bolestima vezivnog tkiva",
        "la": "Disordines respiratorii in morbis textus connexivi diffusis"
    },
    {
        "icd": "J998",
        "rs": "Respiratorni poremećaji u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Disordines respiratorii in morbis aliis alibi classificatis"
    },
    {
        "icd": "K00",
        "rs": "Poremećaji razvoja i nicanja zuba",
        "la": "Disordines evolutionis et eruptionis dentium"
    },
    {
        "icd": "K000",
        "rs": "Urođeni nedostatak zuba",
        "la": "Anodontia"
    },
    {
        "icd": "K001",
        "rs": "Prekobrojni zubi",
        "la": "Dentes supernumerarii"
    },
    {
        "icd": "K002",
        "rs": "Nepravilan oblik i veličina zuba",
        "la": "Magnitudo et forma dentium abnormalis"
    },
    {
        "icd": "K003",
        "rs": "Išarani zubi",
        "la": "Dentes versicolores"
    },
    {
        "icd": "K004",
        "rs": "Poremećaji stvaranja zuba",
        "la": "Disordines formationis dentium"
    },
    {
        "icd": "K005",
        "rs": "Nasledne anomalije strukture zuba, neklasifikovane na drugom mestu",
        "la": "Anomaliae structurae dentium congenitae, non alibi classificatae"
    },
    {
        "icd": "K006",
        "rs": "Poremećaji nicanja zuba",
        "la": "Disordines eruptionis dentium"
    },
    {
        "icd": "K007",
        "rs": "Sindrom izbijanja zuba",
        "la": "Syndroma dentitionis"
    },
    {
        "icd": "K008",
        "rs": "Drugi poremećaji razvoja zuba",
        "la": "Disordines evolutionis dentium alii"
    },
    {
        "icd": "K009",
        "rs": "Poremećaj razvoja zuba, neoznačen",
        "la": "Disordo evolutionis dentium, non specificatus"
    },
    {
        "icd": "K01",
        "rs": "Neiznikli i uklešteni zubi",
        "la": "Dentes retenti et impacti"
    },
    {
        "icd": "K010",
        "rs": "Neiznikli zubi",
        "la": "Dentes retenti"
    },
    {
        "icd": "K011",
        "rs": "Uklešteni zubi",
        "la": "Dentes impacti"
    },
    {
        "icd": "K02",
        "rs": "Karijes – kvar zuba",
        "la": "Caries dentium"
    },
    {
        "icd": "K020",
        "rs": "Karijes gleđi zuba",
        "la": "Caries adamantini dentium"
    },
    {
        "icd": "K021",
        "rs": "Karijes dentina zuba",
        "la": "Caries dentini dentium"
    },
    {
        "icd": "K022",
        "rs": "Karijes cementa zuba",
        "la": "Caries caementi dentium"
    },
    {
        "icd": "K023",
        "rs": "Zaustavljen karijes zuba",
        "la": "Caries dentium inhibita"
    },
    {
        "icd": "K024",
        "rs": "Kružni karijes zuba",
        "la": "Odontoclasia"
    },
    {
        "icd": "K028",
        "rs": "Drugi karijes zuba",
        "la": "Caries dentium alia"
    },
    {
        "icd": "K029",
        "rs": "Karijes zuba, neoznačen",
        "la": "Caries dentium, non specificata"
    },
    {
        "icd": "K03",
        "rs": "Druge bolesti tvrdih tkiva zuba",
        "la": "Morbi textuum dentium durarum alii"
    },
    {
        "icd": "K030",
        "rs": "Trošenje zuba",
        "la": "Attritio dentium"
    },
    {
        "icd": "K031",
        "rs": "Abrazija zuba",
        "la": "Abrasio dentium"
    },
    {
        "icd": "K032",
        "rs": "Erozija zuba",
        "la": "Erosio dentium"
    },
    {
        "icd": "K033",
        "rs": "Patološka resorpcija zuba",
        "la": "Resorptio dentium pathologica"
    },
    {
        "icd": "K034",
        "rs": "Hipercementoza - višak cementnog sastava zuba",
        "la": "Hypercementosis"
    },
    {
        "icd": "K035",
        "rs": "Ankiloza zuba",
        "la": "Ancylosis dentis"
    },
    {
        "icd": "K036",
        "rs": "Naslage na zubu",
        "la": "Sedimenta dentis"
    },
    {
        "icd": "K037",
        "rs": "Promena boje posle nicanja zuba",
        "la": "Discoloratio dentis posteruptiva"
    },
    {
        "icd": "K038",
        "rs": "Druge označene bolesti tvrdih tkiva zuba",
        "la": "Morbi textuum dentium durarum alii, specificati"
    },
    {
        "icd": "K039",
        "rs": "Bolest tvrdih tkiva zuba, neoznačena",
        "la": "Morbus textuum dentium durorum, non specificatus"
    },
    {
        "icd": "K04",
        "rs": "Bolesti pulpe zuba i tkiva oko vrha zuba",
        "la": "Morbi pulpae et textus periapicalis"
    },
    {
        "icd": "K040",
        "rs": "Zapaljenje zubne pulpe",
        "la": "Pulpitis"
    },
    {
        "icd": "K041",
        "rs": "Nekroza zubne pulpe",
        "la": "Necrosis pulpae dentis"
    },
    {
        "icd": "K042",
        "rs": "Degeneracija zubne pulpe",
        "la": "Degeneratio pulpae dentis"
    },
    {
        "icd": "K043",
        "rs": "Nenormalno formiranje tvrdog tkiva u pulpi",
        "la": "Formatio textus duri in pulpa abnormalis"
    },
    {
        "icd": "K044",
        "rs": "Akutno zapaljenje pulpe vrha korena zuba",
        "la": "Periodontitis pulpae apicalis acuta"
    },
    {
        "icd": "K045",
        "rs": "Hronično zapaljenje pulpe vrha korena zuba",
        "la": "Periodontitis pulpae apicalis chronica"
    },
    {
        "icd": "K046",
        "rs": "Apsces vrha korena sa fistulom",
        "la": "Abscessus periapicalis fistulosus"
    },
    {
        "icd": "K047",
        "rs": "Apsces vrha korena bez fistule",
        "la": "Abscessus periapicalis non fistulosus"
    },
    {
        "icd": "K048",
        "rs": "Cista korena zuba",
        "la": "Cystis dentis radicularis"
    },
    {
        "icd": "K049",
        "rs": "Druge i neoznačene bolesti pulpe zuba i tkiva oko vrha zuba",
        "la": "Morbi pulpae et textus periapicalis alii et non specificati"
    },
    {
        "icd": "K05",
        "rs": "Gingivitis - zapaljenje desni - i bolesti okoline zuba",
        "la": "Gingivitis et morbi periodontales"
    },
    {
        "icd": "K050",
        "rs": "Akutno zapaljenje desni",
        "la": "Gingivitis acuta"
    },
    {
        "icd": "K051",
        "rs": "Hronično zapaljenje desni",
        "la": "Gingivitis chronica"
    },
    {
        "icd": "K052",
        "rs": "Akutno zapaljenje okoline zuba",
        "la": "Periodontitis acuta"
    },
    {
        "icd": "K053",
        "rs": "Periodontitis - hronično zapaljenje okoline zuba",
        "la": "Periodontitis chronica"
    },
    {
        "icd": "K054",
        "rs": "Periodontosis - degenerativno oboljenje okoline zuba",
        "la": "Periodontosis"
    },
    {
        "icd": "K055",
        "rs": "Druge bolesti okoline zuba",
        "la": "Morbi periodontales alii"
    },
    {
        "icd": "K056",
        "rs": "Bolesti okoline zuba, neoznačene",
        "la": "Morbi periodontales, non specificati"
    },
    {
        "icd": "K06",
        "rs": "Druge bolesti desni i bezubog alveolarnog grebena",
        "la": "Morbi gingivae et processus alveolaris edentati alii"
    },
    {
        "icd": "K060",
        "rs": "Povlačenje desni",
        "la": "Recessio gingivae"
    },
    {
        "icd": "K061",
        "rs": "Zadebljanje tkiva desni",
        "la": "Hypertrophia gingivae"
    },
    {
        "icd": "K062",
        "rs": "Traumatsko oštećenje desni i bezubog alveolarnog grebena",
        "la": "Laesio gingivae et processus alveolaris edentati traumatica"
    },
    {
        "icd": "K068",
        "rs": "Druge označene bolesti desni i bezubog alveolarnog grebena",
        "la": "Morbi gingivae et processus alveoralis edentati alii, specificati"
    },
    {
        "icd": "K069",
        "rs": "Bolest desni i bezubog alveolarnog grebena, neoznačena",
        "la": "Morbus gingivae et processus alveolaris edentati, non specificatus"
    },
    {
        "icd": "K07",
        "rs": "Anomalije zuba i kostiju lica [uključujući smetnje zagrižaja]",
        "la": "Anomaliae dentofaciales [malocclusiones includens]"
    },
    {
        "icd": "K070",
        "rs": "Veće anomalije vilica",
        "la": "Gnathanomaliae majores"
    },
    {
        "icd": "K071",
        "rs": "Anomalije odnosa vilica i baze lobanje",
        "la": "Gnathanomaliae et anomaliae basis cranii"
    },
    {
        "icd": "K072",
        "rs": "Anomalije odnosa zubnih lukova",
        "la": "Gnathanomaliae arcuum dentalium"
    },
    {
        "icd": "K073",
        "rs": "Anomalije položaja zuba",
        "la": "Malpositiones dentis"
    },
    {
        "icd": "K074",
        "rs": "Nenormalan zagrižaj, neoznačen",
        "la": "Malocclusio dentis, non specificata"
    },
    {
        "icd": "K075",
        "rs": "Nenormalni zagrižaj zuba",
        "la": "Abnormitas functionis dentofacialis"
    },
    {
        "icd": "K076",
        "rs": "Poremećaji slepoočno - donjeviličnog zgloba",
        "la": "Disordines articuli temporomandibularis"
    },
    {
        "icd": "K078",
        "rs": "Druge anomalije zagrižaja zuba",
        "la": "Anomaliae dentofaciales aliae"
    },
    {
        "icd": "K079",
        "rs": "Anomalija zagrižaja zuba, neoznačena",
        "la": "Anomalia dentofacialis, non specificata"
    },
    {
        "icd": "K08",
        "rs": "Druge bolesti zuba i potpornog tkiva",
        "la": "Morbi dentium et textus sustentionalis alii"
    },
    {
        "icd": "K080",
        "rs": "Ljuštenje zuba u sistemskim bolestima",
        "la": "Exfoliatio dentium in morbis systematicis"
    },
    {
        "icd": "K081",
        "rs": "Gubitak zuba zbog akcidenta, vađenja ili bolesti periodoncijuma",
        "la": "Edentatio dentium propter accidentem, extractionem sive morbum periodonti"
    },
    {
        "icd": "K082",
        "rs": "Atrofija bezubnog alveolarnog grebena",
        "la": "Atrophia processus alveolaris edentati"
    },
    {
        "icd": "K083",
        "rs": "Zaostao koren zuba",
        "la": "Radix relicta"
    },
    {
        "icd": "K088",
        "rs": "Druge označene bolesti zuba i potpornog tkiva",
        "la": "Morbi dentium et textus sustentionalis alii, specificati"
    },
    {
        "icd": "K089",
        "rs": "Bolest zuba i potpornog tkiva, neoznačena",
        "la": "Morbus dentium et textus sustentionalis, non specificatus"
    },
    {
        "icd": "K09",
        "rs": "Ciste usta, neklasifikovane na drugom mestu",
        "la": "Cistes regionis oralis, non alibi classificatae"
    },
    {
        "icd": "K090",
        "rs": "Razvojne zubne ciste",
        "la": "Cystes odontogenales evolutionales"
    },
    {
        "icd": "K091",
        "rs": "Razvojne nezubne ciste",
        "la": "Cystes non odontogenes evolutionales"
    },
    {
        "icd": "K092",
        "rs": "Druge ciste gornje vilice",
        "la": "Cystes maxillae, aliae"
    },
    {
        "icd": "K098",
        "rs": "Druge ciste usta, neklasifikovane na drugom mestu",
        "la": "Cystes regionis oralis aliae, non alibi classificatae"
    },
    {
        "icd": "K099",
        "rs": "Cista usta, neoznačena",
        "la": "Cystis regionis oralis, non specificata"
    },
    {
        "icd": "K10",
        "rs": "Druge bolesti vilica",
        "la": "Morbi maxillae et mandibulae alii"
    },
    {
        "icd": "K100",
        "rs": "Razvojni poremećaji vilica",
        "la": "Disordines maxillae et mandibulae evolutionales"
    },
    {
        "icd": "K101",
        "rs": "Centralni čvorasti tumor velikih ćelija vezivnog tkiva vilica",
        "la": "Granuloma maxillae et mandibulae gigantocellulare centrale"
    },
    {
        "icd": "K102",
        "rs": "Zapaljenja vilica",
        "la": "Status inflammatorii maxillae et mandibulae"
    },
    {
        "icd": "K103",
        "rs": "Zapaljenje čašice zuba",
        "la": "Alveolitis"
    },
    {
        "icd": "K108",
        "rs": "Druge označene bolesti vilica",
        "la": "Morbi maxillae et mandibulae alii, specificati"
    },
    {
        "icd": "K109",
        "rs": "Bolest vilice, neoznačena",
        "la": "Morbus maxillae et mandibulae, non specificatus"
    },
    {
        "icd": "K11",
        "rs": "Bolesti pljuvačnih žlezda",
        "la": "Morbi glandularum salivarium"
    },
    {
        "icd": "K110",
        "rs": "Atrofija pljuvačne žlezde",
        "la": "Atrophia glandulae salivaris"
    },
    {
        "icd": "K111",
        "rs": "Uvećanje pljuvačne žlezde",
        "la": "Hypertrophia glandulae salivaris"
    },
    {
        "icd": "K112",
        "rs": "Zapaljenje pljuvačne žlezde",
        "la": "Sialoadenitis"
    },
    {
        "icd": "K113",
        "rs": "Apsces pljuvačne žlezde",
        "la": "Abscessus glandulae salivaris"
    },
    {
        "icd": "K114",
        "rs": "Fistula pljuvačne žlezde",
        "la": "Fistula glandulae salivaris"
    },
    {
        "icd": "K115",
        "rs": "Kamenci u pljuvačnoj žlezdi",
        "la": "Sialolithiasis"
    },
    {
        "icd": "K116",
        "rs": "Sluzna cista pljuvačne žlezde",
        "la": "Mucocele glandulae salivaris"
    },
    {
        "icd": "K117",
        "rs": "Poremećaj lučenja pljuvačke",
        "la": "Dysfunctio secretionis glandulae salivaris"
    },
    {
        "icd": "K118",
        "rs": "Druge bolesti pljuvačnih žlezda",
        "la": "Morbi glandularum salivarium alii"
    },
    {
        "icd": "K119",
        "rs": "Bolest pljuvačne žlezde, neoznačena",
        "la": "Morbus glandulae salivaris, non specificatus"
    },
    {
        "icd": "K12",
        "rs": "Zapaljenje usta i srodne lezije",
        "la": "Stomatitis et affinibus laesiones"
    },
    {
        "icd": "K120",
        "rs": "Povratne jašterice usta",
        "la": "Aphtae oris recurrentes"
    },
    {
        "icd": "K121",
        "rs": "Drugi oblici zapaljenja usta",
        "la": "Stomatitis, formae aliae"
    },
    {
        "icd": "K122",
        "rs": "Flegmona i apsces usta",
        "la": "Phlegmone et abscessus oris"
    },
    {
        "icd": "K123",
        "rs": "Zapaljenje sluznice usta (ulcerozno)",
        "la": "Mucositis oris (ulcerosa)"
    },
    {
        "icd": "K13",
        "rs": "Druge bolesti usne i sluznice usta",
        "la": "Morbi labii et mucosae oris alii"
    },
    {
        "icd": "K130",
        "rs": "Bolesti usne",
        "la": "Morbi labii"
    },
    {
        "icd": "K131",
        "rs": "Ujed obraza i usne",
        "la": "Morsus buccae et labii oris"
    },
    {
        "icd": "K132",
        "rs": "Leukoplakija i drugi poremećaji epitela usta i jezika",
        "la": "Leucoplakia et disordines epithelii oris et linguae alii"
    },
    {
        "icd": "K133",
        "rs": "Leukoplakija sa dlakama",
        "la": "Leucoplakia pilosa"
    },
    {
        "icd": "K134",
        "rs": "Čvorasti tumor i zrakasta oštećenja sluznice usta",
        "la": "Granuloma et laesiones mucosae oris granulomatosae"
    },
    {
        "icd": "K135",
        "rs": "Bujanje vezivnog tkiva podsluznice usta",
        "la": "Fibrosis submucosae oris"
    },
    {
        "icd": "K136",
        "rs": "Nadražajno bujanje sluznice usta",
        "la": "Hyperplasia mucosae oris irritativa"
    },
    {
        "icd": "K137",
        "rs": "Druga i neoznačena oštećenja sluznice usta",
        "la": "Laesiones mucosae oris aliae et non specificatae"
    },
    {
        "icd": "K14",
        "rs": "Bolesti jezika",
        "la": "Morbi linguae"
    },
    {
        "icd": "K140",
        "rs": "Zapaljenje jezika",
        "la": "Glossitis"
    },
    {
        "icd": "K141",
        "rs": "Geografski jezik",
        "la": "Lingua geographica"
    },
    {
        "icd": "K142",
        "rs": "Središnje romboidno zapaljenje jezika",
        "la": "Glossitis rhomboides mediana"
    },
    {
        "icd": "K143",
        "rs": "Zadebljanje papila jezika",
        "la": "Hypertrophia papillarum linguae"
    },
    {
        "icd": "K144",
        "rs": "Atrofija papila jezika",
        "la": "Atrophia papillarum linguae"
    },
    {
        "icd": "K145",
        "rs": "Naboran jezik",
        "la": "Lingua plicata"
    },
    {
        "icd": "K146",
        "rs": "Bolan jezik",
        "la": "Lingua dolorosa"
    },
    {
        "icd": "K148",
        "rs": "Druge bolesti jezika",
        "la": "Morbi linguae alii"
    },
    {
        "icd": "K149",
        "rs": "Bolesti jezika, neoznačena",
        "la": "Morbus linguae, non specificatus"
    },
    {
        "icd": "K20",
        "rs": "Zapaljenje jednjaka",
        "la": "Oesophagitis"
    },
    {
        "icd": "K21",
        "rs": "Gastro-ezofagealna refluksna bolest",
        "la": "Morbus refluxualis gastro-oesophageus"
    },
    {
        "icd": "K210",
        "rs": "Gastro-ezofagealna refluksna bolest sa ezofagitisom",
        "la": "Morbus refluxualis gastro-oesophageus cum oesophagitide"
    },
    {
        "icd": "K219",
        "rs": "Gastro-ezofagealna refluksna bolest bez ezofagitisa",
        "la": "Morbus refluxualis gastro-oesophageus sine oesophagitide"
    },
    {
        "icd": "K22",
        "rs": "Druge bolesti jednjaka",
        "la": "Morbi oesophagi alii"
    },
    {
        "icd": "K220",
        "rs": "Ahalazija donjeg dela jednjaka",
        "la": "Achalasia cardialis"
    },
    {
        "icd": "K221",
        "rs": "Grizlica jednjaka",
        "la": "Ulcus oesophagi"
    },
    {
        "icd": "K222",
        "rs": "Neprolaznost jednjaka",
        "la": "Obstructio oesophagi"
    },
    {
        "icd": "K223",
        "rs": "Proboj jednjaka",
        "la": "Perforatio oesophagi"
    },
    {
        "icd": "K224",
        "rs": "Poremećaj pokretljivosti jednjaka",
        "la": "Dyskinesia oesophagi"
    },
    {
        "icd": "K225",
        "rs": "Stečeno kesasto proširenje jednjaka",
        "la": "Diverticulum oesophagi acquisitum"
    },
    {
        "icd": "K226",
        "rs": "Sindrom želudačno-jednjačkog krvarenja",
        "la": "Syndroma haemorrhagica gastrooesophagica"
    },
    {
        "icd": "K227",
        "rs": "Baretov jednjak",
        "la": "Oesophagus Barrett"
    },
    {
        "icd": "K228",
        "rs": "Druge označene bolesti jednjaka",
        "la": "Morbi oesophagi alii, specificati"
    },
    {
        "icd": "K229",
        "rs": "Bolest jednjaka, neoznačena",
        "la": "Morbus oesophagi, non specificatus"
    },
    {
        "icd": "K23",
        "rs": "Bolesti jednjaka u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi oesophagi in morbis alibi classificatis"
    },
    {
        "icd": "K230",
        "rs": "Tuberkulozno zapaljenje jednjaka",
        "la": "Oesophagis tuberculosa"
    },
    {
        "icd": "K231",
        "rs": "Široki jednjak u Chagasovoj bolesti",
        "la": "Megaoesophagus in morbo Chagas"
    },
    {
        "icd": "K238",
        "rs": "Druge bolesti jednjaka u drugim bolestima, klasifikovanim na drugom mestu",
        "la": "Morbi oesophagi in morbis aliis, alibi classificati"
    },
    {
        "icd": "K25",
        "rs": "Grizlica želuca",
        "la": "Ulcus ventriculi"
    },
    {
        "icd": "K250",
        "rs": "Grizlica želuca, akutna sa hemoragijom",
        "la": "Ulcus ventriculi, acutus cum haemorrhagia"
    },
    {
        "icd": "K251",
        "rs": "Grizlica želuca, akutna sa perforacijom",
        "la": "Ulcus ventriculi, acutus cum perforatione"
    },
    {
        "icd": "K252",
        "rs": "Grizlica želuca, akutna sa krvarenjem i perforacijom",
        "la": "Ulcus ventriculi, acutus cum haemorrhagia et perforatione"
    },
    {
        "icd": "K253",
        "rs": "Grizlica želuca, akutna bez krvarenja ili perforacije",
        "la": "Ulcus ventriculi, acutus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K254",
        "rs": "Grizlica želuca, hronična ili neoznačena sa krvarenjem",
        "la": "Ulcus ventriculi, chronicus sive non specificatus, cum haemorrhagia"
    },
    {
        "icd": "K255",
        "rs": "Grizlica želuca, hronična ili neoznačena sa perforacijom",
        "la": "Ulcus ventriculi, chronicus sive non specificatus, cum perforatione"
    },
    {
        "icd": "K256",
        "rs": "Grizlica želuca, hronična ili neoznačena sa krvarenjem i perforacijom",
        "la": "Ulcus ventriculi, chronicus sive non specificatus, cum haemorrhagia et perforatione"
    },
    {
        "icd": "K257",
        "rs": "Grizlica želuca, hronična bez krvarenja ili perforacije",
        "la": "Ulcus ventriculi, chronicus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K259",
        "rs": "Grizlica želuca, neoznačena kao akutna ili hronična, bez krvarenja ili perforacije",
        "la": "Ulcus ventriculi, non specificatus ut acutus sive chronicus, sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K26",
        "rs": "Grizlica dvanaestopalačnog creva",
        "la": "Ulcus duodeni"
    },
    {
        "icd": "K260",
        "rs": "Grizlica dvanaestopalačnog creva, akutna sa hemoragijom",
        "la": "Ulcus duodeni, acutus cum haemorrhagia"
    },
    {
        "icd": "K261",
        "rs": "Grizlica dvanaestopalačnog creva, akutna sa perforacijom",
        "la": "Ulcus duodeni, acutus cum perforatione"
    },
    {
        "icd": "K262",
        "rs": "Grizlica dvanaestopalačnog creva, akutna sa krvarenjem i perforacijom",
        "la": "Ulcus duodeni, acutus cum haemorrhagia et perforatione"
    },
    {
        "icd": "K263",
        "rs": "Grizlica dvanaestopalačnog creva, akutna bez krvarenja ili perforacije",
        "la": "Ulcus duodeni, acutus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K264",
        "rs": "Grizlica dvanaestopalačnog creva, hronična ili neoznačena sa krvarenjem",
        "la": "Ulcus duodeni, chronicus sive non specificatus, cum haemorrhagia"
    },
    {
        "icd": "K265",
        "rs": "Grizlica dvanaestopalačnog creva, hronična ili neoznačena sa perforacijom",
        "la": "Ulcus duodeni, chronicus sive non specificatus, cum perforatione"
    },
    {
        "icd": "K266",
        "rs": "Grizlica dvanaestopalačnog creva, hronična ili neoznačena sa krvarenjem i perforacijom",
        "la": "Ulcus duodeni, chronicus sive non specificatus, cum haemorrhagia et perforatione"
    },
    {
        "icd": "K267",
        "rs": "Grizlica dvanaestopalačnog creva, hronična bez krvarenja ili perforacije",
        "la": "Ulcus duodeni, chronicus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K269",
        "rs": "Grizlica dvanaestopalačnog creva, neoznačena kao akutna ili hronična, bez krvarenja ili perforacije",
        "la": "Ulcus duodeni, non specificatus ut acutus sive chronicus, sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K27",
        "rs": "Peptička grizlica, neoznačene lokalizacije",
        "la": "Ulcus pepticum, loci non specificati"
    },
    {
        "icd": "K270",
        "rs": "Peptička grizlica, neoznačene lokalizacije, akutna sa hemoragijom",
        "la": "Ulcus pepticum, acutus cum haemorrhagia"
    },
    {
        "icd": "K271",
        "rs": "Peptička grizlica, neoznačene lokalizacije, akutna sa perforacijom",
        "la": "Ulcus pepticum, acutus cum perforatione"
    },
    {
        "icd": "K272",
        "rs": "Peptička grizlica, neoznačene lokalizacije, akutna sa krvarenjem i perforacijom",
        "la": "Ulcus pepticum, acutus cum haemorrhagia et perforatione"
    },
    {
        "icd": "K273",
        "rs": "Peptička grizlica, neoznačene lokalizacije, akutna bez krvarenja ili perforacije",
        "la": "Ulcus pepticum, acutus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K274",
        "rs": "Peptička grizlica, neoznačene lokalizacije, hronična ili neoznačena sa krvarenjem",
        "la": "Ulcus pepticum, chronicus sive non specificatus, cum haemorrhagia"
    },
    {
        "icd": "K275",
        "rs": "Peptička grizlica, neoznačene lokalizacije, hronična ili neoznačena sa perforacijom",
        "la": "Ulcus pepticum, chronicus sive non specificatus, cum perforatione"
    },
    {
        "icd": "K276",
        "rs": "Peptička grizlica, neoznačene lokalizacije, hronična ili neoznačena sa krvarenjem i perforacijom",
        "la": "Ulcus pepticum, chronicus sive non specificatus, cum haemorrhagia et perforatione"
    },
    {
        "icd": "K277",
        "rs": "Peptička grizlica, neoznačene lokalizacije, hronična bez krvarenja ili perforacije",
        "la": "Ulcus pepticum, chronicus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K279",
        "rs": "Peptička grizlica, neoznačene lokalizacije, neoznačena kao akutna ili hronična, bez krvarenja ili perforacije",
        "la": "Ulcus pepticum, non specificatus ut acutus sive chronicus, sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K28",
        "rs": "Želudačno-crevna grizlica",
        "la": "Ulcus gastrojejunale"
    },
    {
        "icd": "K280",
        "rs": "Želudačno-crevna grizlica, akutna sa hemoragijom",
        "la": "Ulcus gastrojejunale, acutus cum haemorrhagia"
    },
    {
        "icd": "K281",
        "rs": "Želudačno-crevna grizlica, akutna sa perforacijom",
        "la": "Ulcus gastrojejunale, acutus cum perforatione"
    },
    {
        "icd": "K282",
        "rs": "Želudačno-crevna grizlica, akutna sa krvarenjem i perforacijom",
        "la": "Ulcus gastrojejunale, acutus cum haemorrhagia et perforatione"
    },
    {
        "icd": "K283",
        "rs": "Želudačno-crevna grizlica, akutna bez krvarenja ili perforacije",
        "la": "Ulcus gastrojejunale, acutus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K284",
        "rs": "Želudačno-crevna grizlica, hronična ili neoznačena sa krvarenjem",
        "la": "Ulcus gastrojejunale, chronicus sive non specificatus, cum haemorrhagia"
    },
    {
        "icd": "K285",
        "rs": "Želudačno-crevna grizlica, hronična ili neoznačena sa perforacijom",
        "la": "Ulcus gastrojejunale, chronicus sive non specificatus, cum perforatione"
    },
    {
        "icd": "K286",
        "rs": "Želudačno-crevna grizlica, hronična ili neoznačena sa krvarenjem i perforacijom",
        "la": "Ulcus gastrojejunale, chronicus sive non specificatus, cum haemorrhagia et perforatione"
    },
    {
        "icd": "K287",
        "rs": "Želudačno-crevna grizlica, hronična bez krvarenja ili perforacije",
        "la": "Ulcus gastrojejunale, chronicus sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K289",
        "rs": "Želudačno-crevna grizlica, neoznačena kao akutna ili hronična, bez krvarenja ili perforacije",
        "la": "Ulcus gastrojejunale, non specificatus ut acutus sive chronicus, sine haemorrhagia sive perforatione"
    },
    {
        "icd": "K29",
        "rs": "Zapaljenje želuca i dvanaestopalačnog creva",
        "la": "Gastritis et duedenitis"
    },
    {
        "icd": "K290",
        "rs": "Akutno zapaljenje želuca sa krvarenjem",
        "la": "Gastritis haemorrhagica acuta"
    },
    {
        "icd": "K291",
        "rs": "Drugo akutno zapaljenje želuca",
        "la": "Gastritis acuta alia"
    },
    {
        "icd": "K292",
        "rs": "Zapaljenje želuca uzrokovano alkoholom",
        "la": "Gastritis alcoholica"
    },
    {
        "icd": "K293",
        "rs": "Hronično površinsko zapaljenje želuca",
        "la": "Gastritis superficialis chronica"
    },
    {
        "icd": "K294",
        "rs": "Hronično atrofijsko zapaljenje želuca",
        "la": "Gastritis atrophica chronica"
    },
    {
        "icd": "K295",
        "rs": "Hronično zapaljenje želuca, neoznačeno",
        "la": "Gastritis chronica, non specificata"
    },
    {
        "icd": "K296",
        "rs": "Drugo zapaljenje želuca",
        "la": "Gastritis alia"
    },
    {
        "icd": "K297",
        "rs": "Zapaljenje želuca, neoznačeno",
        "la": "Gastritis, non specificata"
    },
    {
        "icd": "K298",
        "rs": "Zapaljenje dvanaestopalačnog creva",
        "la": "Duodenitis"
    },
    {
        "icd": "K299",
        "rs": "Zapaljenje želuca i dvanaestopalačnog creva, neoznačeno",
        "la": "Gastroduodenitis, non specificata"
    },
    {
        "icd": "K30",
        "rs": "Poremećeno varenje",
        "la": "Dyspepsia"
    },
    {
        "icd": "K31",
        "rs": "Druge bolesti želuca i bolesti dvanaestopalačnog creva",
        "la": "Morbi ventriculi et morbi duodeni alii"
    },
    {
        "icd": "K310",
        "rs": "Akutno proširenje želuca",
        "la": "Dilatatio ventriculi acuta"
    },
    {
        "icd": "K311",
        "rs": "Hipertrofično suženje pilorusa kod odraslih osoba",
        "la": "Stenosis pylori hypertrophica adultorum"
    },
    {
        "icd": "K312",
        "rs": "Sužavanje želuca u obliku peščanog sata",
        "la": "Strictura et stenosis ventriculi formae horologii arenarii"
    },
    {
        "icd": "K313",
        "rs": "Grč pilorusa, neklasifikovan na drugom mestu",
        "la": "Pylorospasmus, non alibi classificatus"
    },
    {
        "icd": "K314",
        "rs": "Kesasto proširenje zida želuca",
        "la": "Diverticulum ventriculi"
    },
    {
        "icd": "K315",
        "rs": "Začepljenje dvanaestopalačnog creva",
        "la": "Obstructio duodeni"
    },
    {
        "icd": "K316",
        "rs": "Fistula želuca i fistula dvanaestopalačnog creva",
        "la": "Fistula ventriculi et fistula duodeni"
    },
    {
        "icd": "K317",
        "rs": "Polip želuca i dvanaestopalačnog creva",
        "la": "Polypus ventriculi et duodeni"
    },
    {
        "icd": "K318",
        "rs": "Druge označene bolesti želuca i bolesti dvanaestopalačnog creva",
        "la": "Morbi ventriculi et morbi duodeni alii, specificati"
    },
    {
        "icd": "K319",
        "rs": "Bolest želuca i bolest dvanaestopalačnog creva, neoznačena",
        "la": "Morbus ventriculi et morbus duodeni, non specificatus"
    },
    {
        "icd": "K35",
        "rs": "Akutno zapaljenje slepog creva",
        "la": "Appendicitis acuta"
    },
    {
        "icd": "K352",
        "rs": "Akutno zapaljenje slepog creva generalizovanim peritonitisom",
        "la": "Appendicitis acuta cum peritonitide generalisata"
    },
    {
        "icd": "K353",
        "rs": "Akutno zapaljenje slepog creva sa lokalizovanim peritonitisom",
        "la": "Appendicitis acuta cum peritonitide localisata"
    },
    {
        "icd": "K358",
        "rs": "Akutno zapaljenje slepog creva, drugo i neoznačeno",
        "la": "Appendicitis acuta, alia et non specificata"
    },
    {
        "icd": "K36",
        "rs": "Drugo zapaljenje slepog creva",
        "la": "Appendicitis alia"
    },
    {
        "icd": "K37",
        "rs": "Zapaljenje slepog creva, neoznačeno",
        "la": "Appendicitis, non specificata"
    },
    {
        "icd": "K38",
        "rs": "Druge bolesti slepog creva",
        "la": "Morbi apendicis alii"
    },
    {
        "icd": "K380",
        "rs": "Uvećano slepo crevo",
        "la": "Hyperplasia appendicis"
    },
    {
        "icd": "K381",
        "rs": "Kamenci u slepom crevu",
        "la": "Concrementa appendicis"
    },
    {
        "icd": "K382",
        "rs": "Kesasto proširenje zida slepog creva",
        "la": "Diverticulum appendicis"
    },
    {
        "icd": "K383",
        "rs": "Fistula slepog creva",
        "la": "Fistula appendicis"
    },
    {
        "icd": "K388",
        "rs": "Druge označene bolesti slepog creva",
        "la": "Morbi appendicis alii, specificati"
    },
    {
        "icd": "K389",
        "rs": "Bolest slepog creva, neoznačena",
        "la": "Morbus appendicis, non specificatus"
    },
    {
        "icd": "K40",
        "rs": "Preponska kila",
        "la": "Hernia inguinalis"
    },
    {
        "icd": "K400",
        "rs": "Ukleštena preponska obostrana kila bez gangrene",
        "la": "Hernia inguinalis incarcerata, non gangraenosa, bilateralis"
    },
    {
        "icd": "K401",
        "rs": "Preponska gangrenozna obostrana kila",
        "la": "Hernia inguinalis gangraenosa bilateralis"
    },
    {
        "icd": "K402",
        "rs": "Neukleštena preponska obostrana kila bez gangrene",
        "la": "Hernia inguinalis bilateralis non incarcerata, non gangraenosa"
    },
    {
        "icd": "K403",
        "rs": "Preponska jednostrana neoznačena ili ukleštena kila bez gangrene",
        "la": "Hernia inguinalis unilateralis non specificata sive incarcerata, non gangraenosa"
    },
    {
        "icd": "K404",
        "rs": "Gangrenozna preponska jednostrana kila, neoznačena",
        "la": "Hernia inguinalis gangraenosa unilateralis, non specificata"
    },
    {
        "icd": "K409",
        "rs": "Neukleštena preponska jednostrana kila bez gangrene, neoznačena",
        "la": "Hernia inguinalis unilateralis, non incarcerata, non gangraenosa, non specificata"
    },
    {
        "icd": "K41",
        "rs": "Butna kila",
        "la": "Hernia femoralis"
    },
    {
        "icd": "K410",
        "rs": "Butna obostrana ukleštena kila, bez gangrene",
        "la": "Hernia femoralis incarcerata bilateralis, non gangraenosa"
    },
    {
        "icd": "K411",
        "rs": "Butna obostrana gangrenozna kila",
        "la": "Hernia femoralis gangraenosa bilateralis"
    },
    {
        "icd": "K412",
        "rs": "Butna obostrana neukleštena kila, bez gangrene",
        "la": "Hernia femoralis non incarcerata, non gangraenosa, bilateralis"
    },
    {
        "icd": "K413",
        "rs": "Butna jednostrana ukleštena kila bez gangrene, neoznačena",
        "la": "Hernia femoralis unilateralis incarcerata, non gangraenosa, non specificata"
    },
    {
        "icd": "K414",
        "rs": "Butna jednostrana gangrenozna kila, neoznačena",
        "la": "Hernia femoralis gangraenosa unilateralis, non specificata"
    },
    {
        "icd": "K419",
        "rs": "Neukleštena butna jednostrana kila, bez gangrene, neoznačena",
        "la": "Hernia femoralis unilateralis non incarcerata, non gangraenosa, non specificata"
    },
    {
        "icd": "K42",
        "rs": "Pupčana kila",
        "la": "Hernia umbilicalis"
    },
    {
        "icd": "K420",
        "rs": "Ukleštena pupčana kila, bez gangrene",
        "la": "Hernia umbilicalis incarcerata, non gangraenosa"
    },
    {
        "icd": "K421",
        "rs": "Gangrenozna pupčana kila",
        "la": "Hernia umbilicalis gangraenosa"
    },
    {
        "icd": "K429",
        "rs": "Neukleštena pupčana kila, bez gangrene",
        "la": "Hernia umbilicalis non incarcerata, non gangraenosa"
    },
    {
        "icd": "K43",
        "rs": "Kila prednjeg zida trbuha",
        "la": "Hernia ventralis"
    },
    {
        "icd": "K430",
        "rs": "Ukleštena kila prednjeg zida trbuha, bez gangrene",
        "la": "Hernia ventralis incarcerata, non gangraenosa"
    },
    {
        "icd": "K431",
        "rs": "Gangrenozna kila prednjeg zida trbuha",
        "la": "Hernia ventralis gangraenosa"
    },
    {
        "icd": "K439",
        "rs": "Kila prednjeg zida trbuha, neukleštena, bez gangrene",
        "la": "Hernia ventralis non incarcerata, non gangraenosa"
    },
    {
        "icd": "K44",
        "rs": "Kila grudno-trbušne prečage",
        "la": "Hernia diaphragmatica"
    },
    {
        "icd": "K440",
        "rs": "Ukleštena kila grudno-trbušne prečage bez gangrene",
        "la": "Hernia diaphragmatica incarcerata, non gangraenosa"
    },
    {
        "icd": "K441",
        "rs": "Kila grudno-trbušne prečage sa gangrenom",
        "la": "Hernia diaphragmatica gangraenosa"
    },
    {
        "icd": "K449",
        "rs": "Kila grudno-trbušne prečage, neukleštena, bez gangrene",
        "la": "Hernia diaphragmatica non incarcerata, non gangraenosa"
    },
    {
        "icd": "K45",
        "rs": "Druga trbušna kila",
        "la": "Hernia abdominalis alia"
    },
    {
        "icd": "K450",
        "rs": "Druga označena trbušna kila sa ukleštenjem, bez gangrene",
        "la": "Hernia abdominalis incarcerata, non gangraenosa, alia, specificata"
    },
    {
        "icd": "K451",
        "rs": "Druga trbušna kila sa gangrenom",
        "la": "Hernia abdominalis gangraenosa alia"
    },
    {
        "icd": "K458",
        "rs": "Druga označena neukleštena trbušna kila bez gangrene",
        "la": "Hernia abdominalis specificata alia, non incarcerata, non gangraenosa"
    },
    {
        "icd": "K46",
        "rs": "Trbušna kila, neoznačena",
        "la": "Hernia abdominalis, non specificata"
    },
    {
        "icd": "K460",
        "rs": "Neoznačena ukleštena trbušna kila, bez gangrene",
        "la": "Hernia abdominalis incarcerata, non gangraenosa, non specificata"
    },
    {
        "icd": "K461",
        "rs": "Trbušna kila sa gangrenom, neoznačena",
        "la": "Hernia abdominalis gangraenosa, non specificata"
    },
    {
        "icd": "K469",
        "rs": "Trbušna kila, neukleštena, bez gangrene",
        "la": "Hernia abdominalis, non obstructionalis, non gangraenosa"
    },
    {
        "icd": "K50",
        "rs": "Crohnova bolest",
        "la": "Morbus Crohn [enteritis regionalis]"
    },
    {
        "icd": "K500",
        "rs": "Crohnova bolest tankog creva",
        "la": "Morbus Crohn intestini tenuis"
    },
    {
        "icd": "K501",
        "rs": "Crohnova bolest debelog creva",
        "la": "Morbus Crohn coli"
    },
    {
        "icd": "K508",
        "rs": "Druga Crohnova bolest",
        "la": "Morbus Crohn alius"
    },
    {
        "icd": "K509",
        "rs": "Crohnova bolest, neoznačena",
        "la": "Morbus Crohn, non specificatus"
    },
    {
        "icd": "K51",
        "rs": "Ulcerozni kolitis",
        "la": "Colitis ulcerosa"
    },
    {
        "icd": "K510",
        "rs": "Ulcerozni (hronični) pankolitis",
        "la": "Pancolitis ulcerosa (chronica)"
    },
    {
        "icd": "K512",
        "rs": "Ulcerozni (hronični) proktitis",
        "la": "Proctitis ulcerosa (chronica)"
    },
    {
        "icd": "K513",
        "rs": "Ulcerozni (hronični) rektosigmoiditis",
        "la": "Rectosigmoiditis ulcerosa (chronica)"
    },
    {
        "icd": "K514",
        "rs": "Inflamatorna polipoza debelog creva",
        "la": "Polyposis inflammatoria"
    },
    {
        "icd": "K515",
        "rs": "Zapaljenje debelog creva sa leve strane",
        "la": "Colitis lateralis sinistri"
    },
    {
        "icd": "K518",
        "rs": "Drugi ulcerozni kolitis",
        "la": "Colitis ulcerosa alia"
    },
    {
        "icd": "K519",
        "rs": "Ulcerozni kolitis, neoznačen",
        "la": "Colitis ulcerosa, non specificata"
    },
    {
        "icd": "K52",
        "rs": "Druga nezarazna zapaljenja želuca, tankog creva i debelog creva",
        "la": "Gastroenteritis et colitis non infectiva alia"
    },
    {
        "icd": "K520",
        "rs": "Zapaljenje želuca, tankog creva i debelog creva uzrokovano ozračenjem",
        "la": "Gastroenteritis et colitis radiationalis"
    },
    {
        "icd": "K521",
        "rs": "Toksično zapaljenje želuca, tankog creva i debelog creva",
        "la": "Gastroenteritis et colitis toxica"
    },
    {
        "icd": "K522",
        "rs": "Alergijsko i dijetetsko zapaljenje želuca, tankog creva i debelog creva",
        "la": "Gastroenteritis et colitis allergica et diaetetica"
    },
    {
        "icd": "K523",
        "rs": "Neodređeno zapaljenje debelog creva",
        "la": "Colitis inderterminata"
    },
    {
        "icd": "K528",
        "rs": "Druga označena nezarazna zapaljenja želuca, tankog creva i debelog creva",
        "la": "Gastroenteritis et colitis non infectiva alia, specificata"
    },
    {
        "icd": "K529",
        "rs": "Nezarazno zapaljenje želuca, tankog creva i debelog creva, neoznačeno",
        "la": "Gastroenteritis et colitis non infectiva, non specificata"
    },
    {
        "icd": "K55",
        "rs": "Vaskularne bolesti creva",
        "la": "Morbi intestini vasculares"
    },
    {
        "icd": "K550",
        "rs": "Akutne vaskularne bolesti creva",
        "la": "Morbi intestini vasculares acuti"
    },
    {
        "icd": "K551",
        "rs": "Hronične vaskularne bolesti creva",
        "la": "Morbi intestini vasculares chronici"
    },
    {
        "icd": "K552",
        "rs": "Angiodisplazija debelog creva",
        "la": "Angiodysplasia coli"
    },
    {
        "icd": "K558",
        "rs": "Druge vaskularne bolesti creva",
        "la": "Morbi intestini vasculares alii"
    },
    {
        "icd": "K559",
        "rs": "Vaskularna boles creva, neoznačena",
        "la": "Morbus intestini vascularis, non specificatus"
    },
    {
        "icd": "K56",
        "rs": "Paralitički zavezano crevo i začepljenje creva, bez kile",
        "la": "Ileus paralyticus et obstructio intestinalis, sine hernia"
    },
    {
        "icd": "K560",
        "rs": "Paralitički zavezano crevo",
        "la": "Ileus paralyticus"
    },
    {
        "icd": "K561",
        "rs": "Ukleštenje creva u crevo",
        "la": "Incarceratio"
    },
    {
        "icd": "K562",
        "rs": "Uvrtanje creva",
        "la": "Volvulus"
    },
    {
        "icd": "K563",
        "rs": "Zavezano crevo uzrokovano zučnim kamencem",
        "la": "Ileus cholelithiatica"
    },
    {
        "icd": "K564",
        "rs": "Drugo začepljenje creva",
        "la": "Impactio intestini alia"
    },
    {
        "icd": "K565",
        "rs": "Crevne priraslice sa smetnjama u prolaznosti",
        "la": "Adhaesiones intestini obstructionales"
    },
    {
        "icd": "K566",
        "rs": "Drugo zavezano crevo, neoznačeno",
        "la": "Ileus alius, non specificatus"
    },
    {
        "icd": "K567",
        "rs": "Zavezano crevo, neoznačeno",
        "la": "Ileus, non specificatus"
    },
    {
        "icd": "K57",
        "rs": "Divertikuloza - vrećasta proširenja creva",
        "la": "Diverticulosis intestini"
    },
    {
        "icd": "K570",
        "rs": "Divertikuloza tankog creva sa probojem i apscesom",
        "la": "Diverticulosis intestini tenuis perforans cum abscessu"
    },
    {
        "icd": "K571",
        "rs": "Divertikuloza tankog creva bez proboja i apscesa",
        "la": "Diverticulosis intestini tenuis non perforans sine abscessu"
    },
    {
        "icd": "K572",
        "rs": "Divertikuloza debelog creva sa probojem i apscesom",
        "la": "Diverticulosis coli perforans cum abscessu"
    },
    {
        "icd": "K573",
        "rs": "Divertikuloza debelog creva bez proboja, sa apscesomm",
        "la": "Diverticulosis coli non perforans cum abscessu"
    },
    {
        "icd": "K574",
        "rs": "Divertikuloza tankog i debelog creva sa probojem i apscesom",
        "la": "Diverticulosis intestini tenuis et coli perforans cum abscessu"
    },
    {
        "icd": "K575",
        "rs": "Divertikuloza tankog i debelog creva bez proboja i apscesa",
        "la": "Diverticulosis intestini tenuis et coli non perforans sine abscessu"
    },
    {
        "icd": "K578",
        "rs": "Divertikuloza neoznačenog dela creva sa probojem i apscesom",
        "la": "Diverticulosis intestini, partis non specificatae, perforans cum abscessu"
    },
    {
        "icd": "K579",
        "rs": "Divertikuloza creva, neoznačenog dela, bez proboja i apscesa",
        "la": "Diverticulosis intestini, partis non specificatae, non perforans sine abscessu"
    },
    {
        "icd": "K58",
        "rs": "Sindrom nadraženog creva",
        "la": "Syndroma intestini irritabilis"
    },
    {
        "icd": "K580",
        "rs": "Sindrom nadraženja creva sa prolivom",
        "la": "Syndroma intestini irritabilis cum diarrhoea"
    },
    {
        "icd": "K589",
        "rs": "Sindrom nadraženja creva bez proliva",
        "la": "Syndroma intestini irritabilis sine diarrhoea"
    },
    {
        "icd": "K59",
        "rs": "Drugi funkcionalni poremećaji rada creva",
        "la": "Dysfunctiones intestinales aliae"
    },
    {
        "icd": "K590",
        "rs": "Zatvor",
        "la": "Opstipatio"
    },
    {
        "icd": "K591",
        "rs": "Psihogeni proliv",
        "la": "Diarrhoea functionalis"
    },
    {
        "icd": "K592",
        "rs": "Neurogeno crevo, neklasifikovano na drugom mestu",
        "la": "Intestinum neurogenes non alibi classificatum"
    },
    {
        "icd": "K593",
        "rs": "Široko debelo crevo, neklasifikovano na drugom mestu",
        "la": "Megacolon, non alibi classificatum"
    },
    {
        "icd": "K594",
        "rs": "Grč anusa",
        "la": "Spasmus analis"
    },
    {
        "icd": "K598",
        "rs": "Drugi označeni funkcionalni poremećaji rada creva",
        "la": "Dysfunctiones intestinales aliae, specificati"
    },
    {
        "icd": "K599",
        "rs": "Funkcionalni poremećaj rada creva, neoznačen",
        "la": "Dysfunctio intestinalis, non specificatus"
    },
    {
        "icd": "K60",
        "rs": "Prskotina i fistula analnog i rektalnog predela",
        "la": "Fissura et fistula regionis analis et rectalis"
    },
    {
        "icd": "K600",
        "rs": "Akutna prskotina anusa",
        "la": "Fissura ani acuta"
    },
    {
        "icd": "K601",
        "rs": "Hronična prskotina anusa",
        "la": "Fissura ani chronica"
    },
    {
        "icd": "K602",
        "rs": "Prskotina anusa, neoznačena",
        "la": "Fissura ani, non specificata"
    },
    {
        "icd": "K603",
        "rs": "Analna fistula",
        "la": "Fistula ani"
    },
    {
        "icd": "K604",
        "rs": "Rektalna fistula",
        "la": "Fistula recti"
    },
    {
        "icd": "K605",
        "rs": "Anorektalna fistula",
        "la": "Fistula anorectalis"
    },
    {
        "icd": "K61",
        "rs": "Apsces analnog i rektalnog predela",
        "la": "Abscessus regionis analis et rectalis"
    },
    {
        "icd": "K610",
        "rs": "Analni apsces",
        "la": "Abscessus ani"
    },
    {
        "icd": "K611",
        "rs": "Rektalni apsces",
        "la": "Abscessus recti"
    },
    {
        "icd": "K612",
        "rs": "Anorektalni apsces",
        "la": "Abscessus anorectalis"
    },
    {
        "icd": "K613",
        "rs": "Apsces sedalne oblasti",
        "la": "Abscessus ischiorectalis"
    },
    {
        "icd": "K614",
        "rs": "Apsces u mišiću zatvaraču anusa",
        "la": "Abscessus intrasphinctericus"
    },
    {
        "icd": "K62",
        "rs": "Druge bolesti anusa i rektuma",
        "la": "Morbi ani et morbi recti alii"
    },
    {
        "icd": "K620",
        "rs": "Polip anusa",
        "la": "Polypus ani"
    },
    {
        "icd": "K621",
        "rs": "Polip rektuma",
        "la": "Polypus recti"
    },
    {
        "icd": "K622",
        "rs": "Ispadanje anusa",
        "la": "Prolapsus ani"
    },
    {
        "icd": "K623",
        "rs": "Ispadanje rektuma",
        "la": "Prolapsus recti"
    },
    {
        "icd": "K624",
        "rs": "Suženje anusa i rektuma",
        "la": "Stenosis ani et recti"
    },
    {
        "icd": "K625",
        "rs": "Krvarenje anusa i rektuma",
        "la": "Haemorrhagia ani et recti"
    },
    {
        "icd": "K626",
        "rs": "Grizlica anusa i rektuma",
        "la": "Ulcus ani et recti"
    },
    {
        "icd": "K627",
        "rs": "Proktitis uzrokovan zračenjem",
        "la": "Proctitis radiationalis"
    },
    {
        "icd": "K628",
        "rs": "Druge označene bolesti anusa i rektuma",
        "la": "Morbi ani et recti alii specificati"
    },
    {
        "icd": "K629",
        "rs": "Bolest anusa i rektuma, neoznačena",
        "la": "Morbus ani et recti, non specificatus"
    },
    {
        "icd": "K63",
        "rs": "Druge bolesti creva",
        "la": "Morbi intestini alii"
    },
    {
        "icd": "K630",
        "rs": "Apsces creva",
        "la": "Abscessus intestini"
    },
    {
        "icd": "K631",
        "rs": "Proboj creva (bez povrede)",
        "la": "Perforatio intestini (non traumatica)"
    },
    {
        "icd": "K632",
        "rs": "Fistula creva",
        "la": "Fistula intestini"
    },
    {
        "icd": "K633",
        "rs": "Grizlica creva",
        "la": "Ulcus intestini"
    },
    {
        "icd": "K634",
        "rs": "Spuštenost creva",
        "la": "Enteroptosis"
    },
    {
        "icd": "K635",
        "rs": "Polip debelog creva",
        "la": "Polypus coli"
    },
    {
        "icd": "K638",
        "rs": "Druge označene bolesti creva",
        "la": "Morbi intestini alii, specificati"
    },
    {
        "icd": "K639",
        "rs": "Bolest creva, neoznačena",
        "la": "Morbus intestini, non specificatus"
    },
    {
        "icd": "K65",
        "rs": "Peritonitis",
        "la": "Peritonitis"
    },
    {
        "icd": "K650",
        "rs": "Akutni peritonitis",
        "la": "Peritonitis acuta"
    },
    {
        "icd": "K658",
        "rs": "Drugi peritonitis",
        "la": "Peritonitis alia"
    },
    {
        "icd": "K659",
        "rs": "Peritonitis, neoznačen",
        "la": "Peritonitis non specificata"
    },
    {
        "icd": "K66",
        "rs": "Druge bolesti peritoneuma",
        "la": "Morbi peritonaei alii"
    },
    {
        "icd": "K660",
        "rs": "Priraslice peritoneuma",
        "la": "Adhaesiones peritonaei"
    },
    {
        "icd": "K661",
        "rs": "Hemoperitoneum",
        "la": "Haemoperitonaeum"
    },
    {
        "icd": "K668",
        "rs": "Druge označene bolesti peritoneuma",
        "la": "Morbi peritonaei alii, specificati"
    },
    {
        "icd": "K669",
        "rs": "Bolest peritoneuma, neoznačena",
        "la": "Morbus peritonaei, non specificatus"
    },
    {
        "icd": "K67",
        "rs": "Bolesti peritoneuma u zaraznim bolestima, klasifikovanim na drugom mestu",
        "la": "Morbi peritonaei in morbis infectivis alibi classificatis"
    },
    {
        "icd": "K670",
        "rs": "Zapaljenje peritoneuma uzrokovano hlamidijama",
        "la": "Peritonitis chlamydialis"
    },
    {
        "icd": "K671",
        "rs": "Zapaljenje peritoneuma uzrokovano gonokokom",
        "la": "Peritonitis gonococcica"
    },
    {
        "icd": "K672",
        "rs": "Zapaljenje peritoneuma uzrokovano bledom spirohetom",
        "la": "Peritonitis syphilitica"
    },
    {
        "icd": "K673",
        "rs": "Tuberkulozno zapaljenje peritoneuma",
        "la": "Peritonitis tuberculosa"
    },
    {
        "icd": "K678",
        "rs": "Druge bolesti peritoneuma u zaraznim bolestima, klasifikovanim na drugom mestu",
        "la": "Morbi peritonaei alii in morbis infectivis alibi classificatis"
    },
    {
        "icd": "K70",
        "rs": "Bolest jetre uzrokovana alkoholom",
        "la": "Morbus hepatis alcoholicus"
    },
    {
        "icd": "K700",
        "rs": "Masna degeneracija jetre uzrokovana alkoholom",
        "la": "Steatosis hepatis alcoholica"
    },
    {
        "icd": "K701",
        "rs": "Zapaljenje jetre uzrokovano alkoholom",
        "la": "Hepatitis alcoholica"
    },
    {
        "icd": "K702",
        "rs": "Fibroza i skleroza jetre uzrokovana alkoholom",
        "la": "Fibrosis et sclerosis hepatis alcoholica"
    },
    {
        "icd": "K703",
        "rs": "Ciroza jetre uzrokovana alkoholom",
        "la": "Cirrhosis hepatis alcoholica"
    },
    {
        "icd": "K704",
        "rs": "Insuficijencija jetre uzrokovana alkoholom",
        "la": "Insufficientia hepatis alcoholica"
    },
    {
        "icd": "K709",
        "rs": "Bolest jetre uzrokovana alkoholom, neoznačena",
        "la": "Morbus hepatis alcoholicus, non specificatus"
    },
    {
        "icd": "K71",
        "rs": "Bolest jetre uzrokovana toksinima",
        "la": "Morbus hepatis toxicus"
    },
    {
        "icd": "K710",
        "rs": "Toksička bolest jetre zbog zastoja žuči",
        "la": "Morbus hepatis toxicus cholestaticus"
    },
    {
        "icd": "K711",
        "rs": "Toksička bolest jetre sa nekrozom jetrinog tkiva",
        "la": "Morbus hepatis toxicus hepatonecroticans"
    },
    {
        "icd": "K712",
        "rs": "Toksička bolest jetre sa akutnim zapaljenjem jetre",
        "la": "Morbus hepatis toxicus cum hepatitide"
    },
    {
        "icd": "K713",
        "rs": "Toksička bolest jetre sa postojanim hroničnim zapaljenjem jetre",
        "la": "Morbus hepatis toxicus cum hepatitide chronica persistenti"
    },
    {
        "icd": "K714",
        "rs": "Toksička bolest jetre sa hroničnim zapaljenjem jetrinih režnjića",
        "la": "Morbus hepatis toxicus cum hepatitide lobulari chronica"
    },
    {
        "icd": "K715",
        "rs": "Toksička bolest jetre sa aktivnim hroničnim zapaljenjem jetre",
        "la": "Morbus hepatis toxicus cum hepatitide activa chronica"
    },
    {
        "icd": "K716",
        "rs": "Toksička bolest jetre sa zapaljenjem jetre, neklasifikovana na drugom mestu",
        "la": "Morbus hepatis toxicus cum hepatitide, non alibi classificata"
    },
    {
        "icd": "K717",
        "rs": "Toksička bolest jetre sa fibrozom i cirozom jetre",
        "la": "Morbus hepatis toxicus cum fibrosi et cirhosi hepatis"
    },
    {
        "icd": "K718",
        "rs": "Toksičke bolesti jetre sa drugim bolestima jetre",
        "la": "Morbi hepatis toxici cum morbis hepatis aliis"
    },
    {
        "icd": "K719",
        "rs": "Toksička bolest jetre, neoznačena",
        "la": "Morbus hepatis toxicus, non specificatus"
    },
    {
        "icd": "K72",
        "rs": "Insuficijencija jetre, neklasifikovana na drugom mestu",
        "la": "Insufficientia hepatis, non alibi classificata"
    },
    {
        "icd": "K720",
        "rs": "Akutna i subakutna insuficijencija jetre",
        "la": "Insufficientia hepatis acuta et subacuta"
    },
    {
        "icd": "K721",
        "rs": "Hronična insuficijencija jetre",
        "la": "Insufficientia hepatis chronica"
    },
    {
        "icd": "K729",
        "rs": "Insuficijencija jetre, neoznačena",
        "la": "Insufficientia hepatis, non specificata"
    },
    {
        "icd": "K73",
        "rs": "Hronično zapaljenje jetre, neklasifikovano na drugom mestu",
        "la": "Hepatitis chronica, non alibi classificata"
    },
    {
        "icd": "K730",
        "rs": "Hronično postojano zapaljenje jetre, neklasifikovano na drugom mestu",
        "la": "Hepatitis chronica persistens, non alibi classificata"
    },
    {
        "icd": "K731",
        "rs": "Hronično postojano zapaljenje jetrinih režnjića, neklasifikovano na drugom mestu",
        "la": "Hepatitis lobularis chronica persistens, non alibi classificata"
    },
    {
        "icd": "K732",
        "rs": "Aktivno hronično zapaljenje jetre, neklasifikovano na drugom mestu",
        "la": "Hepatitis activa chronica, non alibi classificata"
    },
    {
        "icd": "K738",
        "rs": "Drugo hronično zapaljenje jetre, neklasifikovano na drugom mestu",
        "la": "Hepatitis chronica alia, non alibi classificata"
    },
    {
        "icd": "K739",
        "rs": "Hronično zapaljenje jetre, neoznačeno",
        "la": "Hepatitis chronica, non specificata"
    },
    {
        "icd": "K74",
        "rs": "Fibroza i ciroza jetre",
        "la": "Fibrosis et cirrhosis hepatis"
    },
    {
        "icd": "K740",
        "rs": "Fibroza jetre",
        "la": "Fibrosis hepatis"
    },
    {
        "icd": "K741",
        "rs": "Skleroza jetre",
        "la": "Sclerosis hepatis"
    },
    {
        "icd": "K742",
        "rs": "Fibroza jetre sa sklerozom jetre",
        "la": "Fibrosis hepatis cum sclerosi hepatia"
    },
    {
        "icd": "K743",
        "rs": "Primarna ciroza žučnih puteva",
        "la": "Cirrhosis biliaris primaria"
    },
    {
        "icd": "K744",
        "rs": "Sekundarna ciroza žučnih puteva",
        "la": "Cirrhosis biliaris secundaria"
    },
    {
        "icd": "K745",
        "rs": "Ciroza žučnih puteva, neoznačena",
        "la": "Cirrhosis biliaris, non specificata"
    },
    {
        "icd": "K746",
        "rs": "Druga i neoznačena ciroza jetre",
        "la": "Cirrohosis hepatis alia et non specificata"
    },
    {
        "icd": "K75",
        "rs": "Druge zapaljenske bolesti jetre",
        "la": "Morbi hepatis inflammatorii alii"
    },
    {
        "icd": "K750",
        "rs": "Apsces jetre",
        "la": "Abscessus hepatis"
    },
    {
        "icd": "K751",
        "rs": "Zapaljenje vene porte",
        "la": "Phlebitis venae portae"
    },
    {
        "icd": "K752",
        "rs": "Reaktivno zapaljenje jetre, neoznačeno",
        "la": "Hepatitis reactiva, non specificata"
    },
    {
        "icd": "K753",
        "rs": "Zrnasto zapaljenje jetre, neklasifikovano na drugom mestu",
        "la": "Hepatitis granulomatosa, non alibi classificata"
    },
    {
        "icd": "K754",
        "rs": "Autoimuno zapaljenje jetre",
        "la": "Hepatitis autoimmune"
    },
    {
        "icd": "K758",
        "rs": "Druga označena zapaljenja jetre",
        "la": "Morbi hepatis inflammatorii alii, specificati"
    },
    {
        "icd": "K759",
        "rs": "Zapaljenjska bolest jetre, neoznačena",
        "la": "Morbus hepatis inflammatorius, non specificatus"
    },
    {
        "icd": "K76",
        "rs": "Druge bolesti jetre",
        "la": "Morbi hepatis alii"
    },
    {
        "icd": "K760",
        "rs": "Masna (degeneracija) jetre, neklasifikovana na drugom mestu",
        "la": "Steatosis hepatis, non alibi classificata"
    },
    {
        "icd": "K761",
        "rs": "Hronična zastojna jetra",
        "la": "Stasis hepatis chronica"
    },
    {
        "icd": "K762",
        "rs": "Hemoragična nekroza centralnih delova jetre",
        "la": "Necrosis hepatis haemorrhagica centralis"
    },
    {
        "icd": "K763",
        "rs": "Infarkt jetre",
        "la": "Infarctus hepatis"
    },
    {
        "icd": "K764",
        "rs": "Pegavost jetre",
        "la": "Peliosis hepatis"
    },
    {
        "icd": "K765",
        "rs": "Začepljenje vene jetre",
        "la": "Occlusio venae hepaticae"
    },
    {
        "icd": "K766",
        "rs": "Povišen pritisak u veni porti",
        "la": "Hypertensio portalis"
    },
    {
        "icd": "K767",
        "rs": "Jetreno-bubrežni sindrom",
        "la": "Syndroma hepatorenale"
    },
    {
        "icd": "K768",
        "rs": "Druge označene bolesti jetre",
        "la": "Morbi hepatis alii, specificati"
    },
    {
        "icd": "K769",
        "rs": "Bolest jetre, neoznačena",
        "la": "Morbus hepatis, non specificatus"
    },
    {
        "icd": "K77",
        "rs": "Bolesti jetre u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi hepatis in morbis alibi classificatis"
    },
    {
        "icd": "K770",
        "rs": "Bolesti jetre u zaraznim i parazitamim bolestima, klasifikovanim na drugom mestu",
        "la": "Morbi hepatis in morbis infectivis et parasitariis alibi classificatis"
    },
    {
        "icd": "K778",
        "rs": "Bolesti jetre u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Morbi hepatis in morbis aliis, alibi classificatis"
    },
    {
        "icd": "K80",
        "rs": "Kamen u žučnoj kesi",
        "la": "Cholelithiasis"
    },
    {
        "icd": "K800",
        "rs": "Kamen u žuči i akutno zapaljenje žučne kese",
        "la": "Cholelithiasis et cholecystitis acuta"
    },
    {
        "icd": "K801",
        "rs": "Kamen u žuči i drugo zapaljenje žučne kese",
        "la": "Cholelithiasis et cholecystitis alia"
    },
    {
        "icd": "K802",
        "rs": "Kamen u žučnoj kesi bez zapaljenja žučne kese",
        "la": "Calculus vesicae felleae sine cholecystitide"
    },
    {
        "icd": "K803",
        "rs": "Kamen u žučnom kanalu sa zapaljenjem žučnog kanala",
        "la": "Calculus ductus billiaris cum cholangitide"
    },
    {
        "icd": "K804",
        "rs": "Kamen u žučnom kanalu sa zapaljenjem žučne kese",
        "la": "Calculus ductus billiaris cum cholecystitide"
    },
    {
        "icd": "K805",
        "rs": "Kamen u žučnom kanalu bez zapaljenja žučnog kanala ili žučne kese",
        "la": "Calculus ductus billiaris sine cholangitide sive cholecystitide"
    },
    {
        "icd": "K808",
        "rs": "Drugi kamen u žuči",
        "la": "Cholelithiasis alia"
    },
    {
        "icd": "K81",
        "rs": "Zapaljenje žučne kese",
        "la": "Cholecystitis"
    },
    {
        "icd": "K810",
        "rs": "Akutno zapaljenje žučne kese",
        "la": "Cholecystitis acuta"
    },
    {
        "icd": "K811",
        "rs": "Hronično zapaljenje žučne kese",
        "la": "Cholecystitis chronica"
    },
    {
        "icd": "K818",
        "rs": "Drugo zapaljenje žučne kese",
        "la": "Cholecystitis alia"
    },
    {
        "icd": "K819",
        "rs": "Zapaljenje žučne kese, neoznačeno",
        "la": "Cholecystitis, non specificata"
    },
    {
        "icd": "K82",
        "rs": "Druge bolesti žučne kese",
        "la": "Morbi vesicae felleae alii"
    },
    {
        "icd": "K820",
        "rs": "Začepljenje žučne kese",
        "la": "Obstructio vesicae felleae"
    },
    {
        "icd": "K821",
        "rs": "Vodena bolest žučne kese",
        "la": "Hydrops vesicae felleae"
    },
    {
        "icd": "K822",
        "rs": "Proboj žučne kese",
        "la": "Perforatio vesicae felleae"
    },
    {
        "icd": "K823",
        "rs": "Fistula žučne kese",
        "la": "Fistula vesicae felleae"
    },
    {
        "icd": "K824",
        "rs": "Holesteroloza žučne kese",
        "la": "Cholesterolosis vesicae felleae"
    },
    {
        "icd": "K828",
        "rs": "Druge označene bolesti žučne kese",
        "la": "Morbi vesicae felleae alii, specificati"
    },
    {
        "icd": "K829",
        "rs": "Bolest žučne kese, neoznačena",
        "la": "Morbus vesicae felleae, non specificatus"
    },
    {
        "icd": "K83",
        "rs": "Druge bolesti žučnog trakta",
        "la": "Morbi tracti biliares alii"
    },
    {
        "icd": "K830",
        "rs": "Zapaljenje žučnih kanala",
        "la": "Cholangitis"
    },
    {
        "icd": "K831",
        "rs": "Začepljen žučni kanal",
        "la": "Occlusio ductus biliaris"
    },
    {
        "icd": "K832",
        "rs": "Proboj žučnog kanala",
        "la": "Perforatio ductus biliaris"
    },
    {
        "icd": "K833",
        "rs": "Fistula žučnog kanala",
        "la": "Fistula ductus biliaris"
    },
    {
        "icd": "K834",
        "rs": "Grč Oddijevog mišića stezača žučnog kanala",
        "la": "Spasmus sphincteris Oddi"
    },
    {
        "icd": "K835",
        "rs": "Žučna cista",
        "la": "Cystis biliaris"
    },
    {
        "icd": "K838",
        "rs": "Druge označene bolesti žučnog trakta",
        "la": "Morbi tracti biliares alii, specificati"
    },
    {
        "icd": "K839",
        "rs": "Bolest žučnog trakta, neoznačena",
        "la": "Morbus tractus biliaris, non specificatus"
    },
    {
        "icd": "K85",
        "rs": "Akutno zapaljenje pankreasa",
        "la": "Pancreatitis acuta"
    },
    {
        "icd": "K850",
        "rs": "Akutno idiopatsko zapaljenje pankreasa",
        "la": "Pancreatitis acuta idiopathica"
    },
    {
        "icd": "K851",
        "rs": "Akutno bilijarno zapaljenje pankreasa",
        "la": "Pancreatitis acuta biliaris"
    },
    {
        "icd": "K852",
        "rs": "Akutno zapaljenje pankreasa izazvano alkoholom",
        "la": "Pancreatitis acuta alcoholica"
    },
    {
        "icd": "K853",
        "rs": "Akutno zapaljenje pankreasa izazvano lekovima",
        "la": "Pancreatitis acuta medicamentosa"
    },
    {
        "icd": "K858",
        "rs": "Druga akutna zapaljenja pankreasa",
        "la": "Pancreatitis acuta alia"
    },
    {
        "icd": "K859",
        "rs": "Akutno zapaljenje pankreasa, neoznačeno",
        "la": "Pancreatitis acuta, non specificata"
    },
    {
        "icd": "K86",
        "rs": "Druge bolesti pankreasa",
        "la": "Morbi pancreatis alii"
    },
    {
        "icd": "K860",
        "rs": "Hronično zapaljenje pankreasa izazvano alkoholom",
        "la": "Pancreatitis alcoholica chronica"
    },
    {
        "icd": "K861",
        "rs": "Drugo hronično zapaljenje pankreasa",
        "la": "Pancreatitis chronica alia"
    },
    {
        "icd": "K862",
        "rs": "Cista pankreasa",
        "la": "Cystis pancreatis"
    },
    {
        "icd": "K863",
        "rs": "Lažna cista pankreasa",
        "la": "Pseudocystis pancreatis"
    },
    {
        "icd": "K868",
        "rs": "Druge označene bolesti pankreasa",
        "la": "Morbi pancreatis alii, specificati"
    },
    {
        "icd": "K869",
        "rs": "Bolest pankreasa, neoznačena",
        "la": "Morbus pancreatis, non specificatus"
    },
    {
        "icd": "K87",
        "rs": "Bolesti žučne kese, žučnih puteva i pankreasa u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi vesicae felleae, ductuum biliarium et pancreatis in morbis alibi classificatis"
    },
    {
        "icd": "K870",
        "rs": "Bolest žučne kese i žučnih puteva u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi vesicae felleae et ductuum biliarium in morbis alibi classificatis"
    },
    {
        "icd": "K871",
        "rs": "Bolesti pankreasa u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi pancreatis in morbis alibi classificatis"
    },
    {
        "icd": "K90",
        "rs": "Loša apsorpcija u crevima",
        "la": "Malabsorptio intestini"
    },
    {
        "icd": "K900",
        "rs": "Celijačna bolest",
        "la": "Morbus coeliacus"
    },
    {
        "icd": "K901",
        "rs": "Tropski spru",
        "la": "„Sprue“ tropica"
    },
    {
        "icd": "K902",
        "rs": "Sindrom slepe vijuge, neklasifikovan na drugom mestu",
        "la": "Syndroma gyri caeci, non alibi classificatum"
    },
    {
        "icd": "K903",
        "rs": "Pankreasna steatoreja",
        "la": "Steatorrhoea pancreatica"
    },
    {
        "icd": "K904",
        "rs": "Loša apsorpcija uzrokovana nepodnošenjem hrane, neklasifikovana na drugom mestu",
        "la": "Malabsorptio propter intolerantiam alimenti, non alibi classificata"
    },
    {
        "icd": "K908",
        "rs": "Druga loša apsorpcija u crevima",
        "la": "Malabsorptio intestini alia"
    },
    {
        "icd": "K909",
        "rs": "Loša apsorpcija u crevima, neoznačena",
        "la": "Malabsorptio intestini, non specificata"
    },
    {
        "icd": "K91",
        "rs": "Bolesti organa za varenje posle medicinskih intervencija, neklasifikovane na drugom mestu",
        "la": "Morbi organorum digestivorum postprocedurales, non alibi classificati"
    },
    {
        "icd": "K910",
        "rs": "Povraćanje posle operacije želuca i creva",
        "la": "Vomitus post resectionem gastrointestinalem"
    },
    {
        "icd": "K911",
        "rs": "Sindrom posle operacije želuca",
        "la": "Sindroma post gastrectomiam"
    },
    {
        "icd": "K912",
        "rs": "Loša apsorpcija posle operacije, neklasifikovana na drugom mestu",
        "la": "Malabsorptio post operationem"
    },
    {
        "icd": "K913",
        "rs": "Zavezano crevo posle operacije",
        "la": "Ileus postoperativus"
    },
    {
        "icd": "K914",
        "rs": "Poremećena funkcija veštackog otvora debelog i tankog creva",
        "la": "Dysfunctio colostomatis et enterostomatis"
    },
    {
        "icd": "K915",
        "rs": "Sindrom posle hirurškog odstranjenja žučne kese",
        "la": "Syndroma post cholecystectomiam"
    },
    {
        "icd": "K918",
        "rs": "Druge bolesti organa za varenje posle medicinskih intervencija, neklasifikovane na drugom mestu",
        "la": "Morbi organorum digestivorum postprocedurales alii, non alibi classificati"
    },
    {
        "icd": "K919",
        "rs": "Bolest organa za varenje posle medicinskih intervencija, neoznačena",
        "la": "Morbus organorum digestivorum postproceduralis, non specificatus"
    },
    {
        "icd": "K92",
        "rs": "Druge bolesti organa za varenje",
        "la": "Morbi organorum digestivorum alii"
    },
    {
        "icd": "K920",
        "rs": "Povraćanje krvi",
        "la": "Haematemesis"
    },
    {
        "icd": "K921",
        "rs": "Crna stolica",
        "la": "Melaena"
    },
    {
        "icd": "K922",
        "rs": "Krvarenje u želucu i crevima, neoznačeno",
        "la": "Haemorrhagia gastrointestinalis, non specificata"
    },
    {
        "icd": "K928",
        "rs": "Druge označene bolesti organa za varenje",
        "la": "Morbi organorum digestivorum alii, specificati"
    },
    {
        "icd": "K929",
        "rs": "Bolest organa za varenje, neoznačena",
        "la": "Morbus organorum digestivorum, non specificatus"
    },
    {
        "icd": "K93",
        "rs": "Druge bolesti organa za varenje u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi organorum digestivorum alii in morbis alibi classificatis"
    },
    {
        "icd": "K930",
        "rs": "Tuberkuloza creva, peritoneuma i žlezda mezenterijuma",
        "la": "Tuberculosis intestini, peritonaei et glandularum mesentericarum"
    },
    {
        "icd": "K931",
        "rs": "Široko debelo crevo u Chagasovoj bolesti",
        "la": "Megacolon in morbo Chagas"
    },
    {
        "icd": "K938",
        "rs": "Bolesti drugih označenih organa za varenje u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi organorum digestivorum aliorum specificatorum, in morbis alibi classificatis"
    },
    {
        "icd": "L00",
        "rs": "Sindom stafilokoknog ljuštenja kože",
        "la": "Syndroma exfoliationis cutis staphylococica"
    },
    {
        "icd": "L01",
        "rs": "Gnojno oboljenje kože",
        "la": "Impetigo"
    },
    {
        "icd": "L010",
        "rs": "Gnojno oboljenje kože (bilo koji uzročnik, bilo koja lokalizacija)",
        "la": "Impetigo [ullius organismi] [ullius loci]"
    },
    {
        "icd": "L011",
        "rs": "Druga gnojna oboljenja kože",
        "la": "Dermatoses impetiginosae aliae"
    },
    {
        "icd": "L02",
        "rs": "Apsces, čir i grupa čireva kože",
        "la": "Abscessus, furunculus et carbunculus cutis"
    },
    {
        "icd": "L020",
        "rs": "Apsces, čir i grupa čireva kože lica",
        "la": "Abscessus, furunculus et carbunculus cutis faciei"
    },
    {
        "icd": "L021",
        "rs": "Apsces, čir i grupa čireva kože vrata",
        "la": "Abscessus, furunculus et carbunculus cutis colli"
    },
    {
        "icd": "L022",
        "rs": "Apsces, čir i grupa čireva kože trupa",
        "la": "Abscessus, furunculus et carbunculus cutis trunci"
    },
    {
        "icd": "L023",
        "rs": "Apsces, čir i grupa čireva kože sedalnog predela",
        "la": "Abscessus, furunculus et carbunculus cutis regionis glutealis"
    },
    {
        "icd": "L024",
        "rs": "Apsces, čir i grupa čireva kože udova",
        "la": "Abscessus, furunculus et carbunculus cutis extremitatum"
    },
    {
        "icd": "L028",
        "rs": "Apsces, čir i grupa čireva kože drugih lokalizacija",
        "la": "Abscessus, furunculus et carbunculus cutis locorum aliorum"
    },
    {
        "icd": "L029",
        "rs": "Apsces, čir i grupa čireva, neoznačen",
        "la": "Abscessus, furunculus et carbunculus cutis, non specificatus"
    },
    {
        "icd": "L03",
        "rs": "Celulitis [Flegmona] – zapaljenje vezivnog ćelijskog tkiva",
        "la": "Cellulitis [Phlegmone]"
    },
    {
        "icd": "L030",
        "rs": "Celulitis [Flegmona] prsta ruke i noge",
        "la": "Cellulitis [Phlegmone] digitorum manus et pedis"
    },
    {
        "icd": "L031",
        "rs": "Celulitis [Flegmona] drugih delova udova",
        "la": "Cellulitis [Phlegmone] extremitatum partium aliarum"
    },
    {
        "icd": "L032",
        "rs": "Celulitis [Crveni vetar] lica",
        "la": "Cellulitis [Erysipelas] faciei"
    },
    {
        "icd": "L033",
        "rs": "Celulitis [Flegmona] trupa",
        "la": "Cellulitis [Phlegmone] trunci"
    },
    {
        "icd": "L038",
        "rs": "Celulitis [Flegmona] drugih lokalizacija",
        "la": "Cellulitis [Phlegmone] locorum aliorum"
    },
    {
        "icd": "L039",
        "rs": "Celulitis [Flegmona], neoznačena",
        "la": "Cellulitis [Phlegmone], non specificata"
    },
    {
        "icd": "L04",
        "rs": "Akutno zapaljenje limfnog čvora",
        "la": "Lymphadenitis acuta"
    },
    {
        "icd": "L040",
        "rs": "Akutno zapaljenje limfnog čvora lica, glave i vrata",
        "la": "Lymphadenitis faciei, capitis et colli acuta"
    },
    {
        "icd": "L041",
        "rs": "Akutno zapaljenje limfnog čvora trupa",
        "la": "Lymphadenitis trunci acuta"
    },
    {
        "icd": "L042",
        "rs": "Akutno zapaljenje limfnog čvora ruke",
        "la": "Lymphadenitis extremitatis superioris acuta"
    },
    {
        "icd": "L043",
        "rs": "Akutno zapaljenje limfnog čvora noge",
        "la": "Lymphadenitis extremitatis inferioris acuta"
    },
    {
        "icd": "L048",
        "rs": "Akutno zapaljenje limfnog čvora drugih lokalizacija",
        "la": "Lymphadenitis acuta locorum aliorum"
    },
    {
        "icd": "L049",
        "rs": "Akutno zapaljenje limfnog čvora, neoznačeno",
        "la": "Lymphadenitis acuta, non specificata"
    },
    {
        "icd": "L05",
        "rs": "Pilonidalna cista",
        "la": "Cystis pilonidalis"
    },
    {
        "icd": "L050",
        "rs": "Pilonidalna cista sa apscesom",
        "la": "Cystis pilonidalis cum abscessu"
    },
    {
        "icd": "L059",
        "rs": "Pilonidalna cista bez apscesa",
        "la": "Cystis pilonidalis sine abscessu"
    },
    {
        "icd": "L08",
        "rs": "Ostale lokalizovane infekcije kože i potkožnog tkiva",
        "la": "Infectiones locales cutis et subcutis aliae"
    },
    {
        "icd": "L080",
        "rs": "Pioderma - gnojno zapaljenje kože",
        "la": "Pyoderma"
    },
    {
        "icd": "L081",
        "rs": "Eritrazma - hronično bakterijsko oboljenje velikih pregiba",
        "la": "Erythrasma"
    },
    {
        "icd": "L088",
        "rs": "Druge označene lokalizovane infekcije kože i potkožnog tkiva",
        "la": "Infectiones locales cutis et subcutis aliae, specificatae"
    },
    {
        "icd": "L089",
        "rs": "Lokazovana infekcija kože i potkožnog tkiva, neoznačena",
        "la": "Infectio localis cutis et subcutis, non specificata"
    },
    {
        "icd": "L10",
        "rs": "Pemfigus – Plikovnjača",
        "la": "Pemphigus"
    },
    {
        "icd": "L100",
        "rs": "Obična plikovnjača",
        "la": "Pemphigus vulgaris"
    },
    {
        "icd": "L101",
        "rs": "Karfiolasta plikovnjača",
        "la": "Pemphigus vegetans"
    },
    {
        "icd": "L102",
        "rs": "Listasta plikovnjača",
        "la": "Pemphigus foliaceus"
    },
    {
        "icd": "L103",
        "rs": "Brazilska plikovnjača",
        "la": "Pemphigus brazilicus [fogo selvagem]"
    },
    {
        "icd": "L104",
        "rs": "Eritematozna plikovnjača",
        "la": "Pemphigus erythematosus"
    },
    {
        "icd": "L105",
        "rs": "Plikovnjača uzrokovana i/ili pokrenuta lekovima",
        "la": "Pemphigus medicamentosus"
    },
    {
        "icd": "L108",
        "rs": "Druga plikovnjača",
        "la": "Pemphigus alius"
    },
    {
        "icd": "L109",
        "rs": "Plikovnjača, neoznačena",
        "la": "Pemphigus, non specificatus"
    },
    {
        "icd": "L11",
        "rs": "Drugi akantolitički poremećaji",
        "la": "Disordines acantolici alii"
    },
    {
        "icd": "L110",
        "rs": "Stečeno orožavanje meškova kože",
        "la": "Keratosis follicularis acquisita"
    },
    {
        "icd": "L111",
        "rs": "Groverovo prolazno akantolitičko oboljenje kože",
        "la": "Dermatosis acantholytica transitoria (Grover)"
    },
    {
        "icd": "L118",
        "rs": "Drugi označeni akantolitički poremećaji",
        "la": "Disordines acantolici alii, specificati"
    },
    {
        "icd": "L119",
        "rs": "Akantolitički poremećaj, neoznačen",
        "la": "Disordines acantolici, non specificati"
    },
    {
        "icd": "L12",
        "rs": "Pemfigoid – oboljenje slično plikovnjači",
        "la": "Pemphigoides"
    },
    {
        "icd": "L120",
        "rs": "Plikoviti pemfigoid",
        "la": "Pemphigoides bullosae"
    },
    {
        "icd": "L121",
        "rs": "Ožiljni pemfigoid",
        "la": "Pemphigoides cicatricialis"
    },
    {
        "icd": "L122",
        "rs": "Dečje hronično plikovito oboljenje kože",
        "la": "Dermatitis bullosa infantilis chronica"
    },
    {
        "icd": "L123",
        "rs": "Stečeno plikovito ljuštenje pokožice",
        "la": "Epidermolysis bullosa acquisita"
    },
    {
        "icd": "L128",
        "rs": "Drugi pemfigoid",
        "la": "Pemphigoides aliae"
    },
    {
        "icd": "L129",
        "rs": "Pemfigoid, neoznačen",
        "la": "Pemphigoides, non specificata"
    },
    {
        "icd": "L13",
        "rs": "Drugi plikoviti poremećaji kože",
        "la": "Disordines bullosum, alii"
    },
    {
        "icd": "L130",
        "rs": "Zapaljenje kože slično herpesu",
        "la": "Dermatitis herpetiformis"
    },
    {
        "icd": "L131",
        "rs": "Gnojno podrožnjačno zapaljenje kože",
        "la": "Dermatitis pustulosa subcornealis"
    },
    {
        "icd": "L138",
        "rs": "Drugi označeni plikoviti poremećaji kože",
        "la": "Dermatoses bullosae aliae, specificatae"
    },
    {
        "icd": "L139",
        "rs": "Plikoviti poremećaj kože, neoznačen",
        "la": "Dermatosis bullosa, non specificata"
    },
    {
        "icd": "L14",
        "rs": "Plikovita oboljenja kože u bolestima klasifikovanim na drugom mestu",
        "la": "Dermatosis bullosa in morbis alibi classificatis"
    },
    {
        "icd": "L20",
        "rs": "Atopijsko zapaljenje kože",
        "la": "Dermatitis atopica"
    },
    {
        "icd": "L200",
        "rs": "Besnierov svrab",
        "la": "Prurigo Besnier"
    },
    {
        "icd": "L208",
        "rs": "Drugo atopijsko zapaljenje kože",
        "la": "Dermatitis atopica alia"
    },
    {
        "icd": "L209",
        "rs": "Atopijsko zapaljenje kože, neoznačeno",
        "la": "Dermatitis atopica, non specifica"
    },
    {
        "icd": "L21",
        "rs": "Seboreja – zapaljenje kože uzrokovano povećanim lučenjem lojnih žlezda",
        "la": "Dermatitis seborrhoeica"
    },
    {
        "icd": "L210",
        "rs": "Seboreja poglavine – temenjača",
        "la": "Dermatitis seborrhoeica capitis"
    },
    {
        "icd": "L211",
        "rs": "Dečja seboreja",
        "la": "Dermatitis seborrhoeica infantilis"
    },
    {
        "icd": "L218",
        "rs": "Druga seboreja",
        "la": "Dermatitis seborrhoeica alia"
    },
    {
        "icd": "L219",
        "rs": "Seboreja, neoznačena",
        "la": "Dermatitis seborrhoeica, non specificata"
    },
    {
        "icd": "L22",
        "rs": "Zapaljenje kože izazvano pelenama",
        "la": "Diaper [napkin] dermatitis"
    },
    {
        "icd": "L23",
        "rs": "Kontaktno alergijsko zapaljenje kože",
        "la": "Dermatitis contacta allergica"
    },
    {
        "icd": "L230",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano metalima",
        "la": "Dermatitis contacta allergica metallogenes"
    },
    {
        "icd": "L231",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano lepkovima",
        "la": "Dermatitis contacta allergica propter materias adhaesivas"
    },
    {
        "icd": "L232",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano kozmetičkim preparatima",
        "la": "Dermatitis contacta allergica cosmeticogenes"
    },
    {
        "icd": "L233",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano lekovima",
        "la": "Dermatitis contacta allergica medicamentosa"
    },
    {
        "icd": "L234",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano bojama",
        "la": "Dermatitis contacta allergica colorogenes"
    },
    {
        "icd": "L235",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano drugim hemijskim sredstvima",
        "la": "Dermatitis contacta allergica propter chemicalias alias"
    },
    {
        "icd": "L236",
        "rs": "Alergijsko kontaktno zapaljenje kože uzrokovano kontaktom kože sa hranom",
        "la": "Dermatitis contacta allergica alimentaria"
    },
    {
        "icd": "L237",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano biljkama, osim uzetih kao hrana",
        "la": "Dermatitis contacta allergica plantogenes, praeter plantas nutritivas"
    },
    {
        "icd": "L238",
        "rs": "Kontaktno alergijsko zapaljenje kože uzrokovano drugim uzročnicima",
        "la": "Dermatitis contacta allergica propter agentes alios"
    },
    {
        "icd": "L239",
        "rs": "Kontaktno alergijsko zapaljenje kože, neoznačenog uzroka",
        "la": "Dermatitis contacta allergica, causae non specificatae."
    },
    {
        "icd": "L24",
        "rs": "Kontaktno nadražajno zapaljenje kože",
        "la": "Dermatitis contacta irritans"
    },
    {
        "icd": "L240",
        "rs": "Kontaktno nadražajno zapaljenje kože izazvano deterdžentima",
        "la": "Dermatitis contacta irritans detergentogenes"
    },
    {
        "icd": "L241",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano uljima i mastima",
        "la": "Dermatitis contacta irritans oleogenes et unguentogenes"
    },
    {
        "icd": "L242",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano rastvorima",
        "la": "Dermatitis contacta irritans solventogenes"
    },
    {
        "icd": "L243",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano kozmetičkim preparatima",
        "la": "Dermatitis contacta irritans cosmeticogens"
    },
    {
        "icd": "L244",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano lekovima",
        "la": "Dermatitis contacta irritans medicamentosa"
    },
    {
        "icd": "L245",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano drugim hemijskim supstancama",
        "la": "Dermatitis contacta irritans chemicalis"
    },
    {
        "icd": "L246",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano kontaktom kože sa hranom",
        "la": "Dermatitis contacta irritans alimentaria"
    },
    {
        "icd": "L247",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano biljkama, osim uzetih kao hrana",
        "la": "Dermatitis contacta irritans plantogenes, praeter plantas nutritivas"
    },
    {
        "icd": "L248",
        "rs": "Kontaktno nadražajno zapaljenje kože uzrokovano drugim faktorima",
        "la": "Dermatitis contacta irritans propter agentes alios"
    },
    {
        "icd": "L249",
        "rs": "Kontaktno nadražajno zapaljenje kože, neoznačeno",
        "la": "Dermatitis contacta irritans causae non specificatae"
    },
    {
        "icd": "L25",
        "rs": "Kontaktno zapaljenje kože, neoznačeno",
        "la": "Dermatitis contacta, non specificata"
    },
    {
        "icd": "L250",
        "rs": "Kontaktno zapaljenje kože uzrokovano kozmetičkim preparatima, neoznačeno",
        "la": "Dermatitis contacta cosmeticogens, non specificata"
    },
    {
        "icd": "L251",
        "rs": "Kontaktno zapaljenje kože uzrokovano lekovima, neoznačeno",
        "la": "Dermatitis contacta medicamentosa, non specificata"
    },
    {
        "icd": "L252",
        "rs": "Kontaktno zapaljenje kože uzrokovano bojama, neoznačeno",
        "la": "Dermatitis contacta colorogenes, non specificata"
    },
    {
        "icd": "L253",
        "rs": "Kontaktno zapaljenje kože uzrokovano drugim hemijskim sredstvima, neoznačeno",
        "la": "Dermatitis contacta chemicalis alia, non specificata"
    },
    {
        "icd": "L254",
        "rs": "Neoznačeno kontaktno zapaljenje kože uzrokovano kontaktom kože sa hranom",
        "la": "Dermatitis contacta alimentaria, non specificata"
    },
    {
        "icd": "L255",
        "rs": "Neoznačeno kontaktno zapaljenje kože uzrokovano biljkama, osim uzetih kao hrana",
        "la": "Dermatitis contacta plantogenes, praeter plantas nutritivas, non specificata"
    },
    {
        "icd": "L258",
        "rs": "Kontaktno zapaljenje kože uzrokovano drugim uzročnicima, neoznačeno",
        "la": "Dermatitis contacta propter agentes alios, non specificata"
    },
    {
        "icd": "L259",
        "rs": "Kontaktno zapaljenje kože, neoznačenog uzroka",
        "la": "Dermatitis contacta, causae non specificatae"
    },
    {
        "icd": "L26",
        "rs": "Zapaljenje kože sa ljuštenjem",
        "la": "Dermatitis exfoliativa"
    },
    {
        "icd": "L27",
        "rs": "Zapaljenje kože uzrokovano progutanom materijom",
        "la": "Dermatitis propter materiam consumptam"
    },
    {
        "icd": "L270",
        "rs": "Generalizovan osip na koži uzrokovan lekovima i medikamentimaa",
        "la": "Eruptio cutis medicamentosa generalisata"
    },
    {
        "icd": "L271",
        "rs": "Lokalizovan kozni osip uzrokovan lekovima i medikamentima",
        "la": "Eruptio cutis medicamentosa localisata"
    },
    {
        "icd": "L272",
        "rs": "Zapaljenje kože uzrokovano konzumiranjem hrane",
        "la": "Dermatitis alimentaria ingestiva"
    },
    {
        "icd": "L278",
        "rs": "Zapaljenje kože uzrokovano drugim progutanim materijama",
        "la": "Dermatitis propter materias consumptas alias"
    },
    {
        "icd": "L279",
        "rs": "Zapaljenje kože uzrokovano progutanom nespecifikovanom materijom",
        "la": "Dermatitis propter materiam consumptam non specificatum"
    },
    {
        "icd": "L28",
        "rs": "Običan hroničan lišaj i svrab",
        "la": "Lichen simplex chronicus et prurigo"
    },
    {
        "icd": "L280",
        "rs": "Običan hroničan lišaj",
        "la": "Lichen simplex chronicus"
    },
    {
        "icd": "L281",
        "rs": "Svrab i nodozne promene",
        "la": "Prurigo nodularis"
    },
    {
        "icd": "L282",
        "rs": "Druga pruriginozna dermatoza",
        "la": "Prurigo alia"
    },
    {
        "icd": "L29",
        "rs": "Svrab",
        "la": "Pruritus"
    },
    {
        "icd": "L290",
        "rs": "Svrab anusa",
        "la": "Pruritus ani"
    },
    {
        "icd": "L291",
        "rs": "Svrab skrotuma",
        "la": "Pruritus scroti"
    },
    {
        "icd": "L292",
        "rs": "Svrab stidnice",
        "la": "Pruritus vulvae"
    },
    {
        "icd": "L293",
        "rs": "Svrab anusa i spoljnih polnih organa, neoznačen",
        "la": "Pruritus anogenitalis, non specificatus"
    },
    {
        "icd": "L298",
        "rs": "Svrab, drugi",
        "la": "Pruritus alia"
    },
    {
        "icd": "L299",
        "rs": "Svrab, neoznačen",
        "la": "Pruritus, non specificatus"
    },
    {
        "icd": "L30",
        "rs": "Ostala zapaljenja kože",
        "la": "Dermatitis alia"
    },
    {
        "icd": "L300",
        "rs": "Zapaljenje kože u obliku metalnog novca",
        "la": "Dermatitis nummularis"
    },
    {
        "icd": "L301",
        "rs": "Poremećaj znojenja",
        "la": "Dyshidrosis [pompholyx]"
    },
    {
        "icd": "L302",
        "rs": "Autosenzibilizacija kože",
        "la": "Autosensibilisatio cutanea"
    },
    {
        "icd": "L303",
        "rs": "Zarazno zapaljenje kože",
        "la": "Dermatitis infectiva"
    },
    {
        "icd": "L304",
        "rs": "Zapaljeni ojed pregiba kože",
        "la": "Erythema intertriginosum"
    },
    {
        "icd": "L305",
        "rs": "Beličaste ploče sa sitnim mekinjastim perutanjem",
        "la": "Pityriasis alba"
    },
    {
        "icd": "L308",
        "rs": "Drugo označeno zapaljenje kože",
        "la": "Dermatitis alia, specificata"
    },
    {
        "icd": "L309",
        "rs": "Zapaljenje kože, neoznačeno",
        "la": "Dermatitis, non specificata"
    },
    {
        "icd": "L40",
        "rs": "Psoriaza - Ljuskavica",
        "la": "Psoriasis"
    },
    {
        "icd": "L400",
        "rs": "Obična psorijaza",
        "la": "Psoriasis vulgaris"
    },
    {
        "icd": "L401",
        "rs": "Generalizovana gnojna psorijaza",
        "la": "Psoriasis pustulosa generalisata"
    },
    {
        "icd": "L402",
        "rs": "Neprekidno zapaljenje kože udova",
        "la": "Acrodermatitis continua"
    },
    {
        "icd": "L403",
        "rs": "Gnojnice na dlanovima i tabanima",
        "la": "Pustulosis palmaris et plantaris"
    },
    {
        "icd": "L404",
        "rs": "Psoriaza u obliku kapi",
        "la": "Psoriasis guttata"
    },
    {
        "icd": "L405",
        "rs": "Psoriaza sa promenama na zglobovima",
        "la": "Psoriasis arthropathica"
    },
    {
        "icd": "L408",
        "rs": "Druga psoriaza",
        "la": "Psoriasis alia"
    },
    {
        "icd": "L409",
        "rs": "Psoriaza, neoznačena",
        "la": "Psoriasis non specificata"
    },
    {
        "icd": "L41",
        "rs": "Parapsorijaza - ljuskavičaste promene kože slične psorijazi",
        "la": "Parapsoriasis"
    },
    {
        "icd": "L410",
        "rs": "Akutno perutanje slično lišaju i velikim boginjama",
        "la": "Pityriasis lichenoides et varioliformis acuta"
    },
    {
        "icd": "L411",
        "rs": "Hronično perutanje slično lišaju",
        "la": "Pityriasis lichenoides chronica"
    },
    {
        "icd": "L412",
        "rs": "Limfomatoidna papuloza",
        "la": "Papulosis lymphomatoides"
    },
    {
        "icd": "L413",
        "rs": "Parapsoriaza u malim plakovima",
        "la": "Parapsoriasis microlamellaris"
    },
    {
        "icd": "L414",
        "rs": "Parapsorijaza u velikim plakovima",
        "la": "Parapsoriasis macrolamellaris"
    },
    {
        "icd": "L415",
        "rs": "Mrežasta parapsoriaza",
        "la": "Parapsoriasis retiformis"
    },
    {
        "icd": "L418",
        "rs": "Druga parapsoriaza",
        "la": "Parapsoriasis alia"
    },
    {
        "icd": "L419",
        "rs": "Parapsoriaza, neoznačena",
        "la": "Parapsoriasis, non specificata"
    },
    {
        "icd": "L42",
        "rs": "Ružičaste kružne pločice sa perutanjem",
        "la": "Pityriasis rosea"
    },
    {
        "icd": "L43",
        "rs": "Ravan lišaj",
        "la": "Lichen planus"
    },
    {
        "icd": "L430",
        "rs": "Veći ravan lišaj",
        "la": "Lichen planus hypertrophicus"
    },
    {
        "icd": "L431",
        "rs": "Plikovit ravan lišaj",
        "la": "Lichen planus bullosus"
    },
    {
        "icd": "L432",
        "rs": "Lihenoidna reakcija na lekove",
        "la": "Lichenoid medicamentosus"
    },
    {
        "icd": "L433",
        "rs": "Subakutni ravan lišaj (aktivni)",
        "la": "Lichen planus subacutus (activum)"
    },
    {
        "icd": "L438",
        "rs": "Drugi ravan lišaj",
        "la": "Lichen planus alius"
    },
    {
        "icd": "L439",
        "rs": "Ravan lišaj, neoznačen",
        "la": "Lichen planus, non specificatus"
    },
    {
        "icd": "L44",
        "rs": "Ostali papulo-ljuskasti poremećaji",
        "la": "Disordines papulosquamosi alii"
    },
    {
        "icd": "L440",
        "rs": "Hronično crveno perutanje",
        "la": "Pityriasis rubra pilaris"
    },
    {
        "icd": "L441",
        "rs": "Lišaj sa sitnim papulama",
        "la": "Lichen nitidus"
    },
    {
        "icd": "L442",
        "rs": "Lišaj sa linearnim papulama",
        "la": "Lichen striatus"
    },
    {
        "icd": "L443",
        "rs": "Crveni lišaj sa papulama u obliku ogrlice",
        "la": "Lichen ruber moniliformis"
    },
    {
        "icd": "L444",
        "rs": "Dečje papulo-ljuskasto zapaljenje kože krajeva udova",
        "la": "Acrodermatitis papulosa infantilis [Giannotti-Crosti]"
    },
    {
        "icd": "L448",
        "rs": "Drugi označeni papulo-ljuskasti poremećaji",
        "la": "Disordines papulosquamosi alii, specificati"
    },
    {
        "icd": "L449",
        "rs": "Papulo-ljuskast poremećaj, neoznačen",
        "la": "Disordo papulosquamosis, non specificatus"
    },
    {
        "icd": "L45",
        "rs": "Papulo-ljuskasti poremećaji u bolestima koje su klasifikovane na drugom mestu",
        "la": "Disordines papulosquamosi in morbis alibi classificatis"
    },
    {
        "icd": "L50",
        "rs": "Koprivnjača",
        "la": "Urticaria"
    },
    {
        "icd": "L500",
        "rs": "Alergijska koprivnjača",
        "la": "Urticaria allergica"
    },
    {
        "icd": "L501",
        "rs": "Idiopatska koprivnjača",
        "la": "Urticaria idiopathica"
    },
    {
        "icd": "L502",
        "rs": "Koprivnjača na hladnoću i toplotu",
        "la": "Urticaria propter frigorem et calorem"
    },
    {
        "icd": "L503",
        "rs": "Koprivnjača uzrokovana češanjem",
        "la": "Urticaria dermatographica"
    },
    {
        "icd": "L504",
        "rs": "Koprivnjača uzrokovana vibracijama",
        "la": "Urticaria vibratoria"
    },
    {
        "icd": "L505",
        "rs": "Koprivnjača uzrokovana holinergijskim lekovima",
        "la": "Urticaria cholinergica"
    },
    {
        "icd": "L506",
        "rs": "Kontaktna koprivnjača",
        "la": "Urticaria contacta"
    },
    {
        "icd": "L508",
        "rs": "Druga koprivnjača",
        "la": "Urticaria alia"
    },
    {
        "icd": "L509",
        "rs": "Koprivnjača, neoznačena",
        "la": "Urticaria, non specificata"
    },
    {
        "icd": "L51",
        "rs": "Raznoliki eritem",
        "la": "Erythema multiforme"
    },
    {
        "icd": "L510",
        "rs": "Raznoliki eritem bez plikova",
        "la": "Erythema multiforme non bullosum"
    },
    {
        "icd": "L511",
        "rs": "Eritem sa plikovima, raznoliki",
        "la": "Erythema bullosum multiforme"
    },
    {
        "icd": "L512",
        "rs": "Toksička epidermalna nekroliza (Lyell)",
        "la": "Necrolysis epidermatica toxica (Lyell)"
    },
    {
        "icd": "L518",
        "rs": "Drugi raznoliki eritem",
        "la": "Erythema multiforme aliud"
    },
    {
        "icd": "L519",
        "rs": "Raznoliki eritem, neoznačen",
        "la": "Erythema multiforme, non specificatum"
    },
    {
        "icd": "L52",
        "rs": "Eritem sa nodusima",
        "la": "Erythema nodosum"
    },
    {
        "icd": "L53",
        "rs": "Druga eritematozna stanja",
        "la": "Conditiones erythematosi, alii"
    },
    {
        "icd": "L530",
        "rs": "Eritem uzrokovan toksinima",
        "la": "Erythema toxicum"
    },
    {
        "icd": "L531",
        "rs": "Prstenasti centrifugalni eritem",
        "la": "Erythema annulare centrifugum"
    },
    {
        "icd": "L532",
        "rs": "Oivičeni eritem",
        "la": "Erythema marginatum"
    },
    {
        "icd": "L533",
        "rs": "Drugi hronični uobličeni eritem",
        "la": "Erythema figuratum chronicum aliud"
    },
    {
        "icd": "L538",
        "rs": "Druga označena eritematozna stanja",
        "la": "Conditiones erythematosus alii, specificati"
    },
    {
        "icd": "L539",
        "rs": "Eritematozno stanje, neoznačeno",
        "la": "Conditio erythematosa, non specificata"
    },
    {
        "icd": "L54",
        "rs": "Eritem u bolestima koje su klasifikovane na drugom mestu",
        "la": "Erythema in morbis alibi classificatis"
    },
    {
        "icd": "L540",
        "rs": "Oivičeni eritem kod akutne reumatske bolesti",
        "la": "Erythema marginatum in febri rheumatica acuta"
    },
    {
        "icd": "L548",
        "rs": "Eritem kod ostalih bolesti koje su klasifikovane na drugom mestu",
        "la": "Erythema in morbis aliis alibi classificatis"
    },
    {
        "icd": "L55",
        "rs": "Opekotina uzrokovana sunčevim zracima",
        "la": "Combustio solaris"
    },
    {
        "icd": "L550",
        "rs": "Opekotina uzrokovana sunčevim zracima, prvog stepena",
        "la": "Combustio solaris gradus minoris"
    },
    {
        "icd": "L551",
        "rs": "Opekotina uzrokovana sunčevim zracima, drugog stepena",
        "la": "Combustio solaris gradus medii"
    },
    {
        "icd": "L552",
        "rs": "Opekotina uzrokovana sunčevim zracima, trećeg stepena",
        "la": "Combustio solaris gradus majoris"
    },
    {
        "icd": "L558",
        "rs": "Druga opekotina uzrokovana sunčevim zracima",
        "la": "Combustio solaris alia"
    },
    {
        "icd": "L559",
        "rs": "Opekotina uzrokovana sunčevim zracima, neoznačena",
        "la": "Combustio solaris, non specificata"
    },
    {
        "icd": "L56",
        "rs": "Druge akutne promene na koži uzrokovane ultraljubičastim zračenjem",
        "la": "Alterationes cutis acutae propter radiationem ultraviolaceam, aliae"
    },
    {
        "icd": "L560",
        "rs": "Fototoksička reakcija na lek",
        "la": "Reactio phototoxica medicamentosa"
    },
    {
        "icd": "L561",
        "rs": "Fotoalergijska reakcija na lek",
        "la": "Reactio photoallergica medicamentosa"
    },
    {
        "icd": "L562",
        "rs": "Fotokontaktno zapaljenje kože",
        "la": "Dermatitis photocontacta [berloque dermatitis]"
    },
    {
        "icd": "L563",
        "rs": "Koprivnjača uzrokovana sunčevim zracima",
        "la": "Urticaria solaris"
    },
    {
        "icd": "L564",
        "rs": "Polimorfni osip uzrokovan svetlom",
        "la": "Eruptio photogenes polymorpha"
    },
    {
        "icd": "L568",
        "rs": "Druge označene akutne promene na koži uzrokovane ultraljubičastim zračenjem",
        "la": "Alterationes cutis acutae propter radiationem ultraviolaceam, specificatae, aliae"
    },
    {
        "icd": "L569",
        "rs": "Akutne promene na koži uzrokovane ultraljubičastim zračenjem, neoznačene",
        "la": "Alterationes acutae cutis propter radiationem ultraviolaceam, non specificatae, aliae"
    },
    {
        "icd": "L57",
        "rs": "Promene kože uzrokovane hroničnom izloženošću nejonizirajućim zračenjima",
        "la": "Alterationes cutis propter expositionem chronicum in radiatione non ionica"
    },
    {
        "icd": "L570",
        "rs": "Aktinička keratoza",
        "la": "Keratosis actinica"
    },
    {
        "icd": "L571",
        "rs": "Aktinička retikuloza",
        "la": "Reticulosis actinica"
    },
    {
        "icd": "L572",
        "rs": "Romboidna promena kože potiljka",
        "la": "Cutis rhomboidalis nuchae"
    },
    {
        "icd": "L573",
        "rs": "Civateova poikiloderma",
        "la": "Poikiloderma Civatte"
    },
    {
        "icd": "L574",
        "rs": "Staračka mlitavost kože",
        "la": "Cutis laxa senilis"
    },
    {
        "icd": "L575",
        "rs": "Aktinički granulom",
        "la": "Granuloma actinicum"
    },
    {
        "icd": "L578",
        "rs": "Druge promene kože uzrokovane hroničnom izloženošću nejonizirajućim zračenjima",
        "la": "Alterationes cutis propter expositionem chronicum in radiatione non ionica, aliae"
    },
    {
        "icd": "L579",
        "rs": "Promene kože uzrokovane hroničnom izloženošću nejonizirajućim zračenjima, neoznačene",
        "la": "Alterationes cutis propter expositionem chronicum in radiatione non ionica, non spacificatae"
    },
    {
        "icd": "L58",
        "rs": "Zapaljenje kože uzrokovano X zracima",
        "la": "Radiodermatitis"
    },
    {
        "icd": "L580",
        "rs": "Akutno zapaljenje kože uzrokovano X - zracima",
        "la": "Radiodermatitis acuta"
    },
    {
        "icd": "L581",
        "rs": "Hronično zapaljenje kože uzrokovano X - zracima",
        "la": "Radiodermatitis chronica"
    },
    {
        "icd": "L589",
        "rs": "Zapaljenje kože uzrokovano X – zracima, neoznačeno",
        "la": "Radiodermatitis, non specificata"
    },
    {
        "icd": "L59",
        "rs": "Drugi poremećaji kože i potkožnog tkiva u vezi sa zračenjem",
        "la": "Disordines cutis et subcutis propter radiationem, alii"
    },
    {
        "icd": "L590",
        "rs": "Eritem uzrokovan toplotom (zapaljenje kože uzrokovano toplotom)",
        "la": "Erythema propter ignem [dermatitis propter ignem]"
    },
    {
        "icd": "L598",
        "rs": "Drugi označeni poremećaji kože i potkožnog tkiva u vezi sa zračenjem",
        "la": "Disordines cutis et subcutis propter radiationem, specificati, alii"
    },
    {
        "icd": "L599",
        "rs": "Poremećaj kože i potkožnog tkiva u vezi sa zračenjem, neoznačen",
        "la": "Disordo cutis et subcutis propter radiationem, non specificatus"
    },
    {
        "icd": "L60",
        "rs": "Poremećaji nokta",
        "la": "Disordines unguium"
    },
    {
        "icd": "L600",
        "rs": "Urastao nokat",
        "la": "Unguis incarnatus"
    },
    {
        "icd": "L601",
        "rs": "Otpadanje nokta",
        "la": "Onycholysis"
    },
    {
        "icd": "L602",
        "rs": "Kandžasti nokti",
        "la": "Onychogryphosis"
    },
    {
        "icd": "L603",
        "rs": "Distrofija nokta (loša ishrana i rast nokta)",
        "la": "Onychodystrophia"
    },
    {
        "icd": "L604",
        "rs": "Beauove brazde",
        "la": "Lineae Beau"
    },
    {
        "icd": "L605",
        "rs": "Sindrom žutog nokta",
        "la": "Syndroma unguis flavi"
    },
    {
        "icd": "L608",
        "rs": "Drugi poremećaji nokata",
        "la": "Disordines unguium, alii"
    },
    {
        "icd": "L609",
        "rs": "Poremećaj nokata, neoznačen",
        "la": "Disordo unguium non specificatus"
    },
    {
        "icd": "L62",
        "rs": "Poremećaji nokta kod bolesti koje su klasifikovane na drugom mestu",
        "la": "Disordines unguium in morbis alibi classificatis"
    },
    {
        "icd": "L620",
        "rs": "Zadebljanje nokata kod kožno-pokosičnog oboljenja",
        "la": "Onychophyma in pachydermoperiosteosi"
    },
    {
        "icd": "L628",
        "rs": "Poremećaji nokata kod drugih bolesti koje su klasifikovane na drugom mstu",
        "la": "Disordines unguium in morbis aliis alibi classificatis"
    },
    {
        "icd": "L63",
        "rs": "Kružno opadanje kose",
        "la": "Alopecia areata"
    },
    {
        "icd": "L630",
        "rs": "Potpuno opadanje kose",
        "la": "Alopecia totalis (capitis)"
    },
    {
        "icd": "L631",
        "rs": "Opšte opadanje dlaka",
        "la": "Alopecia universalis"
    },
    {
        "icd": "L632",
        "rs": "Opadanje kose duž ivice skalpa",
        "la": "Ophiasis"
    },
    {
        "icd": "L638",
        "rs": "Drugo kružno opadanja kose",
        "la": "Alopecia areata, alia"
    },
    {
        "icd": "L639",
        "rs": "Kružno opadanje kose, neoznačeno",
        "la": "Alopecia areata, non specificata"
    },
    {
        "icd": "L64",
        "rs": "Androgena ćelavost",
        "la": "Alopecia androgenes"
    },
    {
        "icd": "L640",
        "rs": "Androgeno opadanje kose uzrokovano lekom",
        "la": "Alopecia androgenes medicamentosa"
    },
    {
        "icd": "L648",
        "rs": "Druga androgena opadanja kose",
        "la": "Alopecia androgenes alia"
    },
    {
        "icd": "L649",
        "rs": "Androgeno opadanje kose, neoznačeno",
        "la": "Alopecia androgenes, non specificata"
    },
    {
        "icd": "L65",
        "rs": "Drugo opadanje kose bez ožiljaka",
        "la": "Alopecia alia non cicatricosa"
    },
    {
        "icd": "L650",
        "rs": "Telogeno difuzno opadanje kose",
        "la": "Effluvium telogenes"
    },
    {
        "icd": "L651",
        "rs": "Anageno difuzno opadanje kose",
        "la": "Effluvium anagenes"
    },
    {
        "icd": "L652",
        "rs": "Mucinozno opadanje kose",
        "la": "Alopecia mucinosa"
    },
    {
        "icd": "L658",
        "rs": "Ostalo označeno opadanje kose bez ožiljaka",
        "la": "Alopecia alia non cicatricosa, specificata"
    },
    {
        "icd": "L659",
        "rs": "Opadanje kose bez ožiljaka, neoznačeno",
        "la": "Alopecia non cicatricosa, non specificata"
    },
    {
        "icd": "L66",
        "rs": "Ožiljno opadanje kose",
        "la": "Alopecia cicatricosa"
    },
    {
        "icd": "L660",
        "rs": "Ožiljno opadanje kose na malim površinama",
        "la": "Pseudopelada"
    },
    {
        "icd": "L661",
        "rs": "Lišaj sa opadanjem kose",
        "la": "Lichen planopilaris"
    },
    {
        "icd": "L662",
        "rs": "Zapaljenje korena dlake sa opadanjem",
        "la": "Folliculitis decalvans"
    },
    {
        "icd": "L663",
        "rs": "Gnojno zapaljenje oko korena kose",
        "la": "Perifolliculitis capitis abscedens"
    },
    {
        "icd": "L664",
        "rs": "Zapaljenje korena dlake sa mrežastim eritemom i ožiljcima",
        "la": "Folliculitis ulerythematosa reticulata"
    },
    {
        "icd": "L668",
        "rs": "Drugo ožiljno opadanje kose",
        "la": "Alopecia cicatricosa, alia"
    },
    {
        "icd": "L669",
        "rs": "Ožiljno opadanje kose, neoznačeno",
        "la": "Alopecia cicatricosa, non specificata"
    },
    {
        "icd": "L67",
        "rs": "Nenormalnosti boje i stabla dlaka",
        "la": "Abnormitates coloris et scapi pilorum"
    },
    {
        "icd": "L670",
        "rs": "Nodozna lomljivost dlaka",
        "la": "Trichorrhexis nodosa"
    },
    {
        "icd": "L671",
        "rs": "Varijacije boje dlaka",
        "la": "Variationes coloris pilorum"
    },
    {
        "icd": "L678",
        "rs": "Druge nenormalnosti boje i stabla dlaka",
        "la": "Abnormitates coloris et scapi pilorum, aliae"
    },
    {
        "icd": "L679",
        "rs": "Nenormalnosti boje i stabla dlaka, neoznačene",
        "la": "Abnormitates coloris et scapi pilorum, non specificati"
    },
    {
        "icd": "L68",
        "rs": "Prekomerna maljavost",
        "la": "Hypertrichosis"
    },
    {
        "icd": "L680",
        "rs": "Pojačana maljavost",
        "la": "Hirsutism"
    },
    {
        "icd": "L681",
        "rs": "Izrazita stečena maljavost",
        "la": "Hypertrichosis lanuginosa acquisita"
    },
    {
        "icd": "L682",
        "rs": "Lokalizovana prekomerna maljavost",
        "la": "Hypertrichosis localisata"
    },
    {
        "icd": "L683",
        "rs": "Prekomerno rastenje dlaka",
        "la": "Polytrichia"
    },
    {
        "icd": "L688",
        "rs": "Druge prekomerne maljavosti",
        "la": "Hypertrichosis alia"
    },
    {
        "icd": "L689",
        "rs": "Prekomerna maljavost, neoznačena",
        "la": "Hypertrichosis, non specificata"
    },
    {
        "icd": "L70",
        "rs": "Akne - Bubuljice",
        "la": "Acnae"
    },
    {
        "icd": "L700",
        "rs": "Obicne akne",
        "la": "Acnae vulgares"
    },
    {
        "icd": "L701",
        "rs": "Grupisane akne",
        "la": "Acnae conglobatae"
    },
    {
        "icd": "L702",
        "rs": "Akne slične velikim boginjama",
        "la": "Acnae varioliformes"
    },
    {
        "icd": "L703",
        "rs": "Tropske akne",
        "la": "Acnae tropicae"
    },
    {
        "icd": "L704",
        "rs": "Dečje akne",
        "la": "Acnae infantiles"
    },
    {
        "icd": "L705",
        "rs": "Akne u mladih uzrokovane češanjem",
        "la": "Acnae excoriatae iuveniles"
    },
    {
        "icd": "L708",
        "rs": "Druge akne",
        "la": "Acnae, aliae"
    },
    {
        "icd": "L709",
        "rs": "Akne, neoznačene",
        "la": "Acnae, non specificatae"
    },
    {
        "icd": "L71",
        "rs": "Rozacea",
        "la": "Rosacea"
    },
    {
        "icd": "L710",
        "rs": "Zapaljenje kože oko usta",
        "la": "Dermatitis perioralis"
    },
    {
        "icd": "L711",
        "rs": "Kvrgav nos",
        "la": "Rhinophyma"
    },
    {
        "icd": "L718",
        "rs": "Druga rozacea",
        "la": "Rosacea alia"
    },
    {
        "icd": "L719",
        "rs": "Rozacea, neoznačena",
        "la": "Rosacea, non specificata"
    },
    {
        "icd": "L72",
        "rs": "Ciste korena dlake kože i potkožnog tkiva",
        "la": "Cystes folliculares cutis et subcutis"
    },
    {
        "icd": "L720",
        "rs": "Potkožna cista",
        "la": "Cystis epidermalis"
    },
    {
        "icd": "L721",
        "rs": "Cista meška dlake",
        "la": "Cystis trichilemmalis"
    },
    {
        "icd": "L722",
        "rs": "Višestruka lojna cista",
        "la": "Steatocystoma multiplex"
    },
    {
        "icd": "L728",
        "rs": "Druge ciste korena dlake kože i potkožnog tkiva",
        "la": "Cystes folliculares cutis et subcutis, aliae"
    },
    {
        "icd": "L729",
        "rs": "Ciste korena dlake kože i potkožnog tkiva, neoznačene",
        "la": "Cystes folliculares cutis et subcutis, non specificatae"
    },
    {
        "icd": "L73",
        "rs": "Drugi folikularni poremećaji",
        "la": "Disordines folliculares alii"
    },
    {
        "icd": "L730",
        "rs": "Keloidne akne",
        "la": "Acnee keloides"
    },
    {
        "icd": "L731",
        "rs": "Lažno zapaljenje korena dlake brade",
        "la": "Pseudofolliculitis barbae"
    },
    {
        "icd": "L732",
        "rs": "Gnojno zapaljenje znojnih žlezda",
        "la": "Hidradenitis suppurativa"
    },
    {
        "icd": "L738",
        "rs": "Ostali označeni folikularni poremećaji",
        "la": "Disordines folliculares alii, specificati"
    },
    {
        "icd": "L739",
        "rs": "Folikularni poremećaj, neoznačen",
        "la": "Disordo folliculares, non specificatus"
    },
    {
        "icd": "L74",
        "rs": "Poremećaji lučenja ekrinih znojnih žlezda",
        "la": "Disordines sudoris eccrinae"
    },
    {
        "icd": "L740",
        "rs": "Crvena prosasta ospa",
        "la": "Miliaria rubra"
    },
    {
        "icd": "L741",
        "rs": "Bistra prosasta ospa",
        "la": "Miliaria crystallina"
    },
    {
        "icd": "L742",
        "rs": "Duboka prosasta ospa",
        "la": "Miliaria profunda"
    },
    {
        "icd": "L743",
        "rs": "Prosasta ospa, neoznačena",
        "la": "Miliaria, non specificata"
    },
    {
        "icd": "L744",
        "rs": "Nelučenje znoja",
        "la": "Anhidrosis"
    },
    {
        "icd": "L748",
        "rs": "Drugi poremećaji lučenja ekrinih znojnih žlezda",
        "la": "Disordines sudoris eccrinae alii"
    },
    {
        "icd": "L749",
        "rs": "Poremećaj lučenja ekrinih znojnih žlezda, neoznačen",
        "la": "Disordo sudoris eccrinae, non specificatus"
    },
    {
        "icd": "L75",
        "rs": "Poremećaji lučenja apokrinih znojnih žlezda",
        "la": "Disordines sudoris apoccrinae"
    },
    {
        "icd": "L750",
        "rs": "Znoj neprijatnog mirisa",
        "la": "Bromhidrosis"
    },
    {
        "icd": "L751",
        "rs": "Obojeni znoj",
        "la": "Chromhidrosis"
    },
    {
        "icd": "L752",
        "rs": "Apokrina prosasta ospa",
        "la": "Miliaria apocrinis"
    },
    {
        "icd": "L758",
        "rs": "Drugi poremećaji lučenja apokrinih znojnih žlezda",
        "la": "Disordines sudoris apoccrinae alii"
    },
    {
        "icd": "L759",
        "rs": "Poremeća lučenja apokrinih znojnih žlezda, neoznačen",
        "la": "Disordo sudoris apoccrinae, non specificatus"
    },
    {
        "icd": "L80",
        "rs": "Vitiligo - stečene depigmentovane mrlje kože",
        "la": "Vitiligo"
    },
    {
        "icd": "L81",
        "rs": "Drugi poremećaji pigmentacije kože",
        "la": "Disordines pigmentationis cutis alii"
    },
    {
        "icd": "L810",
        "rs": "Pojačana pigmentacija posle zapaljenja",
        "la": "Hyperpigmentatio postinflammatoria"
    },
    {
        "icd": "L811",
        "rs": "Zeleno-žute mrlje na kozi lica",
        "la": "Chloasma"
    },
    {
        "icd": "L812",
        "rs": "Pege",
        "la": "Ephelides"
    },
    {
        "icd": "L813",
        "rs": "Mrlje (pege) boje bele kafe",
        "la": "Maculae „Café au lait”"
    },
    {
        "icd": "L814",
        "rs": "Druga pojačana melaninska pigmentacija",
        "la": "Hyperpigmentatio melaninotica alia"
    },
    {
        "icd": "L815",
        "rs": "Leukoderma - pojava belih pega na koži, neklasifikovana na drugom mestu",
        "la": "Leukoderma non alibi classificata"
    },
    {
        "icd": "L816",
        "rs": "Drugi poremećaji sa smanjenim stvaranjem melanina",
        "la": "Disordines cum formatione melaninica deminuta aliae"
    },
    {
        "icd": "L817",
        "rs": "Hronična pigmentna tačkasta krvarenja",
        "la": "Purpura pigmentosa chronica"
    },
    {
        "icd": "L818",
        "rs": "Drugi označeni poremećaji pigmentacije kože",
        "la": "Disordines pigmentationes cutis alii, specificati"
    },
    {
        "icd": "L819",
        "rs": "Poremećaj pigmentacije, neoznačen",
        "la": "Disordo pigmentationes, non specificatus"
    },
    {
        "icd": "L82",
        "rs": "Seboreičko orožavanje kože",
        "la": "Keratosis seborrhoeica"
    },
    {
        "icd": "L83",
        "rs": "Crnkasta akantoza kože",
        "la": "Acanthosis nigricans"
    },
    {
        "icd": "L84",
        "rs": "Žuljevi i žuljevitost",
        "la": "Clavi et callositates"
    },
    {
        "icd": "L85",
        "rs": "Druga epidermalna zadebljanja",
        "la": "Crassificatio epidermidis alia"
    },
    {
        "icd": "L850",
        "rs": "Stečena ihtioza",
        "la": "Ichthyosis acquisita"
    },
    {
        "icd": "L851",
        "rs": "Stecena keratoza dlanova i tabana",
        "la": "Keratosis (keratoderma) palmaris et plantaris acquisita"
    },
    {
        "icd": "L852",
        "rs": "Tačkasto orožavanje kože dlanova i tabana",
        "la": "Keratosis punctata (palmaris et plantaris)"
    },
    {
        "icd": "L853",
        "rs": "Suva koža",
        "la": "Xerosis cutis (sin. Dermatitis sicca)"
    },
    {
        "icd": "L858",
        "rs": "Druga označena epidermalna zadebljanja",
        "la": "Crassificatio epidermidis alia, specificata"
    },
    {
        "icd": "L859",
        "rs": "Epidermalna zadebljanja, neoznačena",
        "la": "Crassificatio epidermidis, non specificata"
    },
    {
        "icd": "L86",
        "rs": "Keratodermija u bolestima klasifikovanim na drugom mestu",
        "la": "Keratoderma in morbis alibi classificatis"
    },
    {
        "icd": "L87",
        "rs": "Poremećaji lučenja preko pokožice",
        "la": "Disordines eliminationis transepidermalis"
    },
    {
        "icd": "L870",
        "rs": "Kyrleovo orožavanje folikula i njegove okoline sa prodiranjem u kožu",
        "la": "Keratosis follicularis et parafollicularis in cutem penetrans [Kyrle]"
    },
    {
        "icd": "L871",
        "rs": "Reaktivna perforativna kolagenoza",
        "la": "Collagenosis perforans reactiva"
    },
    {
        "icd": "L872",
        "rs": "Pokretna perforativna elastoza",
        "la": "Elastosis perforans serpiginosa"
    },
    {
        "icd": "L878",
        "rs": "Drugi poremećaji lučenja preko pokožice",
        "la": "Disordines eliminationis transepidermalis alii"
    },
    {
        "icd": "L879",
        "rs": "Poremećaj lučenja preko pokožice, neoznačen",
        "la": "Disordo eliminationis transepidermalis, non specificatus"
    },
    {
        "icd": "L88",
        "rs": "Gnojno-gangrenozno zapaljenje kože",
        "la": "Pyoderma gangrenosum"
    },
    {
        "icd": "L89",
        "rs": "Dekubitus",
        "la": "Ulcus decubitale"
    },
    {
        "icd": "L890",
        "rs": "Dekubitus I stepena",
        "la": "Ulcus decubitale gradus I"
    },
    {
        "icd": "L891",
        "rs": "Dekubitus II stepena",
        "la": "Ulcus decubitale gradus II"
    },
    {
        "icd": "L892",
        "rs": "Dekubitus III stepena",
        "la": "Ulcus decubitale gradus III"
    },
    {
        "icd": "L893",
        "rs": "Dekubitus IV stepena",
        "la": "Ulcus decubitale gradus IV"
    },
    {
        "icd": "L899",
        "rs": "Dekubitus, neoznačen",
        "la": "Ulcus decubitale, non specificatus"
    },
    {
        "icd": "L90",
        "rs": "Atrofički poremećaji kože",
        "la": "Disordines atrophicae cutis"
    },
    {
        "icd": "L900",
        "rs": "Sklerozni lišaj i atrofični lišaj",
        "la": "Lichen sclerosus et lichen atrophicus"
    },
    {
        "icd": "L901",
        "rs": "Schweninger-Buzzijeva anetodermija - ograničena mlitavost i atrofija kože",
        "la": "Anetodermia Schweninger-Buzzi"
    },
    {
        "icd": "L902",
        "rs": "Jadassohn-Pellizzarijeva anetodermija",
        "la": "Anetodermia Jadassohn-Pellizzari"
    },
    {
        "icd": "L903",
        "rs": "Pasini-Pierinijeva atrofija kože",
        "la": "Atrophodermia Pasini and Pierini"
    },
    {
        "icd": "L904",
        "rs": "Hronicno atrofičko zapaljenje kože krajeva udova",
        "la": "Acrodermatitis chronica atrophicans"
    },
    {
        "icd": "L905",
        "rs": "Ožiljna stanja kože i pojačano stvaranje vezivnog tkiva kože",
        "la": "Status cicatriciales et fibrosis cutis"
    },
    {
        "icd": "L906",
        "rs": "Atrofičke strije",
        "la": "Striae atrophicae"
    },
    {
        "icd": "L908",
        "rs": "Drugi atrofički poremećaji kože",
        "la": "Disordines atrophicae cutis, alii"
    },
    {
        "icd": "L909",
        "rs": "Atrofički poremećaj kože, neoznačen",
        "la": "Disordo atrophicae cutis, non specificatus"
    },
    {
        "icd": "L91",
        "rs": "Hipertrofični poremećaji (uvećanja, zadebljanja) kože",
        "la": "Disordines hypertrophici cutis"
    },
    {
        "icd": "L910",
        "rs": "Hipertrofični ožiljak",
        "la": "Cicatrix keloides"
    },
    {
        "icd": "L918",
        "rs": "Drugi hipertrofični poremećaji kože",
        "la": "Disordines hypertrophicae cutis alii"
    },
    {
        "icd": "L919",
        "rs": "Hipertrofični poremećaj kože, neoznačen",
        "la": "Disordo hypertrophicae cutis, non specificatus"
    },
    {
        "icd": "L92",
        "rs": "Granulomatoza - višestruki zrnasti tumori kože i potkožnog tkiva",
        "la": "Cutis et subcutis granulomatosa"
    },
    {
        "icd": "L920",
        "rs": "Prstenasti zrnasti tumor (granulom)",
        "la": "Granuloma annulare"
    },
    {
        "icd": "L921",
        "rs": "Nekroza masnih ćelija, neklasifikovano na drugom mestu",
        "la": "Necrobiosis lipoidica, non alibi classificata"
    },
    {
        "icd": "L922",
        "rs": "Eozinofilni zrnasti tumor lica",
        "la": "Granuloma faciei [eosinophilicum]"
    },
    {
        "icd": "L923",
        "rs": "Granulom uzrokovan stranim telom u koži i potkožnom tkivu",
        "la": "Granuloma cutis et subcutis corpore alieno excitatum"
    },
    {
        "icd": "L928",
        "rs": "Druge granulomatoze kože i potkožnog tkiva",
        "la": "Cutes et subcutes granulomatosae aliae"
    },
    {
        "icd": "L929",
        "rs": "Granulomatoza kože i potkožnog tkiva, neoznačena",
        "la": "Cutis et subcutis granulomatosa, non specificata"
    },
    {
        "icd": "L93",
        "rs": "Eritemski lupus – sistemsko oboljenje kože",
        "la": "Lupus erythematosus"
    },
    {
        "icd": "L930",
        "rs": "Diskoidni eritemski lupus",
        "la": "Lupus erythematosus discoides"
    },
    {
        "icd": "L931",
        "rs": "Subakutni kožni eritemski lupus",
        "la": "Lupus erythematosus cutaneus subacutas"
    },
    {
        "icd": "L932",
        "rs": "Drugi lokalizovani eritemski lupus",
        "la": "Lupus erythematosus alius, localisatus"
    },
    {
        "icd": "L94",
        "rs": "Drugi lokalizovani poremećaji vezivnog tkiva",
        "la": "Collagenoses localisatae aliae"
    },
    {
        "icd": "L940",
        "rs": "Lokalizovano otvrdnuće kože",
        "la": "Scleroderma (morphea) localisatum"
    },
    {
        "icd": "L941",
        "rs": "Linijsko otvrdnuce kože",
        "la": "Scleroderma lineare"
    },
    {
        "icd": "L942",
        "rs": "Prožimanje kože kalcijumom",
        "la": "Calcinosis cutis"
    },
    {
        "icd": "L943",
        "rs": "Otvrdnuće kože prstiju",
        "la": "Sclerodactylia"
    },
    {
        "icd": "L944",
        "rs": "Gottronove papule",
        "la": "Papulae Gottron"
    },
    {
        "icd": "L945",
        "rs": "Poikiloderma uzrokovana slabom prokrvljenošću",
        "la": "Poikiloderma vasculare atrophicans"
    },
    {
        "icd": "L946",
        "rs": "Trakasta konstrikcija oko prsta ili uda",
        "la": "Ainhum"
    },
    {
        "icd": "L948",
        "rs": "Druge označene lokalizovane bolesti vezivnog tkiva",
        "la": "Collagenoses localisatae aliae, specificatae"
    },
    {
        "icd": "L949",
        "rs": "Lokalizovani poremećaj vezivnog tkiva, neoznačena",
        "la": "Collagenosis localisata, non specificata"
    },
    {
        "icd": "L95",
        "rs": "Zapaljenje krvnih sudova ograničeno na kožu, neklasifikovano na drugom mestu",
        "la": "Vasculitis in cute limitata, non alibi classificata"
    },
    {
        "icd": "L950",
        "rs": "Zapaljenje malih krvnih sudova",
        "la": "Vasculitis livedoides"
    },
    {
        "icd": "L951",
        "rs": "Dugotrajni eritem kože",
        "la": "Erythema elevatum diutinum"
    },
    {
        "icd": "L958",
        "rs": "Drugi vaskulitis ograničen na kožu",
        "la": "Vasculitis in cute limitata, alius"
    },
    {
        "icd": "L959",
        "rs": "Vaskulitis ograničen na kožu, neoznačen",
        "la": "Vasculitis in cute limitata, non specificatus"
    },
    {
        "icd": "L97",
        "rs": "Grizlica na nozi neklasifikovana na drugom mestu",
        "la": "Ulcus extremitatis inferioris non alibi classificatis"
    },
    {
        "icd": "L98",
        "rs": "Drugi poremećaji kože i potkožnog tkiva neklasifikovani na drugom mestu",
        "la": "Disordines cutis et subcutis alii, non alibi classificati"
    },
    {
        "icd": "L980",
        "rs": "Gnojni tumor granulacionog tkiva",
        "la": "Granuloma pyogenes"
    },
    {
        "icd": "L981",
        "rs": "Namerno izazvano zapaljenje kože",
        "la": "Dermatitis factitia"
    },
    {
        "icd": "L982",
        "rs": "Neutrofilocitno Sweetovo febrilno oboljenje kože",
        "la": "Dermatosis febrilis neutrophilocytica (Sweet)"
    },
    {
        "icd": "L983",
        "rs": "Eozinofilno Wellsovo zapaljenje vezivnog ćelijskog tkiva",
        "la": "Cellulitis / Phlegmone eosinophilica (Wells)"
    },
    {
        "icd": "L984",
        "rs": "Hronična grizlica kože, neklasifikovana na drugom mestu",
        "la": "Ulcus cutis chronicum, non alibi classificatum"
    },
    {
        "icd": "L985",
        "rs": "Sluzava koža",
        "la": "Mucinosis cutis"
    },
    {
        "icd": "L986",
        "rs": "Druge infiltrativni poremećaji kože i potkožnog tkiva",
        "la": "Disordines cutis et subcutis infiltrativi, alii"
    },
    {
        "icd": "L988",
        "rs": "Drugi označeni poremećaji kože i potkožnog tkiva",
        "la": "Disordines cutis et subcutis specificati, alii"
    },
    {
        "icd": "L989",
        "rs": "Poremećaj kože i potkožnog tkiva, neoznačen",
        "la": "Disordo cutis et subcutis, non specificatus"
    },
    {
        "icd": "L99",
        "rs": "Drugi poremećaji kože i potkožnog tkiva u bolestima koje su klasifikovane na drugom mestu",
        "la": "Disordines cutis et subcutis alii in morbis alibi classificatis"
    },
    {
        "icd": "L990",
        "rs": "Amiloidoza – taloženje amiloiday u koži",
        "la": "Amyloidosis cutis"
    },
    {
        "icd": "L998",
        "rs": "Drugi označeni poremećaji kože i potkožnog tkiva u bolestima koje su klasifikovane na drugom mestu",
        "la": "Disordines cutis et subcutis alii specificati in morbis alibi classificatis"
    },
    {
        "icd": "M00",
        "rs": "Gnojno zapaljenje zgloba",
        "la": "Arthritis purulenta"
    },
    {
        "icd": "M000",
        "rs": "Zapaljenje jednog i više zglobova uzrokovano stafilokokom",
        "la": "Arthritis et polyarthritis staphylococcica"
    },
    {
        "icd": "M001",
        "rs": "Zapaljenje jednog i više zglobova uzrokovano pneumokokom",
        "la": "Atrhritis et polyarthritis pneumococcica"
    },
    {
        "icd": "M002",
        "rs": "Zapaljenje jednog i više zglobova uzrokovano drugim streptokokom",
        "la": "Arthritis et polyarthritis streptococcica alia"
    },
    {
        "icd": "M008",
        "rs": "Gnojna zapaljenja jednog i više zglobova uzrokovano drugim označenim bakterijama",
        "la": "Arthritis et polyarthritis purulenta per bacterias specificatas alias"
    },
    {
        "icd": "M009",
        "rs": "Gnojno zapaljenje zgloba, neoznačeno",
        "la": "Arthritis purulenta, non specificata"
    },
    {
        "icd": "M01",
        "rs": "Direktna infekcija zglobova u zaraznim i parazitarnim bolestima klasifikovanim na drugom mestu",
        "la": "Infectiones directae articulationum in morbis infectiosis et parasiticis alibi classificatis"
    },
    {
        "icd": "M010",
        "rs": "Zapaljenje zgloba uzrokovano meningokokom",
        "la": "Arthritis meningococcica"
    },
    {
        "icd": "M011",
        "rs": "Tuberkulozno zapaljenje zgloba",
        "la": "Arthritis tuberculosa"
    },
    {
        "icd": "M012",
        "rs": "Zapaljenje zgloba u lajmskoj bolesti",
        "la": "Arthritis in morbo Lyme"
    },
    {
        "icd": "M013",
        "rs": "Zapaljenje zgloba u drugim bakterijskim bolestima klasifikovanim na drugom mestu",
        "la": "Arthritis in morbis bacterialibus aliis alibi classificatis"
    },
    {
        "icd": "M014",
        "rs": "Zapaljenje zgloba kod rubele-crvenke",
        "la": "Arthritis rubellosa"
    },
    {
        "icd": "M015",
        "rs": "Zapaljenje zgloba u drugim virusnim bolestima klasifikovanim na drugom mestu",
        "la": "Arthritis in morbis viralibus aliis alibi classificatis"
    },
    {
        "icd": "M016",
        "rs": "Zapaljenje zgloba kod gljivičnih oboljenja",
        "la": "Arthritis mycotica"
    },
    {
        "icd": "M018",
        "rs": "Zapaljenje zgloba u drugim zaraznim i parazitarnim bolestima klasifikovanim na drugom mestu",
        "la": "Arthritis in morbis infectivis et parasitariis aliis alibi classificatis"
    },
    {
        "icd": "M02",
        "rs": "Reaktivna oboljenja zglobova",
        "la": "Arthropathiae reactivae"
    },
    {
        "icd": "M020",
        "rs": "Oboljenje zgloba posle premošćavanja creva",
        "la": "Arthropathia post circuitum (\"by pass\") intestinalem"
    },
    {
        "icd": "M021",
        "rs": "Oboljenje zgloba posle dizenterije",
        "la": "Arthropathia postdysenterica"
    },
    {
        "icd": "M022",
        "rs": "Oboljenje zgloba posle imunizacije",
        "la": "Arthropathia postimmunisationalis"
    },
    {
        "icd": "M023",
        "rs": "Reiterova bolest",
        "la": "Morbus Reiter"
    },
    {
        "icd": "M028",
        "rs": "Druga reaktivna oboljenja zglobova",
        "la": "Arthropathiae reactivae aliae"
    },
    {
        "icd": "M029",
        "rs": "Reaktivno oboljenje zgloba, neoznačeno",
        "la": "Arthropathia reactiva, non specificata"
    },
    {
        "icd": "M03",
        "rs": "Postinfektivna i reaktivna oboljenja zglobova u bolestima klasifikovanim na drugom mestu",
        "la": "Arthropathiae postinfectivae et reactivae in morbis alibi classificatis"
    },
    {
        "icd": "M030",
        "rs": "Zapaljenje zgloba posle infekcije meningokokom",
        "la": "Arthritis postmeningococcica"
    },
    {
        "icd": "M031",
        "rs": "Postinfektivna artropatija kod sifilisa",
        "la": "Arthropathia syphilitica"
    },
    {
        "icd": "M032",
        "rs": "Druga postinfektivna oboljenja zglobova u bolestima klasifikovanim na drugom mestu",
        "la": "Arthropathiae postinfectivae aliae in morbis alibi classificatis"
    },
    {
        "icd": "M036",
        "rs": "Reaktivno oboljenje zgloba u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Arthropathia reactiva in morbis aliis alibi classificatis"
    },
    {
        "icd": "M05",
        "rs": "Seropozitivni reumatoidni artritis",
        "la": "Arthritis rheumatoides seropositiva"
    },
    {
        "icd": "M050",
        "rs": "Feltyjev sindrom",
        "la": "Syndroma Felty"
    },
    {
        "icd": "M051",
        "rs": "Reumatoidno oboljenje pluća",
        "la": "Morbus pulmonum rheumatoides"
    },
    {
        "icd": "M052",
        "rs": "Reumatoidno zapaljenje krvnih sudova",
        "la": "Vasculitis rheumatoides"
    },
    {
        "icd": "M053",
        "rs": "Reumatoidno zapaljenje zgloba sa zahvatanjem drugih organa i sistema",
        "la": "Arthritis rheumatoides cum apprehensione organorum et systematum aliorum"
    },
    {
        "icd": "M058",
        "rs": "Drugi seropozitivni reumatoidni artritis",
        "la": "Arthritis rheumatoides seropositiva alia"
    },
    {
        "icd": "M059",
        "rs": "Seropozitivni reumatoidni artritis, neoznačen",
        "la": "Arthritis rheumatoides seropositiva, non specificata"
    },
    {
        "icd": "M06",
        "rs": "Ostali reumatoidni artritisi",
        "la": "Arthritis rheumatoides alia"
    },
    {
        "icd": "M060",
        "rs": "Seronegativni reumatoidni artritis",
        "la": "Arthritis rheumatoides seronegativa"
    },
    {
        "icd": "M061",
        "rs": "Stillova bolest sa početkom kod odraslih osoba",
        "la": "Morbus Still tardus"
    },
    {
        "icd": "M062",
        "rs": "Reumatoidni burzitis",
        "la": "Bursitis rheumatoides"
    },
    {
        "icd": "M063",
        "rs": "Reumatoidni čvor",
        "la": "Nodulus rheumatoides"
    },
    {
        "icd": "M064",
        "rs": "Zapaljensko oboljenje više zglobova",
        "la": "Polyarthropathia inflammatoria"
    },
    {
        "icd": "M068",
        "rs": "Drugi označeni reumatoidni artritis",
        "la": "Arthritis rheumatoides specificata alia"
    },
    {
        "icd": "M069",
        "rs": "Reumatoidni artritis, neoznačen",
        "la": "Arthritis rheumatoides, non specificata"
    },
    {
        "icd": "M07",
        "rs": "Psorijazna oboljenja i enteropatička oboljenja zgloba",
        "la": "Arthropathiae psoriaticae et arthropathiae enteropathicae"
    },
    {
        "icd": "M070",
        "rs": "Distalna interfalangealna psorijatična artropatija",
        "la": "Arthropathia psoriatica interphalangica distalis"
    },
    {
        "icd": "M071",
        "rs": "Osakaćujuće zapaljenje zgloba",
        "la": "Arthritis psoriatica mutilans"
    },
    {
        "icd": "M072",
        "rs": "Zapaljenje kičmenog stuba uzrokovano psorijazom",
        "la": "Spondylitis psoriatica"
    },
    {
        "icd": "M073",
        "rs": "Druga oboljenja zglobova uzrokovana psorijazom",
        "la": "Arthropathiae psoriaticae aliae"
    },
    {
        "icd": "M074",
        "rs": "Oboljenja zgloba u Crohnovoj bolesti",
        "la": "Arthropathia in morbo Crohn [enteritis regionalis]"
    },
    {
        "icd": "M075",
        "rs": "Oboljenje zgloba u ulceroznom zapaljenju debelog creva",
        "la": "Arthropathia in colitide ulcerativa"
    },
    {
        "icd": "M076",
        "rs": "Druga enteropatijska oboljenja zglobova",
        "la": "Arthropathiae enteropaticae aliae"
    },
    {
        "icd": "M08",
        "rs": "Juvenilni artritis",
        "la": "Arthritis juvenilis"
    },
    {
        "icd": "M080",
        "rs": "Juvenilni reumatoidni artritis",
        "la": "Arthritis juvenilis rheumatoides"
    },
    {
        "icd": "M081",
        "rs": "Juvenilni ankilozirajući spondilitis",
        "la": "Spondylitis juvenilis ankylopoietica"
    },
    {
        "icd": "M082",
        "rs": "Juvenilni artritis sa sistemskim početkom",
        "la": "Arthritis juvenilis cum initio systemico"
    },
    {
        "icd": "M083",
        "rs": "Juvenilni poliartritis (seronegativni)",
        "la": "Polyarthritis juvenilis (seronegativa)"
    },
    {
        "icd": "M084",
        "rs": "Juvenilni artritis sa zahvatanjem malog broja zglobova",
        "la": "Arthritis juvenilis oligoarticularis"
    },
    {
        "icd": "M088",
        "rs": "Drugi juvenilni artritisi",
        "la": "Arthritis juvenilis alia"
    },
    {
        "icd": "M089",
        "rs": "Juvenilni artritis, neoznačeni",
        "la": "Arthritis juvenilis, non specificata"
    },
    {
        "icd": "M09",
        "rs": "Juvenilni artritis u bolestima klasifikovanim na drugom mestu",
        "la": "Arthritis juvenilis in morbis alibi classificatis"
    },
    {
        "icd": "M090",
        "rs": "Juvenilni artritis uzrokovan psorijazom",
        "la": "Arthritis juvenilis psoriatica"
    },
    {
        "icd": "M091",
        "rs": "Juvenilni artritis u Crohnovoj bolesti",
        "la": "Arthritis juvenilis in morbo Crohn [enteritis regionalis]"
    },
    {
        "icd": "M092",
        "rs": "Juvenilni artritis u ulcerativnom kolitisu",
        "la": "Arthritis juvenilis in colitide ulcerativa"
    },
    {
        "icd": "M098",
        "rs": "Juvenilni artritis u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Arthritis juvenilis in morbis aliis alibi classificatis"
    },
    {
        "icd": "M10",
        "rs": "Giht",
        "la": "Arthritis urica"
    },
    {
        "icd": "M100",
        "rs": "Idiopatski giht",
        "la": "Arthritis urica idiopathica"
    },
    {
        "icd": "M101",
        "rs": "Giht uzrokovan olovom",
        "la": "Arthritis urica plumbica"
    },
    {
        "icd": "M102",
        "rs": "Giht uzrokovan lekovima",
        "la": "Arthritis urica medicamentosa"
    },
    {
        "icd": "M103",
        "rs": "Giht uzrokovan nedovoljnom funkcijom bubrega",
        "la": "Arthritis urica propter insufficientiam renalem"
    },
    {
        "icd": "M104",
        "rs": "Drugi sekundarni giht",
        "la": "Arthritis urica secundaria alia"
    },
    {
        "icd": "M109",
        "rs": "Giht, neoznačen",
        "la": "Arthritis urica, non specificata"
    },
    {
        "icd": "M11",
        "rs": "Druga oboljenja zglobova uzrokovana taloženjem kristala",
        "la": "Arthropathiae crystallogenes aliae"
    },
    {
        "icd": "M110",
        "rs": "Bolest uzrokovana taloženjem hidroksiapatita",
        "la": "Morbus hydroxyapatitogenes"
    },
    {
        "icd": "M111",
        "rs": "Familijarna hondrokalcinoza",
        "la": "Chondrocalcinosis familiaris"
    },
    {
        "icd": "M112",
        "rs": "Druge hondrokalcinoze",
        "la": "Chondrocalcinosis alia"
    },
    {
        "icd": "M118",
        "rs": "Druga označena oboljenja zglobova uzrokovana taloženjem kristala",
        "la": "Arthropathiae crystallogenes aliae, specificatae"
    },
    {
        "icd": "M119",
        "rs": "Oboljenje zgloba uzrokovano taloženjem kristala, neoznačeno",
        "la": "Arthropathia crystallogenes, non specificata"
    },
    {
        "icd": "M12",
        "rs": "Druga označena oboljenja zglobova",
        "la": "Arthropathiae aliae, specificatae"
    },
    {
        "icd": "M120",
        "rs": "Jaccoudovo hronično postreumatično oboljenje zgloba",
        "la": "Arthropathia postrheumatica chronica Jaccoud"
    },
    {
        "icd": "M121",
        "rs": "Kaschin-Beckova bolest",
        "la": "Morbus Kaschin-Beck"
    },
    {
        "icd": "M122",
        "rs": "Resičasto-čvorasto pigmentno zapaljenje sinovije",
        "la": "Synovitis villonodularis (pigmentosa)"
    },
    {
        "icd": "M123",
        "rs": "Palindromični reumatizam",
        "la": "Rheumatismus palindromicus"
    },
    {
        "icd": "M124",
        "rs": "Intermitentna hidartroza",
        "la": "Hydrarthrosis intermittens"
    },
    {
        "icd": "M125",
        "rs": "Oboljenje zgloba uzrokovano povredom",
        "la": "Arthropathia traumatica"
    },
    {
        "icd": "M128",
        "rs": "Ostala označena oboljenja zglobova, koja nisu klasifikovana na drugom mestu",
        "la": "Arthropathiae aliae specificatae, non alibi classificatae"
    },
    {
        "icd": "M13",
        "rs": "Druga zapaljenja zgloba",
        "la": "Arthritis alia"
    },
    {
        "icd": "M130",
        "rs": "Zapaljenje više zglobova, neoznačeno",
        "la": "Polyarthritis, non specificata"
    },
    {
        "icd": "M131",
        "rs": "Zapaljenje jednog zgloba, koje nije klasifikovano na drugom mestu",
        "la": "Monarthritis, non alibi classificata"
    },
    {
        "icd": "M138",
        "rs": "Druga označena zapaljenja zgloba",
        "la": "Arthritis alia, specificata"
    },
    {
        "icd": "M139",
        "rs": "Zapaljenja zgloba, neoznačena",
        "la": "Arthritis, non specificata"
    },
    {
        "icd": "M14",
        "rs": "Oboljenja zglobova u drugim bolestima, klasifikovanim na drugom mestu",
        "la": "Arthropathiae in morbis aliis, alibi classificatis"
    },
    {
        "icd": "M140",
        "rs": "Giht uzrokovan defektima enzima i drugim naslednim poremećajima",
        "la": "Arthropathia urica propter defectus enzimarum et disordines hereditarios alios"
    },
    {
        "icd": "M141",
        "rs": "Oboljenje zgloba uzrokovano taloženjem kristala u drugim poremećajima metabolizma",
        "la": "Arthropathia crystaliogenes in disordinibus metabolicis aliis"
    },
    {
        "icd": "M142",
        "rs": "Oboljenje zgloba kod šećerne bolesti",
        "la": "Arthropathia diabetica"
    },
    {
        "icd": "M143",
        "rs": "Lipoidno zapaljenje kože zgloba",
        "la": "Dermatoarthritis lipoidalis"
    },
    {
        "icd": "M144",
        "rs": "Amiloidno oboljenje zgloba",
        "la": "Arthropathia amyloidalis"
    },
    {
        "icd": "M145",
        "rs": "Oboljenja zglobova u drugim bolestima žlezda sa unutrašnjim lučenjem, ishrane i metabolizma",
        "la": "Arthropathiae in morbis endocrinis, nutritionalibus et metabolicis aliis"
    },
    {
        "icd": "M146",
        "rs": "Neuropatsko oboljenje zgloba",
        "la": "Arthropathia neuropathica"
    },
    {
        "icd": "M148",
        "rs": "Oboljenja zglobova u drugim označenim bolestima klasifikovanim na drugom mestu",
        "la": "Arthropathiae in morbis aliis specificatis, alibi classificatis"
    },
    {
        "icd": "M15",
        "rs": "Degenerativna oboljenja više zglobova",
        "la": "Polyarthrosis"
    },
    {
        "icd": "M150",
        "rs": "Primarna generalizovana (osteo)artroza",
        "la": "(Osteo)arthrosis generalisata primaria"
    },
    {
        "icd": "M151",
        "rs": "Heberdenovi čvorovi sa oboljenjem zgloba",
        "la": "Nodi Heberden (cum arthropathia)"
    },
    {
        "icd": "M152",
        "rs": "Bouchardovi čvorovi sa oboljenjem zgloba",
        "la": "Nodi Bouchard (cum arthropathia)"
    },
    {
        "icd": "M153",
        "rs": "Sekundarno višestruko degenerativno oboljenje zglobova",
        "la": "Arthrosis multiplex secundaria"
    },
    {
        "icd": "M154",
        "rs": "Degenerativno erozivno oboljenje zgloba",
        "la": "Arthrosis erosiva"
    },
    {
        "icd": "M158",
        "rs": "Druga degenerativna oboljenja više zglobova",
        "la": "Polyarthrosis alia"
    },
    {
        "icd": "M159",
        "rs": "Degenerativno oboljenje više zglobova, neoznačeno",
        "la": "Polyarthrosis, non specificata"
    },
    {
        "icd": "M16",
        "rs": "Degenerativno oboljenje zgloba kuka",
        "la": "Coxarthrosis"
    },
    {
        "icd": "M160",
        "rs": "Primarno degenerativno oboljenje oba kuka",
        "la": "Coxarthrosis primaria bilateralis"
    },
    {
        "icd": "M161",
        "rs": "Drugo primarno degenerativno oboljenje kuka",
        "la": "Coxarthrosis primaria alia"
    },
    {
        "icd": "M162",
        "rs": "Degenerativno oboljenje oba kuka uzrokovano razvojnim poremećajem",
        "la": "Coxarthrosis dysplastica bilateralis"
    },
    {
        "icd": "M163",
        "rs": "Drugo degenerativno oboljenje kuka uzrokovano razvojnim poremećajem",
        "la": "Coxarthrosis dysplastica alia"
    },
    {
        "icd": "M164",
        "rs": "Degenerativno oboljenje oba kuka posle povrede",
        "la": "Coxarthrosis posttraumatica bilateralis"
    },
    {
        "icd": "M165",
        "rs": "Drugo degenerativno oboljenje kuka posle povrede",
        "la": "Coxarthrosis posttraumatica alia"
    },
    {
        "icd": "M166",
        "rs": "Drugo sekundarno degenerativno oboljenje oba kuka",
        "la": "Coxarthrosis secundaria bilateralis alia"
    },
    {
        "icd": "M167",
        "rs": "Drugo sekundarno degenerativno oboljenje kuka",
        "la": "Coxarthrosis secundaria alia"
    },
    {
        "icd": "M169",
        "rs": "Degenerativno oboljenje kuka, neoznačeno",
        "la": "Coxarthrosis, non specificata"
    },
    {
        "icd": "M17",
        "rs": "Degenerativno oboljenje zgloba kolena",
        "la": "Gonarthrosis"
    },
    {
        "icd": "M170",
        "rs": "Primarno obostrano degenerativno oboljenje kolena",
        "la": "Gonarthrosis primaria bilateralis"
    },
    {
        "icd": "M171",
        "rs": "Drugo primarno degenerativno oboljenje kolena",
        "la": "Gonarthrosis primaria alia"
    },
    {
        "icd": "M172",
        "rs": "Obostrano degenerativno oboljenje kolena posle povrede",
        "la": "Gonarthrosis posttraumatica bilateralis"
    },
    {
        "icd": "M173",
        "rs": "Drugo degenerativno oboljenje kolena posle povrede",
        "la": "Gonarthrosis posttraumatica alia"
    },
    {
        "icd": "M174",
        "rs": "Drugo sekundarno obostrano degenerativno oboljenje kolena",
        "la": "Gonarthrosis secundaria bilateralis alia"
    },
    {
        "icd": "M175",
        "rs": "Drugo sekundarno degenerativno oboljenje kolena",
        "la": "Gonarthrosis secundaria alia"
    },
    {
        "icd": "M179",
        "rs": "Degenerativno oboljenje kolena, neoznačeno",
        "la": "Gonarthrosis, non specificata"
    },
    {
        "icd": "M18",
        "rs": "Degenerativno oboljenje prvog ručno-doručnog zgloba",
        "la": "Arthrosis articuli carpometacarpalis pollicis"
    },
    {
        "icd": "M180",
        "rs": "Primarno obostrano degenerativno oboljenje prvog ručno-doručnog zgloba",
        "la": "Arthrosis articuli carpometacarpalis poliicis primaria bilateralis"
    },
    {
        "icd": "M181",
        "rs": "Drugo primarno degenerativno oboljenje prvog ručno-doručnog zgloba",
        "la": "Arthrosis articuli carpometacarpalis pollicis primaria alia"
    },
    {
        "icd": "M182",
        "rs": "Obostrano degenerativno oboljenje prvog ručno-doručnog zgloba posle povrede",
        "la": "Arthrosis articuli carpometacarpalis pollicis posttraumatica bilateralis"
    },
    {
        "icd": "M183",
        "rs": "Drugo degenerativno oboljenje prvog ručno-doručnog zgloba posle povrede",
        "la": "Arthrosis articuli carpometacarpalis pollicis posttraumatica alia"
    },
    {
        "icd": "M184",
        "rs": "Drugo sekundarno obostrano oboljenje prvog ručno-doručnog zgloba",
        "la": "Arthrosis articuli carpometacarpalis pollicis secundaria bilateralis alia"
    },
    {
        "icd": "M185",
        "rs": "Drugo sekundarno degenerativno oboljenje prvog ručno-doručnog zgloba",
        "la": "Arthrosis articuli carpometacarpalis pollicis secundaria alia"
    },
    {
        "icd": "M189",
        "rs": "Degenerativno oboljenje prvog ručno-doručnog zgloba, neoznačeno",
        "la": "Arthrosis articuli carpometacarpalis pollicis, non specificata"
    },
    {
        "icd": "M19",
        "rs": "Druge artroze",
        "la": "Arthrosis alia"
    },
    {
        "icd": "M190",
        "rs": "Primarno degenerativno obolljenje drugih zglobova",
        "la": "Arthrosis artriculorum aliorum primaria"
    },
    {
        "icd": "M191",
        "rs": "Degenerativno oboljenje drugih zglobova posle povrede",
        "la": "Arthrosis articulorum aliorum posttraumatica"
    },
    {
        "icd": "M192",
        "rs": "Sekundarno degenerativno oboljenje drugih zglobova",
        "la": "Arthrosis articulorum aliorum secundaria"
    },
    {
        "icd": "M198",
        "rs": "Drugo označeno degenerativno oboljenje zglobova",
        "la": "Arthrosis alia, specificata"
    },
    {
        "icd": "M199",
        "rs": "Degenerativno oboljenje zgloba, neoznačeno",
        "la": "Artrhrosis, non specificata"
    },
    {
        "icd": "M20",
        "rs": "Stečeni deformiteti prstiju šake i prstiju stopala",
        "la": "Deformationes digitorum manus et digitorum pedis acquisitae"
    },
    {
        "icd": "M200",
        "rs": "Deformacija prsta šake",
        "la": "Deformatio digiti manus"
    },
    {
        "icd": "M201",
        "rs": "Iskrivljenost palca upolje (stečena)",
        "la": "Hallux valgus (acquisitus)"
    },
    {
        "icd": "M202",
        "rs": "Ukrućen palac",
        "la": "Hallux rigidus"
    },
    {
        "icd": "M203",
        "rs": "Drugi deformitet palca (stečen)",
        "la": "Deformatio hallucis (acquisita) alia"
    },
    {
        "icd": "M204",
        "rs": "Drugi batičasti deformiteti prstiju stopala (stečeni)",
        "la": "Deformatio digiti pedis maleiformis (acquisita) alia"
    },
    {
        "icd": "M205",
        "rs": "Drugi deformiteti prstiju stopala (stečeni)",
        "la": "Deformationes digitorum pedis (acquisitae) aliae"
    },
    {
        "icd": "M206",
        "rs": "Stečeni deformitet prsta stopala, neoznačen",
        "la": "Deformatio digiti pedis acquisita, non specificata"
    },
    {
        "icd": "M21",
        "rs": "Drugi stečeni deformiteti udova",
        "la": "Deformationes extremitatum acquisitae aliae"
    },
    {
        "icd": "M210",
        "rs": "Izvrnut deformitet stopala, neklasifikovan na drugom mestu",
        "la": "Deformatio valga, non alibi classificata"
    },
    {
        "icd": "M211",
        "rs": "Uvrnut deformitet stopala, neklasifikovan na drugom mestu",
        "la": "Deformatio vara, non alibi classificata"
    },
    {
        "icd": "M212",
        "rs": "Deformitet pregibanja udova",
        "la": "Deformatio flexionis"
    },
    {
        "icd": "M213",
        "rs": "Nestabilan zglob ili viseće stopalo (stečeno)",
        "la": "Articulus instabilis sive pes haerens (acquisitus)"
    },
    {
        "icd": "M214",
        "rs": "Ravno stopalo (stečeno)",
        "la": "Pes planus (acquisitus)"
    },
    {
        "icd": "M215",
        "rs": "Stečena kandžasta šaka, zgrčena šaka, kandžasto stopalo i zgrčeno stopalo",
        "la": "Manus unguliformis, talipomanus, pes unguliformis et talipes acquisita"
    },
    {
        "icd": "M216",
        "rs": "Drugi stečeni deformiteti gležnja i stopala",
        "la": "Deformationes malleoli et pedis acquisitae aliae"
    },
    {
        "icd": "M217",
        "rs": "Nejednaka dužina udova (stečena)",
        "la": "Longitudo extremitatum impar (acquisita)"
    },
    {
        "icd": "M218",
        "rs": "Drugi označeni stečeni deformiteti udova",
        "la": "Deformationes extremitatum acquisitae aliae, specificatae"
    },
    {
        "icd": "M219",
        "rs": "Stečeni deformitet uda, neoznačen",
        "la": "Deformatio extremitatis acquisita, non specificata"
    },
    {
        "icd": "M22",
        "rs": "Bolesti čašice kolena",
        "la": "Morbi patellae"
    },
    {
        "icd": "M220",
        "rs": "Ponavljano iščašenje čašice",
        "la": "Luxatio patellae recurrens (recidiva)"
    },
    {
        "icd": "M221",
        "rs": "Ponavljano nepotpuno iščašenje čašice",
        "la": "Subluxatio patellae recurrens"
    },
    {
        "icd": "M222",
        "rs": "Čašično-butni poremećaji",
        "la": "Disordines patellofemorales"
    },
    {
        "icd": "M223",
        "rs": "Druge promene čašice",
        "la": "Mutationes patellae aliae"
    },
    {
        "icd": "M224",
        "rs": "Razmekšavanje hrskavice čašice",
        "la": "Chondromalacia patellae"
    },
    {
        "icd": "M228",
        "rs": "Druge bolesti čašice",
        "la": "Morbi patellae alii"
    },
    {
        "icd": "M229",
        "rs": "Bolest čašice, neoznačena",
        "la": "Morbus patellae, non specificatus"
    },
    {
        "icd": "M23",
        "rs": "Unutrašnje bolesti kolena",
        "la": "Morbi genus interni"
    },
    {
        "icd": "M230",
        "rs": "Cista meniskusa - međuzglobne hrskavice kolena",
        "la": "Cystis menisci genus"
    },
    {
        "icd": "M231",
        "rs": "Urođeni diskoidni meniskus kolena",
        "la": "Meniscus genus discoides (congenitus)"
    },
    {
        "icd": "M232",
        "rs": "Zastarela ozleda meniskusa uzrokovana povredom",
        "la": "Laesio menisci genus traumatica obsoleta"
    },
    {
        "icd": "M233",
        "rs": "Druge promene meniskusa",
        "la": "Mutationes menisci aliae"
    },
    {
        "icd": "M234",
        "rs": "Slobodno telo kolena",
        "la": "Corpus liberum genus"
    },
    {
        "icd": "M235",
        "rs": "Hronična nestabilnost kolena",
        "la": "Instabilitas genus chronica"
    },
    {
        "icd": "M236",
        "rs": "Drugo spontano prsnuće veze kolena",
        "la": "Ruptura ligamenti genus spontanea alia"
    },
    {
        "icd": "M238",
        "rs": "Druge unutrašnje promene kolena",
        "la": "Mutationes genus internae aliae"
    },
    {
        "icd": "M239",
        "rs": "Unutrašnja promena kolena, neoznačena",
        "la": "Mutatio genus interna, non specificata"
    },
    {
        "icd": "M24",
        "rs": "Druge označene promene zglobova",
        "la": "Mutationes articuli aliae specificatae"
    },
    {
        "icd": "M240",
        "rs": "Slobodno telo zgloba",
        "la": "Corpus liberum articuli"
    },
    {
        "icd": "M241",
        "rs": "Druge bolesti hrskavice zgloba",
        "la": "Morbi cartilaginis articularis alii"
    },
    {
        "icd": "M242",
        "rs": "Bolest ligamenta",
        "la": "Morbus ligamenti"
    },
    {
        "icd": "M243",
        "rs": "Patološko potpuno i nepotpuno iščašenje zgloba, koje nije klasifikovano na drugom mestu",
        "la": "Luxatio et subluxatio articuli pathologica, non alibi classificata"
    },
    {
        "icd": "M244",
        "rs": "Povratno potpuno i nepotpuno iščašenje",
        "la": "Luxatio et subluxatio articuli recidiva"
    },
    {
        "icd": "M245",
        "rs": "Zgrčenost zgloba",
        "la": "Contractura articuli"
    },
    {
        "icd": "M246",
        "rs": "Ukočenost zgloba",
        "la": "Ankylosis articuli"
    },
    {
        "icd": "M247",
        "rs": "Plitka čašica kostiju karlice",
        "la": "Protrusio acetabuli"
    },
    {
        "icd": "M248",
        "rs": "Druge označene promene zgloba, koje nisu klasifikovane na drugom mestu",
        "la": "Mutationes articuli aliae specificatae, non alibi classificatae"
    },
    {
        "icd": "M249",
        "rs": "Promena zgloba, neoznačena",
        "la": "Mutatio articuli, non specificata"
    },
    {
        "icd": "M25",
        "rs": "Druge bolesti zglobova, koje nisu klasifikovane na drugom mestu",
        "la": "Morbi articulorum alii, non alibi classificati"
    },
    {
        "icd": "M250",
        "rs": "Krv u zglobu",
        "la": "Haemorthrosis"
    },
    {
        "icd": "M251",
        "rs": "Fistula zgloba",
        "la": "Fistula articuli"
    },
    {
        "icd": "M252",
        "rs": "Nestabilan zglob",
        "la": "Instabilitas articuli"
    },
    {
        "icd": "M253",
        "rs": "Druga nestabilnost zgloba",
        "la": "Instabilitas articuli alia"
    },
    {
        "icd": "M254",
        "rs": "Izliv tečnosti u zglob",
        "la": "Hydrarthron"
    },
    {
        "icd": "M255",
        "rs": "Bol u zglobu",
        "la": "Arthralgia"
    },
    {
        "icd": "M256",
        "rs": "Ukočenost zgloba, neklasifikovana na drugom mestu",
        "la": "Rigiditas articuli, non alibi classificata"
    },
    {
        "icd": "M257",
        "rs": "Izraštaj na kosti [Osteofit]",
        "la": "Osteophyton"
    },
    {
        "icd": "M258",
        "rs": "Druge označene bolesti zglobova",
        "la": "Morbi articulorum alii, specificati"
    },
    {
        "icd": "M259",
        "rs": "Bolest zgloba, neoznačena",
        "la": "Morbus articuli, non specificatus"
    },
    {
        "icd": "M30",
        "rs": "Čvoričasto zapaljenje više arterija i slične bolesti",
        "la": "Polyarteritis nodosa et morbi affines"
    },
    {
        "icd": "M300",
        "rs": "Čvoričasto zapaljenje više arterija",
        "la": "Polyarteritis nodosa"
    },
    {
        "icd": "M301",
        "rs": "Poliarteritis sa zahvatanjem pluća [Churg-Strauss]",
        "la": "Polyarteritis pulmonum [Churg-Strauss]"
    },
    {
        "icd": "M302",
        "rs": "Zapaljenje više arterija u dece",
        "la": "Polyarteritis juvenilis"
    },
    {
        "icd": "M303",
        "rs": "Kawasakijev sluznično-kožni sindrom limfnog čvora",
        "la": "Syndroma nodi lymphatici mucocutaneum [Kawasaki]"
    },
    {
        "icd": "M308",
        "rs": "Druga slična stanja povezana sa čvorastim zapaljenjem više arterija",
        "la": "Status similes cum polyarteritide nodosa alii"
    },
    {
        "icd": "M31",
        "rs": "Druga nekrozna oboljenja krvnih sudova",
        "la": "Vasculopathiae necroticae aliae"
    },
    {
        "icd": "M310",
        "rs": "Alergijsko zapaljenje krvnog suda",
        "la": "Angiitis hypersensitiva"
    },
    {
        "icd": "M311",
        "rs": "Trombotična mikroangiopatija",
        "la": "Microangiopathia thrombotica"
    },
    {
        "icd": "M312",
        "rs": "Smrtonosni središnji granulom",
        "la": "Granuloma centrale letale"
    },
    {
        "icd": "M313",
        "rs": "Wegnerovi zrnasti tumori",
        "la": "Granulomatosis Wegner"
    },
    {
        "icd": "M314",
        "rs": "Takayasuov sindrom luka aorte",
        "la": "Syndroma arcus aortae [Takayasu]"
    },
    {
        "icd": "M315",
        "rs": "Arteritis džinovskih ćelija arterija sa reumatskom polimijalgijom",
        "la": "Arteritis gigantocellularis cum polymyalgia rheumatica"
    },
    {
        "icd": "M316",
        "rs": "Drugi arteritis džinovskih ćelija",
        "la": "Arteritis gigantocellularis alia"
    },
    {
        "icd": "M317",
        "rs": "Mikroskopski poliangitis",
        "la": "Polyangiitis microscopica"
    },
    {
        "icd": "M318",
        "rs": "Druga označena nekrozna oboljenja krvnih sudova",
        "la": "Vasculopathiae necroticae aliae, specificatae"
    },
    {
        "icd": "M319",
        "rs": "Nekrozno oboljenje krvnih sudova, neoznačeno",
        "la": "Vasculopathia necrotica, non specificata"
    },
    {
        "icd": "M32",
        "rs": "Sistemski eritemski lupus - vučja rana",
        "la": "Lupus erythematosus systemicus"
    },
    {
        "icd": "M320",
        "rs": "Sistemski eritemski lupus uzrokovan lekovima",
        "la": "Lupus erythematosus systematicus medicamentosus"
    },
    {
        "icd": "M321",
        "rs": "Sistemski eritemski lupus sa zahvatanjem organa ili sistema",
        "la": "Lupus erythematosus systemicus cum organo sive systemate apprehendenti"
    },
    {
        "icd": "M328",
        "rs": "Drugi oblici sistemskog eritemskog lupusa",
        "la": "Lupus erythematosus systemicus, formae aliae"
    },
    {
        "icd": "M329",
        "rs": "Sistemski eritemski lupus, neoznačen",
        "la": "Lupus erythematosus systemicus, non specificatus"
    },
    {
        "icd": "M33",
        "rs": "Zapaljenje kože i više mišića",
        "la": "Dermatopolymyositis"
    },
    {
        "icd": "M330",
        "rs": "Kožno-mišićno zapaljenje u dece",
        "la": "Dermatomyositis juvenilis"
    },
    {
        "icd": "M331",
        "rs": "Drugo zapaljenje kože i mišića",
        "la": "Dermatomyositis alia"
    },
    {
        "icd": "M332",
        "rs": "Zapaljenje više mišića",
        "la": "Polymyositis"
    },
    {
        "icd": "M339",
        "rs": "Zapaljenje kože i više mišića, neoznačeno",
        "la": "Dermatopolymyositis, non specificata"
    },
    {
        "icd": "M34",
        "rs": "Sistemska skleroza",
        "la": "Sclerosis systemica"
    },
    {
        "icd": "M340",
        "rs": "Progresivna skleroderma",
        "la": "Scleroderma progressivum diffusum"
    },
    {
        "icd": "M341",
        "rs": "CREST sindrom",
        "la": "Syndroma CREST"
    },
    {
        "icd": "M342",
        "rs": "Sistemska skleroza uzrokovana lekovima i hemikalijama",
        "la": "Sclerosis systemica medicamentosa et chemicaliis"
    },
    {
        "icd": "M348",
        "rs": "Drugi oblici sistemske skleroze",
        "la": "Sclerosis systemica, formae aliae"
    },
    {
        "icd": "M349",
        "rs": "Sistemska skleroza, neoznačena",
        "la": "Sclerosis systemica, non specificatum"
    },
    {
        "icd": "M35",
        "rs": "Druge sistemske bolesti vezivnog tkiva",
        "la": "Morbi textus connexivi systemici alii"
    },
    {
        "icd": "M350",
        "rs": "Sjögrenov suvi sindrom",
        "la": "Syndroma sicca [Sjögren]"
    },
    {
        "icd": "M351",
        "rs": "Drugi sindromi preklapanja [\"Overlap\"]",
        "la": "Syndromata flectentes aliae"
    },
    {
        "icd": "M352",
        "rs": "Behçetova bolest",
        "la": "Morbus Behçet"
    },
    {
        "icd": "M353",
        "rs": "Reumatski bolovi u mišićima",
        "la": "Polymyalgia rheumatica"
    },
    {
        "icd": "M354",
        "rs": "Rašireno (eozinifilno) zapaljenje ovojne opne (fascije)",
        "la": "Fasciitis diffusa (eosinophilica)"
    },
    {
        "icd": "M355",
        "rs": "Višestruka skleroza vezivnog tkiva",
        "la": "Fibrosclerosis multifocalis"
    },
    {
        "icd": "M356",
        "rs": "Povratno zapaljenje potkožnog masnog tkiva",
        "la": "Panniculitis recidiva [Weber-Christian]"
    },
    {
        "icd": "M357",
        "rs": "Sindrom povećane pokretljivosti",
        "la": "Syndroma hypermobilitatis"
    },
    {
        "icd": "M358",
        "rs": "Druge označene sistemske bolesti vezivnog tkiva",
        "la": "Morbi textus connexivi systemici alii, specificati"
    },
    {
        "icd": "M359",
        "rs": "Druga sistemska bolest vezivnog tkiva, neoznačena",
        "la": "Morbus textus connexivi systemicus alius, non specificatus"
    },
    {
        "icd": "M36",
        "rs": "Sistemske bolesti vezivnog tkiva u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi textus connexivi systemici in morbis alibi classificatis"
    },
    {
        "icd": "M360",
        "rs": "Zapaljenje kože i više mišića u tumorima",
        "la": "Dermato(poly)myositis in morbo neoplastico"
    },
    {
        "icd": "M361",
        "rs": "Oboljenja zgloba u tumorima",
        "la": "Arthropathia in morbo neoplastico"
    },
    {
        "icd": "M362",
        "rs": "Hemofilijsko oboljenje zgloba",
        "la": "Arthropathia haemophilica"
    },
    {
        "icd": "M363",
        "rs": "Oboljenje zgloba u drugim bolestima krvi",
        "la": "Arthropathia in morbis sanguinis aliis"
    },
    {
        "icd": "M364",
        "rs": "Artropatija kod reakcija preosetljivosti, klasifikovanih na drugom mestu",
        "la": "Arthropathia in reactionibus hypersensibilitatis alibi classificatis"
    },
    {
        "icd": "M368",
        "rs": "Sistemske bolesti vezivnog tkiva u drugim bolestima, koje su klasifikovane na drugom mestu",
        "la": "Morbi textus connexivi systemici in morbis aliis alibi classificatis"
    },
    {
        "icd": "M40",
        "rs": "Grba i unapred iskrivljena kičma",
        "la": "Kyphosis et lordosis"
    },
    {
        "icd": "M400",
        "rs": "Položajna grba",
        "la": "Kyphosis posturalis"
    },
    {
        "icd": "M401",
        "rs": "Druga sekundarna grba",
        "la": "Kyphosis secundaria alia"
    },
    {
        "icd": "M402",
        "rs": "Druga i neoznačna grba",
        "la": "Kyphosis alia et non specificata"
    },
    {
        "icd": "M403",
        "rs": "Sindrom ravnih leđa",
        "la": "Syndroma dorsi plani"
    },
    {
        "icd": "M404",
        "rs": "Druga unapred iskrivljena kičma",
        "la": "Lordosis alia"
    },
    {
        "icd": "M405",
        "rs": "Unapred iskrivljena kičma, neoznačena",
        "la": "Lordosis, non specificata"
    },
    {
        "icd": "M41",
        "rs": "Bočno iskrivljena kičma",
        "la": "Scoliosis"
    },
    {
        "icd": "M410",
        "rs": "Dečja [infantilna] idiopatska skolioza",
        "la": "Scoliosis idiopathica infantilis"
    },
    {
        "icd": "M411",
        "rs": "Juvenilna idiopatska skolioza",
        "la": "Scoliosis idiopathica juvenilis"
    },
    {
        "icd": "M412",
        "rs": "Druga idiopatski, bočno iskrivljena kičma",
        "la": "Scoliosis idiopathica alia"
    },
    {
        "icd": "M413",
        "rs": "Torakogena skolioza",
        "la": "Scoliosis thoracogenes"
    },
    {
        "icd": "M414",
        "rs": "Neuromuskularna skolioza",
        "la": "Scoliosis neuromuscularis"
    },
    {
        "icd": "M415",
        "rs": "Druga sekundarna bočno iskrivljena kičma",
        "la": "Scoliosis secundaria alia"
    },
    {
        "icd": "M418",
        "rs": "Drugi oblici bočno iskrivljene kičme",
        "la": "Scoliosis formae aliae"
    },
    {
        "icd": "M419",
        "rs": "Bočno iskrivljena kičma, neoznačena",
        "la": "Scoliosis, non specificata"
    },
    {
        "icd": "M42",
        "rs": "Koštano-hrskavično oboljenje kičme",
        "la": "Osteochondrosis spinalis"
    },
    {
        "icd": "M420",
        "rs": "Koštano-hrskavičavo oboljenje kičme u dece",
        "la": "Osteochondrosis spinalis juvenilis"
    },
    {
        "icd": "M421",
        "rs": "Koštano-hrskavičavo oboljenje kičme u odraslih osoba",
        "la": "Osteochondrosis spinalis adultorum"
    },
    {
        "icd": "M429",
        "rs": "Koštano-hrskavičavo oboljenje kičme, neoznačeno",
        "la": "Osteochondrosis spinalis, non specificata"
    },
    {
        "icd": "M43",
        "rs": "Druga deformacijska oboljenja kičme",
        "la": "Dorsopathiae deformantes aliae"
    },
    {
        "icd": "M430",
        "rs": "Raspadanje pršljena",
        "la": "Spondylolysis"
    },
    {
        "icd": "M431",
        "rs": "Iskliznuće pršljena",
        "la": "Spondylolisthesis"
    },
    {
        "icd": "M432",
        "rs": "Druga stapanja pršljenova kičme",
        "la": "Fusio columnae vertebralis alia"
    },
    {
        "icd": "M433",
        "rs": "Ponavljano nepotpuno iščašenje I i II vratnog pršljena sa oboljenjem kičmene moždine",
        "la": "Subluxatio atlantoaxialis recurrens cum myelopathia"
    },
    {
        "icd": "M434",
        "rs": "Drugo ponavljano nepotpuno iščašenje I i II vratnog pršljena",
        "la": "Subluxatio atlantoaxialis recurrens alia"
    },
    {
        "icd": "M435",
        "rs": "Drugo ponavljano nepotpuno iščašenje pršljena",
        "la": "Subluxatio vertebralis recurrens alia"
    },
    {
        "icd": "M436",
        "rs": "Krivošija",
        "la": "Torticollis"
    },
    {
        "icd": "M438",
        "rs": "Druga označena deformacijska oboljenja kičme",
        "la": "Dorsopathiae deformantes aliae, specificatae"
    },
    {
        "icd": "M439",
        "rs": "Deformacijsko oboljenje kičme, neoznačeno",
        "la": "Dorsopathia deformans, non specificata"
    },
    {
        "icd": "M45",
        "rs": "Zapaljenje pršljena sa ukočenošću",
        "la": "Spondylitis ankylopoietica"
    },
    {
        "icd": "M46",
        "rs": "Druge zapaljenske spondilopatije",
        "la": "Spondylopathiae inflammatoriae aliae"
    },
    {
        "icd": "M460",
        "rs": "Entezopatija kičme",
        "la": "Enthesopathia spinalis"
    },
    {
        "icd": "M461",
        "rs": "Zapaljenje krsne i bedrene kosti, koje nije klasifikovano na drugom mestu",
        "la": "Sacroiliitis, non alibi classificata"
    },
    {
        "icd": "M462",
        "rs": "Zapaljenje srži kosti pršljena",
        "la": "Osteomyelitis vertebralis"
    },
    {
        "icd": "M463",
        "rs": "Zapaljenje medupršljenskog diskusa (gnojno)",
        "la": "Discitis intervertebralis (pyogenes)"
    },
    {
        "icd": "M464",
        "rs": "Zapaljenje međupršljenskog diskusa, neoznačeno",
        "la": "Discitis, non specificata"
    },
    {
        "icd": "M465",
        "rs": "Druge infektivne spondilopatije",
        "la": "Spondylopathiae infectivae aliae"
    },
    {
        "icd": "M468",
        "rs": "Ostale označene zapaljenske spondilopatije",
        "la": "Spondylopathiae inflammatoriae aliae specificatae"
    },
    {
        "icd": "M469",
        "rs": "Zapaljenska spondilopatija, neoznačena",
        "la": "Spondylopathia inflammatoria non specificata"
    },
    {
        "icd": "M47",
        "rs": "Okoštavanje zglobova pršljenova",
        "la": "Spondylosis"
    },
    {
        "icd": "M470",
        "rs": "Kompresijski sindromi prednje arterije kičme i arterije pršljena",
        "la": "Syndromae compressionales arteriae spinalis anterioris et arteriae vertebralis"
    },
    {
        "icd": "M471",
        "rs": "Drugo okoštavanje zglobova pršljena sa oboljenjem kičmene moždine",
        "la": "Spondylosis cum myelopathia, alia"
    },
    {
        "icd": "M472",
        "rs": "Drugo okoštavanje zglobova pršljena sa oboljenjem korena živca",
        "la": "Spondylosis cum radiculopathia, alia"
    },
    {
        "icd": "M478",
        "rs": "Ostala okoštavanja zglobova pršljena [Spondiloze]",
        "la": "Spondylosis alia"
    },
    {
        "icd": "M479",
        "rs": "Okoštavanje zglobova pršljena [Spondiloza], neoznačena",
        "la": "Spondylosis, non specificata"
    },
    {
        "icd": "M48",
        "rs": "Ostale spondilopatije",
        "la": "Spondylopathiae aliae"
    },
    {
        "icd": "M480",
        "rs": "Suženje kanala kičme",
        "la": "Stenosis spinalis"
    },
    {
        "icd": "M481",
        "rs": "Forestierovi izraštaji na kosti sa ukočenjem",
        "la": "Hyperosteosis ankylopoietica [Forestier]"
    },
    {
        "icd": "M482",
        "rs": "Priljubljena kičma „kissing spine“",
        "la": "Contactus processuum spinosorum [„Kissing spine“]"
    },
    {
        "icd": "M483",
        "rs": "Oboljenja kičme uzrokovana povredom [Traumatska spondilopatija]",
        "la": "Spondylopathia traumatica"
    },
    {
        "icd": "M484",
        "rs": "Prelom pršljena usled zamora",
        "la": "Fractura vertebrae propter contentionem"
    },
    {
        "icd": "M485",
        "rs": "Smanjena visina tela pršljena [prelomljen, kolabiran pršljen], neklasifikovana na drugom mestu",
        "la": "Collapsus vertebrae, non alibi classificatus"
    },
    {
        "icd": "M488",
        "rs": "Druga označena oboljenja kičme",
        "la": "Spondylopathiae aliae, specificatae"
    },
    {
        "icd": "M489",
        "rs": "Oboljenje kičme [Spondilopatija], neoznačeno",
        "la": "Spondylopathia, non specificata"
    },
    {
        "icd": "M49",
        "rs": "Oboljenja kičme u bolestima klasifikovanim na drugom mestu",
        "la": "Spondylopathiae in morbis alibi classificatis"
    },
    {
        "icd": "M490",
        "rs": "Tuberkuloza kičme",
        "la": "Tuberculosis columnae vertebralis"
    },
    {
        "icd": "M491",
        "rs": "Zapaljenje kičme uzrokovano brucelozom",
        "la": "Spondylitis brucellosa"
    },
    {
        "icd": "M492",
        "rs": "Zapaljenje kičme uzrokovano enterobakterijama",
        "la": "Spondylitis enterobacterialis"
    },
    {
        "icd": "M493",
        "rs": "Oboljenje kičme u drugim zaraznim i parazitarnim bolestima, koje su klasifikovane na drugom mestu",
        "la": "Spondylopathia in aliis morbis infectiosis et parasiticis alibi classificatis"
    },
    {
        "icd": "M494",
        "rs": "Neuropatska spondilopatija",
        "la": "Spondylopathia neuropathica"
    },
    {
        "icd": "M495",
        "rs": "Slomljen pršljen u bolestima koje su klasifikovane na drugom mestu",
        "la": "Collapsus vertebrae in morbis alibi classificatis"
    },
    {
        "icd": "M498",
        "rs": "Oboljenja kičme u drugim bolestima, klasifikovanim na drugom mestu",
        "la": "Spondylopathia in morbis aliis, alibi classificatis"
    },
    {
        "icd": "M50",
        "rs": "Bolesti međupršljenskih diskusa vrata",
        "la": "Morbi discorum intervertebralium cervicalium"
    },
    {
        "icd": "M500",
        "rs": "Ispadanje diskusa vrata sa oboljenjem kičmene moždine (mijelopatijom)",
        "la": "Prolapsus disci intervertebralis cervicalis cum myelopathia"
    },
    {
        "icd": "M501",
        "rs": "Ispadanje vratnog diskusa sa oboljenjem korena živca (radikulopatijom)",
        "la": "Prolapsus disci intervertebralis cervicalis cum radiculopathia"
    },
    {
        "icd": "M502",
        "rs": "Drugo ispadanje diskusa vrata",
        "la": "Prolapsus disci intervertebralis cervicalis alius"
    },
    {
        "icd": "M503",
        "rs": "Druga degenerativna oboljenja diskusa vrata",
        "la": "Degenerationes discorum cervicaliorum aliae"
    },
    {
        "icd": "M508",
        "rs": "Druge bolesti diskusa vrata",
        "la": "Morbi discorum intervertebralium cervicalium alii"
    },
    {
        "icd": "M509",
        "rs": "Bolest diskusa vrata, neoznačena",
        "la": "Morbus disci intervertebralis cervicalis, non specificatus"
    },
    {
        "icd": "M51",
        "rs": "Druge bolesti međupršljenskog diskusa",
        "la": "Morbi discorum intervertebralium alii"
    },
    {
        "icd": "M510",
        "rs": "Ispadanje (prolaps) slabinskog i drugih međupršljenskih diskusa sa oboljenjem kičme (mijelopatijom)",
        "la": "Prolapsus disci intervertebralis lumbalis et discorum intervertebralium aliorum cum myelopathia"
    },
    {
        "icd": "M511",
        "rs": "Ispadanje [prolaps] slabinskog i drugih međupršljenskih diskusa sa oboljenjem korena živca [radikulopatijom]",
        "la": "Prolapsus disci intervertebralis lumbalis et disci interverterbralis aliorum cum radiculopathia"
    },
    {
        "icd": "M512",
        "rs": "Druga označena nestabilnost međupršljenskog diskusa",
        "la": "Dislocatio disci intervertebralis alia, specificata"
    },
    {
        "icd": "M513",
        "rs": "Druga označena degenerativna promena međupršljenskog diskusa",
        "la": "Degeneratio disci intervertebralis alia, specificata"
    },
    {
        "icd": "M514",
        "rs": "Schmorlovi čvorići",
        "la": "Nodi Schmorl"
    },
    {
        "icd": "M518",
        "rs": "Ostale označene bolesti međupršljenskih diskusa",
        "la": "Morbi discorum intervertebralium alii, specificati"
    },
    {
        "icd": "M519",
        "rs": "Bolest međupršljenskog diskusa, neoznačena",
        "la": "Morbus disci intervertebralis, non specificatus"
    },
    {
        "icd": "M53",
        "rs": "Ostale dozopatije, koje nisu klasifikovane na drugom mestu",
        "la": "Dorsopathiae aliae, non alibi classificatae"
    },
    {
        "icd": "M530",
        "rs": "Vratno-lobanjski [Cervikokranijalni] sindrom",
        "la": "Syndroma cervicocranialis"
    },
    {
        "icd": "M531",
        "rs": "Vratno-ručni [Cervikobrahijalni] sindrom",
        "la": "Syndroma cervicobrachialis"
    },
    {
        "icd": "M532",
        "rs": "Nestabilnost kičme",
        "la": "Instabilitas columnae vertebralis"
    },
    {
        "icd": "M533",
        "rs": "Krsno-trtične [Sakrokokcigealne] bolesti, koje nisu klasifikovane na drugom mestu",
        "la": "Morbi sacrococcygei, non alibi classificati"
    },
    {
        "icd": "M538",
        "rs": "Ostale označene dorzopatije",
        "la": "Dorsopathiae aliae, specificatae"
    },
    {
        "icd": "M539",
        "rs": "Oboljenje kičme, neoznačeno",
        "la": "Dorsopathia, non specificata"
    },
    {
        "icd": "M54",
        "rs": "Bol u leđima",
        "la": "Dorsalgia"
    },
    {
        "icd": "M540",
        "rs": "Zapaljenje potkožnog masnog tkiva vrata i leđa",
        "la": "Panniculitis regionis colli et dorsi"
    },
    {
        "icd": "M541",
        "rs": "Oboljenja korena živca",
        "la": "Radiculopathia"
    },
    {
        "icd": "M542",
        "rs": "Bol u vratu",
        "la": "Cervicalgia"
    },
    {
        "icd": "M543",
        "rs": "Bol u sedalnom živcu",
        "la": "Ischialgia"
    },
    {
        "icd": "M544",
        "rs": "Bol u slabinskom delu kičme i sedalnom živcu",
        "la": "Lumboischialgia"
    },
    {
        "icd": "M545",
        "rs": "Bol u donjem delu leđa",
        "la": "Dolor sacralis"
    },
    {
        "icd": "M546",
        "rs": "Bol u grudnom delu kičme",
        "la": "Dolor dorsi thoracalis"
    },
    {
        "icd": "M548",
        "rs": "Drugi bol u leđima",
        "la": "Dorsalgia alia"
    },
    {
        "icd": "M549",
        "rs": "Bol u ledima, neoznačen",
        "la": "Dorsalgia, non specificata"
    },
    {
        "icd": "M60",
        "rs": "Zapaljenje mišića",
        "la": "Myositis"
    },
    {
        "icd": "M600",
        "rs": "Infektivni miozitis",
        "la": "Myositis infectiva"
    },
    {
        "icd": "M601",
        "rs": "Međućelijsko zapaljenje mišića",
        "la": "Myositis interstitialis"
    },
    {
        "icd": "M602",
        "rs": "Granulom stranog tela mekog tkiva, neklasifikovan na drugom mestu",
        "la": "Granuloma corporis alieni textus mollis, non alibi classificatum"
    },
    {
        "icd": "M608",
        "rs": "Drugo zapaljenje mišića",
        "la": "Myositis alia"
    },
    {
        "icd": "M609",
        "rs": "Zapaljenje mišića, neoznačeno",
        "la": "Myositis, non specificata"
    },
    {
        "icd": "M61",
        "rs": "Kalcifikacija - zakrečavanje mišića i okoštavanje mišića",
        "la": "Calcificatio musculi et ossificatio musculi"
    },
    {
        "icd": "M610",
        "rs": "Zapaljenje mišića sa okoštavanjem posle povrede [Traumatski osificirajući miozitis]",
        "la": "Myositis ossificans traumatica"
    },
    {
        "icd": "M611",
        "rs": "Progredirajuće zapaljenje mišića sa okoštavanjem [Progresivni osificirajući miozitis]",
        "la": "Myositis ossificans progressiva"
    },
    {
        "icd": "M612",
        "rs": "Kalcifikacija mišića i okoštavanje oduzetog mišića",
        "la": "Calcificatio musculi et ossificatio musculi paralytici"
    },
    {
        "icd": "M613",
        "rs": "Kalcifikacija mišića i okoštavanje mišića udruženo sa opekotinama",
        "la": "Calcificatio musculi et ossificatio musculi cum combustionibus"
    },
    {
        "icd": "M614",
        "rs": "Druga kalcifikacija mišića",
        "la": "Calcificatio musculi alia"
    },
    {
        "icd": "M615",
        "rs": "Drugo okoštavanje mišića",
        "la": "Ossificatio musculi alia"
    },
    {
        "icd": "M619",
        "rs": "Kalcifikacija mišića i okoštavanje mišića, neoznačeno",
        "la": "Calcificatio musculi et ossificatio musculi, non specificata"
    },
    {
        "icd": "M62",
        "rs": "Ostale bolesti mišića",
        "la": "Morbi musculi alii"
    },
    {
        "icd": "M620",
        "rs": "Razmicanje [razdvajanje - dijastaza] mišića",
        "la": "Diastasis musculi"
    },
    {
        "icd": "M621",
        "rs": "Drugo prsnuće mišića (netraumatsko)",
        "la": "Ruptura musculi alia (non traumatica)"
    },
    {
        "icd": "M622",
        "rs": "Infarkt mišića kod prekida krvotoka",
        "la": "Infarctus musculi ischaemicus"
    },
    {
        "icd": "M623",
        "rs": "Sindrom nepokretnosti (paraplegični)",
        "la": "Syndroma immobilitatis (paraplegica)"
    },
    {
        "icd": "M624",
        "rs": "Zgrčenost mišića",
        "la": "Contractura musculi"
    },
    {
        "icd": "M625",
        "rs": "Oslabljenost i atrofija mišića, neklasifikovana na drugom mestu",
        "la": "Deminutio et atrophia musculi, non alibi classificata"
    },
    {
        "icd": "M626",
        "rs": "Povreda mišića [Istegnuti mišić]",
        "la": "Distensio musculi"
    },
    {
        "icd": "M628",
        "rs": "Ostale označene bolesti mišića",
        "la": "Morbi musculorum alii, specificati"
    },
    {
        "icd": "M629",
        "rs": "Bolest mišića, neoznačena",
        "la": "Morbus musculi, non specificatus"
    },
    {
        "icd": "M63",
        "rs": "Bolesti mišića kod bolesti klasifikovanih na drugom mestu",
        "la": "Morbi musculorum in morbis alibi classificatis"
    },
    {
        "icd": "M630",
        "rs": "Zapaljenje mišića [Miozitis] u bakterijskim bolestima koje su klasifikovane na drugom mestu",
        "la": "Myositis in morbis bacterialibus alibi classificatis"
    },
    {
        "icd": "M631",
        "rs": "Zapaljenje mišića [Miozitis] u protozoalnim i parazitarnim infekcijama, koje su klasifikovane na drugom mestu",
        "la": "Myositis in infectionibus protozoalibus et parazitariis alibi classificatis"
    },
    {
        "icd": "M632",
        "rs": "Zapaljenje mišića [Miozitis] kod ostalih infektivnih bolesti koje su klasifikovane na drugom mestu",
        "la": "Myositis in aliis morbis infectivis, alibi classificatis"
    },
    {
        "icd": "M633",
        "rs": "Zapaljenje mišića [Miozitis] u sarkoidozi",
        "la": "Myositis sarcoidosa"
    },
    {
        "icd": "M638",
        "rs": "Ostale mišićne bolesti kod bolesti klasifikovanih na drugom mestu",
        "la": "Morbi musculorum alii in morbis alibi classificatis"
    },
    {
        "icd": "M65",
        "rs": "Zapaljenje sinovije i zapaljenje tetive sa sinovijom",
        "la": "Synovitis et tenosynovitis"
    },
    {
        "icd": "M650",
        "rs": "Gnojno zapaljenje tetiva sa sinovijom",
        "la": "Tenosynovitis purulenta"
    },
    {
        "icd": "M651",
        "rs": "Drugi infektivni (teno)sinovitis",
        "la": "(Teno)synovitis infectiva alia"
    },
    {
        "icd": "M652",
        "rs": "Kalcificirajući tendinitis",
        "la": "Tendinitis calcificata"
    },
    {
        "icd": "M653",
        "rs": "Škljocajući prst („trigger finger“)",
        "la": "Digitus detendens"
    },
    {
        "icd": "M654",
        "rs": "de Quervainova bolest",
        "la": "Tenosynovitis radii styloidis [de Quervain]"
    },
    {
        "icd": "M658",
        "rs": "Drugo zapaljenje sinovije i zapaljenje tetive sa sinovijom",
        "la": "Synovitis et tenosynovitis alia"
    },
    {
        "icd": "M659",
        "rs": "Zapaljenje sinovije i zapaljenje tetive sa sinovijom, neoznačeno",
        "la": "Synovitis et tenosynovitis, non specificata"
    },
    {
        "icd": "M66",
        "rs": "Spontano prsnuće sinovije i tetive",
        "la": "Ruptura synoviae et tendinis spontanea"
    },
    {
        "icd": "M660",
        "rs": "Prsnuće zatkolene ciste",
        "la": "Ruptura cystae popliteae"
    },
    {
        "icd": "M661",
        "rs": "Spontano prsnuće sinovije",
        "la": "Ruptura synoviae spontanea"
    },
    {
        "icd": "M662",
        "rs": "Spontano prsnuće tetive opružača",
        "la": "Ruptura tendinis extensoris spontanea"
    },
    {
        "icd": "M663",
        "rs": "Spontano prsnuće tetive pregibača",
        "la": "Ruptura tendinis flexoris spontanea"
    },
    {
        "icd": "M664",
        "rs": "Spontano prsnuće drugih tetiva",
        "la": "Ruptura tendinum aliorum spontanea"
    },
    {
        "icd": "M665",
        "rs": "Spontano prsnuće neoznačene tetive",
        "la": "Ruptura tendinis spontanea, non specificati"
    },
    {
        "icd": "M67",
        "rs": "Druge bolesti sinovije i tetive",
        "la": "Morbi synoviae et tendinis alii"
    },
    {
        "icd": "M670",
        "rs": "Skraćena Ahilova tetiva (stečena)",
        "la": "Tendo calcanei brevis (acquisita)"
    },
    {
        "icd": "M671",
        "rs": "Druga zgrčenost tetive (ovojnice)",
        "la": "Contractura tendinis (tunicarum) alia"
    },
    {
        "icd": "M672",
        "rs": "Zadebljanje sinovije, neklasifikovano na drugom mestu",
        "la": "Hypertrophia synovialis, non alibi classificata"
    },
    {
        "icd": "M673",
        "rs": "Prolazno zapaljenje sinovije",
        "la": "Synovitis transitiva"
    },
    {
        "icd": "M674",
        "rs": "Ganglion",
        "la": "Ganglion"
    },
    {
        "icd": "M678",
        "rs": "Ostale označene bolesti sinovije i tetive",
        "la": "Morbi synoviae et tendinis alii, specificati"
    },
    {
        "icd": "M679",
        "rs": "Bolest sinovije i tetive, neoznačene",
        "la": "Morbus synovialis et tendinis, non specificatus"
    },
    {
        "icd": "M68",
        "rs": "Bolesti sinovije i tetive u bolestima koje su klasifikovane na drugom mestu",
        "la": "Morbi synoviae et tendinis in morbis alibi classificatis"
    },
    {
        "icd": "M680",
        "rs": "Sinovitis i tenosinovitis kod bakterijskih bolesti klasifikovanih na drugom mestu",
        "la": "Morbi synoviae et tendinis in morbis bacterialibus alibi classificatis"
    },
    {
        "icd": "M688",
        "rs": "Ostale bolesti sinovije i tetive kod bolesti klasifikovanih na drugom mestu",
        "la": "Morbi synoviae et tendinis alii in morbis alibi classificatis"
    },
    {
        "icd": "M70",
        "rs": "Bolesti mekih tkiva nastale kao posledica aktivnosti, povećane aktivnosti i pritiska",
        "la": "Morbi textuum mollium propter activitatem, hyperactivitatem et pressionem"
    },
    {
        "icd": "M700",
        "rs": "Hronični krepitantni sinovitis šake i ručnog zgloba",
        "la": "Synovitis manus et carpi crepitans chronica"
    },
    {
        "icd": "M701",
        "rs": "Zapaljenje serozne kese šake",
        "la": "Bursitis manus"
    },
    {
        "icd": "M702",
        "rs": "Zapaljenje serozne kese vrha lakta",
        "la": "Bursitis olecrani"
    },
    {
        "icd": "M703",
        "rs": "Drugo zapaljenje serozne kese lakta",
        "la": "Bursitis cubiti alia"
    },
    {
        "icd": "M704",
        "rs": "Zapaljenje serozne kese ispred čašice",
        "la": "Bursitis praepatellaris"
    },
    {
        "icd": "M705",
        "rs": "Drugo zapaljenje serozne kese kolena",
        "la": "Bursitis genus alia"
    },
    {
        "icd": "M706",
        "rs": "Zapaljenje serozne kese gornje kvrge buta",
        "la": "Bursitis trochanterica"
    },
    {
        "icd": "M707",
        "rs": "Drugo zapaljenje serozne kese kuka",
        "la": "Bursitis coxae alia"
    },
    {
        "icd": "M708",
        "rs": "Ostale bolesti mekog tkiva nastale kao posledica aktivnosti, povećane aktivnosti i pritiska",
        "la": "Morbi textuum mollium propter activitatem, hyperactivitatem et pressionem alii"
    },
    {
        "icd": "M709",
        "rs": "Neoznačena bolest mekog tkiva nastala kao posledica aktivnosti, povećane aktivnosti i pritiska",
        "la": "Morbus textuum mollium propter activitatem, hyperactivitatem et pressionem, non specificatus"
    },
    {
        "icd": "M71",
        "rs": "Ostala oboljenja serozne kese",
        "la": "Bursopathiae aliae"
    },
    {
        "icd": "M710",
        "rs": "Gnojno zapaljenje serozne kese",
        "la": "Bursitis purulenta"
    },
    {
        "icd": "M711",
        "rs": "Drugo infektivno zapaljenje serozne kese",
        "la": "Bursitis infectiva alia"
    },
    {
        "icd": "M712",
        "rs": "Bakerova serozna cista zatkolene jame",
        "la": "Cystis synovialis poplitea [Baker]"
    },
    {
        "icd": "M713",
        "rs": "Druga cista serozne kese",
        "la": "Cystis bursae alia"
    },
    {
        "icd": "M714",
        "rs": "Kalcifikacija serozne kese",
        "la": "Calcificatio bursae"
    },
    {
        "icd": "M715",
        "rs": "Drugo zapaljenje serozne kese koje nije klasifikovano na drugom mestu",
        "la": "Bursitis alia, non alibi classificata"
    },
    {
        "icd": "M718",
        "rs": "Ostala označena oboljenja serozne kese",
        "la": "Bursopathia alia, specificata"
    },
    {
        "icd": "M719",
        "rs": "Neoznačeno oboljenje serozne kese",
        "la": "Bursopathia, non specificata"
    },
    {
        "icd": "M72",
        "rs": "Bolesti umnožavanja vezivnog tkiva",
        "la": "Morbi fibroblastici"
    },
    {
        "icd": "M720",
        "rs": "Dupuytrenova zgrčenost tetive dlana",
        "la": "Fibromatosis fasciae palmaris [Dupuytren]"
    },
    {
        "icd": "M721",
        "rs": "Jastučići na zglobovima nastali zbog pritiska [„knuckle pads“]",
        "la": "Caro malleoli mollis [malleolus carnosus]"
    },
    {
        "icd": "M722",
        "rs": "Stvaranje mnogobrojnih tumora ovojnica tabana [Plantarna fascijalna fibromatoza]",
        "la": "Fibromatosis fasciae plantaris"
    },
    {
        "icd": "M724",
        "rs": "Pseudosarkomatoza tetive",
        "la": "Fibromatosis pseudosarcomatosa"
    },
    {
        "icd": "M726",
        "rs": "Nekrotizujući fascitis",
        "la": "Fasciitis necroticans"
    },
    {
        "icd": "M728",
        "rs": "Druge bolesti umnožavanja vezivnog tkiva [Ostale fibroblastične bolesti]",
        "la": "Morbi fibroblastici alii"
    },
    {
        "icd": "M729",
        "rs": "Bolest umnožavanja vezivnog tkiva [Fibroblastična bolest], neoznačena",
        "la": "Morbus fibroblasticus, non specificatus"
    },
    {
        "icd": "M73",
        "rs": "Bolesti mekih tkiva u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi textuum mollium in morbis alibi classificatis"
    },
    {
        "icd": "M730",
        "rs": "Zapaljenje serozne kese uzrokovano gonokokom [Gonokokni burzitis]",
        "la": "Bursitis gonococcica"
    },
    {
        "icd": "M731",
        "rs": "Zapaljenje serozne kese uzrokovano sifilisom [Sifilitični burzitis]",
        "la": "Bursitis syphilitica"
    },
    {
        "icd": "M738",
        "rs": "Druge bolesti mekih tkiva u bolestima klasifikovanim na drugom mestu",
        "la": "Morbi textuum mollium alii in morbis alibi classificatis"
    },
    {
        "icd": "M75",
        "rs": "Oštećenja ramena",
        "la": "Laesiones regionis humeroscapularis"
    },
    {
        "icd": "M750",
        "rs": "Adhezivni kapsulitis ramena",
        "la": "Capsulitis humeroscapularis adhaesiva"
    },
    {
        "icd": "M751",
        "rs": "Sindrom rotatorne manžetne",
        "la": "Syndroma armillae rotatoriae"
    },
    {
        "icd": "M752",
        "rs": "Zapaljenje tetive dvoglavog nadlaktičnog mišića",
        "la": "Tendinitis bicipitis"
    },
    {
        "icd": "M753",
        "rs": "Kalcifikacijsko zapaljenje tetiva ramena",
        "la": "Tendinitis humeroscapularis calcificata"
    },
    {
        "icd": "M754",
        "rs": "Sindrom ramena uzrokovan udarom [„Impingement syndrome“]",
        "la": "Syndroma humeri percussionale"
    },
    {
        "icd": "M755",
        "rs": "Burzitis ramena",
        "la": "Bursitis regionis humeroscapularis"
    },
    {
        "icd": "M758",
        "rs": "Druga oštećenja ramena",
        "la": "Laesiones regionis humeroscapularis, alii"
    },
    {
        "icd": "M759",
        "rs": "Oštećenje ramena, neoznačeno",
        "la": "Laesio regionis humeroscapularis, non specificata"
    },
    {
        "icd": "M76",
        "rs": "Entezopatija noge, isključujući stopalo",
        "la": "Enthesopathia extremitatis inferioris, pedem excludens"
    },
    {
        "icd": "M760",
        "rs": "Zapaljenje tetive sedalnog mišića",
        "la": "Tendinitis glutaealis"
    },
    {
        "icd": "M761",
        "rs": "Zapaljenje tetive bedreno-slabinskog mišića",
        "la": "Tendinitis iliopsoae"
    },
    {
        "icd": "M762",
        "rs": "Izraštaj grebena bedrene kosti [Egzostoza grebena ilijačne kosti]",
        "la": "Excrescentia cristae ilicae"
    },
    {
        "icd": "M763",
        "rs": "Sindrom bedreno-golenjačke trake [Sindrom iliotibijalne mišićne tetive]",
        "la": "Syndroma lemnisci iliotibialis"
    },
    {
        "icd": "M764",
        "rs": "Tibijalni kolateralni burzitis [Pellegrini-Stieda]",
        "la": "Bursitis collateralis tibiae [Pellegrini-Stieda]"
    },
    {
        "icd": "M765",
        "rs": "Zapaljenje tetive čašice kolena",
        "la": "Tendinitis patellaris"
    },
    {
        "icd": "M766",
        "rs": "Zapaljenje tetive kosti pete [Tendinitis Ahilove tetive]",
        "la": "Tendinitis calcanei"
    },
    {
        "icd": "M767",
        "rs": "Zapaljenje tetive lista",
        "la": "Tendinitis peronealis"
    },
    {
        "icd": "M768",
        "rs": "Ostale entezopatije noge, osim stopala",
        "la": "Enthesopathiae extremitatis inferioris, pedem excludens, aliae"
    },
    {
        "icd": "M769",
        "rs": "Entezopatija noge, neoznačena",
        "la": "Enthesopathia extremitatis inferioris, non specificata"
    },
    {
        "icd": "M77",
        "rs": "Ostale entezopatije",
        "la": "Enthesopathiae aliae"
    },
    {
        "icd": "M770",
        "rs": "Medijalni epikondilitis",
        "la": "Epicondylitis medialis"
    },
    {
        "icd": "M771",
        "rs": "Lateralni epikondilitis",
        "la": "Epicondylitis lateralis"
    },
    {
        "icd": "M772",
        "rs": "Periartritis ručja",
        "la": "Periarthritis carpi"
    },
    {
        "icd": "M773",
        "rs": "Izraslina na kosti pete",
        "la": "Excrescentia calcanei [calx dolorosa]"
    },
    {
        "icd": "M774",
        "rs": "Bol u donožju",
        "la": "Metatarsalgia"
    },
    {
        "icd": "M775",
        "rs": "Druga entezopatija stopala",
        "la": "Enthesopathia pedis alia"
    },
    {
        "icd": "M778",
        "rs": "Ostale entezopatije, koje nisu klasifikovane na drugom mestu",
        "la": "Enthesopathiae aliae, non alibi classificatae"
    },
    {
        "icd": "M779",
        "rs": "Entezopatija, neoznačena",
        "la": "Enthesopathia, non specificata"
    },
    {
        "icd": "M79",
        "rs": "Ostale bolesti mekih tkiva, koje nisu klasifikovane na drugom mestu",
        "la": "Morbi textuum mollium alii, non alibi classificati"
    },
    {
        "icd": "M790",
        "rs": "Reumatizam, neoznačen",
        "la": "Rheumatismus, non specificatus"
    },
    {
        "icd": "M791",
        "rs": "Bol u mišiću",
        "la": "Myalgia"
    },
    {
        "icd": "M792",
        "rs": "Neuralgija i neuritis, neoznačeni",
        "la": "Neuralgia et neuritis, non specificata"
    },
    {
        "icd": "M793",
        "rs": "Zapaljenje masnog potkožnog tkiva, neoznačeno",
        "la": "Panniculitis, non specificata"
    },
    {
        "icd": "M794",
        "rs": "Zadebljanje podčašičnog masnog tkiva kolena [Hipertrofija infrapatelarnog masnog jastučeta]",
        "la": "Hypertrophia corporis adiposi infrapatellaris"
    },
    {
        "icd": "M795",
        "rs": "Zaostalo strano telo u mekom tkivu",
        "la": "Corpus alienum in textu molli residuale"
    },
    {
        "icd": "M796",
        "rs": "Bol u ekstremitetu",
        "la": "Dolor extremitatis"
    },
    {
        "icd": "M797",
        "rs": "Fibromijalgija",
        "la": "Fibromyalgia"
    },
    {
        "icd": "M798",
        "rs": "Druge označene bolesti mekih tkiva",
        "la": "Morbi textuum mollium alii, specificati"
    },
    {
        "icd": "M799",
        "rs": "Bolest mekih tkiva, neoznačena",
        "la": "Morbus textuum mollium, non specificatus"
    },
    {
        "icd": "M80",
        "rs": "Gubitak koštane mase sa patološkim prelomom [Osteoporoza sa patološkim prelomom]",
        "la": "Osteoporosis cum fractura pathologica"
    },
    {
        "icd": "M800",
        "rs": "Gubitak koštane mase sa patološkim prelomom posle menopauze",
        "la": "Osteoporosis postmenopausalis cum fractura pathologica"
    },
    {
        "icd": "M801",
        "rs": "Gubitak koštane mase sa patološkim prelomom posle odstranjenja jajnika",
        "la": "Osteoporosis post ovariectomiam cum fractura pathologica"
    },
    {
        "icd": "M802",
        "rs": "Gubitak koštane mase sa patološkim prelomom uzrokovan fizičkom nepokretnošću",
        "la": "Osteoporosis immobilisationalis cum fractura pathologica"
    },
    {
        "icd": "M803",
        "rs": "Malapsorpcijska osteoporoza s patološkim prelomom nakon hirurškog zahvata",
        "la": "Osteoporosis malabsorptionalis postchirurgica cum fractura pathologica"
    },
    {
        "icd": "M804",
        "rs": "Osteoporoza s patološkim prelomom uzrokovna lekovima",
        "la": "Osteoporosis medicamentosa cum fractura pathologica"
    },
    {
        "icd": "M805",
        "rs": "Idiopatska osteoporoza sa patološkim prelomom",
        "la": "Osteoporosis idiopathica cum fractura pathologica"
    },
    {
        "icd": "M808",
        "rs": "Drugi gubitak koštane mase sa patološkim prelomom",
        "la": "Osteoporosis cum fractura pathologica, alia"
    },
    {
        "icd": "M809",
        "rs": "Neoznačeni gubitak koštane mase sa patološkim prelomom",
        "la": "Osteoporosis cum fractura pathologica, non specificata"
    },
    {
        "icd": "M81",
        "rs": "Gubitak koštane mase bez patološkog preloma [Osteoporoza bez patološkog preloma]",
        "la": "Osteoporosis sine fractura pathologica"
    },
    {
        "icd": "M810",
        "rs": "Gubitak koštane mase posle menopauze",
        "la": "Osteoporosis postmenopausalis"
    },
    {
        "icd": "M811",
        "rs": "Gubitak koštane mase posle odstranjenja jajnika",
        "la": "Osteoporosis post ovariectomiam"
    },
    {
        "icd": "M812",
        "rs": "Gubitak koštane mase uzrokovan nepokretnošću",
        "la": "Osteoporosis immobilisationalis"
    },
    {
        "icd": "M813",
        "rs": "Malapsorpcijska osteoporoza nakon hirurškog zahvata",
        "la": "Osteoporosis malabsorptionalis postchirurgica"
    },
    {
        "icd": "M814",
        "rs": "Osteoporoza uzrokovana lekovima",
        "la": "Osteoporosis medicamentosa"
    },
    {
        "icd": "M815",
        "rs": "Idiopatska osteoporoza",
        "la": "Osteoporosis idiopathica"
    },
    {
        "icd": "M816",
        "rs": "Ograničena, lokalizovana osteoporoza [Lequesne]",
        "la": "Osteoporosis localisata [Lequesne]"
    },
    {
        "icd": "M818",
        "rs": "Drugi gubitak koštane mase",
        "la": "Osteoporosis alia"
    },
    {
        "icd": "M819",
        "rs": "Osteoporoza, neoznačena",
        "la": "Osteoporosis, non specificata"
    },
    {
        "icd": "M82",
        "rs": "Osteoporoza kod bolesti klasifikovanih na drugom mestu",
        "la": "Osteoporosis in morbis alibi classificatis"
    },
    {
        "icd": "M820",
        "rs": "Osteoporoza kod višestrukog tumora koštane srži",
        "la": "Osteoporosis in myelomatosi multiplici"
    },
    {
        "icd": "M821",
        "rs": "Osteoporoza u bolestima žlezda sa unutrašnjim lučenjem",
        "la": "Osteoporosis in morbis endocrinis"
    },
    {
        "icd": "M828",
        "rs": "Osteoporoza u drugim bolestima klasifikovanim na drugom mestu",
        "la": "Osteoporosis in morbis aliis alibi classificatis"
    },
    {
        "icd": "M83",
        "rs": "Osteomalacija [Razmekšavanje kosti] u odraslih osoba",
        "la": "Osteomalacia ad adultum"
    },
    {
        "icd": "M830",
        "rs": "Razmekšavanje kosti u babinjama",
        "la": "Osteomalacia puerperalis"
    },
    {
        "icd": "M831",
        "rs": "Razmekšavanje kosti u starosti",
        "la": "Osteomalacia senilis"
    },
    {
        "icd": "M832",
        "rs": "Osteomalacija u odraslih kao posledica malapsorpcije",
        "la": "Osteomalacia ad adultum malabsorptionalis"
    },
    {
        "icd": "M833",
        "rs": "Osteomalacija u odraslih kao posledica loše ishrane [malnutricijska osteomalacija]",
        "la": "Osteomalacia ad adultum malnutritionalis"
    },
    {
        "icd": "M834",
        "rs": "Oboljenje kosti uzrokovano aluminijumom",
        "la": "Morbus ossium aluminicus"
    },
    {
        "icd": "M835",
        "rs": "Druga osteomalacija u odraslih uzrokovana lekovima",
        "la": "Osteomalacia ad adultum medicamentosa alia"
    },
    {
        "icd": "M838",
        "rs": "Druga osteomalacija u odraslih",
        "la": "Osteomalacia ad adultum alia"
    },
    {
        "icd": "M839",
        "rs": "Osteomalacija u odraslih, neoznačena",
        "la": "Osteomalacia ad adultum, non specificata"
    },
    {
        "icd": "M84",
        "rs": "Poremećaji kontinuiteta kosti",
        "la": "Morbi continuitatis ossis"
    },
    {
        "icd": "M840",
        "rs": "Loše srastao prelom",
        "la": "Malconcretio fracturae"
    },
    {
        "icd": "M841",
        "rs": "Nesrastao prelom [pseudoartoza]",
        "la": "Inconcretio ossis [pseudarthrosis]"
    },
    {
        "icd": "M842",
        "rs": "Usporeno zarastanje preloma",
        "la": "Concretio fracturae retardata"
    },
    {
        "icd": "M843",
        "rs": "Prelom zbog stresa, neklasifikovan na drugom mestu",
        "la": "Fractura pressionalis, non alibi classificata"
    },
    {
        "icd": "M844",
        "rs": "Patološki prelom, neklasifikovan na drugom mestu",
        "la": "Fractura pathologica, non alibi classificata"
    },
    {
        "icd": "M848",
        "rs": "Ostali poremećaji kontinuiteta kosti",
        "la": "Morbi continuitatis ossis alii"
    },
    {
        "icd": "M849",
        "rs": "Bolest kontinuiteta kosti, neoznačena",
        "la": "Morbus continuitatis ossis, non specificatus"
    },
    {
        "icd": "M85",
        "rs": "Ostali poremećaji u čvrstoći i građi kosti",
        "la": "Morbi densitatis et structurae ossis alii"
    },
    {
        "icd": "M850",
        "rs": "Nepravilan razvoj vezivnog tkiva",
        "la": "Dysplasia fibrosa (monostotica)"
    },
    {
        "icd": "M851",
        "rs": "Fluoroza skeleta",
        "la": "Fluorosis sceleti"
    },
    {
        "icd": "M852",
        "rs": "Izraštaj kosti lobanje [Hiperostoza lobanje]",
        "la": "Hyperosteosis cranii"
    },
    {
        "icd": "M853",
        "rs": "Zapaljenje kosti sa povećanjem gustine koštane strukture",
        "la": "Osteitis condensans"
    },
    {
        "icd": "M854",
        "rs": "Solitarna cista kosti",
        "la": "Cystis ossis solitaria"
    },
    {
        "icd": "M855",
        "rs": "Aneurizmatska cista kosti",
        "la": "Cystis ossis aneurismatica"
    },
    {
        "icd": "M856",
        "rs": "Druga cista kosti",
        "la": "Cystis ossis alia"
    },
    {
        "icd": "M858",
        "rs": "Druge označene bolesti čvrstine i strukture kosti",
        "la": "Morbi densitatis et structurae ossis alii, specificati"
    },
    {
        "icd": "M859",
        "rs": "Poremećaj u čvrstini i strukturi kosti, neoznačeni",
        "la": "Morbus densitatis et structurae ossis, non specificatus"
    },
    {
        "icd": "M86",
        "rs": "Zapaljenje koštane srži",
        "la": "Osteomyelitis"
    },
    {
        "icd": "M860",
        "rs": "Akutno hematogeno zapaljenje koštane srži",
        "la": "Osteomyelitis haematogenes acuta"
    },
    {
        "icd": "M861",
        "rs": "Drugo akutno zapaljenje koštane srži",
        "la": "Osteomyelitis acuta alia"
    },
    {
        "icd": "M862",
        "rs": "Subakutno zapaljenje koštane srži",
        "la": "Osteomyelitis subacuta"
    },
    {
        "icd": "M863",
        "rs": "Hronično višežarišno zapaljenje koštane srži",
        "la": "Osteomyelitis multifocalis chronica"
    },
    {
        "icd": "M864",
        "rs": "Hronično zapaljenje koštane srži sa dreniranjem žarišta",
        "la": "Osteomyelitis cum fluore foci chronica"
    },
    {
        "icd": "M865",
        "rs": "Drugo hronično hematogeno zapaljenje koštane srži",
        "la": "Osteomyelitis haematogenes chronica alia"
    },
    {
        "icd": "M866",
        "rs": "Drugo hronično zapaljenje koštane srži",
        "la": "Osteomyelitis chronica alia"
    },
    {
        "icd": "M868",
        "rs": "Drugo zapaljenje koštane srži",
        "la": "Osteomyelitis alia"
    },
    {
        "icd": "M869",
        "rs": "Neoznačeno zapaljenje koštane srži",
        "la": "Osteomyelitis, non specificata"
    },
    {
        "icd": "M87",
        "rs": "Osteonekroza",
        "la": "Osteonecrosis"
    },
    {
        "icd": "M870",
        "rs": "Idiopatska aseptička nekroza kosti",
        "la": "Osteonecrosis aseptica idiopathica"
    },
    {
        "icd": "M871",
        "rs": "Osteonekroza uzrokovana lekovima",
        "la": "Osteonecrosis medicamentosa"
    },
    {
        "icd": "M872",
        "rs": "Osteonekroza uzrokovana prethodnom povredom",
        "la": "Osteonecrosis posttraumatica"
    },
    {
        "icd": "M873",
        "rs": "Druga sekundarna osteonekroza",
        "la": "Osteonecrosis secundaria alia"
    },
    {
        "icd": "M878",
        "rs": "Druga osteonekroza",
        "la": "Osteonecrosis alia"
    },
    {
        "icd": "M879",
        "rs": "Neoznačena osteonekroza",
        "la": "Osteonecrosis, non specificata"
    },
    {
        "icd": "M88",
        "rs": "Pagetova bolest kosti",
        "la": "Morbus Paget ossium [osteitis deformans]"
    },
    {
        "icd": "M880",
        "rs": "Pagetova bolest lobanje",
        "la": "Morbus Paget cranii"
    },
    {
        "icd": "M888",
        "rs": "Pagetova bolest ostalih kostiju",
        "la": "Morbus Paget ossium aliorum"
    },
    {
        "icd": "M889",
        "rs": "Pagetova bolest kostiju, neoznačena",
        "la": "Morbus Paget ossium, non specificatus"
    },
    {
        "icd": "M89",
        "rs": "Ostali poremećaji kosti",
        "la": "Morbi ossium alii"
    },
    {
        "icd": "M890",
        "rs": "Bolni živčano mišićni poremećaji ishranjivanja",
        "la": "Algoneurodystrophia"
    },
    {
        "icd": "M891",
        "rs": "Zastoj rasta okrajka duge kosti [Epifizni zastoj]",
        "la": "Stasis epiphysica"
    },
    {
        "icd": "M892",
        "rs": "Ostali poremećaji rasta i razvoja kostiju",
        "la": "Morbi staturae et evolutionis ossium alii"
    },
    {
        "icd": "M893",
        "rs": "Zadebljanje kostiju [Hipertrofija kostiju]",
        "la": "Hypertrophia ossium"
    },
    {
        "icd": "M894",
        "rs": "Ostala koštano - zglobna zadebljanja [Ostale hipertrofične osteoartropatije]",
        "la": "Osteoartropathia hypertrophica alia"
    },
    {
        "icd": "M895",
        "rs": "Razgradnja kosti [osteoliza]",
        "la": "Osteolysis"
    },
    {
        "icd": "M896",
        "rs": "Oboljenje kostiju posle dečije paralize",
        "la": "Osteopathia postpolyomyelitica"
    },
    {
        "icd": "M898",
        "rs": "Ostali označeni poremećaji kosti",
        "la": "Morbi ossium alii, specificati"
    },
    {
        "icd": "M899",
        "rs": "Poremećaj kosti, neoznačen",
        "la": "Morbus ossium, non specificatus"
    },
    {
        "icd": "M90",
        "rs": "Oboljenja kosti [Osteopatije] kod bolesti koje su klasifikovane na drugom mestu",
        "la": "Osteopathiae in morbis alibi classificatis"
    },
    {
        "icd": "M900",
        "rs": "Tuberkuloza kosti",
        "la": "Osteomyelitis tuberculosa"
    },
    {
        "icd": "M901",
        "rs": "Zapaljenje pokosnice u drugim zaraznim bolestima, koje su klasifikovane na drugom mestu",
        "la": "Periostitis in morbis infectivis aliis alibi classificatis"
    },
    {
        "icd": "M902",
        "rs": "Oboljenje kosti u drugim zaraznim bolestima koje su klasifikovane na drugom mestu",
        "la": "Osteopathia in morbis infectivis aliis alibi classificatis"
    },
    {
        "icd": "M903",
        "rs": "Osteonekroza uzrokovana kesonskom bolešću",
        "la": "Osteonecrosis in morbo capsico"
    },
    {
        "icd": "M904",
        "rs": "Osteonekroza uzrokovana poremećajem hemoglobina",
        "la": "Osteonecrosis haemoglobinopathica"
    },
    {
        "icd": "M905",
        "rs": "Osteonekroza u ostalim bolestima koje su klasifikovane na drugom mestu",
        "la": "Osteonecrosis in morbis aliis alibi classificatis"
    },
    {
        "icd": "M906",
        "rs": "Deformacijsko zapaljenje kosti uzrokovano tumorom",
        "la": "Osteitis deformans neoplastica"
    },
    {
        "icd": "M907",
        "rs": "Prelom kostiju uzrokovan tumorom",
        "la": "Fractura ossium neoplastica"
    },
    {
        "icd": "M908",
        "rs": "Oboljenje kosti u drugim bolestima koje su klasifikovane na drugom mestu",
        "la": "Osteopathia in morbis aliis alibi classificatis"
    },
    {
        "icd": "M91",
        "rs": "Juvenilna osteohondroza kuka i karlice",
        "la": "Osteochondrosis coxae et pelvis juvenilis"
    },
    {
        "icd": "M910",
        "rs": "Juvenilna osteohondroza karlice",
        "la": "Osteochondrosis pelvis juvenilis"
    },
    {
        "icd": "M911",
        "rs": "Juvenilna osteohondroza glave butne kosti [Legg-Calvé-Perthes]",
        "la": "Osteochondrosis capitis femoralis juvenilis [Legg-Calvé-Perthes]"
    },
    {
        "icd": "M912",
        "rs": "Ravan kuk",
        "la": "Coxa plana"
    },
    {
        "icd": "M913",
        "rs": "Lažni bol u kuku",
        "la": "Pseudocoxalgia"
    },
    {
        "icd": "M918",
        "rs": "Ostale juvenilne osteohondroze kuka i karlice",
        "la": "Osteochondrosis coxae et pelvis juvenilis alia"
    },
    {
        "icd": "M919",
        "rs": "Juvenilna osteohondroza kuka i karlice, neoznačena",
        "la": "Osteochondrosis coxae et pelvis juvenilis, non specificata"
    },
    {
        "icd": "M92",
        "rs": "Druge juvenilne osteohondroze",
        "la": "Osteochondrosis juvenilis alia"
    },
    {
        "icd": "M920",
        "rs": "Juvenilna osteohondroza ramenjače",
        "la": "Osteochondrosis humeri juvenilis"
    },
    {
        "icd": "M921",
        "rs": "Juvenilna osteohondroza žbice i lakatne kosti",
        "la": "Osteochondrosis radii et ulnae juvenilis"
    },
    {
        "icd": "M922",
        "rs": "Juvenilna osteohondroza šake",
        "la": "Osteochondrosis manus juvenilis"
    },
    {
        "icd": "M923",
        "rs": "Druga juvenilna osteohondroza gornjih ekstremiteta",
        "la": "Osteochondrosis extremitatis superioris juvenilis alia"
    },
    {
        "icd": "M924",
        "rs": "Juvenilna osteohondroza čašice kolena",
        "la": "Osteochondrosis patellae juvenilis"
    },
    {
        "icd": "M925",
        "rs": "Juvenilna osteohondroza golenjače i lišnjače",
        "la": "Osteochondrosis tibiae et fibulae juvenilis"
    },
    {
        "icd": "M926",
        "rs": "Juvenilna osteohondroza nožja",
        "la": "Osteochondrosis tarsi juvenilis"
    },
    {
        "icd": "M927",
        "rs": "Juvenilna osteohondroza donožja",
        "la": "Osteochondrosis metatarsi juvenilis"
    },
    {
        "icd": "M928",
        "rs": "Druga označena juvenilna osteohondroza",
        "la": "Osteochondrosis juvenilis alia specificata"
    },
    {
        "icd": "M929",
        "rs": "Juvenilna osteohondroza, neoznačena",
        "la": "Osteochondrosis juvenilis, non specificata"
    },
    {
        "icd": "M93",
        "rs": "Ostale osteohondropatije",
        "la": "Osteochondropathiae aliae"
    },
    {
        "icd": "M930",
        "rs": "Iskliznuće gornjeg okrajka kosti buta neuzrokovano povredom",
        "la": "Prolapsus epiphysis femoralis superioris (non traumaticus)"
    },
    {
        "icd": "M931",
        "rs": "Kienböckova bolest odrasle osobe",
        "la": "Morbus Kienböck adulti"
    },
    {
        "icd": "M932",
        "rs": "Razarajuće (disekantno) koštano-hrskavičavo zapaljenje (osteohondritis)",
        "la": "Osteochondritis dissecans"
    },
    {
        "icd": "M938",
        "rs": "Ostale označene osteohondropatije",
        "la": "Osteochondropathiae aliae, specificatae"
    },
    {
        "icd": "M939",
        "rs": "Osteohondropatija, neoznačena",
        "la": "Osteochondropathia, non specificata"
    },
    {
        "icd": "M94",
        "rs": "Ostali poremećaji hrskavice",
        "la": "Morbi cartilaginis alii"
    },
    {
        "icd": "M940",
        "rs": "Tietzeov sindrom hrskavično-rebarnog spoja",
        "la": "Syndroma junctionis chondrocostalis [Tietze]"
    },
    {
        "icd": "M941",
        "rs": "Povratni polihondritis",
        "la": "Polychondritis recidiva"
    },
    {
        "icd": "M942",
        "rs": "Razmekšavanje hrskavice",
        "la": "Chondromalacia"
    },
    {
        "icd": "M943",
        "rs": "Rastapanje hrskavice",
        "la": "Chondrolysis"
    },
    {
        "icd": "M948",
        "rs": "Drugi označeni poremećaji hrskavice",
        "la": "Morbi cartilaginis alii, specificati"
    },
    {
        "icd": "M949",
        "rs": "Bolest hrskavice, neoznačena",
        "la": "Morbus cartilaginis, non specificatus"
    },
    {
        "icd": "M95",
        "rs": "Ostale stečene deformacije mišićno-koštanog sistema i vezivnog tkiva",
        "la": "Deformationes musculorum et ossium acquisitae aliae"
    },
    {
        "icd": "M950",
        "rs": "Stečeni deformitet nosa",
        "la": "Deformatio nasi acquisita"
    },
    {
        "icd": "M951",
        "rs": "Uho u obliku karfiola",
        "la": "Auris cymosa"
    },
    {
        "icd": "M952",
        "rs": "Drugi stečeni deformitet glave",
        "la": "Deformatio capitis acquisita alia"
    },
    {
        "icd": "M953",
        "rs": "Stečena deformacija vrata",
        "la": "Deformatio colli acquisita"
    },
    {
        "icd": "M954",
        "rs": "Stečena deformacija grudnog koša i rebra",
        "la": "Deformatio thoracis et costae acquisita"
    },
    {
        "icd": "M955",
        "rs": "Stečena deformacija karlice",
        "la": "Deformatio pelvis acquisita"
    },
    {
        "icd": "M958",
        "rs": "Ostali označeni stečeni deformiteti mišićno-koštanog sistema",
        "la": "Deformationes musculorum et ossium acquisitae aliae, specificatae"
    },
    {
        "icd": "M959",
        "rs": "Stečeni deformitet mišićno-koštanog sistema, neoznačeni",
        "la": "Deformatio musculorum et ossium acquisita, non specificata"
    },
    {
        "icd": "M96",
        "rs": "Poremećaji mišića i kostiju posle medicinskih intervencija, neklasifikovani na drugom mestu",
        "la": "Morbi musculorum et ossium postprocedurales, non alibi classificata"
    },
    {
        "icd": "M960",
        "rs": "Lažan zglob (pseudoartroza) nakon osteosinteze ili artrodeze",
        "la": "Pseudoarthrosis post fusionem sive arthrodesis"
    },
    {
        "icd": "M961",
        "rs": "Postlaminektomijski sindrom, neklasifikovan na drugom mestu",
        "la": "Syndroma post laminectomiam, non alibi classificata"
    },
    {
        "icd": "M962",
        "rs": "Grba (kifoza) kao posledica zračenja",
        "la": "Kyphosis postradiationalis"
    },
    {
        "icd": "M963",
        "rs": "Grba (kifoza) posle operacije pršljena",
        "la": "Kyphosis postlaminectomialis"
    },
    {
        "icd": "M964",
        "rs": "Kičma iskrivljena unapred posle operacije pršljena",
        "la": "Lordosis postoperativa"
    },
    {
        "icd": "M965",
        "rs": "Kičma iskrivljena u stranu kao posledica zračenja",
        "la": "Scoliosis postradiationalis"
    },
    {
        "icd": "M966",
        "rs": "Prelom kostiju posle ugradnje ortopedskih implantata, proteze zgloba ili koštane pločice",
        "la": "Fractura ossium post instalationem inplantatuum orthopaedicorum, prothesis articuli sive lamelae osseae"
    },
    {
        "icd": "M968",
        "rs": "Druge bolesti mišića i kostiju posle medicinskih intervencija",
        "la": "Morbi musculorum et ossium postprocedurales alii"
    },
    {
        "icd": "M969",
        "rs": "Poremećaj mišićno-koštanog sistema posle medicinskih intervencija, neoznačen",
        "la": "Morbus musculorum et ossium postprocedurales, non specificatus"
    },
    {
        "icd": "M99",
        "rs": "Biomehanička oštećenja koja nisu klasifikovana na drugom mestu",
        "la": "Leasiones biomechanicae, non alibi classificatae"
    },
    {
        "icd": "M990",
        "rs": "Poremećaj funkcije delova tela i celog tela [Segmentalna i somatska disfunkcija]",
        "la": "Dysfunctio segmentalis et somatica"
    },
    {
        "icd": "M991",
        "rs": "Složena subluksacija kičme [Nepotpuno iščašenje pršljenova kičme]",
        "la": "Subluxatio columnae vertebralis complex"
    },
    {
        "icd": "M992",
        "rs": "Suženje kanala živca kod iščašenja pršljena [Subluksacijska stenoza nervnog kanala]",
        "la": "Stenosis canalis neuralis subluxationalis"
    },
    {
        "icd": "M993",
        "rs": "Koštano suženje kanala živca",
        "la": "Stenosis canalis neuralis ossea"
    },
    {
        "icd": "M994",
        "rs": "Suženje kanala živca vezivnim tkivom",
        "la": "Stenosis canalis neuralis textu connexivo"
    },
    {
        "icd": "M995",
        "rs": "Suženje kanala živca međupršljenskim diskusom",
        "la": "Stenosis canalis neuralis discalis"
    },
    {
        "icd": "M996",
        "rs": "Koštana i subluksacijska stenoza intervertebralnih otvora",
        "la": "Stenosis foraminis intervertebralis ossea et subluxatio discalis"
    },
    {
        "icd": "M997",
        "rs": "Stenoza intervertebralnih otvora vezivnim tkivom i diskom",
        "la": "Stenosis foraminis intervertebralis textu connexivo et discalis"
    },
    {
        "icd": "M998",
        "rs": "Ostala biomehanička oštećenja",
        "la": "Laesiones biomechanicae aliae"
    },
    {
        "icd": "M999",
        "rs": "Biomehanička oštećenja, neoznačena",
        "la": "Laesio biomechanica, non specificata"
    },
    {
        "icd": "O00",
        "rs": "Vanmaterična trudnoća",
        "la": "Graviditas extrauterina"
    },
    {
        "icd": "O000",
        "rs": "Trbušna trudnoća",
        "la": "Graviditas abdominalis"
    },
    {
        "icd": "O001",
        "rs": "Jajovodna trudnoća",
        "la": "Graviditas tubaria"
    },
    {
        "icd": "O002",
        "rs": "Jajnička trudnoća",
        "la": "Graviditas ovarialis"
    },
    {
        "icd": "O008",
        "rs": "Druga vanmaterična trudnoća",
        "la": "Graviditas extrauterina alia"
    },
    {
        "icd": "O009",
        "rs": "Vanmaterična trudnoća, neoznačena",
        "la": "Graviditas extrauterina, non specificata"
    },
    {
        "icd": "O01",
        "rs": "Grozdasta mola",
        "la": "Mola hydatidosa"
    },
    {
        "icd": "O010",
        "rs": "Obična grozdasta mola",
        "la": "Mola hydatidosa simplex"
    },
    {
        "icd": "O011",
        "rs": "Nepotpuna i delimična grozdasta mola",
        "la": "Mola hydatidosa incompleta et partialis"
    },
    {
        "icd": "O019",
        "rs": "Grozdasta mola, neoznačena",
        "la": "Mola hydatidosa non specificata"
    },
    {
        "icd": "O02",
        "rs": "Drugi nenormalnan ishod začeća",
        "la": "Eventus conceptionis abnormalis alius"
    },
    {
        "icd": "O020",
        "rs": "Oštećeno jajašce i negrozdasta mola",
        "la": "Ovum laesum et mola non hydatidosa"
    },
    {
        "icd": "O021",
        "rs": "Nepotpun pobačaj",
        "la": "Abortus imperfectus"
    },
    {
        "icd": "O028",
        "rs": "Drugi neoznačen nenormalan ishod začeća",
        "la": "Eventus conceptionalis abnormalis alius non specificatus"
    },
    {
        "icd": "O029",
        "rs": "Nenormalan ishod trudnoće, neoznačen",
        "la": "Eventus conceptionalis abnormalis, non specificatus"
    },
    {
        "icd": "O03",
        "rs": "Spontani pobačaj",
        "la": "Abortus spontaneus"
    },
    {
        "icd": "O030",
        "rs": "Spontan pobačaj, nepotpun, komplikovan infekcijom polnih organa i karlice",
        "la": "#Spontan pobačaj, nepotpun, komplikovan infekcijom polnih organa i karlice"
    },
    {
        "icd": "O031",
        "rs": "Spontan pobačaj, nepotpun, komplikovan kasnim ili obilnim krvarenjem",
        "la": "#Spontan pobačaj, nepotpun, komplikovan kasnim ili obilnim krvarenjem"
    },
    {
        "icd": "O032",
        "rs": "Spontan pobačaj, nepotpun, komplikovan embolijom",
        "la": "#Spontan pobačaj, nepotpun, komplikovan embolijom"
    },
    {
        "icd": "O033",
        "rs": "Spontan pobačaj, nepotpun, sa drugim i neoznačenim komplikacijama",
        "la": "#Spontan pobačaj, nepotpun, sa drugim i neoznačenim komplikacijama"
    },
    {
        "icd": "O034",
        "rs": "Spontan pobačaj, nepotpun, bez komplikacija",
        "la": "#Spontan pobačaj, nepotpun, bez komplikacija"
    },
    {
        "icd": "O035",
        "rs": "Spontan pobačaj, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice ",
        "la": "#Spontan pobačaj, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice "
    },
    {
        "icd": "O036",
        "rs": "Spontan pobačaj, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem ",
        "la": "#Spontan pobačaj, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem "
    },
    {
        "icd": "O037",
        "rs": "Spontan pobačaj, potpun ili neoznačen, komplikovan embolijom",
        "la": "#Spontan pobačaj, potpun ili neoznačen, komplikovan embolijom"
    },
    {
        "icd": "O038",
        "rs": "Spontan pobačaj, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama ",
        "la": "#Spontan pobačaj, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama "
    },
    {
        "icd": "O039",
        "rs": "Spontan pobačaj, potpun ili neoznačen, bez komplikacija ",
        "la": "#Spontan pobačaj, potpun ili neoznačen, bez komplikacija "
    },
    {
        "icd": "O04",
        "rs": "Medicinski abortus",
        "la": "Abortus medicalis"
    },
    {
        "icd": "O040",
        "rs": "Medicinski abortus, nepotpun, komplikovan infekcijom polnih organa i karlice",
        "la": "#Medicinski abortus, nepotpun, komplikovan infekcijom polnih organa i karlice"
    },
    {
        "icd": "O041",
        "rs": "Medicinski abortus, nepotpun, komplikovan kasnim ili obilnim krvarenjem",
        "la": "#Medicinski abortus, nepotpun, komplikovan kasnim ili obilnim krvarenjem"
    },
    {
        "icd": "O042",
        "rs": "Medicinski abortus, nepotpun, komplikovan embolijom",
        "la": "#Medicinski abortus, nepotpun, komplikovan embolijom"
    },
    {
        "icd": "O043",
        "rs": "Medicinski abortus, nepotpun, sa drugim i neoznačenim komplikacijama",
        "la": "#Medicinski abortus, nepotpun, sa drugim i neoznačenim komplikacijama"
    },
    {
        "icd": "O044",
        "rs": "Medicinski abortus, nepotpun, bez komplikacija",
        "la": "#Medicinski abortus, nepotpun, bez komplikacija"
    },
    {
        "icd": "O045",
        "rs": "Medicinski abortus, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice ",
        "la": "#Medicinski abortus, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice "
    },
    {
        "icd": "O046",
        "rs": "Medicinski abortus, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem ",
        "la": "#Medicinski abortus, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem "
    },
    {
        "icd": "O047",
        "rs": "Medicinski abortus, potpun ili neoznačen, komplikovan embolijom",
        "la": "#Medicinski abortus, potpun ili neoznačen, komplikovan embolijom"
    },
    {
        "icd": "O048",
        "rs": "Medicinski abortus, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama ",
        "la": "#Medicinski abortus, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama "
    },
    {
        "icd": "O049",
        "rs": "Medicinski abortus, potpun ili neoznačen, bez komplikacija ",
        "la": "#Medicinski abortus, potpun ili neoznačen, bez komplikacija "
    },
    {
        "icd": "O05",
        "rs": "Drugi pobačaj",
        "la": "Abortus alius"
    },
    {
        "icd": "O050",
        "rs": "Drugi pobačaj, nepotpun, komplikovan infekcijom polnih organa i karlice",
        "la": "#Drugi pobačaj, nepotpun, komplikovan infekcijom polnih organa i karlice"
    },
    {
        "icd": "O051",
        "rs": "Drugi pobačaj, nepotpun, komplikovan kasnim ili obilnim krvarenjem",
        "la": "#Drugi pobačaj, nepotpun, komplikovan kasnim ili obilnim krvarenjem"
    },
    {
        "icd": "O052",
        "rs": "Drugi pobačaj, nepotpun, komplikovan embolijom",
        "la": "#Drugi pobačaj, nepotpun, komplikovan embolijom"
    },
    {
        "icd": "O053",
        "rs": "Drugi pobačaj, nepotpun, sa drugim i neoznačenim komplikacijama",
        "la": "#Drugi pobačaj, nepotpun, sa drugim i neoznačenim komplikacijama"
    },
    {
        "icd": "O054",
        "rs": "Drugi pobačaj, nepotpun, bez komplikacija",
        "la": "#Drugi pobačaj, nepotpun, bez komplikacija"
    },
    {
        "icd": "O055",
        "rs": "Drugi pobačaj, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice ",
        "la": "#Drugi pobačaj, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice "
    },
    {
        "icd": "O056",
        "rs": "Drugi pobačaj, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem ",
        "la": "#Drugi pobačaj, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem "
    },
    {
        "icd": "O057",
        "rs": "Drugi pobačaj, potpun ili neoznačen, komplikovan embolijom",
        "la": "#Drugi pobačaj, potpun ili neoznačen, komplikovan embolijom"
    },
    {
        "icd": "O058",
        "rs": "Drugi pobačaj, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama ",
        "la": "#Drugi pobačaj, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama "
    },
    {
        "icd": "O059",
        "rs": "Drugi pobačaj, potpun ili neoznačen, bez komplikacija ",
        "la": "#Drugi pobačaj, potpun ili neoznačen, bez komplikacija "
    },
    {
        "icd": "O06",
        "rs": "Neoznačen pobačaj",
        "la": "Abortus non specificatus"
    },
    {
        "icd": "O060",
        "rs": "Neoznačen pobačaj, nepotpun, komplikovan infekcijom polnih organa i karlice",
        "la": "#Neoznačen pobačaj, nepotpun, komplikovan infekcijom polnih organa i karlice"
    },
    {
        "icd": "O061",
        "rs": "Neoznačen pobačaj, nepotpun, komplikovan kasnim ili obilnim krvarenjem",
        "la": "#Neoznačen pobačaj, nepotpun, komplikovan kasnim ili obilnim krvarenjem"
    },
    {
        "icd": "O062",
        "rs": "Neoznačen pobačaj, nepotpun, komplikovan embolijom",
        "la": "#Neoznačen pobačaj, nepotpun, komplikovan embolijom"
    },
    {
        "icd": "O063",
        "rs": "Neoznačen pobačaj, nepotpun, sa drugim i neoznačenim komplikacijama",
        "la": "#Neoznačen pobačaj, nepotpun, sa drugim i neoznačenim komplikacijama"
    },
    {
        "icd": "O064",
        "rs": "Neoznačen pobačaj, nepotpun, bez komplikacija",
        "la": "#Neoznačen pobačaj, nepotpun, bez komplikacija"
    },
    {
        "icd": "O065",
        "rs": "Neoznačen pobačaj, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice ",
        "la": "#Neoznačen pobačaj, potpun ili neoznačen, komplikovan infekcijom polnih organa i karlice "
    },
    {
        "icd": "O066",
        "rs": "Neoznačen pobačaj, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem ",
        "la": "#Neoznačen pobačaj, potpun ili neoznačen, komplikovan kasnim ili obilnim krvarenjem "
    },
    {
        "icd": "O067",
        "rs": "Neoznačen pobačaj, potpun ili neoznačen, komplikovan embolijom",
        "la": "#Neoznačen pobačaj, potpun ili neoznačen, komplikovan embolijom"
    },
    {
        "icd": "O068",
        "rs": "Neoznačen pobačaj, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama ",
        "la": "#Neoznačen pobačaj, potpun ili neoznačen, sa drugim i neoznačenim komplikacijama "
    },
    {
        "icd": "O069",
        "rs": "Neoznačen pobačaj, potpun ili neoznačen, bez komplikacija ",
        "la": "#Neoznačen pobačaj, potpun ili neoznačen, bez komplikacija "
    },
    {
        "icd": "O07",
        "rs": "Neuspeo pokušaj pobačaja",
        "la": "Abortus temptatus inefficax"
    },
    {
        "icd": "O070",
        "rs": "Neuspeo medicinski pobačaj, komlikovan zbog infekcije polnih organa i karlice",
        "la": "Abortus medicalis inefficax propter infectionem tractus genitalis et pelvis complicatus"
    },
    {
        "icd": "O071",
        "rs": "Neuspeo medicinski pobačaj, komplikovan zbog kasnog ili obilnog krvarenja",
        "la": "Abortus medicalis inefficax propter hemorrhagiam retardatam sine gravem complicatus"
    },
    {
        "icd": "O072",
        "rs": "Neuspeo medicinski pobačaj, komplikovan embolijom",
        "la": "Abortus medicalis inefficax propter emboliam complicatus"
    },
    {
        "icd": "O073",
        "rs": "Neuspeo medicinski pobačaj, sa drugim i neoznačenim komplikacijama",
        "la": "Abortus medicalis inefficax, cum complicationibus aliis et non specificatis"
    },
    {
        "icd": "O074",
        "rs": "Neuspeo medicinski pobačaj, bez komplikacija",
        "la": "Abortus medicalis inefficax, sine complicationibus"
    },
    {
        "icd": "O075",
        "rs": "Drugi (neoznačen) neuspeo pobačaj komlikovan zbog infekcije polnih organa i karlice",
        "la": "Abortus inefficax propter infectionem tractus genitalis et pelvis complicatus alius, non specificatus"
    },
    {
        "icd": "O076",
        "rs": "Drugi neuspeo pokušaj pobačaja komplikovam zbog kasnog ili obilnog krvarenja",
        "la": "Abortus temptatus inefficax propter haemorrhagiam retardatam gravem complicatus alius, non specificatus"
    },
    {
        "icd": "O077",
        "rs": "Drugi i neoznačen neuspeo pokušaj pobačaja komplikovan embolijom",
        "la": "Abortus temptatus inefficax propter emboliam comlicatus alius et non specificatus"
    },
    {
        "icd": "O078",
        "rs": "Drugi neoznačen neuspeo pokušaj pobačaja sa komplikacijama",
        "la": "Abortus temptatus inefficax cum complicatus alius, non specificatus"
    },
    {
        "icd": "O079",
        "rs": "Drugi neuspeo pokušaj pobačaja bez komlikacija, neoznačen",
        "la": "Abortus temptatus inefficax sine complicationibus alius, non specificatus"
    },
    {
        "icd": "O08",
        "rs": "Komplikacije posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Complicationes post abortum, graviditatem ectopicam et molarem"
    },
    {
        "icd": "O080",
        "rs": "Infekcija polnih organa i karlice posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Infectio tractus genitalis et pelvis post abortum, graviditatem ectopicam et molarem"
    },
    {
        "icd": "O081",
        "rs": "Kasno ili obilno krvarenje posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Haemorrhagia retardata sive gravis post abortum, graviditatem ectopicam et molarem"
    },
    {
        "icd": "O082",
        "rs": "Embolija posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Embolia post abortum, graviditatem ectopicam et molarem"
    },
    {
        "icd": "O083",
        "rs": "Šok posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Afflictus (shock) post abortum, graviditatem ectopiam et molarem"
    },
    {
        "icd": "O084",
        "rs": "Bubrežna insuficijencija posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Insufficientia renalis post abortum, graviditatem ectopicam et molarem"
    },
    {
        "icd": "O085",
        "rs": "Poremećaji metabolizma posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Disordines metabolismi post abortum, graviditatem ectopicam et molarem"
    },
    {
        "icd": "O086",
        "rs": "Oštećenje organa i tkiva karlice posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Laesio organorum et textus pelvis post abortum, graviditatem ectopicam et molarem"
    },
    {
        "icd": "O087",
        "rs": "Druge komplikacije vena posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Complicationes venarum post abortum, graviditatem ectopicam et molarem, aliae"
    },
    {
        "icd": "O088",
        "rs": "Druge komlikacije posle pobačaja, vanmaterične i molarne trudnoće",
        "la": "Complicationes post abortum, graviditatem ectopicam et molarem aliae"
    },
    {
        "icd": "O089",
        "rs": "Komplikacija posle pobačaja, vanmaterične i molarne trudnoće, neoznačena",
        "la": "Complicatio post abortum, graviditatem ectopicam et molarem, non specificata"
    },
    {
        "icd": "O10",
        "rs": "Ranije postojeći povišen krvni pritisak komplikovan u toku trudnoće, porođaja i babinja",
        "la": "Hypertensio arterialis praeexistens per graviditatem, partum et puerperium complicata"
    },
    {
        "icd": "O100",
        "rs": "Ranije postojeći primarni povišen krvni pritisak komplikovan u toku trudnoće, porođaja i babinja",
        "la": "Hypertensio arterialis essentialis praeexistens, per graviditatem, partum et puerperium complicata"
    },
    {
        "icd": "O101",
        "rs": "Ranije postojeća bolest srca komplikovana u toku trudnoće, porođaja i babinja",
        "la": "Morbus cardiacus hypertensivus praeexistens, per graviditatem, partum et puerperium complicatus"
    },
    {
        "icd": "O102",
        "rs": "Ranije postojeća bolest bubrega komplikovana u toku trudnoće, porođaja i babinja",
        "la": "Morbus renalis hypertensivus praeexistens, per graviditatem, partum et puerperium complicatus"
    },
    {
        "icd": "O103",
        "rs": "Ranije postojeća bolest srca i bubrega komplikovana u toku trudnoće, porođaja i babinja",
        "la": "Morbus cardiacus et renalis hypertensivus praeexistens, per graviditatem, partum et puerperium complicatus"
    },
    {
        "icd": "O104",
        "rs": "Ranije postojeći sekundarni povišeni krvni pritisak, komplikovan u toku trudnoće, porođaja i babinja",
        "la": "Hypertensio arterialis secundaria praeexistens, per graviditatem, partum et puerperium complicatus"
    },
    {
        "icd": "O109",
        "rs": "Neoznačen ranije postojeći povišen krvni pritisak komplikovan u toku trudnoće, porođaja i babinja",
        "la": "Hypertensio arterialis praeexistens, per graviditatem, partum et puerperium complicata, non specificata"
    },
    {
        "icd": "O11",
        "rs": "Povišen krvni pritisak pre trudnoće sa pojavom belančevina u mokraći",
        "la": "Hypertensio arterialis ante graviditatem cum proteinuria"
    },
    {
        "icd": "O12",
        "rs": "Edem i belančevine u mokraći u toku trudnoće bez povišenog krvnog pritiska",
        "la": "Oedema et proteinuria in graviditate sine hypertensione arteriali"
    },
    {
        "icd": "O120",
        "rs": "Edem u trudnoći",
        "la": "Oedema in graviditate"
    },
    {
        "icd": "O121",
        "rs": "Belančevine u mokraći tokom trudnoće",
        "la": "Proteinuria per graviditatem"
    },
    {
        "icd": "O122",
        "rs": "Edem i belančevine u mokraći tokom trudnoće",
        "la": "Oedema et proteinuria per graviditatem"
    },
    {
        "icd": "O13",
        "rs": "Povišen krvni pritisak u toku trudnoće bez belančevina u mokraći",
        "la": "Hypertensio arterialis in graviditate sine proteinuria"
    },
    {
        "icd": "O14",
        "rs": "Preeklampsija – Povišen krvni pritisak u toku trudnoće sa belančevinama u mokraći",
        "la": "Praeeclampsia – Hypertensio arterialis in graviditate cum proteinuria"
    },
    {
        "icd": "O140",
        "rs": "Umerena preeklampsija",
        "la": "Praeeclampsia moderata"
    },
    {
        "icd": "O141",
        "rs": "Teška preeklampsija",
        "la": "Praeeclampsia severa"
    },
    {
        "icd": "O142",
        "rs": "„HELLP“ sindrom",
        "la": "Syndroma „HELLP“"
    },
    {
        "icd": "O149",
        "rs": "Preeklampsija, neoznačena",
        "la": "Praeeclampsia non specificata"
    },
    {
        "icd": "O15",
        "rs": "Eklampsija – povišen krvni pritisak, grčevi i belančevine u mokraći u trudnoći",
        "la": "Eclampsia"
    },
    {
        "icd": "O150",
        "rs": "Eklampsija u trudnoći",
        "la": "Eclampsia in graviditate"
    },
    {
        "icd": "O151",
        "rs": "Eklampsija u toku porođaja",
        "la": "Eclampsia partualis"
    },
    {
        "icd": "O152",
        "rs": "Eklampsija u babinjama",
        "la": "Eclampsia in puerperio"
    },
    {
        "icd": "O159",
        "rs": "Eklampsija, neoznačena",
        "la": "Eclampsia, non specificata"
    },
    {
        "icd": "O16",
        "rs": "Povišen krvni pritisak majke, neoznačen",
        "la": "Hypertensio arterialis matris, non specificata"
    },
    {
        "icd": "O20",
        "rs": "Krvarenje u početnoj trudnoći",
        "la": "Haemorrhagia in graviditate initiali"
    },
    {
        "icd": "O200",
        "rs": "Preteći pobačaj",
        "la": "Abortus imminens"
    },
    {
        "icd": "O208",
        "rs": "Drugo krvarenje u početnoj trudnoći",
        "la": "Haemorrhagia in graviditate initiali alia"
    },
    {
        "icd": "O209",
        "rs": "Krvarenje u početnoj trudnoći, neozančeno",
        "la": "Haemorrhagia in graviditate initiali non specificata"
    },
    {
        "icd": "O21",
        "rs": "Prekomerno povraćanje u trudnoći",
        "la": "Hyperemesis in graviditate"
    },
    {
        "icd": "O210",
        "rs": "Umereno povraćanje trudnice",
        "la": "Hyperemesis gravidae mitis"
    },
    {
        "icd": "O211",
        "rs": "Prekomerno povraćanje trudnice sa poremećajem metabolizma",
        "la": "Hyperemesis gravidae cum disordine metabolico"
    },
    {
        "icd": "O212",
        "rs": "Kasno povraćanje trudnice",
        "la": "Vomitus gravidae tardus"
    },
    {
        "icd": "O218",
        "rs": "Drugo prekomerno povraćanje trudnice, sa komplikacijama",
        "la": "Hyperemesis gravidae complicata alia"
    },
    {
        "icd": "O219",
        "rs": "Prekomerno povraćanje trudnice, neoznačeno",
        "la": "Hyperemesis gravidae, non specificata"
    },
    {
        "icd": "O22",
        "rs": "Komplikacije vena u trudnoći",
        "la": "Complicationes venarum gravidae"
    },
    {
        "icd": "O220",
        "rs": "Proširene vene nogu trudnice",
        "la": "Varicaes extremitatis inferioris gravidae"
    },
    {
        "icd": "O221",
        "rs": "Proširene vene polnih organa trudnice",
        "la": "Varices genitaliorum gravidae"
    },
    {
        "icd": "O222",
        "rs": "Površinski tromboflebitis u trudnoći",
        "la": "Trombophlebitis gravidae superficialis"
    },
    {
        "icd": "O223",
        "rs": "Duboka flebotromboza u trudnoći",
        "la": "Thrombophlebitis gravidae profunda"
    },
    {
        "icd": "O224",
        "rs": "Hemoroidi u trudnoći",
        "la": "Haemorrhoides gravidae"
    },
    {
        "icd": "O225",
        "rs": "Zgrušavanje krvi vena mozga trudnice",
        "la": "Thrombosis venarum cerebri gravidae"
    },
    {
        "icd": "O228",
        "rs": "Druge komplikacije vena kod trudnice",
        "la": "Complicationes venarum gravidae aliae"
    },
    {
        "icd": "O229",
        "rs": "Komplikacija vena u kod trudnice, neoznačena",
        "la": "Complicatio venarum gravidae, non specificata"
    },
    {
        "icd": "O23",
        "rs": "Infekcije mokraćno–polnih puteva u trudnoći",
        "la": "Infectiones tractus urogenitalis in graviditate"
    },
    {
        "icd": "O230",
        "rs": "Zapaljenje bubrega u trudnoći",
        "la": "Pyelonephritis in graviditate"
    },
    {
        "icd": "O231",
        "rs": "Zapaljenje mokraćne bešike u trudnoći",
        "la": "Cystitis in graviditate"
    },
    {
        "icd": "O232",
        "rs": "Zapaljenje mokraćovoda bubrega u trudnoći",
        "la": "Urethritis in graviditate"
    },
    {
        "icd": "O233",
        "rs": "Infekcije drugih delova mokraćnih puteva u trudnoći",
        "la": "Infectiones partium tractus urinarii aliarum in graviditate"
    },
    {
        "icd": "O234",
        "rs": "Neoznačene infekcije mikraćnih puteva u trudnoći",
        "la": "Infectiones tractus urinarii in graviditate, non specificatae"
    },
    {
        "icd": "O235",
        "rs": "Infekcije polnih organa u trudnoći",
        "la": "Infectiones tractus genitalis in graviditate"
    },
    {
        "icd": "O239",
        "rs": "Druga i neoznačena infekcija mokraćno polnih puteva u trudnoći",
        "la": "Infectio tractus urogenitalis in graviditate alia et non specificata"
    },
    {
        "icd": "O24",
        "rs": "Šećerna bolest u trudnoći",
        "la": "Diabetes mellitus in graviditate"
    },
    {
        "icd": "O240",
        "rs": "Ranije postojeća šećerna bolest, insulin zavisna",
        "la": "Diabetes mellitus praeexistens, insulinum-dependens"
    },
    {
        "icd": "O241",
        "rs": "Ranije postojeća šećerna bolest, insulin-nezavisna",
        "la": "Diabetes mellitus praeexistens, non-insulinum-dependens"
    },
    {
        "icd": "O242",
        "rs": "Ranije postojeća šećerna bolest uzrokovana nedovoljnom ishranom",
        "la": "Diabetes mellitus praeexistens malnutritionalis"
    },
    {
        "icd": "O243",
        "rs": "Ranije postojeća šećerna bolest, neoznačena",
        "la": "Diabetes mellitus praeexistens, non specificatus"
    },
    {
        "icd": "O244",
        "rs": "Šećerna bolest nastala u trudnoći",
        "la": "Diabetes mellitus in graviditate ortus"
    },
    {
        "icd": "O249",
        "rs": "Šećerna bolest u trudnoći, neoznačena",
        "la": "Diabetes mellitus in graviditate, non specificatus"
    },
    {
        "icd": "O25",
        "rs": "Nedovoljna ishrana trudnice",
        "la": "Malnutritio gravidae"
    },
    {
        "icd": "O26",
        "rs": "Zbrinjavanje majke zbog drugih stanja pretežno vezanih za trudnoću",
        "la": "Curatio matris propter alias conditiones praedominantes coniunctas cum graviditate"
    },
    {
        "icd": "O260",
        "rs": "Prevelik porast telesne težine u trudnoći",
        "la": "Auctus ponderis corporalis in graviditate enormis"
    },
    {
        "icd": "O261",
        "rs": "Mali porast telesne težine u trudnoći",
        "la": "Auctus ponderis corporalis in graviditate parvus"
    },
    {
        "icd": "O262",
        "rs": "Zbrinjavanje žene koja je imala spontani pobačaj",
        "la": "Curatio feminae propter abortum spontaneum antecedentem"
    },
    {
        "icd": "O263",
        "rs": "Zadržana kontracepcijska spirala u trudnoći",
        "la": "Spira contraceptiva intrauterina in graviditate retenta"
    },
    {
        "icd": "O264",
        "rs": "Herpes u trudnoći",
        "la": "Herpes gestationalis"
    },
    {
        "icd": "O265",
        "rs": "Snižen krvni pritisak trudnice",
        "la": "Hypotensio arterialis gravidae"
    },
    {
        "icd": "O266",
        "rs": "Poremećaji jetre u trudnoći, porođaju i babinjama",
        "la": "Disordines hepatis in graviditate, partu et puerperio"
    },
    {
        "icd": "O267",
        "rs": "Nepotpuno iščašenje stidne kosti u trudnoći, porođaju i babinjama",
        "la": "Subluxatio pubis in graviditate, partu et puerperio"
    },
    {
        "icd": "O268",
        "rs": "Druga označena stanja vezana za trudnoću",
        "la": "Status cum graviditate coniuncti alii, specificati"
    },
    {
        "icd": "O269",
        "rs": "Stanje vezano za trudnoću, neoznačeno",
        "la": "Status cum graviditate coniunctus, non specificatus"
    },
    {
        "icd": "O28",
        "rs": "Nenormalan nalaz otkriven kod majke pretporođajnom kontrolom",
        "la": "Status matris abnormalis, proba antenatali detectus"
    },
    {
        "icd": "O280",
        "rs": "Nenormalan hematološki nalaz kod majke otkriven pretporođajnom kontrolom",
        "la": "Status haematologicus matris abnormalis, proba antenatali detectus"
    },
    {
        "icd": "O281",
        "rs": "Nenormalan biohemijski nalaz kod majke otkriven pretporođajnom kontrolom",
        "la": "Status biochemicus matris abnormalis, proba antenatali detectus"
    },
    {
        "icd": "O282",
        "rs": "Nenormalan citološki nalaz u majke otkriven pretporođajnom kontrolom",
        "la": "Status cytologicus matris abnormalis, proba antenatali detectus"
    },
    {
        "icd": "O283",
        "rs": "Nenormalan ultrazvučni nalaz kod majke otkriven pretporođajnom kontrolom",
        "la": "Status ultrasonorus matris abnormalis, proba antenatali detectus"
    },
    {
        "icd": "O284",
        "rs": "Nenormalan radiološki nalaz kod majke otkriven pretporođajnom kontrolom",
        "la": "Status radiologicus matris abnormalis, proba antenatali detectus"
    },
    {
        "icd": "O285",
        "rs": "Nenormalan hromozomski i genetski nalaz otkriven pretporođajnom kontrolom",
        "la": "Status chromosomaticus et geneticus abnormalis, proba antenatali detectus"
    },
    {
        "icd": "O288",
        "rs": "Drugi nenormalan nalaz kod majke otkriven pretporođajnom kontrolom",
        "la": "Status matris abnormalis, proba antenatali detectus, alius"
    },
    {
        "icd": "O289",
        "rs": "Nenormalan nalaz kod majke otkriven pretporođajnom kontrolom, neoznačen",
        "la": "Status matris abnormalis, proba antenatali detectus, non specificatus"
    },
    {
        "icd": "O29",
        "rs": "Komplikacije uzrokovane davanjem anestezije tokom trudnoće",
        "la": "Complicationes anaesthesionales, per graviditatem"
    },
    {
        "icd": "O290",
        "rs": "Komplikacije pluća uzrokovane davanjem anestezije tokom trudnoće",
        "la": "Complicationes pulmonales anaesthesionales, per graviditatem"
    },
    {
        "icd": "O291",
        "rs": "Komplikacije srca uzrokovane davanjem anestezije tokom trudnoće",
        "la": "Complicationes cardiacae anaesthesionalis, per graviditatem"
    },
    {
        "icd": "O292",
        "rs": "Komplikacije centralnog nervnog sistema uzrokovane davanjem anestezije tokom trudnoće",
        "la": "Complicationes systematis nervosi centralis anaestesionales, per graviditatem"
    },
    {
        "icd": "O293",
        "rs": "Toksična reakcija na lokalnu anesteziju tokom trudnoće",
        "la": "Reactio toxica ad anaestesiam localem, per graviditatem"
    },
    {
        "icd": "O294",
        "rs": "Glavobolja zbog lumbalne i epiduralne anestezije tokom trudnoće",
        "la": "Cephalea propter anesthesiam spinalem et epiduralem, per graviditatem"
    },
    {
        "icd": "O295",
        "rs": "Druge komplikacije uzrokovane davanjem lumbalne i epiduralne anestezije tokom trudnoće",
        "la": "Complicationes propter anaesthesiam spinalem et epiduralem, per graviditatem, aliae"
    },
    {
        "icd": "O296",
        "rs": "Neuspela ili sa teškoćom urađena intubacija tokom trudnoće",
        "la": "Intubatio inefficax sive difficile facta, per graviditatem"
    },
    {
        "icd": "O298",
        "rs": "Druge komplikacije uzrokovane davanjem anestezije tokom trudnoće",
        "la": "Complicationes anaestesionales, per graviditatem, aliae"
    },
    {
        "icd": "O299",
        "rs": "Komplikacija uzrokovana anestezijom tokom trudnoće, neoznačena",
        "la": "Complicatio anaesthesionalis, per graviditatem, non specificata"
    },
    {
        "icd": "O30",
        "rs": "Višeplodna trudnoća",
        "la": "Graviditas plurifetalis"
    },
    {
        "icd": "O300",
        "rs": "Blizanačka trudnoća",
        "la": "Graviditas duplex"
    },
    {
        "icd": "O301",
        "rs": "Troplodna trudnoća",
        "la": "Graviditas triplex"
    },
    {
        "icd": "O302",
        "rs": "Četvoroplodna trudnoća",
        "la": "Graviditas quadriplex"
    },
    {
        "icd": "O308",
        "rs": "Druga višeplodna trudnoća",
        "la": "Graviditas plurifetalis alia"
    },
    {
        "icd": "O309",
        "rs": "Višeplodna trudnoća, neoznačena",
        "la": "Graviditas plurifetalis, non specificata"
    },
    {
        "icd": "O31",
        "rs": "Komplikacije višeplodne trudnoće",
        "la": "Complicationes graviditatis plurifetalis"
    },
    {
        "icd": "O310",
        "rs": "Papirasti plod",
        "la": "Fetus papyraceus"
    },
    {
        "icd": "O311",
        "rs": "Nastavak trudnoće posle pobačaja jednog ili više plodova",
        "la": "Graviditas post abortum unius sive fetuum plurium continuata"
    },
    {
        "icd": "O312",
        "rs": "Nastavak trudnoće posle smrti jednog ili više plodova u materici",
        "la": "Graviditas post mortem intrauterinam unius sive fetuum plurium continuata"
    },
    {
        "icd": "O318",
        "rs": "Druge označene komplikacije višeplodne trudnoće",
        "la": "Complicationes graviditatis plurifetalis aliae, specificatae"
    },
    {
        "icd": "O32",
        "rs": "Zbrinjavanje majke kod poznatog ili sumnjivog nepravilnog stava ploda",
        "la": "Curatio matris ad malpraesentationem fetus notam sive suspectam"
    },
    {
        "icd": "O320",
        "rs": "Zbrinjavanje majke kod nestabilnog položaja ploda",
        "la": "Curatio matris ad positionem fetus instabilem"
    },
    {
        "icd": "O321",
        "rs": "Zbrinjavanje majke kod prednjačenja ploda karlicom",
        "la": "Curatio matris ad positionem fetus pelvicam"
    },
    {
        "icd": "O322",
        "rs": "Zbrinjavanje majke kod poprečnog i kosog položaja ploda",
        "la": "Curatio matris ad positionem fetus transversam et obliquam"
    },
    {
        "icd": "O323",
        "rs": "Zbrinjavanje majke kod prednjačenja ploda licem, čelom i bradom",
        "la": "Curatio matris ad praesentationem fetus facie, fronte et mento"
    },
    {
        "icd": "O324",
        "rs": "Zbrinjavanje majke kod položaja glave ploda visokog za termin",
        "la": "Curatio matris ad positionem capitis fetus altam in relatione ad terminum"
    },
    {
        "icd": "O325",
        "rs": "Zbrinjavanje majke kod višeplodne trudnoće sa nepravilnim stavom jednog ili više plodova",
        "la": "Curatio matris ad graviditatem plurifetalem cum malpraesentatione unius sive fetuum plurium"
    },
    {
        "icd": "O326",
        "rs": "Zbrinjavanje majke kod složenog nepravilnog stava ploda",
        "la": "Curatio matris ad malpresentationem fetus compositam"
    },
    {
        "icd": "O328",
        "rs": "Zbrinjavanje majke kod drugog nepravilnog stava ploda",
        "la": "Curatio matris ad malpraesentationem fetus aliam"
    },
    {
        "icd": "O329",
        "rs": "Zbrinjavanje majke kod nepravilnog stava ploda, neoznačen",
        "la": "Curatio matris ad malpraesentationem fetus, non specificata"
    },
    {
        "icd": "O33",
        "rs": "Zbrinjavanje majke kod poznate ili sumnjive disproporcije",
        "la": "Curatio matris ad disproportionem notam sive suspectam"
    },
    {
        "icd": "O330",
        "rs": "Zbrinjavanje majke kod disproporcije usled deformacije kostiju karlice",
        "la": "Curatio matris ad disproportionem propter deformationem ossium pelvicorum"
    },
    {
        "icd": "O331",
        "rs": "Zbrinjavanje majke kod disproporcije usled opšteg suženja karlice",
        "la": "Curatio matris ad disproportionem propter contractionem pelvis totalem"
    },
    {
        "icd": "O332",
        "rs": "Zbrinjavanje majke kod disproporcije usled suženja ulaza u karlicu",
        "la": "Curatio matris ad disproportionem propter contractionem aditus pelvis"
    },
    {
        "icd": "O333",
        "rs": "Zbrinjavanje majke kod disproporcije usled suženja izlaza karlice",
        "la": "Curatio matris ad disproportionem propter contractionem exitus pelvis"
    },
    {
        "icd": "O334",
        "rs": "Zbrinjavanje majke kod disproporcije porekla od majke i ploda",
        "la": "Curatio matris ad disproportionem matris et fetus"
    },
    {
        "icd": "O335",
        "rs": "Zbrinjavanje majke kod disproporcije usled neuobičajeno velikog ploda",
        "la": "Curatio matris ad disproportionem propter fetum inusitate magnum"
    },
    {
        "icd": "O336",
        "rs": "Zbrinjavanje majke kod disproporcije usled vodene glave ploda",
        "la": "Curatio matris ad disproportionem propter fetum hydrocephalicum"
    },
    {
        "icd": "O337",
        "rs": "Zbrinjavanje majke kod disproporcije usled druge deformacije ploda",
        "la": "Curatio matris ad disproportionem propter deformationem fetalem aliam"
    },
    {
        "icd": "O338",
        "rs": "Zbrinjavanje majke kod disproporcije usled drugih uzroka",
        "la": "Curatio matris ad disproportionem propter causas alias"
    },
    {
        "icd": "O339",
        "rs": "Zbrinjavanje majke kod disproporcije, neoznačena",
        "la": "Curatio matris ad disproportionem, non specificata"
    },
    {
        "icd": "O34",
        "rs": "Zbrinjavanje majke kod poznatih ili sumnjivih nenormalnosti organa karlice",
        "la": "Curatio matris ad abnormitates organorum pelvicorum notas sive suspectas"
    },
    {
        "icd": "O340",
        "rs": "Zbrinjavanje majke kod urođene anomalije materice",
        "la": "Curatio matris ad malformationem uteri congenitam"
    },
    {
        "icd": "O341",
        "rs": "Zbrinjavanje majke kod tumora tela materice",
        "la": "Curatio matris ad tumorem corporis uteri"
    },
    {
        "icd": "O342",
        "rs": "Zbrinjavanje majke kod ožiljaka materice od ranije operacije",
        "la": "Curatio matris ad cicatricem uteri post operationem praecedentem factam"
    },
    {
        "icd": "O343",
        "rs": "Zbrinjavanje majke kod nedovoljne funkcije grlića materice",
        "la": "Curatio matris ad insufficientiam cervicis"
    },
    {
        "icd": "O344",
        "rs": "Zbrinjavanje majke kod drugih nenormalnosti grlića materice",
        "la": "Curatio matris ad abnormitates cervicis alias"
    },
    {
        "icd": "O345",
        "rs": "Zbrinjavanje majke kod drugih nenormalnosti materice trudnice",
        "la": "Curatio matris ad abnormitates uteri gravidae alias"
    },
    {
        "icd": "O346",
        "rs": "Zbrinjavanje majke kod nenormalnosti vagine",
        "la": "Curatio matris ad abnormalitatem vaginae"
    },
    {
        "icd": "O347",
        "rs": "Zbrinjavanje majke kod nenormalnosti stidnice i međice",
        "la": "Curatio matris ad abnormitatem vulvae et perinei"
    },
    {
        "icd": "O348",
        "rs": "Zbrinjavanje majke kod drugih nenormalnosti organa karlice",
        "la": "Curatio matris ad abnormalitates organorum pelvicorum alias"
    },
    {
        "icd": "O349",
        "rs": "Zbrinjavanje majke kod nenormalnosti organa karlice, neoznačen",
        "la": "Curatio matris ad abnormalitates organorum pelvicorum, non specificata"
    },
    {
        "icd": "O35",
        "rs": "Zbrinjavanje majke kod poznatih ili sumnjivih nenormalnosti i oštećenja ploda",
        "la": "Curatio matris ad abnormitates et laesiones fetus notas sive suspectas"
    },
    {
        "icd": "O350",
        "rs": "Zbrinjavanje majke sa sumnjivim malformacijama centralnog nervnog sistema ploda",
        "la": "Curatio matris ad malformationes systematis nervosi centralis fetus suspectas"
    },
    {
        "icd": "O351",
        "rs": "Zbrinjavanje majke kod sumnje na urođeni hromozomski poremećaj ploda",
        "la": "Curatio matris ad disordinem chromosomatosum (suspectum)"
    },
    {
        "icd": "O352",
        "rs": "Zbrinjavanje majke kod sumnje na urođenu bolest ploda",
        "la": "Curatio matris ad morbum fetus hereditarium suspectum"
    },
    {
        "icd": "O353",
        "rs": "Zbrinjavanje majke kod sumnje na oštećenja ploda infekcijom majke uzrokovanom virusima",
        "la": "Curatio matris ad laesionem fetus infectione matris virali (suspectam)"
    },
    {
        "icd": "O354",
        "rs": "Zbrinjavanje majke kod sumnje na oštećenje ploda alkoholom",
        "la": "Curatio matris ad laesionem fetus alcoholicam (suspectam)"
    },
    {
        "icd": "O355",
        "rs": "Zbrinjavanje majke kod sumnje na oštećenje ploda lekovima",
        "la": "Curatio matris ad laesionem fetus medicamentosam (suspectam)"
    },
    {
        "icd": "O356",
        "rs": "Zbrinjavanje majke kod sumnje na oštećenje ploda zračenjem",
        "la": "Curatio matris ad laesionem fetus radiationalem (suspectam)"
    },
    {
        "icd": "O357",
        "rs": "Zbrinjavanje majke kod sumnje na oštećenje ploda drugim medicinskim intervencijama",
        "la": "Curatio matris ad laesionem fetus proceduris medicinalis aliis (suspectam)"
    },
    {
        "icd": "O358",
        "rs": "Zbrinjavanje majke kod sumnje na druge nenormalnosti i oštećenja ploda",
        "la": "Curatio matris ad abnormalitates et laesiones fetus alias (suspectas)"
    },
    {
        "icd": "O359",
        "rs": "Zbrinjavenje majke kod sumnje na nenormalnosti i oštećenja fetusa, neoznačena",
        "la": "Curatio matris ad abnormalitates et laesiones fetus (suspectas), non specificata"
    },
    {
        "icd": "O36",
        "rs": "Zbrinjavanje majke kod drugih poznatih ili sumnjivih problema ploda",
        "la": "Curatio matris ad problema fetus nota sive suspecta alia"
    },
    {
        "icd": "O360",
        "rs": "Zbrinjavanje majke kod rezus-izoimunizacije",
        "la": "Curatio matris ad rhesus-isoimmunizationem"
    },
    {
        "icd": "O361",
        "rs": "Briga o majci kod druge izoimunizacije",
        "la": "Curatio matris ad isoimmunisationem aliam"
    },
    {
        "icd": "O362",
        "rs": "Zbrinjavanje majke sa vodenim plodom",
        "la": "Curatio matris cum hydrope fetali"
    },
    {
        "icd": "O363",
        "rs": "Zbrinjavanje majke kod znakova hipoksije ploda",
        "la": "Curatio matris ad signa hypoxiae fetalis"
    },
    {
        "icd": "O364",
        "rs": "Briga o majci kod smrti ploda u materici",
        "la": "Curatio matris ad mortem intrauterinam"
    },
    {
        "icd": "O365",
        "rs": "Zbrinjavanje majke kod slabog rasta ploda",
        "la": "Curatio matris ad staturam fetalem parvam"
    },
    {
        "icd": "O366",
        "rs": "Zbrinjavanje majke kod prevelikog rasta ploda",
        "la": "Curatio matris ad staturam fetalem magnam"
    },
    {
        "icd": "O367",
        "rs": "Zbrinjavanje majke kod živog ploda u trbušnoj trudnoći",
        "la": "Curatio matris ad fetum vivum in graviditate abdominali"
    },
    {
        "icd": "O368",
        "rs": "Zbrinjavanje majke kod drugih označenih problema sa plodom",
        "la": "Curatio matris ad problemata fetalia alia, specificata"
    },
    {
        "icd": "O369",
        "rs": "Zbrinjavanje majke kod neoznačenih problema sa plodom",
        "la": "Curatio matris ad problemata fetalia, non specificata"
    },
    {
        "icd": "O40",
        "rs": "Prevelika količina amnionske tečnosti",
        "la": "Polyhydramnion"
    },
    {
        "icd": "O41",
        "rs": "Drugi poremećaji amnionske tečnosti i vodenjaka",
        "la": "Disordines liquoris et membranarum amnii alii"
    },
    {
        "icd": "O410",
        "rs": "Smanjena količina amnionske tečnosti",
        "la": "Oligohydramnion"
    },
    {
        "icd": "O411",
        "rs": "Infekcija amnionske tečnosti i vodenjaka",
        "la": "Infectio liquoris et membranarum amnii"
    },
    {
        "icd": "O418",
        "rs": "Ostali poremećaji amnionske tečnosti i vodenjaka, označeni",
        "la": "Disordines liquoris et membranarum amnii alii, specificati"
    },
    {
        "icd": "O419",
        "rs": "Poremećaj amnionske tečnosti i vodenjaka, neoznačen",
        "la": "Disordo liquoris et membranarum amnii, non specificatus"
    },
    {
        "icd": "O42",
        "rs": "Prerana ruptura vodenjaka",
        "la": "Ruptura membranarum amnii praematura"
    },
    {
        "icd": "O420",
        "rs": "Prerana ruptura vodenjaka, početak porođaja u toku 24 časa",
        "la": "Ruptura membranarum amnii, initium partus per 24 horas"
    },
    {
        "icd": "O421",
        "rs": "Prerana ruptura vodenjaka, početak porođaja posle 24 časa",
        "la": "Ruptura membranarum amnii praematura, initium partus post 24 horas"
    },
    {
        "icd": "O422",
        "rs": "Prerana ruptura vodenjaka, porođaj odložen terapijom",
        "la": "Ruptura membranarum amnii praematura, partus therapia prolongatus"
    },
    {
        "icd": "O429",
        "rs": "Prerana ruptura vodenjaka, neoznačena",
        "la": "Ruptura membranarum amnii praematura, non specificata"
    },
    {
        "icd": "O43",
        "rs": "Bolesti posteljice",
        "la": "Morbi placentae"
    },
    {
        "icd": "O430",
        "rs": "Sindrom posteljične transfuzije",
        "la": "Syndroma transfusionis placentalis"
    },
    {
        "icd": "O431",
        "rs": "Deformacija posteljice",
        "la": "Malformatio placentae"
    },
    {
        "icd": "O432",
        "rs": "Patološki adherentna posteljica",
        "la": "Placenta adherens morbida"
    },
    {
        "icd": "O438",
        "rs": "Druge bolesti posteljice",
        "la": "Morbi placaentae alii"
    },
    {
        "icd": "O439",
        "rs": "Bolest posteljice, neoznačena",
        "la": "Morbus placentae, non specificatus"
    },
    {
        "icd": "O44",
        "rs": "Nisko usađena posteljica",
        "la": "Placenta praevia"
    },
    {
        "icd": "O440",
        "rs": "Nisko usađena posteljica bez krvarenja",
        "la": "Placenta praevia sine haemorrhagia"
    },
    {
        "icd": "O441",
        "rs": "Nisko usađena posteljica sa krvarenjem",
        "la": "Placenta praevia cum haemorrhagia"
    },
    {
        "icd": "O45",
        "rs": "Prerano odlubljivanje posteljice",
        "la": "Abruptio placentae praematura"
    },
    {
        "icd": "O450",
        "rs": "Prerano odlubljivanje posteljice sa poremećajem zgrušavanja krvi",
        "la": "Abruptio placentae cum defectu coagulationis"
    },
    {
        "icd": "O458",
        "rs": "Drugo prerano odlubljivanje posteljice",
        "la": "Abruptio placentae praematura alia"
    },
    {
        "icd": "O459",
        "rs": "Prerano odlubljivanje posteljice, neoznačeno",
        "la": "Abruptio placentae praematura, non specificata"
    },
    {
        "icd": "O46",
        "rs": "Krvarenje pre porođaja, neklasifikovano na drugom mestu",
        "la": "Haemorrhagia ante partum, non alibi classificata"
    },
    {
        "icd": "O460",
        "rs": "Krvarenje pre porođaja sa poremećajem zgrušavanja krvi",
        "la": "Haemorrhagia ante partum cum defectu coagulationis"
    },
    {
        "icd": "O468",
        "rs": "Drugo krvarenje pre porođaja",
        "la": "Haemorrhagia ante partum alia"
    },
    {
        "icd": "O469",
        "rs": "Krvarenje pre porođaja, neoznačeno",
        "la": "Haemorrhagia ante partum, non specificata"
    },
    {
        "icd": "O47",
        "rs": "Lažni trudovi",
        "la": "Labores falsi"
    },
    {
        "icd": "O470",
        "rs": "Lažni trudovi pre punih 37 nedelja trudnoće",
        "la": "Labores falsi ante 37 septimanas graviditatis completas"
    },
    {
        "icd": "O471",
        "rs": "Lažni trudovi u toku ili posle punih 37 nedelja trudnoće",
        "la": "Labores falsi per sive post 37 septimanas graviditatis completas"
    },
    {
        "icd": "O479",
        "rs": "Lažni trudovi, neoznačeni",
        "la": "Labores falsi, non specificatus"
    },
    {
        "icd": "O48",
        "rs": "Produžena trudnoća",
        "la": "Graviditas prolongata"
    },
    {
        "icd": "O60",
        "rs": "Prevremeni trudovi i prevremeni porođaj",
        "la": "Labores praematuri et partus praematurus"
    },
    {
        "icd": "O600",
        "rs": "Preveremeni trudovi bez porođaja",
        "la": "Labores praetermini sine partu"
    },
    {
        "icd": "O601",
        "rs": "Prevremeni spontani trudovi sa prevremenim porođajem",
        "la": "Labores praematuri spontanei cum partu pretermini"
    },
    {
        "icd": "O602",
        "rs": "Spontani prevremeni trudovi sa porođajem na vreme",
        "la": "Labores praetermini spontanei cum partu termini"
    },
    {
        "icd": "O603",
        "rs": "Prevremeni porođaj bez spontanih trudova",
        "la": "Partus praematurus sine labores spontanei"
    },
    {
        "icd": "O61",
        "rs": "Neuspela indukcija porođaja",
        "la": "Inductio partus inefficax"
    },
    {
        "icd": "O610",
        "rs": "Neuspela indukcija porođaja lekovima",
        "la": "Inductio partus medicalis inefficax"
    },
    {
        "icd": "O611",
        "rs": "Neuspela indukcija porođaja instrumentima",
        "la": "Inductio partus instrumentalis inefficax"
    },
    {
        "icd": "O618",
        "rs": "Druga neuspela indukcija porođaja",
        "la": "Inductio partus inefficax alia"
    },
    {
        "icd": "O619",
        "rs": "Neuspela indukcija porođaja, neoznačena",
        "la": "Inductio partus inefficax, non specificata"
    },
    {
        "icd": "O62",
        "rs": "Nenormalnosti jačine trudova",
        "la": "Abnormitates vis laborum"
    },
    {
        "icd": "O620",
        "rs": "Primarne nedovoljne kontrakcije materice",
        "la": "Contractiones uteri primariae inadaequatae"
    },
    {
        "icd": "O621",
        "rs": "Sekundarna lenjost materice",
        "la": "Inertia uteri secundaria"
    },
    {
        "icd": "O622",
        "rs": "Druga lenjost materice",
        "la": "Inertia uteri alia"
    },
    {
        "icd": "O623",
        "rs": "Nagli porođaj",
        "la": "Partus praeceps"
    },
    {
        "icd": "O624",
        "rs": "Snažne, nekoordinirane i produžene kontrakcije materice",
        "la": "Contractiones uteri hypotonicae, incoordinatae et prolongatae"
    },
    {
        "icd": "O628",
        "rs": "Ostale nenormalnosti jačine trudova",
        "la": "Abnormitates vis laborum aliae"
    },
    {
        "icd": "O629",
        "rs": "Nenormalnost jačine trudova, neoznačena",
        "la": "Abnormitas vis laborum, non specificatus"
    },
    {
        "icd": "O63",
        "rs": "Produžen porođaj",
        "la": "Partus prolongatus"
    },
    {
        "icd": "O630",
        "rs": "Produžena prva faza porođaja",
        "la": "Partus prolongatus, phasis prima"
    },
    {
        "icd": "O631",
        "rs": "Produžena druga faza porođaja",
        "la": "Partus prolongatus, phasis secundaria"
    },
    {
        "icd": "O632",
        "rs": "Kasno rođenje drugog, trećeg itd. ploda",
        "la": "Partus gemini secundi, tertii et tardivus"
    },
    {
        "icd": "O639",
        "rs": "Produžen porođaj, neoznačen",
        "la": "Partus prolongatus, non specificatus"
    },
    {
        "icd": "O64",
        "rs": "Komplikovan porođaj zbog nepravilnog položaja i stava ploda",
        "la": "Partus propter malpositionem et malpraesentationem fetus complicatus"
    },
    {
        "icd": "O640",
        "rs": "Komplikovan porođaj zbog nepotpune rotacije glave ploda",
        "la": "Partus propter rotationem capitis fetus incompletam complicatus"
    },
    {
        "icd": "O641",
        "rs": "Sprečen porođaj zbog prednjačenja karlice",
        "la": "Partus propter praesentationem pelvis obstruens"
    },
    {
        "icd": "O642",
        "rs": "Komplikovan porođaj zbog prednjačenja lica",
        "la": "Partus propter praesentationem faciei complicatus"
    },
    {
        "icd": "O643",
        "rs": "Komplikovan porođaj zbog prednjačenja čela",
        "la": "Partus propter praesentationem frontis complicatus"
    },
    {
        "icd": "O644",
        "rs": "Komplikovan porođaj zbog prednjačenja ramena",
        "la": "Partus propter praesentationem omae complicatus"
    },
    {
        "icd": "O645",
        "rs": "Komplikovan porođaj zbog složenog prednjačenja (šaka, stopala)",
        "la": "Partus propter praesentationem (manuum, pedum) compositam complicatus"
    },
    {
        "icd": "O648",
        "rs": "Komplikovan porođaj zbog drugog nepravilnog položaja i stava ploda",
        "la": "Partus propter malpositionem et malpraesentationem fetus aliam complicatus"
    },
    {
        "icd": "O649",
        "rs": "Komplikovan porođaj zbog nepravilnog položaja i stava ploda, neoznačen",
        "la": "Partus propter malpositionem et malpraesentationem fetus complicatus, non specificatus"
    },
    {
        "icd": "O65",
        "rs": "Komplikovan porođaj zbog nenormalnosti karlice majke",
        "la": "Partus propter pelvem matris abnormalem complicatus"
    },
    {
        "icd": "O650",
        "rs": "Komplikovan porođaj zbog deformisane karlice",
        "la": "Partus propter pelvem deformatam complicatus"
    },
    {
        "icd": "O651",
        "rs": "Komplikovan porođaj zbog ukupnog suženja karlice",
        "la": "Partus propter contractionem aditus pelvis totalem complicatus"
    },
    {
        "icd": "O652",
        "rs": "Komplikovan porođaj zbog suženja ulaza karlice",
        "la": "Partus propter contractionem aditus pelvis complicatus"
    },
    {
        "icd": "O653",
        "rs": "Komplikovan porođaj zbog suženja izlaza i srednjeg dela karlice",
        "la": "Partus propter contractionem orificii et cavitatis mediae complicatus"
    },
    {
        "icd": "O654",
        "rs": "Komplikovan porođaj zbog neoznačene disproporcije ploda i karlice",
        "la": "Partus propter disproportionem fetus et pelvis, non specificatam complicatus"
    },
    {
        "icd": "O655",
        "rs": "Komplikovan porođaj zbog nenormalnosti organa karlice majke",
        "la": "Partus propter abnormitatem organorum pelvis matris complicatus"
    },
    {
        "icd": "O658",
        "rs": "Komplikovan porođaj zbog drugih nenormalnosti karlice majke",
        "la": "Partus propter abnormitates pelvis matris aliae complicatus"
    },
    {
        "icd": "O659",
        "rs": "Komplikovan porođaj zbog nenormalnosti karlice majke, neoznačen",
        "la": "Partus propter abnormitatem pelvis matris complicatus, non specificatus"
    },
    {
        "icd": "O66",
        "rs": "Drugi komplikovan porođaj",
        "la": "Partus complicatus alius"
    },
    {
        "icd": "O660",
        "rs": "Komplikovan porođaj zbog prolaska ramena",
        "la": "Partus propter dystociam omae complicatus"
    },
    {
        "icd": "O661",
        "rs": "Komplikovan porođaj zbog poprečnog položaja blizanaca",
        "la": "Partus propter geminos transversos complicatus"
    },
    {
        "icd": "O662",
        "rs": "Komplikovan porođaj zbog neobično velikog ploda",
        "la": "Partus propter fetum inusitate magnum complicatus"
    },
    {
        "icd": "O663",
        "rs": "Komplikovan porođaj zbog drugih nenormalnosti ploda",
        "la": "Partus propter abnormitates fetus alias complicatus"
    },
    {
        "icd": "O664",
        "rs": "Neuspeo pokušaj porođaja, neoznačen",
        "la": "Conatus partus inefficax, non specificatus"
    },
    {
        "icd": "O665",
        "rs": "Neuspela upotreba vakuum – ekstraktora i forcepsa, neoznačena",
        "la": "Usus vacuum-extractoris et forcipis inefficax, non specificata"
    },
    {
        "icd": "O668",
        "rs": "Drugi označen komplikovan porođaj",
        "la": "Partus complicatus alius, specificatus"
    },
    {
        "icd": "O669",
        "rs": "Komplikovan porođaj, neoznačen",
        "la": "Partus complicatus, non specificatus"
    },
    {
        "icd": "O67",
        "rs": "Porođaj komplikovan zbog krvarenja, neklasifikovan na drugom mestu",
        "la": "Partus propter haemorrhagiam complicatus, non alibi classificatus"
    },
    {
        "icd": "O670",
        "rs": "Krvarenje u toku porođaja zbog poremećaja zgrušavanja krvi",
        "la": "Haemorrhagia intrapartualis propter defectum coagulationis"
    },
    {
        "icd": "O678",
        "rs": "Drugo krvarenje u toku porođaja",
        "la": "Haemorrhagia intrapartualis alia"
    },
    {
        "icd": "O679",
        "rs": "Krvarenje u toku porođaja, neoznačeno",
        "la": "Haemorrhagia intrapartualis, non specificata"
    },
    {
        "icd": "O68",
        "rs": "Porođaj komplikovan zbog gušenja ploda",
        "la": "Partus propter asphyxiam fetus complicatus"
    },
    {
        "icd": "O680",
        "rs": "Porođaj komplikovan zbog nepravilnosti ritma srca fetusa",
        "la": "Partus propter anomaliam rythmi cardiaci fetalis complicatus"
    },
    {
        "icd": "O681",
        "rs": "Porođaj komplikovan zbog mekonijuma u amnionskoj tečnosti",
        "la": "Partus propter meconium in fluido amniotico complicatus"
    },
    {
        "icd": "O682",
        "rs": "Porođaj komplikovan zbog nepravilnog ritma srca fetusa i mekonijuma u amnionskoj tečnosti",
        "la": "Partus propter rythmum cardiacum irregularem et meconium in fluido amniotico complicatus"
    },
    {
        "icd": "O683",
        "rs": "Porođaj komplikovan zbog biohemijskih dokaza za stres ploda",
        "la": "Partus propter argumenta biochemica ad „stress“ fetus complicatus"
    },
    {
        "icd": "O688",
        "rs": "Porođaj komplikovan zbog drugih dokaza za stres ploda",
        "la": "Partus propter argumenta ad „stress“ fetus alia complicatus"
    },
    {
        "icd": "O689",
        "rs": "Porođaj komplikovan zbog stresa ploda, neoznačen",
        "la": "Partus propter „stress“ fetus complicatus, non specificatus"
    },
    {
        "icd": "O69",
        "rs": "Porođaj komplikovan zbog komplikacija sa pupčanikom",
        "la": "Partus propter complicationes chordae umbilicalis complicatus"
    },
    {
        "icd": "O690",
        "rs": "Porođaj komplikovan zbog ispada pupčanika",
        "la": "Partus propter prolapsum chordae umbilicalis complicatus"
    },
    {
        "icd": "O691",
        "rs": "Porođaj komplikovan zbog pupčanika oko vrata",
        "la": "Partus propter chordam umbilicalem collum circumvolutam complicatus"
    },
    {
        "icd": "O692",
        "rs": "Porođaj komplikovan zbog drugog zapetljavanja pupčanika",
        "la": "Partus propter implicationem chordae umbilicalis aliam complicatus"
    },
    {
        "icd": "O693",
        "rs": "Porođaj komplikovan zbog kratkog pupčanika",
        "la": "Partus propter chordam umbilicalem brevem complicatus"
    },
    {
        "icd": "O694",
        "rs": "Porođaj komplikovan zbog isturenih krvnih sudova",
        "la": "Partus propter vasa praevia complicatus"
    },
    {
        "icd": "O695",
        "rs": "Porođaj komplikovan zbog oštećenja krvnih sudova pupčanika",
        "la": "Partus propter laesionem vascularem chordae umbilicalis complicatus"
    },
    {
        "icd": "O698",
        "rs": "Porođaj komplikovan zbog drugih komplikacija sa pupčanikom",
        "la": "Partus propter aliae complicationes chordae umbilicalis complicatus"
    },
    {
        "icd": "O699",
        "rs": "Porođaj komplikovan zbog komplikacija sa pupčanikom, neoznačen",
        "la": "Partus complicatus propter complicationem chordae umbilicalis, non specificatus"
    },
    {
        "icd": "O70",
        "rs": "Povreda međice u toku porođaja",
        "la": "Laesio perinei, per partum"
    },
    {
        "icd": "O700",
        "rs": "Povreda međice u toku porođaja, prvi stepen",
        "la": "Laesio perinaei per partum, gradus primus"
    },
    {
        "icd": "O701",
        "rs": "Povreda međice u toku porođaja, drugi stepen",
        "la": "Laesio perinei per partum, gradus secundus"
    },
    {
        "icd": "O702",
        "rs": "Povreda međice u toku porođaja, treći stepen",
        "la": "Laesio perinei per partum, gradus tertius"
    },
    {
        "icd": "O703",
        "rs": "Povreda međice u toku porođaja, četvrti stepen",
        "la": "Laesio perinei per partum, gradus quartus"
    },
    {
        "icd": "O709",
        "rs": "Povreda međice u toku porođaja, neoznačena",
        "la": "Laesio perinei per partum, non specificatus"
    },
    {
        "icd": "O71",
        "rs": "Druge akušerske ozlede u toku porođaja",
        "la": "Trauma obstetricia alia"
    },
    {
        "icd": "O710",
        "rs": "Ruptura materice pre porođaja",
        "la": "Ruptura uteri, ante partum"
    },
    {
        "icd": "O711",
        "rs": "Ruptura materice tokom porođaja",
        "la": "Ruptura uteri, per partum"
    },
    {
        "icd": "O712",
        "rs": "Izvrtanje materice posle porođaja",
        "la": "Inversio uteri postpartualis"
    },
    {
        "icd": "O713",
        "rs": "Akušersko kidanje grlića materice",
        "la": "Laceratio cervicis uteri obstetricia"
    },
    {
        "icd": "O714",
        "rs": "Akušersko kidanje zida vagine",
        "la": "Laceratio solum parietalis vaginae obstetricia"
    },
    {
        "icd": "O715",
        "rs": "Druga akušerska povreda organa karlice",
        "la": "Laesio organorum pelvicorum obstetricia"
    },
    {
        "icd": "O716",
        "rs": "Akušerska oštećenja zglobova i veza karlice",
        "la": "Laesio articulorum et ligamentorum obstetricia alia"
    },
    {
        "icd": "O717",
        "rs": "Nakupljanje krvi u karlici",
        "la": "Haematoma pelvis obstetricum"
    },
    {
        "icd": "O718",
        "rs": "Druga označena akušerska ozleda",
        "la": "Trauma obstetricum aliud, specificatum"
    },
    {
        "icd": "O719",
        "rs": "Akušerska ozleda, neoznačena",
        "la": "Trauma obstetricum, non specificatum"
    },
    {
        "icd": "O72",
        "rs": "Krvarenje posle porođaja",
        "la": "Haemorrhagia post partum"
    },
    {
        "icd": "O720",
        "rs": "Krvarenje posle porođaja, treća faza",
        "la": "Haemorrhagia post partum, phasis tertia"
    },
    {
        "icd": "O721",
        "rs": "Drugo krvarenje neposredno posle porođaja",
        "la": "Haemorrhagia directa, post partum, alia"
    },
    {
        "icd": "O722",
        "rs": "Kasno i sekundarno krvarenje posle porođaja",
        "la": "Haemorrhagia tarda, post partum"
    },
    {
        "icd": "O723",
        "rs": "Poremećaj zgrušavanja krvi posle porođaja",
        "la": "Coagulopathia sanguinis, post partum"
    },
    {
        "icd": "O73",
        "rs": "Zaostajanje posteljice i ovojnica posteljice bez krvarenja",
        "la": "Retentio placentae et membranarum placentae, sine haemorrhagia"
    },
    {
        "icd": "O730",
        "rs": "Zaostajanje posteljice bez krvarenja",
        "la": "Retentio placentae, sine haemorrhagia"
    },
    {
        "icd": "O731",
        "rs": "Zaostajanje dela posteljice i ovojnica bez krvarenja",
        "la": "Retentio placentae partialis et membranarum, sine haemorrhagia"
    },
    {
        "icd": "O74",
        "rs": "Komplikacije uzorkovane anestezijom tokom porođaja",
        "la": "Complicationes anaesthesionales, per partum"
    },
    {
        "icd": "O740",
        "rs": "Zapaljenje pluća uzrokovano anestezijom tokom porođaja",
        "la": "Pneumonia aspirationalis anaesthesionalis, per partum"
    },
    {
        "icd": "O741",
        "rs": "Druge komplikacije pluća uzrokovane anestezijom tokom porođaja",
        "la": "Complicationes pulmonum anaesthesionales aliae, per partum"
    },
    {
        "icd": "O742",
        "rs": "Komplikacije srca uzrokovane anestezijom tokom porođaja",
        "la": "Complicationes cardiacae anaesthesionales, per partum"
    },
    {
        "icd": "O743",
        "rs": "Komplikacije centralnog nervnog sistema uzrokovane anestezijom tokom porođaja",
        "la": "Complicationes systematis nervosi centralis anaesthesioanles, per partum"
    },
    {
        "icd": "O744",
        "rs": "Toksička reakcija uzrokovana anestezijom tokom porođaja",
        "la": "Reactio toxica anaesthesionalis, per partum"
    },
    {
        "icd": "O745",
        "rs": "Glavobolja uzrokovana lumbalnom ili epiduralnom anestezijom tokom porođaja",
        "la": "Cephalalgia propter anaesthesiam spinalem et epiduralem, per partum"
    },
    {
        "icd": "O746",
        "rs": "Druge komplikacije uzrokovane lumbalnom i epiduralnom anestezijom tokom porođaja",
        "la": "Complicationes propter anaesthesiam spinalem et epiduralem, aliae, per partum"
    },
    {
        "icd": "O747",
        "rs": "Neuspela ili otežana intubacija tokom porođaja",
        "la": "Intubatio inefficax sive difficilis, per partum"
    },
    {
        "icd": "O748",
        "rs": "Druge komplikacije uzrokovane anestezijom tokom porođaja",
        "la": "Complicationes anaesthesionales, aliae, per partum"
    },
    {
        "icd": "O749",
        "rs": "Komplikacija uzrokovana anestezijom tokom porođaja, neoznačena",
        "la": "Complicatio anaesthesionalis, non specificata, per partum"
    },
    {
        "icd": "O75",
        "rs": "Druge komplikacije tokom porođaja, neklasifikovane na drugom mestu",
        "la": "Complicationes per partum aliae, non alibi classificatae"
    },
    {
        "icd": "O750",
        "rs": "Iscrpljenost majke tokom porođaja",
        "la": "Exhaustio matris per partum"
    },
    {
        "icd": "O751",
        "rs": "Šok tokom ili posle porođaja",
        "la": "Afflictus (shock) per sive post partum"
    },
    {
        "icd": "O752",
        "rs": "Povišena temperatura tokom porođaja, neklasifikovana na drugom mestu",
        "la": "Pyrehia per partum, non alibi classificata"
    },
    {
        "icd": "O753",
        "rs": "Druge infekcije tokom porođaja",
        "la": "Infectiones, aliae, per partum"
    },
    {
        "icd": "O754",
        "rs": "Druge komplikacije akušerskih operacija i procedura",
        "la": "Complicationes propter chirurgiam obstetriciam sive proceduras aliae"
    },
    {
        "icd": "O755",
        "rs": "Odloženi porođaj posle veštačkog bušenja vodenjaka",
        "la": "Partus post ruputuram membranarum arteficialiem prolongatus"
    },
    {
        "icd": "O756",
        "rs": "Odloženi porođaj posle spontane ili neoznačene rupture vodenjaka",
        "la": "Partus post rupturam membranarum spontaneam sive non specificatam prolongatus"
    },
    {
        "icd": "O757",
        "rs": "Prirodni porođaj posle prethodnog carskog reza",
        "la": "Partus vaginalis post sectionem caesaream praecedentem"
    },
    {
        "icd": "O758",
        "rs": "Druge označene komplikacije porođaja",
        "la": "Complicationes partus aliae, specificatae"
    },
    {
        "icd": "O759",
        "rs": "Komplikacija porođaja, neoznačena",
        "la": "Complicatio partus, non specificata"
    },
    {
        "icd": "O80",
        "rs": "Spontani porođaj kod jednoplodne trudnoće",
        "la": "Partus spontaneus simplex"
    },
    {
        "icd": "O800",
        "rs": "Spontani porođaj glavom",
        "la": "Partus spontaneus capite"
    },
    {
        "icd": "O801",
        "rs": "Spontani porođaj zadnjicom",
        "la": "Partus spontaneus natibus"
    },
    {
        "icd": "O808",
        "rs": "Drugi spontani porođaj kod jednoplodne trudnoće",
        "la": "Partus spontaneus simplex alius"
    },
    {
        "icd": "O809",
        "rs": "Sponatni porođaj kod jednoplodne trudnoće, neoznačen",
        "la": "Partus spontaneus simplex, non specificatus"
    },
    {
        "icd": "O81",
        "rs": "Porođaj kod jednoplodne trudnoće forcepsom i vakuum-ekstraktorom",
        "la": "Partus simplex forcipe et vacuum-extractore"
    },
    {
        "icd": "O810",
        "rs": "Porođaj niskim forcepsom",
        "la": "Partus forcipe inferiore"
    },
    {
        "icd": "O811",
        "rs": "Porođaj forcepsom iz sredine",
        "la": "Partus forcipe medio"
    },
    {
        "icd": "O812",
        "rs": "Porođaj forcepsom iz sredine sa rotacijom",
        "la": "Partus forcipe medio cum rotatione"
    },
    {
        "icd": "O813",
        "rs": "Porođaj forcepsom, drugim i neoznačenim",
        "la": "Partus forcipe alio et non specificato"
    },
    {
        "icd": "O814",
        "rs": "Porođaj vakuum ekstraktorom",
        "la": "Partus vacuum- extractore"
    },
    {
        "icd": "O815",
        "rs": "Porođaj kombinovanom primenom forcepsa i vakuum-ekstraktora",
        "la": "Partus combinatione forcipis et vacuum-extractoris"
    },
    {
        "icd": "O82",
        "rs": "Porođaj carskim rezom kod jednoplodne trudnoće",
        "la": "Partus simplex sectione caesarea"
    },
    {
        "icd": "O820",
        "rs": "Porođaj carskim rezom po izboru",
        "la": "Partus sectione caesarea selectivus"
    },
    {
        "icd": "O821",
        "rs": "Hitan porođaj carskim rezom",
        "la": "Partus sectione caesarea urgens"
    },
    {
        "icd": "O822",
        "rs": "Porođaj sa odstranjenjem materice u toku carskog reza",
        "la": "Partus hysterectomia, per sectionem caesaream"
    },
    {
        "icd": "O828",
        "rs": "Drugi porođaj carskim rezom kod jednoplodne trudnoće",
        "la": "Partus simplex sectione caesaream alius"
    },
    {
        "icd": "O829",
        "rs": "Porođaj carskim rezom, neoznačen",
        "la": "Partus sectione caesarea, non specificatus"
    },
    {
        "icd": "O83",
        "rs": "Drugi porođaj kod jednoplodne trudnoće uz stručnu pomoć",
        "la": "Partus simplex cum assistentia, alius"
    },
    {
        "icd": "O830",
        "rs": "Vađenje ploda sa karličnim prednjačenjem",
        "la": "Extractio natibus"
    },
    {
        "icd": "O831",
        "rs": "Porođaj zadnjicom uz drugu pomoć",
        "la": "Partus natibus cum assistentia alia"
    },
    {
        "icd": "O832",
        "rs": "Drugi porođaj uz ručnu pomoć",
        "la": "Partus cum assistentia manuum alius"
    },
    {
        "icd": "O833",
        "rs": "Porođaj živog ploda kod trbušne trudnoće",
        "la": "Partus fetus vivi in graviditate abdominali"
    },
    {
        "icd": "O834",
        "rs": "Destruktivna operacija tokom porođaja",
        "la": "Operatio destructiva obstetricia"
    },
    {
        "icd": "O838",
        "rs": "Drugi označeni porođaj jednoplodne trudnoće uz stručnu pomoć",
        "la": "Partus simplex cum assistentia alius, specificatus"
    },
    {
        "icd": "O839",
        "rs": "Jednoplodan porođaj uz stučnu pomoć, neoznačen",
        "la": "Partus simplex cum assistentia, non specificatus"
    },
    {
        "icd": "O84",
        "rs": "Višeplodni porođaj",
        "la": "Partus multiplex"
    },
    {
        "icd": "O840",
        "rs": "Višeplodni porođaj, svi spontani",
        "la": "Partus multiplex, omnis spontaneus"
    },
    {
        "icd": "O841",
        "rs": "Višeplodni porođaj, svi forcepsom i vakuum-ekstraktorom",
        "la": "Partus multiplex, omnis forcipe et vakuum-extractore"
    },
    {
        "icd": "O842",
        "rs": "Višeplodni porođaj, svi carskim rezom",
        "la": "Partus multiplex, omnis sectione caesarea"
    },
    {
        "icd": "O848",
        "rs": "Drugi višeplodni porođaj",
        "la": "Partus multiplex alius"
    },
    {
        "icd": "O849",
        "rs": "Višeplodni porođaj, neoznačen",
        "la": "Partus multiplex, non specificatus"
    },
    {
        "icd": "O85",
        "rs": "Sepsa (trovanje bakterijama) u babinjama",
        "la": "Sepsis puerperalis"
    },
    {
        "icd": "O86",
        "rs": "Druge infekcije u babinjama",
        "la": "Infectiones puerperales aliae"
    },
    {
        "icd": "O860",
        "rs": "Infekcije porođajne hirurške rane",
        "la": "Infectio vulneris chirurgici obstetricii"
    },
    {
        "icd": "O861",
        "rs": "Druga infekcija polnih organa posle porođaja",
        "la": "Infectio tractus genitalis, alia, post partum"
    },
    {
        "icd": "O862",
        "rs": "Infekcija mokraćnih kanala, posle porođaja",
        "la": "Infectio tractus urinarii, post partum"
    },
    {
        "icd": "O863",
        "rs": "Druge infekcije polno-mokraćnog sistema posle porođaja",
        "la": "Infectiones tractus genitourinarii aliae, post partum"
    },
    {
        "icd": "O864",
        "rs": "Povišena temperatura posle porođaja, uzrok nepoznat",
        "la": "Temperatura postpartualis, causa ignota"
    },
    {
        "icd": "O868",
        "rs": "Druge označene infekcije u babinjama",
        "la": "Infectiones puerperales aliae, specificatae"
    },
    {
        "icd": "O87",
        "rs": "Komplikacije vena u babinjama",
        "la": "Complicationes venosae puerperales"
    },
    {
        "icd": "O870",
        "rs": "Površinski tromboflebitis u babinjama",
        "la": "Thrombophlebitis puerperalis, superficialis"
    },
    {
        "icd": "O871",
        "rs": "Duboka flebotromboza u babinjama",
        "la": "Thrombophlebitis profunda puerperalis"
    },
    {
        "icd": "O872",
        "rs": "Hemoroidi u babinjama",
        "la": "Haemorrhoides puerperales"
    },
    {
        "icd": "O873",
        "rs": "Moždana venska tromboza u babinjama",
        "la": "Thrombosis venosa cerebralis in puerperio"
    },
    {
        "icd": "O878",
        "rs": "Druge komplikacije vena u babinjama",
        "la": "Complicationes venosae puerperales aliae"
    },
    {
        "icd": "O879",
        "rs": "Komplikacija vena u babinjama, neoznačena",
        "la": "Complicatio venosa puerperalis, non specificata"
    },
    {
        "icd": "O88",
        "rs": "Akušerska embolija",
        "la": "Embolia obstetricia"
    },
    {
        "icd": "O880",
        "rs": "Akušerska vazdušna embolija",
        "la": "Embolia aero obstetricia"
    },
    {
        "icd": "O881",
        "rs": "Embolija plodovom vodom",
        "la": "Embolia fluido amniotico"
    },
    {
        "icd": "O882",
        "rs": "Akušerska embolija krvnim ugruškom",
        "la": "Embolia coagulo sanguinis obstetricia"
    },
    {
        "icd": "O883",
        "rs": "Akušerska gnojna i septična embolija",
        "la": "Embolia septica et pyemica obstetricia"
    },
    {
        "icd": "O888",
        "rs": "Druga akušerska embolija",
        "la": "Embolia obstetricia alia"
    },
    {
        "icd": "O89",
        "rs": "Komplikacije uzrokovane anestezijom tokom babinja",
        "la": "Complicationes anaesthesionales, per puerperium"
    },
    {
        "icd": "O890",
        "rs": "Komplikacije pluća uzrokovane anestezijom tokom babinja",
        "la": "Complicationes pulmonales anaestesionales, per puerperium"
    },
    {
        "icd": "O891",
        "rs": "Komplikacije srca uzrokovane anestezijom tokom babinja",
        "la": "Complicationes cardiacae anaestesionales, per puerperium"
    },
    {
        "icd": "O892",
        "rs": "Komplikacije centralnog nervnog sistema uzrokovane anestezijom tokom babinja",
        "la": "Complicationes sistematis nervorum centralis anaesthesionales, per puerperium"
    },
    {
        "icd": "O893",
        "rs": "Toksička reakcija uzrokovana lokalnom anestezijom tokom babinja",
        "la": "Reactio toxica propter anaesthesiam localem, per puerperium"
    },
    {
        "icd": "O894",
        "rs": "Glavobolja uzrokovana lumbalnom i epiduralnom anestezijom tokom babinja",
        "la": "Cephaliea propter anaesthesiam spianlem et epiduralem, per puerperium"
    },
    {
        "icd": "O895",
        "rs": "Druge komplikacije uzrokovane lumbalnom i epiduralnom anestezijom tokom babinja",
        "la": "Complicationes propter anaesthesiam spinalem et epiduralem, per puerperium, aliae"
    },
    {
        "icd": "O896",
        "rs": "Neuspela ili otežana intubacija tokom babinja",
        "la": "Intubatio inefficax sive difficilis, per puerperium"
    },
    {
        "icd": "O898",
        "rs": "Druge komplikacije uzrokovane anestezijom tokom babinja",
        "la": "Complicationes anaesthesionales, per puerperium, aliae"
    },
    {
        "icd": "O899",
        "rs": "Komplikacija uzrokovana anestezijom tokom babinja, neoznačena",
        "la": "Complicatio anaesthesionalis, per puerperium, non specificata"
    },
    {
        "icd": "O90",
        "rs": "Komplikacije u babinjama, neklasifikovane na drugom mestu",
        "la": "Complicationes puerperales, non alibi classificatae"
    },
    {
        "icd": "O900",
        "rs": "Ruptura rane posle carskog reza",
        "la": "Ruptura vulneris sectiones caesarea facti"
    },
    {
        "icd": "O901",
        "rs": "Ruptura rane na međici nastale tokom porođaja",
        "la": "Ruptura vulneris perinealis obstetriciae"
    },
    {
        "icd": "O902",
        "rs": "Krvni ugrušak rane nastale tokom porođaja",
        "la": "Haematoma vulneris obstetricii"
    },
    {
        "icd": "O903",
        "rs": "Srčano oboljenje u babinjama",
        "la": "Cardiomyopathia puerperalis"
    },
    {
        "icd": "O904",
        "rs": "Akutna bubrežna insuficijencija u babinjama",
        "la": "Insuficientia renalis postpartualis acuta"
    },
    {
        "icd": "O905",
        "rs": "Zapaljenje štitaste žlezde u babinjama",
        "la": "Thyroiditis postpartualis"
    },
    {
        "icd": "O908",
        "rs": "Druge komplikacije babinja, neklasifikovane na drugom mestu",
        "la": "Complicationes puerperales aliae, non alibi classificatae"
    },
    {
        "icd": "O909",
        "rs": "Komplikaciia u babinjama, neoznačena",
        "la": "Complicatio puerperalis, non specificata"
    },
    {
        "icd": "O91",
        "rs": "Zapaljenje dojke tokom dojenja",
        "la": "Mastitis per lactationem"
    },
    {
        "icd": "O910",
        "rs": "Infekcija bradavice dojke udružena sa lučenjem mleka",
        "la": "Infectio papille mammae cum lactatione associata"
    },
    {
        "icd": "O911",
        "rs": "Apsces dojke tokom dojenja",
        "la": "Abscessus mammae per lactationem"
    },
    {
        "icd": "O912",
        "rs": "Negnojno yapaljenje dojke tokom dojenja",
        "la": "Mastitis non purulenta per lactationem"
    },
    {
        "icd": "O92",
        "rs": "Drugi poremećaji dojke i lučenja mleka",
        "la": "Disordines mammae et lactationes alii"
    },
    {
        "icd": "O920",
        "rs": "Bradavica uvučena posle porođaja tokom babinja",
        "la": "Papilla mammae post partum et per puerperium retracta"
    },
    {
        "icd": "O921",
        "rs": "Prskotina bradavice posle porođaja tokom babinja",
        "la": "Fissura papillae mammae post partum et per puerperium"
    },
    {
        "icd": "O922",
        "rs": "Druge bolesti dojke posle porođaja, neoznačene",
        "la": "Morbi mammae post partum alii, non specificati"
    },
    {
        "icd": "O923",
        "rs": "Nedostatak mleka",
        "la": "Agalactia"
    },
    {
        "icd": "O924",
        "rs": "Nedovoljno lučenje mleka",
        "la": "Hypogalactia"
    },
    {
        "icd": "O925",
        "rs": "Veštački uzrokovan prestanak lučenja mleka",
        "la": "Agalactia arteficialis"
    },
    {
        "icd": "O926",
        "rs": "Spontano curenje mleka",
        "la": "Galactorrhoea"
    },
    {
        "icd": "O927",
        "rs": "Drugi poremećaji lučenja mleka, neoznačen",
        "la": "Disordines lactationales alii, non specificata"
    },
    {
        "icd": "O94",
        "rs": "Posledice komplikacija trudnoće, porođaja i babinja",
        "la": "Sequelae complicationis graviditatis, partus et puerperii"
    },
    {
        "icd": "O95",
        "rs": "Akušerska smrt neoznačenog uzroka",
        "la": "Mors matris obstetricia, causa ignota"
    },
    {
        "icd": "O96",
        "rs": "Smrt zbog bilo kog akušerskog uzroka koja je nastupila više od 42 dana, ali manje od godinu dana nakon porođaja",
        "la": "MMors ex qualibet causa obstetricia per 42 dies usque ad unum annum post partum"
    },
    {
        "icd": "O960",
        "rs": "Smrt zbog direktnog akušerskog uzroka",
        "la": "Mors propter causam obstetriciam directam"
    },
    {
        "icd": "O961",
        "rs": "Smrt zbog indirektnog akušerskog uzroka",
        "la": "Mors propter causam obstetriciam indirectam"
    },
    {
        "icd": "O969",
        "rs": "Smrt zbog neoznačenog akušerskog uzroka",
        "la": "Mors propter causam obstetriciam non specificatam"
    },
    {
        "icd": "O97",
        "rs": "Smrt zbog kasnih posledica akušerskih uzroka",
        "la": "Mors matris propter sequelas obstetricias"
    },
    {
        "icd": "O970",
        "rs": "Smrt zbog kasnih posledica direktnog akušerskog uzroka",
        "la": "Mors propter sequelas e causa directa obstetricia"
    },
    {
        "icd": "O971",
        "rs": "Smrt zbog kasnih posledica indirektnog akušerskog uzroka",
        "la": "Mors propter sequelas e causa indirecta obstetricia"
    },
    {
        "icd": "O979",
        "rs": "Smrt zbog kasnih posledica akušerskog uzroka, neoznačenih",
        "la": "Mors propter sequelas e causa obstetricia non specificata"
    },
    {
        "icd": "O98",
        "rs": "Zarazne i parazitarne bolesti majke koje mogu bili klasifikovane na drugom mestu, ali koje komplikuju trudnoću, porođaj i babinje",
        "la": "Morbi materni infectiosi et parasitici alibi classificabiles sed complicantes graviditatem, partum et puerperium"
    },
    {
        "icd": "O980",
        "rs": "Tuberkuloza u trudnoći, porođaju i babinjama",
        "la": "Tuberculosis in graviditate, partu et puerperio"
    },
    {
        "icd": "O981",
        "rs": "Sifilis u trudnoći, porođaju i babinjama",
        "la": "Syphilis in graviditate, partu et puerperio"
    },
    {
        "icd": "O982",
        "rs": "Kapavac u trudnoći, porođaju i babinjama",
        "la": "Gonorrhoea in graviditate, partu et puerperio"
    },
    {
        "icd": "O983",
        "rs": "Druge polne infekcije u trudnoći, porođaju i babinjama",
        "la": "Infectiones sexuales in graviditate, partu et puerperio aliae"
    },
    {
        "icd": "O984",
        "rs": "Žutica uzrokovana virusom u trudnoći, porođaju i babinjama",
        "la": "Hepatitis viralis in graviditate, partu et puerperio"
    },
    {
        "icd": "O985",
        "rs": "Druge bolesti uzrokovane virusom u trudnoći, porođaju i babinjama",
        "la": "Morbi virales in graviditate, partu et puerprio, alii"
    },
    {
        "icd": "O986",
        "rs": "Bolesti uzrokovane protozoama u trudnoći, porođaju i babinjama",
        "la": "Morbi protozoales in garviditate, partu et puerperio"
    },
    {
        "icd": "O987",
        "rs": "HIV infekcija u trudnoći, porođaju i babinjama",
        "la": "HIV infectio in graviditate, partu et puerperio"
    },
    {
        "icd": "O988",
        "rs": "Druge zarazne i parazitarne bolesti majki u trudnoći, porođaju i babinjama",
        "la": "Morbus matris infectivi et morbi parasitarii in graviditate, partu et puerperio, alii"
    },
    {
        "icd": "O989",
        "rs": "Zarazne i parazitarne bolesti majke u trudnoći, porođaju i babinjama, neoznačena",
        "la": "Morbus matris infectivus et morbus parasitarius graviditate, partu et puerperio, non specificata"
    },
    {
        "icd": "O99",
        "rs": "Druge bolesti majke koje mogu biti klasifikovane na drugom mestu, ali koje komplikuju trudnoću, porođaj i babinjame",
        "la": "Alii morbi materni alibi classificabiles sed complicantes graviditatem, partum et puerperium"
    },
    {
        "icd": "O990",
        "rs": "Anemija u trudnoći, porođaju i babinjama",
        "la": "Anaemia graviditate partu et puerperio"
    },
    {
        "icd": "O991",
        "rs": "Druge bolesti krvi i krvotvornih organa i poremećaj imunog mehanizma u trudnoći, porođaju i babinjama",
        "la": "Morbi sanguinis et organorum haematopoeticorum alii et disordines mechanismi immunologici in graviditate, partu et puerperio"
    },
    {
        "icd": "O992",
        "rs": "Bolest žlezda sa unutrašnjim lučenjem, ishrane i metabolizma u trudnoći, porođaju i babinjama",
        "la": "Morbi glandularum endocrinarum, nutricionis et metabolismi in graviditate, partu et puerperio"
    },
    {
        "icd": "O993",
        "rs": "Duševni poremećaji i bolesti nervnog sistema u trudnoći, porođaju i babinjama",
        "la": "Disordines mentales et morbi systematis nervosi in graviditate, partu et puerperio"
    },
    {
        "icd": "O994",
        "rs": "Bolesti krvotoka u trudnoći porođaju i babinjama",
        "la": "Morbi circulatorii in graviditate, partu et puerperio"
    },
    {
        "icd": "O995",
        "rs": "Bolesti organa za disanje u trudnoći, porođaju i babinjama",
        "la": "Morbi organorum respiratoriorum in graviditate, partu et puerperio"
    },
    {
        "icd": "O996",
        "rs": "Bolesti oragna za varenje u trudnoći, porođaju i babinjama",
        "la": "Morbi organorum digestivorum in garviditate, partu et puerperio"
    },
    {
        "icd": "O997",
        "rs": "Bolest kože i potkožnog tkiva u trudnoći porođaju i babinjama",
        "la": "Morbi cutis et subcutis in graviditate, partu et puerperio"
    },
    {
        "icd": "O998",
        "rs": "Druge označene bolseti i stanja u trudnoći, porođaju i babinjama",
        "la": "Morbi et status in graviditate, partu et puerperio alii, specificati"
    },
    {
        "icd": "S00",
        "rs": "Površinska povreda glave",
        "la": "Laesio traumatica capitis, superficialis"
    },
    {
        "icd": "S000",
        "rs": "Površinska povreda poglavine",
        "la": "Laesio traumatica capillitii, superficialis"
    },
    {
        "icd": "S001",
        "rs": "Kontuzija kapka oka i okoline oka",
        "la": "Contusio palpebrae et periocularis"
    },
    {
        "icd": "S002",
        "rs": "Druge površinske povrede kapka oka i okoline oka",
        "la": "Laesiones traumaticae palpebrae et perioculares, superficiales aliae"
    },
    {
        "icd": "S003",
        "rs": "Površinska povreda nosa",
        "la": "Laesio traumatica nasi, superficialis"
    },
    {
        "icd": "S004",
        "rs": "Površinska povreda uva",
        "la": "Laesio traumatica auris, superficialis"
    },
    {
        "icd": "S005",
        "rs": "Površinska povreda usne i usta",
        "la": "Lasio traumatica labii et cavitatis oris, superficialis"
    },
    {
        "icd": "S007",
        "rs": "Višestruke površinske povrede glave",
        "la": "Laesiones traumaticae capitis superficiales multiplices"
    },
    {
        "icd": "S008",
        "rs": "Površinska povreda drugih delova glave",
        "la": "Laesio traumatica partium capitis aliarum, superficialis"
    },
    {
        "icd": "S009",
        "rs": "Površinska povreda neoznačenih delova glave",
        "la": "Laesio traumatica capitis superficialis, partium non specificatarum"
    },
    {
        "icd": "S01",
        "rs": "Otvorena rana glave",
        "la": "Vulnus apertum capitis"
    },
    {
        "icd": "S010",
        "rs": "Otvorena rana poglavine",
        "la": "Vulnus apertum capillitii"
    },
    {
        "icd": "S011",
        "rs": "Otvorena rana kapka oka i okoline oka",
        "la": "Vulnus apertum palpebrae et perioculare"
    },
    {
        "icd": "S012",
        "rs": "Otvorena rana nosa",
        "la": "Vulnus apertum nasi"
    },
    {
        "icd": "S013",
        "rs": "Otvorena rana uva",
        "la": "Vulnus apertum auris"
    },
    {
        "icd": "S014",
        "rs": "Otvorena rana obraza i predela zgloba gornje vilice",
        "la": "Vulnus apertum buccae et regionis temporomandibularis"
    },
    {
        "icd": "S015",
        "rs": "Otvorena rana usne i usta",
        "la": "Vulnus apertum labii et cavitatis oris"
    },
    {
        "icd": "S017",
        "rs": "Višestruke otvorene rane glave",
        "la": "Vulnera aperta capitis multiplicia"
    },
    {
        "icd": "S018",
        "rs": "Otvorena rana drugih delova glave",
        "la": "Vulnus apertum capitis (aliarum) partium"
    },
    {
        "icd": "S019",
        "rs": "Otvorena rana neoznačenih delova glave",
        "la": "Vulnus apertum capitis non specificatarum partium"
    },
    {
        "icd": "S02",
        "rs": "Prelom lobanje i prelom kostiju lica",
        "la": "Fractura cranii et fractura ossium faciei"
    },
    {
        "icd": "S020",
        "rs": "Prelom krova lobanje",
        "la": "Fractura calvarii"
    },
    {
        "icd": "S021",
        "rs": "Prelom baze lobanje",
        "la": "Fractura basis cranii"
    },
    {
        "icd": "S022",
        "rs": "Prelom kostiju nosa",
        "la": "Fractura ossium nasalium"
    },
    {
        "icd": "S023",
        "rs": "Prelom poda duplje oka",
        "la": "Fractura orbite, pars inferior"
    },
    {
        "icd": "S024",
        "rs": "Prelom jagodice i prelom kosti gornje vilice",
        "la": "Fractura ossis zygomatici et fractura ossis maxillaris"
    },
    {
        "icd": "S025",
        "rs": "Prelom zuba",
        "la": "Fractura dentis"
    },
    {
        "icd": "S026",
        "rs": "Prelom donje vilice",
        "la": "Fractura mandibulae"
    },
    {
        "icd": "S027",
        "rs": "Višestruki prelom lobanje i kostiju lica",
        "la": "Fractura cranii et fractura ossium faciei multiplex"
    },
    {
        "icd": "S028",
        "rs": "Prelomi ostalih kostiju lobanje i lica",
        "la": "Fracturae aliorum ossium cranii et faciei"
    },
    {
        "icd": "S029",
        "rs": "Prelom neoznačenih delova kostiju lobanje i prelom kostiju lica",
        "la": "Fractura partium ossium cranii et fractura ossium faciei, non specificatarum"
    },
    {
        "icd": "S03",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza glave",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum capitis"
    },
    {
        "icd": "S030",
        "rs": "Iščašenje donje vilice",
        "la": "Luxatio mandibulae"
    },
    {
        "icd": "S031",
        "rs": "Iščašenje hrskavice pregrade nosa",
        "la": "Luxatio cartilaginis septi nasi"
    },
    {
        "icd": "S032",
        "rs": "Iščašenje zuba",
        "la": "Luxatio denti"
    },
    {
        "icd": "S033",
        "rs": "Iščašenje drugih i neoznačenih delova glave",
        "la": "Luxatio partium aliarum et non specificatarum capitis"
    },
    {
        "icd": "S034",
        "rs": "Uganuće i istegnuće donje vilice",
        "la": "Distorsio et distensio mandibulae"
    },
    {
        "icd": "S035",
        "rs": "Uganuće i istegnuće zglobova i veza drugih i neoznačenih delova glave",
        "la": "Distorsio et distensio articulorum et ligamentorum partium capitis aliarum et non specificatarum"
    },
    {
        "icd": "S04",
        "rs": "Povrede živaca lobanje",
        "la": "Laesiones traumaticae nervorum cranialium"
    },
    {
        "icd": "S040",
        "rs": "Povreda vidnog živca i optičkog puta",
        "la": "Laesio traumatica nervi optici et tractus optici"
    },
    {
        "icd": "S041",
        "rs": "Povreda živca pokretača oka",
        "la": "Laesio traumatica nervi oculomotorii"
    },
    {
        "icd": "S042",
        "rs": "Povreda živca pokretača kapka",
        "la": "Laesio traumatica nervi trochlearis"
    },
    {
        "icd": "S043",
        "rs": "Povreda trokrakog živca",
        "la": "Laesio traumatica nervi trigemini"
    },
    {
        "icd": "S044",
        "rs": "Povreda živca odmicača",
        "la": "Laesio traumatica nervi abducentis"
    },
    {
        "icd": "S045",
        "rs": "Povreda živca lica",
        "la": "Laesio traumatica nervi facialis"
    },
    {
        "icd": "S046",
        "rs": "Povreda živca za sluh",
        "la": "Laesio traumatica nervi acustici"
    },
    {
        "icd": "S047",
        "rs": "Povreda pomoćnog živca lobanje",
        "la": "Laesio traumatica nervi accessorii"
    },
    {
        "icd": "S048",
        "rs": "Povreda drugih živaca lobaje",
        "la": "Laesio traumatica nervorum cranialium aliorum"
    },
    {
        "icd": "S049",
        "rs": "Povreda neoznačenog živca lobanje",
        "la": "Laesio traumatica nervi cranialis non specificati"
    },
    {
        "icd": "S05",
        "rs": "Povreda oka i očne duplje",
        "la": "Laesio traumatica oculi et orbitae"
    },
    {
        "icd": "S050",
        "rs": "Povreda vežnjače i oderotina rožnjače bez pominjanja stranog tela",
        "la": "Laesio traumatica conjuctivae et abrasio corneae sine corpore alieno"
    },
    {
        "icd": "S051",
        "rs": "Nagnječenje očne jabučice i tkiva očne duplje",
        "la": "Contusio bulbi oculi et textus orbitae"
    },
    {
        "icd": "S052",
        "rs": "Razderotina i ruptura oka sa ispadom ili gubitkom intraokularnog tkiva",
        "la": "Laceratio et ruptura oculi cum prolapsu sive amissione textus intraocularis"
    },
    {
        "icd": "S053",
        "rs": "Razderotina oka bez ispada ili gubitka intraokularnog tkiva",
        "la": "Laceratio oculi sine prolapsu sive amissione textus intraocularis"
    },
    {
        "icd": "S054",
        "rs": "Penetrantna rana očne duplje sa ili bez stranog tela",
        "la": "Vulnus penetrans orbitae cum sive sine corpore alieno"
    },
    {
        "icd": "S055",
        "rs": "Penetrantna rana očne jabučice sa stranim telom",
        "la": "Vulnus penetrans bulbi oculi cum corpore alieno"
    },
    {
        "icd": "S056",
        "rs": "Penetrantna rana očne jabučice bez stranog tela",
        "la": "Vulnus penetrans bulbi oculi sine corpore alieno"
    },
    {
        "icd": "S057",
        "rs": "Istrgnuće oka",
        "la": "Avulsio oculi"
    },
    {
        "icd": "S058",
        "rs": "Ostale povrede oka i očne duplje",
        "la": "Laesiones traumaticae oculi et orbitae aliae"
    },
    {
        "icd": "S059",
        "rs": "Povreda oka i očne duplje, neoznačena",
        "la": "Laesio traumatica oculi et orbitae, non specificata"
    },
    {
        "icd": "S06",
        "rs": "Intrakranijalna [Unutarlobanjska] povreda",
        "la": "Laesio traumatica intracranialis"
    },
    {
        "icd": "S060",
        "rs": "Potres mozga",
        "la": "Commotio cerebri"
    },
    {
        "icd": "S061",
        "rs": "Edem mozga uzrokovan povredom",
        "la": "Oedema cerebri traumaticum"
    },
    {
        "icd": "S062",
        "rs": "Difuzna povreda mozga",
        "la": "Laesio traumatica cerebri diffusa"
    },
    {
        "icd": "S063",
        "rs": "Fokalna povreda mozga",
        "la": "Laesio traumatica cerebri focalis"
    },
    {
        "icd": "S064",
        "rs": "Krvarenje pod tvrdom moždanicom",
        "la": "Haemorrhagia epiduralis"
    },
    {
        "icd": "S065",
        "rs": "Krvarenje ispod tvrde moždanice uzrokovano povredom",
        "la": "Haemorrhagia subduralis traumatica"
    },
    {
        "icd": "S066",
        "rs": "Krvarenje ispod mrežaste moždanice uzrokovano povredom",
        "la": "Haemorrhagia subarachnoidalis traumatica"
    },
    {
        "icd": "S067",
        "rs": "Intrakranijalna povreda unutar sa produženom komom",
        "la": "Laesio traumatica intracranialis cum comate prolongato"
    },
    {
        "icd": "S068",
        "rs": "Ostale intrakranijalne povrede",
        "la": "Laesiones traumaticae intracraniales aliae"
    },
    {
        "icd": "S069",
        "rs": "Intrakranijalna povreda, neoznačena",
        "la": "Laesio traumatica intracranialis, non specificata"
    },
    {
        "icd": "S07",
        "rs": "Povrede glave uzrokovane zdrobljavanjem – kraš",
        "la": "Conquassatio capitis"
    },
    {
        "icd": "S070",
        "rs": "Povreda lica uzrokovana zdrobljavanjem",
        "la": "Conquassatio faciei"
    },
    {
        "icd": "S071",
        "rs": "Povreda lobanje uzrokovana zdrobljavanjem",
        "la": "Conquassatio cranii"
    },
    {
        "icd": "S078",
        "rs": "Povreda glave uzrokovana zdrobljavanjem ostalih delova glave",
        "la": "Conquassatio partium capitis aliarum"
    },
    {
        "icd": "S079",
        "rs": "Povreda glave uzrokovana zdrobljavanjem neoznačenog dela glave",
        "la": "Conquassatio capitis parte non specificata"
    },
    {
        "icd": "S08",
        "rs": "Traumatska amputacija dela glave",
        "la": "Amputatio traumatica partis capitis"
    },
    {
        "icd": "S080",
        "rs": "Otkinuće poglavine",
        "la": "Avulsio capillitii"
    },
    {
        "icd": "S081",
        "rs": "Traumatska amputacija uva",
        "la": "Amputatio traumatica auris"
    },
    {
        "icd": "S088",
        "rs": "Traumatska amputacija drugih delova glave",
        "la": "Amputatio traumatica partium capitis aliarum"
    },
    {
        "icd": "S089",
        "rs": "Traumatska amputacija neoznačenog dela glave",
        "la": "Amputatio traumatica partis capitis non specificatae"
    },
    {
        "icd": "S09",
        "rs": "Ostale i neoznačene povrede glave",
        "la": "Laesiones traumaticae capitis aliae et non classificatae"
    },
    {
        "icd": "S090",
        "rs": "Povreda krvnih sudova glave, neklasifikovana na drugom mestu",
        "la": "Laesio traumatica vasorum capitis, non alibi classificatae"
    },
    {
        "icd": "S091",
        "rs": "Povreda mišića i tetiva glave",
        "la": "Laesio traumatica musculi et tendinis capitis"
    },
    {
        "icd": "S092",
        "rs": "Traumatska ruptura bubne opne",
        "la": "Ruptura traumatica membranae tympani"
    },
    {
        "icd": "S097",
        "rs": "Višestruka povreda glave",
        "la": "Laesio traumatica capitis multiplex"
    },
    {
        "icd": "S098",
        "rs": "Ostale označene povrede glave",
        "la": "Laesiones traumaticae capitis aliae, specificatae"
    },
    {
        "icd": "S099",
        "rs": "Povreda glave, neoznačena",
        "la": "Laesio traumatica capitis, non specificata"
    },
    {
        "icd": "S10",
        "rs": "Površinska povreda vrata",
        "la": "Laesio traumatica colli superficialis"
    },
    {
        "icd": "S100",
        "rs": "Nagnječenje grla",
        "la": "Contusio juguli"
    },
    {
        "icd": "S101",
        "rs": "Ostale i neoznačene površinske povrede grla",
        "la": "Laesiones traumaticae juguli superfaciales aliae et non specificatae"
    },
    {
        "icd": "S107",
        "rs": "Višestruke površinske povrede vrata",
        "la": "Laesiones traumaticae colli superficiales multiplices"
    },
    {
        "icd": "S108",
        "rs": "Površinska povreda drugih delova vrata",
        "la": "Laesio traumatica partium colli aliairum superficialis"
    },
    {
        "icd": "S109",
        "rs": "Površinska povreda vrata, neoznačenog dela",
        "la": "Laesio traumatica colli superficialis parte non specificata"
    },
    {
        "icd": "S11",
        "rs": "Otvorena rana vrata",
        "la": "Vulnus apertum colli"
    },
    {
        "icd": "S110",
        "rs": "Otvorena rana grkljana i dušnika",
        "la": "Vulnus apertum laryngis et tracheae"
    },
    {
        "icd": "S111",
        "rs": "Otvorena rana štitaste žlezde",
        "la": "Vulnus apertum glanduale thyreoideae"
    },
    {
        "icd": "S112",
        "rs": "Otvorena rana ždrela i vratnog dela jednjaka",
        "la": "Vulnus apertum pharyngis et oesophagi, pars cervicalis"
    },
    {
        "icd": "S117",
        "rs": "Višestruka otvorena rana vrata",
        "la": "Vulnera aperta colli multiplicia"
    },
    {
        "icd": "S118",
        "rs": "Otvorena rana drugih delova vrata",
        "la": "Vulnus apertum partium colli aliarum"
    },
    {
        "icd": "S119",
        "rs": "Otvorena rana dela vrata, neoznačena",
        "la": "Vulnus apertum partis colli, non specificatum"
    },
    {
        "icd": "S12",
        "rs": "Prelom vrata",
        "la": "Fractura colli"
    },
    {
        "icd": "S120",
        "rs": "Prelom prvog vratnog pršljena",
        "la": "Fractura vertebrae cervicalis primae"
    },
    {
        "icd": "S121",
        "rs": "Prelom drugog vratnog pršljena",
        "la": "Fractura vertebrae cervicalis secundae"
    },
    {
        "icd": "S122",
        "rs": "Prelom drugog označenog vratnog pršljena",
        "la": "Fractura vertebrae cervicalis aliae specificatae"
    },
    {
        "icd": "S127",
        "rs": "Višestruki prelomi vratnog dela kičme",
        "la": "Fractureae columnae vertebralis cervicalis multiplices"
    },
    {
        "icd": "S128",
        "rs": "Prelom ostalih delova vrata",
        "la": "Fractura aliarum partium colli"
    },
    {
        "icd": "S129",
        "rs": "Prelom neoznačenog dela vrata",
        "la": "Fractura partis colli, non specificatae"
    },
    {
        "icd": "S13",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza vrata",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum colli"
    },
    {
        "icd": "S130",
        "rs": "Traumatska ruptura vratnog medjupršljenskog diska",
        "la": "Ruptura traumatica disci intervertebralis cervicalis"
    },
    {
        "icd": "S131",
        "rs": "Iščašenje vratnog pršljena",
        "la": "Luxatio vertebrae cervicalis"
    },
    {
        "icd": "S132",
        "rs": "Iščašenje drugih i neoznačenih delova vrata",
        "la": "Luxatio partium colli aliarum et non specificarum"
    },
    {
        "icd": "S133",
        "rs": "Višestruko iščašenje vrata",
        "la": "Luxatio colli multiplex"
    },
    {
        "icd": "S134",
        "rs": "Uganuće i istegnuće vratnog dela kičme",
        "la": "Distorsio et distensio columnae vertebrlis cervicalis"
    },
    {
        "icd": "S135",
        "rs": "Uganuće i istegnuće u predelu štitaste žlezde",
        "la": "Distorsio et distensio regionis thyreoideae"
    },
    {
        "icd": "S136",
        "rs": "Uganuće i istegnuće zglobova i veza drugih i neoznačenih delova vrata",
        "la": "Distorsio et distensio partium articulorum et ligamentorum colli aliarum et non specificatarum"
    },
    {
        "icd": "S14",
        "rs": "Povreda živaca i kičmene moždine u nivou vrata",
        "la": "Laesio traumatica nervorum et medullae spinalis colli"
    },
    {
        "icd": "S140",
        "rs": "Potres i edem vratnog dela kičmene moždine",
        "la": "Commotio et oedema medullae spinalis cervicalis"
    },
    {
        "icd": "S141",
        "rs": "Druge i neoznačene povrede vratnog dela kičmene moždine",
        "la": "Laesiones traumaticae medullae spinalis cervicalis aliae et non specificatae"
    },
    {
        "icd": "S142",
        "rs": "Povreda korena vratnog kičmenog živca",
        "la": "Laesio traumatica radicis nervi spinalis cervicalis"
    },
    {
        "icd": "S143",
        "rs": "Povreda ramenog živčanog spleta",
        "la": "Laesio traumatica plexus brachialis"
    },
    {
        "icd": "S144",
        "rs": "Povreda perifernih živaca vrata",
        "la": "Laesio traumatica nervorum cervicalium periphericorum"
    },
    {
        "icd": "S145",
        "rs": "Povreda simpatičkih živaca vrata",
        "la": "Laesio traumatica nervorum sympatheticorum colli"
    },
    {
        "icd": "S146",
        "rs": "Povreda drugih i neoznačenih živaca vrata",
        "la": "Laesio traumatica nervorum colli aliorum et non specificatorum"
    },
    {
        "icd": "S15",
        "rs": "Povreda krvnih sudova u nivou vrata",
        "la": "Laesio traumatica vasorum colli"
    },
    {
        "icd": "S150",
        "rs": "Povrede glavne arterije vrata",
        "la": "Laesio traumatica arteriae carotidis"
    },
    {
        "icd": "S151",
        "rs": "Povreda arterije kičme",
        "la": "Laesio traumatica arteriae vertebralis"
    },
    {
        "icd": "S152",
        "rs": "Povreda spoljašnje vene vrata",
        "la": "Laesio traumatica venae jugularis externae"
    },
    {
        "icd": "S153",
        "rs": "Povreda unutrašnje vene vrata",
        "la": "Laesio traumatica venae jugularis internae"
    },
    {
        "icd": "S157",
        "rs": "Povreda više krvnih sudova u nivou vrata",
        "la": "Laesio traumatica vasorum colli multiplicium"
    },
    {
        "icd": "S158",
        "rs": "Povreda drugih krvnih sudova u nivou vrata",
        "la": "Laesio traumatica vasorum colli aliorum"
    },
    {
        "icd": "S159",
        "rs": "Povreda neoznačenog krvnog suda u nivou vrata",
        "la": "Laesio traumatica vasis colli, non specificati"
    },
    {
        "icd": "S16",
        "rs": "Povreda mišića i tetive vrata",
        "la": "Laesio traumatica musculi et tendinis colli"
    },
    {
        "icd": "S17",
        "rs": "Povreda vrata uzrokovana zdrobljavanjem – kraš",
        "la": "Conquassatio colli"
    },
    {
        "icd": "S170",
        "rs": "Povreda grkljana i dušnika uzrokovana zdrobljavanjem",
        "la": "Conquassatio laryngis et tracheae"
    },
    {
        "icd": "S178",
        "rs": "Povreda drugog dela vrata uzrokovana zdrobljavanjem",
        "la": "Conquassatio colli, aliarum partium"
    },
    {
        "icd": "S179",
        "rs": "Povreda vrata uzrokovana zdrobljavanjem, neoznačenog dela",
        "la": "Conquassatio colli, parte non specificata"
    },
    {
        "icd": "S18",
        "rs": "Traumatska amputacija u nivou vrata",
        "la": "Amputatio traumatica regionis colli"
    },
    {
        "icd": "S19",
        "rs": "Druge i neoznačene povrede vrata",
        "la": "Laesiones traumatica colli aliae et non specificatae"
    },
    {
        "icd": "S197",
        "rs": "Višestruke povrede vrata",
        "la": "Laesiones traumaticae colli multiplices"
    },
    {
        "icd": "S198",
        "rs": "Druge označene povrede vrata",
        "la": "Laesiones traumaticae coli aliae, specificatae"
    },
    {
        "icd": "S199",
        "rs": "Povreda vrata, neoznačena",
        "la": "Laesio traumatica colli, non specificata"
    },
    {
        "icd": "S20",
        "rs": "Površinska povreda grudnog koša",
        "la": "Laesio traumatica thoracis superficialis"
    },
    {
        "icd": "S200",
        "rs": "Nagnječenje dojke",
        "la": "Contusio mammae"
    },
    {
        "icd": "S201",
        "rs": "Druge i neoznačene površinske povrede dojke",
        "la": "Laesiones traumaticae mammae superficiales aliae et non specificatae"
    },
    {
        "icd": "S202",
        "rs": "Nagenječenje grudnog koša",
        "la": "Contusio thoracis"
    },
    {
        "icd": "S203",
        "rs": "Druge površinske povrede prednjeg zida grudnog koša",
        "la": "Laesiones trumaticae parietis thoracis anterioris superficiales aliae"
    },
    {
        "icd": "S204",
        "rs": "Druge površinske povrede zadnjeg zida grudnog koša",
        "la": "Laesiones traumaticae parietis thoracis posterioris superficiales aliae"
    },
    {
        "icd": "S207",
        "rs": "Višestruke površinske povrede grudnog koša",
        "la": "Laesiones traumaticae thoracis superficiales multiplices"
    },
    {
        "icd": "S208",
        "rs": "Površinska povreda drugih i neoznačenih delova grudnog koša",
        "la": "Laesio traumatica partium thoracis aliarum et non specificarum superficialis"
    },
    {
        "icd": "S21",
        "rs": "Otvorena rana grudnog koša",
        "la": "Vulnus apertum thoracis"
    },
    {
        "icd": "S210",
        "rs": "Otvorena rana dojke",
        "la": "Vulnus apertum mammae"
    },
    {
        "icd": "S211",
        "rs": "Otvorena rana prednjeg zida grudnog koša",
        "la": "Vulnus apertum parietis thoracis anterioris"
    },
    {
        "icd": "S212",
        "rs": "Otvorena rana zadnjeg zida grudnog koša",
        "la": "Vulnus apertum parietis thoracis posterioris"
    },
    {
        "icd": "S217",
        "rs": "Višestruke otvorene rane zida grudnog koša",
        "la": "Vulnera aperta parietis thoracis multiplicia"
    },
    {
        "icd": "S218",
        "rs": "Otvorena rana drugih delova grudnog koša",
        "la": "Vulnus apertum partium thoracis aliarum"
    },
    {
        "icd": "S219",
        "rs": "Otvorena rana grudnog koša, neoznačenog dela",
        "la": "Vulnus apertum thoracis, parte non specificata"
    },
    {
        "icd": "S22",
        "rs": "Prelom rebra(rebara), grudne kosti i grudnog dela kičme",
        "la": "Fractura costae(costarum), sterni and columnae vertebralis thoracalis"
    },
    {
        "icd": "S220",
        "rs": "Prelom grudnog pršljena",
        "la": "Fractura vertebrae thoracalis"
    },
    {
        "icd": "S221",
        "rs": "Višestruki prelomi grudnog dela kičme",
        "la": "Fracturae columnae vertebralis thoracalis multiplices"
    },
    {
        "icd": "S222",
        "rs": "Prelom grudne kosti",
        "la": "Fractura sterni"
    },
    {
        "icd": "S223",
        "rs": "Prelom rebra",
        "la": "Fractura costae"
    },
    {
        "icd": "S224",
        "rs": "Višestruki prelomi rebara",
        "la": "Fracturae costarum multiplices"
    },
    {
        "icd": "S225",
        "rs": "Kolebljivi pokreti grudnog koša (usled povrede)",
        "la": "Thorax fluitans"
    },
    {
        "icd": "S228",
        "rs": "Prelom drugih kostiju grudnog koša",
        "la": "Fractura ossium thoracis aliorum"
    },
    {
        "icd": "S229",
        "rs": "Prelom neoznačenog dela kosti grudnog koša",
        "la": "Fractura ossis thoracis, parte non specificata"
    },
    {
        "icd": "S23",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza grudnog koša",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum thoracis"
    },
    {
        "icd": "S230",
        "rs": "Traumatska ruptura grudnog medjupršljenskog koluta",
        "la": "Ruptura traumatica disci intervertebralis thoracalis"
    },
    {
        "icd": "S231",
        "rs": "Iščašenje grudnog pršljena",
        "la": "Luxatio vertebrae thoracalis"
    },
    {
        "icd": "S232",
        "rs": "Iščašenje drugih i neoznačenih delova grudnog koša",
        "la": "Luxatio partium thoracis aliarum et non specificatarum"
    },
    {
        "icd": "S233",
        "rs": "Uganuće i istegnuće grudnog dela kičme",
        "la": "Distorsio et distensio columnae vertebralis thoracalis"
    },
    {
        "icd": "S234",
        "rs": "Uganuće i istegnuće rebara i grudnog koša",
        "la": "Distorsio et distensio costarum et sterni"
    },
    {
        "icd": "S235",
        "rs": "Uganuće i istegnuće drugih i neoznačenih delova grudnog koša",
        "la": "Distorsio et distensio partium thoracis aliarum et non specificatarum"
    },
    {
        "icd": "S24",
        "rs": "Povrede živaca i kičmene moždine u nivou grudnog koša",
        "la": "Laesiones traumaticae nervorum et medullae spinalis thoracalis"
    },
    {
        "icd": "S240",
        "rs": "Potres i edem grudnog dela kičmene moždine",
        "la": "Commotio et oedema medullae spinalis thoracalis"
    },
    {
        "icd": "S241",
        "rs": "Druge i neoznačene povrede grudnog dela kičmene moždine",
        "la": "Laesiones traumaticae medullae spinalis thoracalis aliae et non specificatae"
    },
    {
        "icd": "S242",
        "rs": "Povreda korena grudnog kičmenog živca",
        "la": "Laesio traumatica radicis nervi spinalis thoracalis"
    },
    {
        "icd": "S243",
        "rs": "Povrede perifernih živaca grudnog koša",
        "la": "Laesiones traumaticae nervorum perifericorum thoracis"
    },
    {
        "icd": "S244",
        "rs": "Povreda simpatičkih grudnih živaca",
        "la": "Laesiones traumaticae nervorum sympatheticorum thoracalium"
    },
    {
        "icd": "S245",
        "rs": "Povreda drugih živaca grudnog koša",
        "la": "Laesio traumatica nervorum thoracalium aliorum"
    },
    {
        "icd": "S246",
        "rs": "Povreda neoznačenog živca grudnog koša",
        "la": "Laesio traumatica nervi thoracalis, non specificata"
    },
    {
        "icd": "S25",
        "rs": "Povreda grudnih krvnih sudova",
        "la": "Laesio traumatica vasorum thoracis"
    },
    {
        "icd": "S250",
        "rs": "Povreda grudnog dela aorte",
        "la": "Laesio traumatica aortae thoracalis"
    },
    {
        "icd": "S251",
        "rs": "Povreda bezimenog stabla ili potključne arterije",
        "la": "Laesio traumatica trunci brachiocephalici sive arteriae subclaviae"
    },
    {
        "icd": "S252",
        "rs": "Povreda gornje šuplje vene",
        "la": "Laesio traumatica venae cavae superioris"
    },
    {
        "icd": "S253",
        "rs": "Povreda bezimene ili potključne vene",
        "la": "Laesio traumatica venae brachiocephalicae sive venae subclaviae"
    },
    {
        "icd": "S254",
        "rs": "Povreda krvnih sudova pluća",
        "la": "Laesio traumatica vasorum pulmonalium"
    },
    {
        "icd": "S255",
        "rs": "Povreda međurebarnih krvnih sudova",
        "la": "Laesio traumatica vasorum intercostalium"
    },
    {
        "icd": "S257",
        "rs": "Povreda više grudnih krvnih sudova",
        "la": "Laesio traumatica vasorum thoracis multiplicium"
    },
    {
        "icd": "S258",
        "rs": "Povreda drugih grudnih krvnih sudova",
        "la": "Laesio traumatica vasorum thoracis aliorum"
    },
    {
        "icd": "S259",
        "rs": "Povreda neoznačenog grudnog krvnog suda",
        "la": "Laesio traumatica vasis thoracis, non specificati"
    },
    {
        "icd": "S26",
        "rs": "Povreda srca",
        "la": "Laesio traumatica cordis"
    },
    {
        "icd": "S260",
        "rs": "Povreda srca sa prisustvom krvi u perikardu",
        "la": "Laesio traumatica cordis cum haemopericardio"
    },
    {
        "icd": "S268",
        "rs": "Druge povrede srca",
        "la": "Laesiones traumaticae cordis aliae"
    },
    {
        "icd": "S269",
        "rs": "Povreda srca, neoznačena",
        "la": "Laesio traumatica cordis, non specificata"
    },
    {
        "icd": "S27",
        "rs": "Povrede drugih neoznačenih unutargrudnih organa",
        "la": "Laesiones traumaticae organorum thoracis aliorum, non specificatorum"
    },
    {
        "icd": "S270",
        "rs": "Prisustvo vazduha u pleuralnom prostoru uzrokovano povredom",
        "la": "Pneumotorax traumaticus"
    },
    {
        "icd": "S271",
        "rs": "Prisustvo krvi u pleuralnom prostoru uzrokovano povredom",
        "la": "Haemothorax traumaticus"
    },
    {
        "icd": "S272",
        "rs": "Udruženo prisustvo vazduha i krvi u pleuralnom prostoru uzrokovano povredom",
        "la": "Haemopneumothorax traumaticus"
    },
    {
        "icd": "S273",
        "rs": "Druge povrede pluća",
        "la": "Laesiones traumatica pulmonis aliae"
    },
    {
        "icd": "S274",
        "rs": "Povreda bronhije",
        "la": "Laesio traumatica bronchi"
    },
    {
        "icd": "S275",
        "rs": "Povreda grudnog dela dušnika",
        "la": "Laesio traumatica partis tracheae thoracalis"
    },
    {
        "icd": "S276",
        "rs": "Povreda pleure",
        "la": "Laesio traumatica pleurae"
    },
    {
        "icd": "S277",
        "rs": "Višestruke povrede unutargrudnih organa",
        "la": "Laesiones traumaticae organorum thoracis multiplices"
    },
    {
        "icd": "S278",
        "rs": "Povreda drugih označenih unutargrudnih organa",
        "la": "Laesio traumatica organorum thoracis aliorum, specificatorum"
    },
    {
        "icd": "S279",
        "rs": "Povreda neoznačenog unutargrudnog organa",
        "la": "Laesio traumatica organi thoracis, non specificati"
    },
    {
        "icd": "S28",
        "rs": "Povreda grudnog koša uzrokovana zdrobljavanjem – kraš i traumatska amputacija dela grudnog koša",
        "la": "Conquassatio et amputatio traumatica partis thoracis"
    },
    {
        "icd": "S280",
        "rs": "Povreda grudnog koša uzrokovana zdrobljavanjem – kraš",
        "la": "Conquassatio thoracis"
    },
    {
        "icd": "S281",
        "rs": "Traumatska amputacija dela grudnog koša",
        "la": "Amputatio traumatica partis thoracis"
    },
    {
        "icd": "S29",
        "rs": "Druge i neoznačene povrede grudnog koša",
        "la": "Laesiones traumaticae thoracis aliae et non specificatae"
    },
    {
        "icd": "S290",
        "rs": "Povreda mišića i tetive u nivou grudnog koša",
        "la": "Laesio traumatica muculi et tendinis thoracis"
    },
    {
        "icd": "S297",
        "rs": "Višestruke povrede grudnog koša",
        "la": "Laesiones traumaticae thoracis multiplices"
    },
    {
        "icd": "S298",
        "rs": "Druge označene povrede grudnog koša",
        "la": "Laesiones traumaticae thoracis aliae, specificatae"
    },
    {
        "icd": "S299",
        "rs": "Povreda grudnog koša, neoznačena",
        "la": "Laesio traumatica thoracis, non specificata"
    },
    {
        "icd": "S30",
        "rs": "Površinska povreda trbuha, slabine i karlice",
        "la": "Laesio traumatica abdominis, lumbi et pelvis, superficialis"
    },
    {
        "icd": "S300",
        "rs": "Nagnječenje slabine i karlice",
        "la": "Contusio lumbi et pelvis"
    },
    {
        "icd": "S301",
        "rs": "Nagnječenje zida trbuha",
        "la": "Contusio parietis abdominis"
    },
    {
        "icd": "S302",
        "rs": "Nagnječenje spoljnih polnih organa",
        "la": "Contusio genitaliorum externorum"
    },
    {
        "icd": "S307",
        "rs": "Višestruke površinske povrede trbuha, slabine i karlice",
        "la": "Laesiones traumaticae abdominis, lumbi et pelvis superficiales multiplices"
    },
    {
        "icd": "S308",
        "rs": "Druge površinske povrede trbuha, slabine i karlice",
        "la": "Laesiones traumaticae abdominis, lumbi et pelvis superficiales aliae"
    },
    {
        "icd": "S309",
        "rs": "Površinska povreda trbuha, slabine i karlice, neoznačena",
        "la": "Laesio traumatica abdominis, lumbi et pelvis, superficialis, non specificata"
    },
    {
        "icd": "S31",
        "rs": "Otvorena rana trbuha, slabine i karlice",
        "la": "Vulnus apertum abdominis lumbi et pelvis"
    },
    {
        "icd": "S310",
        "rs": "Otvorena rana slabine i karlice",
        "la": "Vulnus apertum lumbi et pelvis"
    },
    {
        "icd": "S311",
        "rs": "Otvorena rana zida trbuha",
        "la": "Vulnus apertum parietis abdominis"
    },
    {
        "icd": "S312",
        "rs": "Otvorena rana penisa",
        "la": "Vulnus apertum penis"
    },
    {
        "icd": "S313",
        "rs": "Otvorena rana skrotuma i testisa",
        "la": "Vulnus apertum scroti et testis"
    },
    {
        "icd": "S314",
        "rs": "Otvorena rana vagine i stidnice",
        "la": "Vulnus apertum vaginae et vulvae"
    },
    {
        "icd": "S315",
        "rs": "Otvorena rana drugih neoznačenih spoljnih polnih organa",
        "la": "Vulnus apertum genitaliorum externorum aliorum, non specificorum"
    },
    {
        "icd": "S317",
        "rs": "Višestruke otvorene rane trbuha, slabine i karlice",
        "la": "Vulnera aperta abdominis, lumbi et pelvis multiplicia"
    },
    {
        "icd": "S318",
        "rs": "Otvorena rana drugih i neoznačenih delova trbuha",
        "la": "Vulnus apertum partium abdominis aliarum et non specificatarum"
    },
    {
        "icd": "S32",
        "rs": "Prelom slabinskog dela kičme i karlice",
        "la": "Fractura columnae vertebralis lumbalis et pelvis"
    },
    {
        "icd": "S320",
        "rs": "Prelom slabinskog pršljena",
        "la": "Fractura vertebrae lumbalis"
    },
    {
        "icd": "S321",
        "rs": "Prelom krsne kosti",
        "la": "Fractura ossis sacri"
    },
    {
        "icd": "S322",
        "rs": "Prelom trtične kosti",
        "la": "Fractura coccygis"
    },
    {
        "icd": "S323",
        "rs": "Prelom bedrenjače",
        "la": "Fractura ossis ilii"
    },
    {
        "icd": "S324",
        "rs": "Prelom čašice karlične kosti",
        "la": "Fractura acetabuli"
    },
    {
        "icd": "S325",
        "rs": "Prelom stidne kosti",
        "la": "Fractura ossis pubis"
    },
    {
        "icd": "S327",
        "rs": "Višestruki prelomi slabinskog dela kičme i karlice",
        "la": "Fracturae columnae lumbalis et pelvis multiplices"
    },
    {
        "icd": "S328",
        "rs": "Prelom drugih i neoznačenih delova slabinskog dela kičme i karlice",
        "la": "Fractura partium aliarum et non specificatarum vertebrae lumbalis et pelvis"
    },
    {
        "icd": "S33",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza slabinskog dela kičme i karlice",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum columnae lumbalis et pelvis"
    },
    {
        "icd": "S330",
        "rs": "Traumatska ruptura slabinskog međupršljenskog diska",
        "la": "Ruptura disci intervertebralis lumbalis traumatica"
    },
    {
        "icd": "S331",
        "rs": "Iščašenje slabinskog pršljena",
        "la": "Luxatio vertebrae lumbalis"
    },
    {
        "icd": "S332",
        "rs": "Iščašenje krsno-bedrenog i krsno-trtičnog zgloba",
        "la": "Luxatio articuli sacroilici et sacrococcygei"
    },
    {
        "icd": "S333",
        "rs": "Iščašenje drugih i neoznačenih delova slabinskog dela kičme i karlice",
        "la": "Luxatio partium aliarum et non specificatarum vertebrae lumbalis et pelvis"
    },
    {
        "icd": "S334",
        "rs": "Traumatska ruptura preponskog spoja",
        "la": "Ruptura traumatica symphisis pubis"
    },
    {
        "icd": "S335",
        "rs": "Uganuće i istegnuće slabinskog dela kičme",
        "la": "Distorsio et distensio columnae lumbalis"
    },
    {
        "icd": "S336",
        "rs": "Uganuće i istegnuće krsno-bedrenog zgloba",
        "la": "Distorsio et distensio articuli sacroilici"
    },
    {
        "icd": "S337",
        "rs": "Uganuće i istegnuće drugih i neoznačenih delova slabinskog dela kičme i karlice",
        "la": "Distorsio et distensio partium aliarum et non specificatarum vertebrae lumbalis et pelvis"
    },
    {
        "icd": "S34",
        "rs": "Povreda živaca i slabinskog dela kičmene moždine u nivou trbuha, slabina i karlice",
        "la": "Laesio traumatica nervorum et medullae spinalis lumbalis regionis abdominis, lumbi et pelvis"
    },
    {
        "icd": "S340",
        "rs": "Potres i edem slabinskog dela kičmene moždine",
        "la": "Commotio et oedema medullae spinalis lumbalis"
    },
    {
        "icd": "S341",
        "rs": "Druga povreda slabinskog dela kičmene moždine",
        "la": "Laesio traumatica medullae spinalis lumbalis alia"
    },
    {
        "icd": "S342",
        "rs": "Povreda korena slabinskog i krsnog kičmenog živca",
        "la": "Laesio traumatica radicis nervi spinalis lumbalis et sacralis"
    },
    {
        "icd": "S343",
        "rs": "Povreda \"konjskog repa\" (krajnjeg dela) kičmene moždine",
        "la": "Laesio traumatica caudae equinae"
    },
    {
        "icd": "S344",
        "rs": "Povreda slabinsko-krsnog spleta živaca",
        "la": "Laesio traumatica plexus lumbosacralis"
    },
    {
        "icd": "S345",
        "rs": "Povreda slabinskih, krsnih i karličnih simpatičkih živaca",
        "la": "Laesio traumatica nervi sympathetici lumbalis, sacralis et pelvici"
    },
    {
        "icd": "S346",
        "rs": "Povreda perifernog živca trbuha, slabine i karlice",
        "la": "Laesio traumatica nervi peripherici abdominis lumbi et pelvis"
    },
    {
        "icd": "S348",
        "rs": "Povreda drugih i neoznačenih živaca u nivou trbuha, slabina i karlice",
        "la": "Laesio traumatica nervorum abdominis, lumbi, pelvis aliorum et non specificatorum"
    },
    {
        "icd": "S35",
        "rs": "Povreda krvnih sudova u nivou trbuha, slabina i karlice",
        "la": "Laesio traumatica vasorum regionis abdominis, lumbi et pelvis"
    },
    {
        "icd": "S350",
        "rs": "Povreda trbušnog dela aorte",
        "la": "Laesio traumatica aortae abdominalis"
    },
    {
        "icd": "S351",
        "rs": "Povreda donje šuplje vene",
        "la": "Laesio traumatica venae cavae inferioris"
    },
    {
        "icd": "S352",
        "rs": "Povreda celijačne ili opornjačke arterije",
        "la": "Laesio traumatica arteriae coeliacae sive mesentericae"
    },
    {
        "icd": "S353",
        "rs": "Povreda vene porte ili vene slezine",
        "la": "Laesio traumatica venae portae sive splenicae"
    },
    {
        "icd": "S354",
        "rs": "Povreda krvnih sudova bubrega",
        "la": "Laesio traumatica vasorum renis"
    },
    {
        "icd": "S355",
        "rs": "Povreda krvnih sudova bedra",
        "la": "Laesio traumatica vasorum iliacorum"
    },
    {
        "icd": "S357",
        "rs": "Povreda više krvnih sudova u nivou trbuha, slabina i karlice",
        "la": "Laesio traumatica vasorum regionis abdominis, lumbi et pelvis multiplicium"
    },
    {
        "icd": "S358",
        "rs": "Povreda drugih krvnih sudova u nivou trbuha, slabina i karlice",
        "la": "Laesio traumatica vasorum regionis abdominis, lumbi et pelvis aliorum"
    },
    {
        "icd": "S359",
        "rs": "Povreda neoznačenog krvnog suda u nivou trbuha, slabina i karlice",
        "la": "Laesio traumatica vasis regionis abdominis, lumbi et pelvis, non clasificati"
    },
    {
        "icd": "S36",
        "rs": "Povreda organa trbuha",
        "la": "Laesio traumatica organorum intraabdominalium"
    },
    {
        "icd": "S360",
        "rs": "Povreda slezine",
        "la": "Laesio traumatica lienis"
    },
    {
        "icd": "S361",
        "rs": "Povreda jetre ili žučne kesice",
        "la": "Laesio traumatica hepatis sive vesicae felleae"
    },
    {
        "icd": "S362",
        "rs": "Povreda pankreasa",
        "la": "Laesio traumatica pancreatis"
    },
    {
        "icd": "S363",
        "rs": "Povreda želuca",
        "la": "Laesio traumatica ventriculi"
    },
    {
        "icd": "S364",
        "rs": "Povreda tankog creva",
        "la": "Laesio traumatica intestini tenuis"
    },
    {
        "icd": "S365",
        "rs": "Povreda debelog creva",
        "la": "Laesio traumatica coli"
    },
    {
        "icd": "S366",
        "rs": "Povreda rektuma",
        "la": "Laesio traumatica recti"
    },
    {
        "icd": "S367",
        "rs": "Povreda više organa trbuha",
        "la": "Laesio traumatica organorum intraabdominalium multiplicium"
    },
    {
        "icd": "S368",
        "rs": "Povrede drugih organa trbuha",
        "la": "Laesio traumatica organorum intraabdominalium aliorum"
    },
    {
        "icd": "S369",
        "rs": "Povreda neoznačenog organa trbuha",
        "la": "Laesio traumatica organi intraabdominalis non specificati"
    },
    {
        "icd": "S37",
        "rs": "Povreda mokraćnih organa i organa karlice",
        "la": "Laesio traumatica organorum urinariorum et pelvicis"
    },
    {
        "icd": "S370",
        "rs": "Povreda bubrega",
        "la": "Laesio traumatica renis"
    },
    {
        "icd": "S371",
        "rs": "Povreda mokraćovoda bubrega",
        "la": "Laesio traumatica ureteris"
    },
    {
        "icd": "S372",
        "rs": "Povreda mokraćne bešike",
        "la": "Laesio traumatica vesicae urinariae"
    },
    {
        "icd": "S373",
        "rs": "Povreda mokraćovoda bešike",
        "la": "Laesio traumatica urethrae"
    },
    {
        "icd": "S374",
        "rs": "Povreda jajnika",
        "la": "Laesio traumatica ovarii"
    },
    {
        "icd": "S375",
        "rs": "Povreda jajovoda",
        "la": "Laesio traumatica tubae uterinae"
    },
    {
        "icd": "S376",
        "rs": "Povreda materice",
        "la": "Laesio traumatica uteri"
    },
    {
        "icd": "S377",
        "rs": "Povreda više organa karlice",
        "la": "Laesio traumatica organorum pelvis multiplicium"
    },
    {
        "icd": "S378",
        "rs": "Povreda drugih organa karlice",
        "la": "Laesio traumatica organorum pelvis aliorum"
    },
    {
        "icd": "S379",
        "rs": "Povreda neoznačenog organa karlice",
        "la": "Laesio traumatica organi pelvis, non specificati"
    },
    {
        "icd": "S38",
        "rs": "Povreda uzrokovana zdrobljavanjem – kraš i traumatska amputacija dela trbuha, slabine i karlice",
        "la": "Conquassatio et amputatio partis abdominis, lumbi et pelvis traumatica"
    },
    {
        "icd": "S380",
        "rs": "Povreda uzrokovana zdrobljavanjem spoljnih polnih organa",
        "la": "Conquassatio genitaliorum externorum"
    },
    {
        "icd": "S381",
        "rs": "Povreda uzrokovana zdrobljavanjem drugih i neoznačenih delova trbuha, slabine i karlice",
        "la": "Conquassatio partium abdominis, lumbi et pelvis aliarum et non specificatarum"
    },
    {
        "icd": "S382",
        "rs": "Traumatska amputacija spoljnih polnih organa",
        "la": "Amputatio traumatica genitaliorum externorum"
    },
    {
        "icd": "S383",
        "rs": "Traumatska amputacija drugih i neoznačenih delova trbuha, slabine i karlice",
        "la": "Amputatio traumatica partium abdominis, lumbi et pelvis aliarum et non specificatarum"
    },
    {
        "icd": "S39",
        "rs": "Druge i neoznačene povrede trbuha, slabine i karlice",
        "la": "Laesiones traumaticae abdominis, lumbi et pelvis aliae et non specificatae"
    },
    {
        "icd": "S390",
        "rs": "Povreda mišića i tetive trbuha, slabine i karlice",
        "la": "Laesio traumatica musculi et tendinis abdominis lumbi et pelvis"
    },
    {
        "icd": "S396",
        "rs": "Povreda organa trbuha sa organima karlice",
        "la": "Laesio traumatica organorum intraabdominalium cum organis pelvis"
    },
    {
        "icd": "S397",
        "rs": "Druge višestruke povrede trbuha, slabine i karlice",
        "la": "Laesiones traumaticae abdominis, lumbi et pelvis multiplices aliae"
    },
    {
        "icd": "S398",
        "rs": "Druge označene povrede trbuha, slabine i karlice",
        "la": "Laesiones traumaticae abdominis, lumbi et pelvis aliae, specificatae"
    },
    {
        "icd": "S399",
        "rs": "Povreda trbuha, slabine i karlice, neoznačena",
        "la": "Laesio traumatica abdominis, lumbi et pelvis, non specificata"
    },
    {
        "icd": "S40",
        "rs": "Površinska povreda u predelu ramena i nadlaktice",
        "la": "Laesio traumatica regionis deltoideae et brachii superficialis"
    },
    {
        "icd": "S400",
        "rs": "Nagnječenje u predelu ramena i nadlaktice",
        "la": "Contusio regionis deltoideae et brachii"
    },
    {
        "icd": "S407",
        "rs": "Višestruke površinske povrede u predelu ramena i nadlaktice",
        "la": "Laesiones traumaticae regionis deltoideae et brachii superficiales multiplices"
    },
    {
        "icd": "S408",
        "rs": "Druge površinske povrede u predelu ramena i nadlaktice",
        "la": "Laesiones traumaticae regionis deltoideae et brachii superficiales aliae"
    },
    {
        "icd": "S409",
        "rs": "Površinska povreda u predelu ramena i nadlaktice, neoznačena",
        "la": "Laesio traumatica regionis deltoideae et brachii, superficialis, non specificata"
    },
    {
        "icd": "S41",
        "rs": "Otvorena rana u predelu ramena i nadlaktice",
        "la": "Vulnus apertum regionis deltoideae et brachii"
    },
    {
        "icd": "S410",
        "rs": "Otvorena rana u predelu ramena",
        "la": "Vulnus apertum regionis deltoideae"
    },
    {
        "icd": "S411",
        "rs": "Otvorena rana nadlaktice",
        "la": "Vulnus apertum brachii"
    },
    {
        "icd": "S417",
        "rs": "Više otvorenih rana u predelu ramena i nadlaktice",
        "la": "Vulnera aperta regionis deltoideae et brachii multiplicia"
    },
    {
        "icd": "S418",
        "rs": "Otvorena rana drugih i neoznačenih delova ramenog pojasa",
        "la": "Vulnus apertum partium cinguli pectoralis aliarum et non specificatarum"
    },
    {
        "icd": "S42",
        "rs": "Prelom u predelu ramena i nadlaktice",
        "la": "Fractura regionis deltoideae et brachii"
    },
    {
        "icd": "S420",
        "rs": "Prelom ključnjače",
        "la": "Fractura claviculae"
    },
    {
        "icd": "S421",
        "rs": "Prelom lopatice",
        "la": "Fractura scapulae"
    },
    {
        "icd": "S422",
        "rs": "Prelom gornjeg okrajka ramenjače",
        "la": "Fractura humeri, extremitatis proximalis"
    },
    {
        "icd": "S423",
        "rs": "Prelom tela ramenjače",
        "la": "Fractura corporis humeri"
    },
    {
        "icd": "S424",
        "rs": "Prelom donjeg okrajka ramenjače",
        "la": "Fractura humeri, extremitatis distalis"
    },
    {
        "icd": "S427",
        "rs": "Višestruki prelom ključnjače, lopatične kosti i ramenjače",
        "la": "Fractura claviculae, scapulae et humeri multiplex"
    },
    {
        "icd": "S428",
        "rs": "Drugi prelom u predelu ramena i nadlaktice",
        "la": "Fractura regionis deltoideae et brachii alia"
    },
    {
        "icd": "S429",
        "rs": "Prelom u predelu ramenog pojasa, neoznačen",
        "la": "Fractura regionis deltoidae, non specificata"
    },
    {
        "icd": "S43",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza ramenog pojasa",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum cinguli extremitatis superioris"
    },
    {
        "icd": "S430",
        "rs": "Iščašenje ramenog zgloba",
        "la": "Luxatio articuli humeroscapularis"
    },
    {
        "icd": "S431",
        "rs": "Iščašenje natplećno-ključnjačnog zgloba",
        "la": "Luxatio articuli acromioclavicularis"
    },
    {
        "icd": "S432",
        "rs": "Iščašenje grudno-ključnjačnog zgloba",
        "la": "Luxatio articuli sternoclavicularis"
    },
    {
        "icd": "S433",
        "rs": "Iščašenje drugih i neoznačenih delova ramenog pojasa",
        "la": "Luxatio partium cinguli extremitatis superioris aliarum et non specificatarum"
    },
    {
        "icd": "S434",
        "rs": "Uganuće i istegnuće ramenog zgloba",
        "la": "Distorsio et distensio articuli humeroscapularis"
    },
    {
        "icd": "S435",
        "rs": "Uganuće i istegnuće natplećno-ključnjačnog zgloba",
        "la": "Distorsio et distensio articuli acromioclavicularis"
    },
    {
        "icd": "S436",
        "rs": "Uganuće i istegnuće grudno-ključnjačnog zgloba",
        "la": "Distorsio et distensio articuli sternoclavicularis"
    },
    {
        "icd": "S437",
        "rs": "Uganuće i istegnuće drugih i neoznačenih delova ramenog pojasa",
        "la": "Distorsio et distensio partium cinguli extremitatis superioris aliarum, non specificatarum"
    },
    {
        "icd": "S44",
        "rs": "Povreda živaca u predelu ramena i nadlaktice",
        "la": "Laesio traumatica nervorum regionis deltoideae et brachii"
    },
    {
        "icd": "S440",
        "rs": "Povreda lakatnog živca u predelu nadlaktice",
        "la": "Laesio traumatica nervi ulnaris regionis brachii"
    },
    {
        "icd": "S441",
        "rs": "Povreda središnjeg živca u predelu nadlaktice",
        "la": "Laesio traumatica nervi mediani regionis brachii"
    },
    {
        "icd": "S442",
        "rs": "Povreda žbičnog živca u predelu nadlaktice",
        "la": "Laesio traumatica nervi radialis regionis brachii"
    },
    {
        "icd": "S443",
        "rs": "Povreda pazušnog živca",
        "la": "Laesio traumatica nervi axillaris"
    },
    {
        "icd": "S444",
        "rs": "Povreda mišićno-kožnog živca",
        "la": "Laesio traumatica nervi musculocutanei"
    },
    {
        "icd": "S445",
        "rs": "Povreda senzitivnog kožnog živca u predelu ramena i nadlaktice",
        "la": "Laesio traumatica nervi cutanei sensitivi regionis deltoideae et brachii"
    },
    {
        "icd": "S447",
        "rs": "Povreda više živaca u predelu ramena i nadlaktice",
        "la": "Laesio traumatica nervorum regionis deltoideae et brachii multiplicium"
    },
    {
        "icd": "S448",
        "rs": "Povreda drugih živaca u predelu ramena i nadlaktice",
        "la": "Laesio traumatica nervorum regionis axillaris et brachii aliorum"
    },
    {
        "icd": "S449",
        "rs": "Povreda neoznačenog živca u predelu ramena i nadlaktice",
        "la": "Laesio traumatica nervi regionis deltoideae et brachii non specificati"
    },
    {
        "icd": "S45",
        "rs": "Povreda krvnih sudova u predelu ramena i nadlaktice",
        "la": "Laesio traumatica vasorum regionis deltoideae et brachii"
    },
    {
        "icd": "S450",
        "rs": "Povreda pazušne arterije",
        "la": "Laesio traumatica arteriae axillaris"
    },
    {
        "icd": "S451",
        "rs": "Povreda nadlaktne arterije",
        "la": "Laesio traumatica arteriae brachialis"
    },
    {
        "icd": "S452",
        "rs": "Povreda pazušne vene ili nadlaktne vene",
        "la": "Laesio traumatica venae axillaris sive venae brachialis"
    },
    {
        "icd": "S453",
        "rs": "Povreda površinske vene u predelu ramena i nadlaktice",
        "la": "Laesio traumatica venae superficialis, regionis deltoideae et brachii"
    },
    {
        "icd": "S457",
        "rs": "Povreda više krvnih sudova u predelu ramena i nadlaktice",
        "la": "Laesio traumatica vasorum regionis deltoideae et brachii multiplicium"
    },
    {
        "icd": "S458",
        "rs": "Povreda drugih krvnih sudova u predelu ramena i nadlaktice",
        "la": "Laesio traumatica vasorum regionis deltoideae et brachii aliorum"
    },
    {
        "icd": "S459",
        "rs": "Povreda neoznačenog krvnog suda u predelu ramena i nadlaktice",
        "la": "Laesio traumatica vasis regionis deltoideae et brachii, non specificati"
    },
    {
        "icd": "S46",
        "rs": "Povrede mišića i tetive u predelu ramena i nadlaktice",
        "la": "Laesio traumatica musculi et tendinis regionis deltoideae et brachii"
    },
    {
        "icd": "S460",
        "rs": "Povreda tetive mišića okretača zgloba ramena",
        "la": "Laesio traumatica tendinis musculi rotatoris articuli humeri"
    },
    {
        "icd": "S461",
        "rs": "Povreda mišića i tetive duge glave dvoglavog mišića nadlaktice",
        "la": "Laesio traumatica capitis longi(et tendinis eius) musculi biciptis brachii"
    },
    {
        "icd": "S462",
        "rs": "Druga povreda dvoglavog mišića nadlaktice i njegove tetive",
        "la": "Laesio traumatica musculi(et tendinis eius) bicipitis brachii alia"
    },
    {
        "icd": "S463",
        "rs": "Povreda troglavog mišića nadlaktice i njegove tetive",
        "la": "Laesio traumatica musculi et tendinis musculi tricipitis brachii"
    },
    {
        "icd": "S467",
        "rs": "Povreda više mišića i tetiva u predelu ramena i nadlaktice",
        "la": "Laesio traumatica musculorum et tendinis regionis deitoideae et brachii multiplicium"
    },
    {
        "icd": "S468",
        "rs": "Povreda drugih mišića i tetiva u predelu ramena i nadlaktice",
        "la": "Laesio traumatica musculorum et tendinum regionis deitoideae et brachii aliorum"
    },
    {
        "icd": "S469",
        "rs": "Povreda neoznačenog mišića i tetive u predelu ramena i nadlaktice",
        "la": "Laesio traumatica musculi et tendinis non specificatae, regionis deltoideae et brachii"
    },
    {
        "icd": "S47",
        "rs": "Povreda uzrokovana zdrobljavanjem u predelu ramena i nadlaktice",
        "la": "Conquassatio regionis deltoideae et brachii"
    },
    {
        "icd": "S48",
        "rs": "Traumatska amputacija u predelu ramena i nadlaktice",
        "la": "Amputatio traumatica regionis deltoideae et brachii"
    },
    {
        "icd": "S480",
        "rs": "Traumatska amputacija zgloba ramena",
        "la": "Amputatio traumatica articuli humeroscapularis"
    },
    {
        "icd": "S481",
        "rs": "Traumatska amputacija u nivou između ramena i lakta",
        "la": "Amputatio traumatica inter regionem deltoideam et cubitum"
    },
    {
        "icd": "S489",
        "rs": "Traumatska amputacija ramena i nadlaktice, neoznačena",
        "la": "Amputatio traumatica humeri et brachii, non specificata"
    },
    {
        "icd": "S49",
        "rs": "Druge neoznačene povrede u predelu ramena i nadlaktice",
        "la": "Laesiones traumaticae regionis deltoideae et brachii aliae, non specificatae"
    },
    {
        "icd": "S497",
        "rs": "Višestruke povrede u predelu ramena i nadlaktice",
        "la": "Laesiones traumaticae regionis deltoideae et brachii multiplices"
    },
    {
        "icd": "S498",
        "rs": "Druge označene povrede u predelu ramena i nadlaktice",
        "la": "Laesiones traumaticae regionis deltoideae et brachii aliae, specificatae"
    },
    {
        "icd": "S499",
        "rs": "Povreda u predelu ramena i nadlaktice, neoznačena",
        "la": "Laesio traumatica regionis deltoideae et brachii, non specificata"
    },
    {
        "icd": "S50",
        "rs": "Površinska povreda podlaktice",
        "la": "Laesio traumatica antebrachii, superficialis"
    },
    {
        "icd": "S500",
        "rs": "Nagnječenje lakta",
        "la": "Contusio cubiti"
    },
    {
        "icd": "S501",
        "rs": "Nagnječenje drugih neoznačenih delova podlaktice",
        "la": "Contusio partium antebrachii aliarum, non specificatarum"
    },
    {
        "icd": "S507",
        "rs": "Višestruka površinska povreda podlaktice",
        "la": "Laesio traumatica antebrachii superficialis multiplex"
    },
    {
        "icd": "S508",
        "rs": "Druge površinske povrede podlaktice",
        "la": "Leasiones traumaticae antebrachii superficialis aliae"
    },
    {
        "icd": "S509",
        "rs": "Površinska povreda podlaktice, neoznačena",
        "la": "Laesio traumatica antebrachii, superficialis, non specificata"
    },
    {
        "icd": "S51",
        "rs": "Otvorena rana podlaktice",
        "la": "Vulnus apertum antebrachii"
    },
    {
        "icd": "S510",
        "rs": "Otvorena rana lakta",
        "la": "Vulnus apertum cubiti"
    },
    {
        "icd": "S517",
        "rs": "Višestruka otvorena rana podlaktice",
        "la": "Vulnus apertum antebrachii multiplex"
    },
    {
        "icd": "S518",
        "rs": "Druga otvorena rana podlaktice",
        "la": "Vulnus apertum antebrachii aliud"
    },
    {
        "icd": "S519",
        "rs": "Otvorena rana neoznačenog dela podlaktice",
        "la": "Vulnus apertum partis antebrachii non specificatae"
    },
    {
        "icd": "S52",
        "rs": "Prelom podlaktice",
        "la": "Fractura antebrachii"
    },
    {
        "icd": "S520",
        "rs": "Prelom gornjeg okrajka lakatne kosti",
        "la": "Fractura ulnae, extremitas proximalis"
    },
    {
        "icd": "S521",
        "rs": "Prelom gornjeg okrajka žbice",
        "la": "Fractura radii, extremitas proximalis"
    },
    {
        "icd": "S522",
        "rs": "Prelom tela lakatne kosti",
        "la": "Fractura corporis ulnae"
    },
    {
        "icd": "S523",
        "rs": "Prelom tela žbice",
        "la": "Fractura corporis radii"
    },
    {
        "icd": "S524",
        "rs": "Prelom tela lakatne kosti i žbice",
        "la": "Fractura corporis ulnae et radii"
    },
    {
        "icd": "S525",
        "rs": "Prelom donjeg okrajka žbice",
        "la": "Fractura radii, extremitas distalis"
    },
    {
        "icd": "S526",
        "rs": "Prelom donjeg okrajka lakatne kosti i žbice",
        "la": "Fractura ulnae et radii, extremitatis distalis"
    },
    {
        "icd": "S527",
        "rs": "Višestruki prelom podlaktice",
        "la": "Fractura antebrachii multiplex"
    },
    {
        "icd": "S528",
        "rs": "Drugi prelom podlaktice",
        "la": "Fractura antebrachii alia"
    },
    {
        "icd": "S529",
        "rs": "Prelom neoznačenog dela podlaktice",
        "la": "Fractura partis antebrachii non specificata"
    },
    {
        "icd": "S53",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza lakta",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum cubiti"
    },
    {
        "icd": "S530",
        "rs": "Iščašenje glave žbice",
        "la": "Luxatio capitis radii"
    },
    {
        "icd": "S531",
        "rs": "Iščašenje lakta, neoznačeno",
        "la": "Luxatio cubiti, non specificata"
    },
    {
        "icd": "S532",
        "rs": "Traumatska ruptura žbične pobočne veze",
        "la": "Ruptura traumatica ligamenti radialis collateralis"
    },
    {
        "icd": "S533",
        "rs": "Traumatska ruptura lakatne pobočne veze",
        "la": "Ruptura traumatica ligamenti ulnaris collateralis"
    },
    {
        "icd": "S534",
        "rs": "Uganuće i istegnuće lakta",
        "la": "Distorsio et distensio cubiti"
    },
    {
        "icd": "S54",
        "rs": "Povreda živaca podlaktice",
        "la": "Laesio traumatica nervorum antebrachii"
    },
    {
        "icd": "S540",
        "rs": "Povreda lakatnog živca u predelu podlaktice",
        "la": "Laesio traumatica nervi ulnaris regionis antebrachii"
    },
    {
        "icd": "S541",
        "rs": "Povreda središnjeg živca u predelu podlaktice",
        "la": "Laesio traumatica nervi mediani regionis antebrachii"
    },
    {
        "icd": "S542",
        "rs": "Povreda zbičnog živca u predelu podlaktice",
        "la": "Laesio traumatica nervi radialis regionis antebrachii"
    },
    {
        "icd": "S543",
        "rs": "Povreda kožnog senzitivnog živca u predelu podlaktice",
        "la": "Laesio traumatica nervi cutanei sensitivi regionis antebrachii"
    },
    {
        "icd": "S547",
        "rs": "Povreda više živaca u predelu podlaktice",
        "la": "Laesio traumatica nervorum regionis antebrachii multiplicium"
    },
    {
        "icd": "S548",
        "rs": "Povreda drugih živaca u predelu podlaktice",
        "la": "Laesio traumatica nervorum regionis antebrachii aliorum"
    },
    {
        "icd": "S549",
        "rs": "Povreda neoznačenog živca u predelu podlaktice",
        "la": "Laesio traumatica nervi regionis antebrachii, non specificati"
    },
    {
        "icd": "S55",
        "rs": "Povreda krvnih sudova u predelu podlaktice",
        "la": "Laesio traumatica vasorum regionis antebrachii"
    },
    {
        "icd": "S550",
        "rs": "Povreda lakatne arterije u predelu podlaktice",
        "la": "Laesio traumatica arteriae ulnaris regionis antebrachii"
    },
    {
        "icd": "S551",
        "rs": "Povreda žbične arterije u predelu podlaktice",
        "la": "Laesio traumatica arteriae radialis regionis antebrachii"
    },
    {
        "icd": "S552",
        "rs": "Povreda vene u predelu podlaktice",
        "la": "Laesio traumatica venae regionis antebrachii"
    },
    {
        "icd": "S557",
        "rs": "Povreda više krvnih sudova u predelu podlaktice",
        "la": "Laesio traumatica vasorum multiplicium regionis antebrachii"
    },
    {
        "icd": "S558",
        "rs": "Druga povreda krvnih sudova u predelu podlaktice",
        "la": "Laesio traumatica vasorum regionis antebrachii alia"
    },
    {
        "icd": "S559",
        "rs": "Povreda neoznačenog krvnog suda u predelu podlaktice",
        "la": "Laesio traumatica vasis regionis antebrachii, non specificati"
    },
    {
        "icd": "S56",
        "rs": "Povreda mišića i tetive u predelu podlaktice",
        "la": "Laesio traumatica musculi et tendinis regionis antebrachii"
    },
    {
        "icd": "S560",
        "rs": "Povreda mišića pregibača palca i njegove tetive u predelu podlaktice",
        "la": "Laesio traumatica musculi (et tendinis eius) flexoris pollicis longi regionis antebrachii"
    },
    {
        "icd": "S561",
        "rs": "Povreda mišića pregibača prstiju i njegove tetive u predelu podlaktice",
        "la": "Laesio traumatica musculi (et tendinis eius) flexoris digitorum regionis antebrachii"
    },
    {
        "icd": "S562",
        "rs": "Povreda drugog mišića savijača i njegove tetive u predelu podlaktice",
        "la": "Laesio traumatica musculi (et tendinis eius) flexoris arterius regionis antebrachii"
    },
    {
        "icd": "S563",
        "rs": "Povreda mišića opružača ili odmicača palca i njihovih tetiva u predelu podlaktice",
        "la": "Laesio traumatica musculorum (et tendinum eorum) extensorum sive abductorum pollicis regionis antebrachii"
    },
    {
        "icd": "S564",
        "rs": "Povreda mišića opružača prsta (prstiju) i njegove tetive u predelu podlaktice",
        "la": "Laesio traumatica musculi (et tendinius eius) extensoris digiti (dogitorum) regionis antebrachii"
    },
    {
        "icd": "S565",
        "rs": "Druga povreda mišića opružača i njegove tetive u predelu podlaktice",
        "la": "Laesio traumatica musculi (et tendinis eius) extensoris regionis antebrachii alia"
    },
    {
        "icd": "S567",
        "rs": "Povreda više mišića i tetiva u predelu podlaktice",
        "la": "Laesio traumatica musculorum et tendinum regionis antebrachii multiplicium"
    },
    {
        "icd": "S568",
        "rs": "Povreda drugih neoznačenih mišića i tetiva u predelu podlaktice",
        "la": "Laesio traumatica musculorum et tendinum regionis antebrachii aliorum, non specificatorum"
    },
    {
        "icd": "S57",
        "rs": "Rana podlaktice uzrokovana zdrobljavanjem – kraš",
        "la": "Conquassatio antebrachii"
    },
    {
        "icd": "S570",
        "rs": "Rana lakta uzrokovana zdrobljavanjem",
        "la": "Conquassatio cubiti"
    },
    {
        "icd": "S578",
        "rs": "Druga rana podlaktice uzrokovana zdrobljavanjem",
        "la": "Conquassatio antebrachii alia"
    },
    {
        "icd": "S579",
        "rs": "Rana uzrokovana zdrobljavanjem neoznačenog dela podlaktice",
        "la": "Conquassatio partis antebrachii non specificatae"
    },
    {
        "icd": "S58",
        "rs": "Traumatska amputacija podlaktice",
        "la": "Amputatio traumatica antebrachii"
    },
    {
        "icd": "S580",
        "rs": "Traumatska amputacija u nivou lakta",
        "la": "Amputatio traumatica regionis cubiti"
    },
    {
        "icd": "S581",
        "rs": "Traumatska amputacija u nivou između lakta i ručnog zgloba",
        "la": "Amputatio traumatica inter regionem cubiti et carpi"
    },
    {
        "icd": "S589",
        "rs": "Traumatska amputacija podlaktice, neoznačenog nivoa",
        "la": "Amputatio traumatica antebrachii, regione non specificata"
    },
    {
        "icd": "S59",
        "rs": "Druge neoznačene povrede podlaktice",
        "la": "Laesiones traumaticae antebrachii aliae, non specificatae"
    },
    {
        "icd": "S597",
        "rs": "Višestruke povrede podlaktice",
        "la": "Laesiones traumaticae antebrachii multiplices"
    },
    {
        "icd": "S598",
        "rs": "Druge označene povrede podlaktice",
        "la": "Laesiones traumaticae antebrachii aliae, specificatae"
    },
    {
        "icd": "S599",
        "rs": "Povreda podlaktice, neoznačena",
        "la": "Laesio traumatica antebrachii, non specificata"
    },
    {
        "icd": "S60",
        "rs": "Površinska povreda ručja i šake",
        "la": "Laesio traumatica carpi et manus, superficalis"
    },
    {
        "icd": "S600",
        "rs": "Nagnječenje prsta (prstiju) šake bez povrede nokta",
        "la": "Contusio digiti (digitorum) manus sine laesione unguis"
    },
    {
        "icd": "S601",
        "rs": "Nagnječenje prsta (prstiju) šake sa povredom nokta",
        "la": "Contusio digiti (digitorum) manus cum laesione unguis"
    },
    {
        "icd": "S602",
        "rs": "Drugo nagnječenje ručja i šake",
        "la": "Contusio carpi et manus alia"
    },
    {
        "icd": "S607",
        "rs": "Višestruka površinska povreda ručja i šake",
        "la": "Laesio traumatica carpi et manus superficialis multiplex"
    },
    {
        "icd": "S608",
        "rs": "Druge površinske povrede ručja i šake",
        "la": "Laesiones traumaticae carpi et manus, superficiales aliae"
    },
    {
        "icd": "S609",
        "rs": "Površinska povreda ručja i šake, neoznačena",
        "la": "Laesio traumatica carpi et manus, superficialis, non specificata"
    },
    {
        "icd": "S61",
        "rs": "Otvorena rana ručja i šake",
        "la": "Vulnus apertum carpi et manus"
    },
    {
        "icd": "S610",
        "rs": "Otvorena rana prsta (prstiju) šake, bez povrede nokta",
        "la": "Vulnus apertum digiti (digitorum) manus sine laesione unguis"
    },
    {
        "icd": "S611",
        "rs": "Otvorena rana prsta (prstiju) šake sa povredom nokta",
        "la": "Vulnus apertum digiti (digitorum) manus cum laesione unguis"
    },
    {
        "icd": "S617",
        "rs": "Višestruka otvorena rana ručja i šake",
        "la": "Vulnus apertum carpi et manus multiplex"
    },
    {
        "icd": "S618",
        "rs": "Otvorena rana drugih delova ručja i šake",
        "la": "Vulnus apertum partium carpi et manus aliarum"
    },
    {
        "icd": "S619",
        "rs": "Otvorena rana neoznačenog dela ručja i šake",
        "la": "Vulnus apertum partis carpi et manus, non specificatae"
    },
    {
        "icd": "S62",
        "rs": "Prelom ručja i šake",
        "la": "Fractura carpi et manus"
    },
    {
        "icd": "S620",
        "rs": "Prelom čunaste kosti šake",
        "la": "Fractura ossis navicularis (scaphoidis) manus"
    },
    {
        "icd": "S621",
        "rs": "Prelom neke druge kosti (kostiju) ručja",
        "la": "Fractura ossis carpi (ossium carpalium), alterius"
    },
    {
        "icd": "S622",
        "rs": "Prelom prve kosti doručja",
        "la": "Fractura ossis metacarpalis primi"
    },
    {
        "icd": "S623",
        "rs": "Prelom neke druge kosti doručja",
        "la": "Fractura ossis metacarpalis alterius"
    },
    {
        "icd": "S624",
        "rs": "Višestruki prelom kostiju doručja",
        "la": "Fractura ossis metacarpalis multiplex"
    },
    {
        "icd": "S625",
        "rs": "Prelom palca",
        "la": "Fractura pollicis"
    },
    {
        "icd": "S626",
        "rs": "Prelom nekog drugog prsta šake",
        "la": "Fractura digiti manus alterius"
    },
    {
        "icd": "S627",
        "rs": "Višestruki prelom prsta šake",
        "la": "Fractura digiti manus multiplex"
    },
    {
        "icd": "S628",
        "rs": "Prelom drugih neoznačenih delova ručja i šake",
        "la": "Fractura partium carpi et manus aliarum, non specificatarum"
    },
    {
        "icd": "S63",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza u predelu ručja i šake",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum regionis carpi et manus"
    },
    {
        "icd": "S630",
        "rs": "Iščašenje korena šake",
        "la": "Luxatio carpi"
    },
    {
        "icd": "S631",
        "rs": "Iščašenje prsta šake",
        "la": "Luxatio digiti manus"
    },
    {
        "icd": "S632",
        "rs": "Višestruko iščašenje prstiju",
        "la": "Luxatio digitorum multiplex"
    },
    {
        "icd": "S633",
        "rs": "Traumatska ruptura veze ručja",
        "la": "Ruptura traumatica ligamenti carpi"
    },
    {
        "icd": "S634",
        "rs": "Traumatska ruptura veze doručno-člankastog i međučlankastog zgloba",
        "la": "Ruptura traumatica ligamenti articuli metacarpophalangici et interphalangici manus"
    },
    {
        "icd": "S635",
        "rs": "Uganuće i istegnuće ručnog zgloba",
        "la": "Distorsio et distensio carpi"
    },
    {
        "icd": "S636",
        "rs": "Uganuće i istegnuće prsta (prstiju) šake",
        "la": "Distorsio et distensio digiti (digitorum) manus"
    },
    {
        "icd": "S637",
        "rs": "Uganuće i istegnuće drugih i neoznačenih delova šake",
        "la": "Distorsio et distensio partium manus aliarum et non specificatarum"
    },
    {
        "icd": "S64",
        "rs": "Povreda živaca ručja i šake",
        "la": "Laesio traumatica nervorum carpi et manus"
    },
    {
        "icd": "S640",
        "rs": "Povreda lakatnog živca u predelu ručja i šake",
        "la": "Laesio traumatica nervi ulnaris regionis carpi et manus"
    },
    {
        "icd": "S641",
        "rs": "Povreda središnjeg živca u predelu ručja i šake",
        "la": "Laesio traumatica nervi mediani regionis carpi et manus"
    },
    {
        "icd": "S642",
        "rs": "Povreda živca žbice u predelu ručja i šake",
        "la": "Laesio traumatica nervi radialis regionis carpi et manus"
    },
    {
        "icd": "S643",
        "rs": "Povreda živca palca",
        "la": "Laesio traumatica nervi pollicis"
    },
    {
        "icd": "S644",
        "rs": "Povreda živca nekog drugog prsta šake",
        "la": "Laesio traumatica nervi digiti manus, alterius"
    },
    {
        "icd": "S647",
        "rs": "Povreda više živaca u predelu ručja i šake",
        "la": "Laesio traumatica nervorum regionis carpi et manus multiplicium"
    },
    {
        "icd": "S648",
        "rs": "Povreda drugih živaca u predelu ručja i šake",
        "la": "Laesio traumatica nervorum regionis carpi et manus aliorum"
    },
    {
        "icd": "S649",
        "rs": "Povreda neoznačenog živca u predelu ručja i šake",
        "la": "Laesio traumatica nervi regionis carpi et manus, non specificati"
    },
    {
        "icd": "S65",
        "rs": "Povreda krvnih sudova u predelu ručja i šake",
        "la": "Laesio traumatica vasorum regionis carpi et manus"
    },
    {
        "icd": "S650",
        "rs": "Povreda lakatne arterije u predelu ručja i šake",
        "la": "Laesio traumatica arteriae ulnaris regionis carpi et manus"
    },
    {
        "icd": "S651",
        "rs": "Povreda arterije žbice u predelu ručja i šake",
        "la": "Laesio traumatica arteriae radialis regionis carpi et manus"
    },
    {
        "icd": "S652",
        "rs": "Povreda površinskog luka dlana",
        "la": "Laesio traumatica arcus palmaris superficialis"
    },
    {
        "icd": "S653",
        "rs": "Povreda dubokog luka dlana",
        "la": "Laesio traumatica arcus palmaris profundi"
    },
    {
        "icd": "S654",
        "rs": "Povreda krvnog (krvnih) suda (sudova) palca",
        "la": "Laesio traumatica vasis (vasorum) pollicis"
    },
    {
        "icd": "S655",
        "rs": "Povreda krvnog (krvnih) suda (sudova) nekog drugog prsta",
        "la": "Laesio traumatica vasis (vasorum) digiti alterius"
    },
    {
        "icd": "S657",
        "rs": "Povreda više krvnih sudova u predelu ručja i šake",
        "la": "Laesio traumatica vasorum regionis carpi et manus multiplicium"
    },
    {
        "icd": "S658",
        "rs": "Povreda drugih krvnih sudova u predelu ručja i šake",
        "la": "Laesio traumatica vasorum regionis carpi et manus aliorum"
    },
    {
        "icd": "S659",
        "rs": "Povreda neoznačenog krvnog suda u predelu ručja i šake",
        "la": "Laesio traumatica vasis regionis carpi et manus, non specificati"
    },
    {
        "icd": "S66",
        "rs": "Povreda mišića i tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi et tendinis regionis carpi et manus"
    },
    {
        "icd": "S660",
        "rs": "Povreda dugog mišića pregibača palca i njegove tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi (et tendinis eius) flexoris pollicis longi regionis carpi et manus"
    },
    {
        "icd": "S661",
        "rs": "Povreda mišića pregibača nekog drugog prsta i njegove tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi (et tendinis eius) flexoris digiti regionis carpi et manus alterius"
    },
    {
        "icd": "S662",
        "rs": "Povreda mišića opružača palca i njegove tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi (et tendinis eius) extensoris pollicis regionis carpi et manus"
    },
    {
        "icd": "S663",
        "rs": "Povreda mišića opružača drugog prsta i njegove tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi (et tendinis eius) extensoris digiti alterius regionis carpi et manus"
    },
    {
        "icd": "S664",
        "rs": "Povreda unutrašnjeg mišića palca i njegove tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi (et tendinis eius) pollicis brevis regionis carpi et manus"
    },
    {
        "icd": "S665",
        "rs": "Povreda unutrašnjeg mišića nekog drugog prsta i njegove tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi (et tendinis eius) interossei digiti alterius regionis carpi et manus"
    },
    {
        "icd": "S666",
        "rs": "Povrede više mišića pregibača i njihovih tetiva u predelu ručja i šake",
        "la": "Laesio traumatica musculorum (et tendinis eorum) flexorum, regionis carpi et manus multiplicium"
    },
    {
        "icd": "S667",
        "rs": "Povreda više mišića opružača i njihovih tetiva u predelu ručja i šake",
        "la": "Laesio traumatica musculorum (et tendinis eorum) extensorum regionis carpi et manus multiplicium"
    },
    {
        "icd": "S668",
        "rs": "Povreda drugih mišića i njihovih tetiva u predelu ručja i šake",
        "la": "Laesio traumatica musculorum (et tendinum eorum) regionis carpi et manus aliorum"
    },
    {
        "icd": "S669",
        "rs": "Povreda neoznačenog mišića i njegove tetive u predelu ručja i šake",
        "la": "Laesio traumatica musculi (et tendinis eius) regionis carpi et manus, non specificati"
    },
    {
        "icd": "S67",
        "rs": "Rana uzrokovana zdrobljavanjem – kraš ručja i šake",
        "la": "Conquasatio carpi et manus"
    },
    {
        "icd": "S670",
        "rs": "Rana uzrokovana zdrobljavanjem palca i nekog drugog prsta",
        "la": "Conquasatio pollicis et digiti alterius"
    },
    {
        "icd": "S678",
        "rs": "Rana uzrokovana zdrobljavanjem drugih neoznačenih delova ručja i šake",
        "la": "Conquasatio partium carpi et manus aliarum, non specificatarum"
    },
    {
        "icd": "S68",
        "rs": "Traumatska amputacija ručja i šake",
        "la": "Amputatio traumatica carpi et manus"
    },
    {
        "icd": "S680",
        "rs": "Traumatska amputacija palca (potpuna)(delimična)",
        "la": "Amputatio traumatica pollicis (completa)(partiallis)"
    },
    {
        "icd": "S681",
        "rs": "Traumatska amputacija drugog pojedinačnog prsta (potpuna)(delimična)",
        "la": "Amputatio traumatica digiti alterius (completa)(partialis)"
    },
    {
        "icd": "S682",
        "rs": "Traumatska amputacija dva ili više samo prstiju (potpuna)(delimična)",
        "la": "Amputatio traumatica digitorum multiplicium (completa)(partialis)"
    },
    {
        "icd": "S683",
        "rs": "Udružena traumatska amputacija (dela) prsta(-iju) sa drugim delovima ručja i šake",
        "la": "Amputatio traumatica (partis) digiti (digitorum) cum partibus aliis carpi et manus combinata"
    },
    {
        "icd": "S684",
        "rs": "Traumatska amputacija šake u nivou ručnog zgloba",
        "la": "Amputatio traumatica manus in regione carpi traumatica"
    },
    {
        "icd": "S688",
        "rs": "Traumatska amputacija drugih delova ručja i šake",
        "la": "Amputatio traumatica partium carpi et manus aliarum"
    },
    {
        "icd": "S689",
        "rs": "Traumatska amputacija ručja i šake, neoznačenog nivoa",
        "la": "Amputatio traumatica carpi et manus, regionis non specificatae"
    },
    {
        "icd": "S69",
        "rs": "Druge i neoznačene povrede ručja i šake",
        "la": "Laesiones traumaticae carpi et manus aliae et non specificatae"
    },
    {
        "icd": "S697",
        "rs": "Višestruka povreda ručja i šake",
        "la": "Laesio traumatica carpi et manus multiplex"
    },
    {
        "icd": "S698",
        "rs": "Druge označene povrede ručja i šake",
        "la": "Laesiones traumaticae carpi et manus aliae, specificatae"
    },
    {
        "icd": "S699",
        "rs": "Povreda ručja i šake u neoznačenom predelu",
        "la": "Laesio traumatica carpi et manus regionis non specificatae"
    },
    {
        "icd": "S70",
        "rs": "Površinska povreda u predelu kuka i butine",
        "la": "Laesio traumatica regionis coxae et femoris superficialis"
    },
    {
        "icd": "S700",
        "rs": "Nagnječenje kuka",
        "la": "Contusio coxae"
    },
    {
        "icd": "S701",
        "rs": "Nagnječenje butine",
        "la": "Contusio femoris"
    },
    {
        "icd": "S707",
        "rs": "Višestruka površinska povreda u predelu kuka i butine",
        "la": "Laesio traumatica regionis coxae et femoris, superficialis, multiplex"
    },
    {
        "icd": "S708",
        "rs": "Druge površinske povrede u predelu kuka i butine",
        "la": "Laesiones traumaticae regionis coxae et femoris superficiales aliae"
    },
    {
        "icd": "S709",
        "rs": "Površinska povreda u predelu kuka i butine, neoznačena",
        "la": "Laesio traumatica regionis coxae et femoris, superficialis, non specificata"
    },
    {
        "icd": "S71",
        "rs": "Otvorena rana u predelu kuka i butine",
        "la": "Vulnus apertum regionis coxae et femoris"
    },
    {
        "icd": "S710",
        "rs": "Otvorena rana kuka",
        "la": "Vulnus apertum coxae"
    },
    {
        "icd": "S711",
        "rs": "Otvorena rana butine",
        "la": "Vulnus apertum femoris"
    },
    {
        "icd": "S717",
        "rs": "Višestruka otvorena rana u predelu kuka i butine",
        "la": "Vulnus apertum regionis coxae et femoris multiplex"
    },
    {
        "icd": "S718",
        "rs": "Otvorena rana drugih neoznačenih delova karličnog pojasa",
        "la": "Vulnus apertum partium cinguli pelvici aliarum, non specificatarum"
    },
    {
        "icd": "S72",
        "rs": "Prelom butnjače",
        "la": "Fractura femoris"
    },
    {
        "icd": "S720",
        "rs": "Prelom vrata butnjače",
        "la": "Fractura colli femoris"
    },
    {
        "icd": "S721",
        "rs": "Pretrohanterni prelom",
        "la": "Fractura praetrochanterica"
    },
    {
        "icd": "S722",
        "rs": "Prelom ispod trohantera",
        "la": "Fractura subtrochanterica"
    },
    {
        "icd": "S723",
        "rs": "Prelom tela butnjače",
        "la": "Fractura corporis femoris"
    },
    {
        "icd": "S724",
        "rs": "Prelom donjeg okrajka butnjače",
        "la": "Fractura femoris extremitas distalis"
    },
    {
        "icd": "S727",
        "rs": "Višestruki prelom butnjače",
        "la": "Fractura femoris multiplex"
    },
    {
        "icd": "S728",
        "rs": "Prelomi drugih delova butnjače",
        "la": "Fracturae partium femoris aliarum"
    },
    {
        "icd": "S729",
        "rs": "Prelom neoznačenog dela butnjače",
        "la": "Fractura femoris, pars non specificata"
    },
    {
        "icd": "S73",
        "rs": "Iščašenje, uganuće i istegnuće zgloba i veza kuka",
        "la": "Luxatio, distorsio et distensio articuli et ligamentorum coxae"
    },
    {
        "icd": "S730",
        "rs": "Iščašenje kuka",
        "la": "Luxatio coxae"
    },
    {
        "icd": "S731",
        "rs": "Uganuće i iščašenje kuka",
        "la": "Distorsio et distensio coxae"
    },
    {
        "icd": "S74",
        "rs": "Povreda živaca u predelu kuka i butine",
        "la": "Laesio traumatica nervorum regionis coxae et femoris"
    },
    {
        "icd": "S740",
        "rs": "Povreda sedalnog živca u predelu kuka i butine",
        "la": "Laesio traumatica nervi ischiadici regionis coxae et femoris"
    },
    {
        "icd": "S741",
        "rs": "Povreda butnog živca u predelu kuka i butine",
        "la": "Laesio traumatica nervi femoralis regionis coxae et femoris"
    },
    {
        "icd": "S742",
        "rs": "Povreda kožnog senzitivnog živca u predelu kuka i butine",
        "la": "Laesio traumatica nervi cutanei sensitivi regionis coxae et femoris"
    },
    {
        "icd": "S747",
        "rs": "Povreda više živaca u predelu kuka i butine",
        "la": "Laesio traumatica nervorum regionis coxae et femoris multiplicium"
    },
    {
        "icd": "S748",
        "rs": "Povreda drugih živaca u predelu kuka i butine",
        "la": "Laesio traumatica nervorum regionis coxae et femoris aliorum"
    },
    {
        "icd": "S749",
        "rs": "Povreda neoznačenog živca u predelu kuka i butine",
        "la": "Laesio traumatica nervi regionis coxae et femoris, non specificati"
    },
    {
        "icd": "S75",
        "rs": "Povreda krvnih sudova u predelu kuka i butine",
        "la": "Laesio traumatica vasorum regionis coxae et femoris"
    },
    {
        "icd": "S750",
        "rs": "Povreda butne arterije",
        "la": "Laesio traumatica arteriae femoris"
    },
    {
        "icd": "S751",
        "rs": "Povreda vene buta u predelu kuka i butine",
        "la": "Laesio traumatica venae femoralis regionis coxae et femoris"
    },
    {
        "icd": "S752",
        "rs": "Povreda velike površinske vene noge u predelu kuka i butine",
        "la": "Laesio traumatica venae saphenae magnae regionis coxae et femoris"
    },
    {
        "icd": "S757",
        "rs": "Povreda više krvnih sudova u predelu kuka i butine",
        "la": "Laesio traumatica vasorum regionis coxae et femoris multiplicium"
    },
    {
        "icd": "S758",
        "rs": "Povreda drugih krvnih sudova u predelu kuka i butine",
        "la": "Laesio traumatica vasorum regionis coxae et femoris aliorum"
    },
    {
        "icd": "S759",
        "rs": "Povreda neoznačenog krvnog suda u predelu kuka i butine",
        "la": "Laesio traumatica vasis regionis coxae et femoris, non specificati"
    },
    {
        "icd": "S76",
        "rs": "Povreda mišića i tetive u predelu kuka i butine",
        "la": "Laesio traumatica musculi et tendinis regionis coxae et femoris"
    },
    {
        "icd": "S760",
        "rs": "Povreda mišića i tetive kuka",
        "la": "Laesio traumatica musculi et tendinis coxae"
    },
    {
        "icd": "S761",
        "rs": "Povreda četvoroglavog mišica natkolenice i njegove tetive",
        "la": "Laesio traumatica musculi (et tendinis eius) quadricipitis femoris"
    },
    {
        "icd": "S762",
        "rs": "Povreda mišića primicača butine i njegove tetive",
        "la": "Laesio traumatica musculi (et tendinis eius) adductoris femoris"
    },
    {
        "icd": "S763",
        "rs": "Povreda mišića i tetive zadnje lože u predelu butine",
        "la": "Laesio traumatica musculi (et tendinis eius) regionis femoris posterioris"
    },
    {
        "icd": "S764",
        "rs": "Povreda drugih neoznačenih mišića i tetiva butine",
        "la": "Laesio traumatica musculorum et tendinum femoris aliorum, non specificatarum"
    },
    {
        "icd": "S767",
        "rs": "Povreda više mišića i tetiva u predelu kuka i butine",
        "la": "Laesio traumatica musoulorum et tendinum regionis coxae et femoris multiplicium"
    },
    {
        "icd": "S77",
        "rs": "Rana uzrokovana zdrobljavanjem – kraš kuka i butine",
        "la": "Conquassatio coxae et conquassatio femoris"
    },
    {
        "icd": "S770",
        "rs": "Rana uzrokovana zdrobljavanjem kuka",
        "la": "Conquassatio coxae"
    },
    {
        "icd": "S771",
        "rs": "Rana uzrokovana zdrobljavanjem butine",
        "la": "Conquassatio femoris"
    },
    {
        "icd": "S772",
        "rs": "Rana uzrokovana zdrobljavanjem kuka i butine",
        "la": "Conquassatio coxae et femoris"
    },
    {
        "icd": "S78",
        "rs": "Traumatska amputacija kuka i butine",
        "la": "Amputatio traumatica coxae et femoris"
    },
    {
        "icd": "S780",
        "rs": "Traumatska amputacija u zglobu kuka",
        "la": "Amputatio traumatica ad articulationem coxae"
    },
    {
        "icd": "S781",
        "rs": "Traumatska amputacija u nivou između kuka i kolena",
        "la": "Amputatio traumatica inter regionem coxae et genus"
    },
    {
        "icd": "S789",
        "rs": "Traumatska amputacija kuka i butine, neoznačenog nivoa",
        "la": "Amputatio traumatica coxae et femoris, regionis non specificatae"
    },
    {
        "icd": "S79",
        "rs": "Druge neoznačene povrede kuka i butine",
        "la": "Laesiones traumaticae coxae et femoris aliae, non specificatae"
    },
    {
        "icd": "S797",
        "rs": "Višestruke povrede kuka i butine",
        "la": "Laesiones traumaticae coxae et femoris multiplices"
    },
    {
        "icd": "S798",
        "rs": "Druge označene povrede kuka i butine",
        "la": "Laesiones traumaticae coxae et femoris aliae, specificatae"
    },
    {
        "icd": "S799",
        "rs": "Povreda kuka i butine, neoznačena",
        "la": "Laesio traumatica coxae et femoris, non specificata"
    },
    {
        "icd": "S80",
        "rs": "Površinska povreda potkolenice",
        "la": "Laesio traumatica cruris superficialis"
    },
    {
        "icd": "S800",
        "rs": "Nagnječenje kolena",
        "la": "Contusio genus"
    },
    {
        "icd": "S801",
        "rs": "Nagnječenje drugih neoznačenih delova potkolenice",
        "la": "Contusio partium cruris aliarum, non specificatarum"
    },
    {
        "icd": "S807",
        "rs": "Višestruka površinska povreda potkolenice",
        "la": "Laesio traumatica cruris, superficialis multiplex"
    },
    {
        "icd": "S808",
        "rs": "Druge površinske povrede potkolenice",
        "la": "Laesiones traumaticae cruris, superficiales aliae"
    },
    {
        "icd": "S809",
        "rs": "Površinska povreda potkolenice, neoznačena",
        "la": "Laesio traumatica cruris, superficialis, non specificata"
    },
    {
        "icd": "S81",
        "rs": "Otvorena rana potkolenice",
        "la": "Vulnus apertum cruris"
    },
    {
        "icd": "S810",
        "rs": "Otvorena rana kolena",
        "la": "Vulnus apertum genus"
    },
    {
        "icd": "S817",
        "rs": "Višestruka otvorena rana potkolenice",
        "la": "Vulnus apertum cruris multiplex"
    },
    {
        "icd": "S818",
        "rs": "Otvorena rana drugih delova potkolenice",
        "la": "Vulnus apertum partium cruris aliarum"
    },
    {
        "icd": "S819",
        "rs": "Otvorena rana neoznačenog dela potkolenice",
        "la": "Vulnus apertum cruris, partis non specificatae"
    },
    {
        "icd": "S82",
        "rs": "Prelom potkolenice uključujući i predeo skočnog zgloba",
        "la": "Fractura cruris, regionem talocruralem includens"
    },
    {
        "icd": "S820",
        "rs": "Prelom čašice",
        "la": "Fractura patellae"
    },
    {
        "icd": "S821",
        "rs": "Prelom gornjeg okrajka golenjače",
        "la": "Fractura tibiae, pars proximalis"
    },
    {
        "icd": "S822",
        "rs": "Prelom tela golenjače",
        "la": "Fractura corporis tibiae"
    },
    {
        "icd": "S823",
        "rs": "Prelom donjeg okrajka golenjače",
        "la": "Fractura tibiae, pars distalis"
    },
    {
        "icd": "S824",
        "rs": "Prelom lisnjače",
        "la": "Fractura fibulae"
    },
    {
        "icd": "S825",
        "rs": "Prelom unutrašnjeg gležnja",
        "la": "Fractura malleoli medialis"
    },
    {
        "icd": "S826",
        "rs": "Prelom spoljnjeg gležnja",
        "la": "Fractura malleoli lateralis"
    },
    {
        "icd": "S827",
        "rs": "Višestruki prelom potkolenice",
        "la": "Fractura cruris multiplex, pars distalis"
    },
    {
        "icd": "S828",
        "rs": "Prelom drugih delova potkolenice",
        "la": "Fractura partium cruris aliarum"
    },
    {
        "icd": "S829",
        "rs": "Prelom neoznačenog dela potkolenice",
        "la": "Fractura cruris, pars non specificata"
    },
    {
        "icd": "S83",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza kolena",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum genus"
    },
    {
        "icd": "S830",
        "rs": "Iščašenje čašice",
        "la": "Luxatio patellae"
    },
    {
        "icd": "S831",
        "rs": "Iščašenje kolena",
        "la": "Luxatio genus"
    },
    {
        "icd": "S832",
        "rs": "Rascep meniskusa, skorašnji",
        "la": "Fissura menisci traumatica recens"
    },
    {
        "icd": "S833",
        "rs": "Rascep zglobne hrskavice kolena, skorašnji",
        "la": "Fissura cartilaginis genus recens"
    },
    {
        "icd": "S834",
        "rs": "Uganuće i istegnuće (spoljašnje) (unutrašnje) pobočne veze kolena",
        "la": "Distorsio et distensio ligamenti genus collateralis (fibularis) (tibialis)"
    },
    {
        "icd": "S835",
        "rs": "Uganuće i istegnuće (prednje) (zadnje) ukrštene veze kolena",
        "la": "Distorsio et distensio ligamenti cruciati genus (anterioris) (posterioris)"
    },
    {
        "icd": "S836",
        "rs": "Uganuće i istegnuće drugih neoznačenih delova kolena",
        "la": "Distorsio et distensio partium genus aliarum, non specificatarum"
    },
    {
        "icd": "S837",
        "rs": "Povreda više delova kolena",
        "la": "Laesio traumatica partium genus multiplicium"
    },
    {
        "icd": "S84",
        "rs": "Povreda živaca potkolenice",
        "la": "Laesio traumatica nervorum cruris"
    },
    {
        "icd": "S840",
        "rs": "Povreda golenjačnog živca u predelu potkolenice",
        "la": "Laesio traumatica nervi tibialis regionis cruris"
    },
    {
        "icd": "S841",
        "rs": "Povreda lisnjačnog živca u predelu potkolenice",
        "la": "Laesio traumatica nervi peronaealis regionis cruris"
    },
    {
        "icd": "S842",
        "rs": "Povreda kožnog senzitivnog živca potkolenice",
        "la": "Laesio traumatica nervi cutanei sensitivi cruris"
    },
    {
        "icd": "S847",
        "rs": "Povreda više živaca potkolenice",
        "la": "Laesio traumatica nervorum cruris multiplicium"
    },
    {
        "icd": "S848",
        "rs": "Povreda drugih živaca potkolenice",
        "la": "Laesio traumatica nervorum cruris aliorum"
    },
    {
        "icd": "S849",
        "rs": "Povreda neoznačenog živca potkolenice",
        "la": "Laesio traumatica nervi cruris, non specificati"
    },
    {
        "icd": "S85",
        "rs": "Povreda krvnih sudova potkolenice",
        "la": "Laesio traumatica vasorum cruris"
    },
    {
        "icd": "S850",
        "rs": "Povreda zatkolene arterije",
        "la": "Laesio traumatica arteriae popliteae"
    },
    {
        "icd": "S851",
        "rs": "Povreda (prednje) (zadnje) golenjačne arterije",
        "la": "Laesio traumatica arteriae tibialis (anterioris) (posterioris)"
    },
    {
        "icd": "S852",
        "rs": "Povreda lisnjačne arterije",
        "la": "Laesio traumatica arteriae fibularis"
    },
    {
        "icd": "S853",
        "rs": "Povreda velike površinske vene nogu u predelu potkolenice",
        "la": "Laesio traumatica venae saphenae magnae regionis cruris"
    },
    {
        "icd": "S854",
        "rs": "Povreda male površine vene noge u predelu potkolenice",
        "la": "Laesio traumatica venae saphenae parvae regionis cruris"
    },
    {
        "icd": "S855",
        "rs": "Povreda zatkolene vene",
        "la": "Laesio traumatica venae popliteae"
    },
    {
        "icd": "S857",
        "rs": "Povreda više krvnih sudova potkolenice",
        "la": "Laesio traumatica vasorum cruris multiplicium"
    },
    {
        "icd": "S858",
        "rs": "Povreda drugih krvnih sudova potkolenice",
        "la": "Laesio traumatica vasorum cruris aliorum"
    },
    {
        "icd": "S859",
        "rs": "Povreda neoznačenog krvnog suda potkolenice",
        "la": "Laesio traumatica vasis cruris, non specificati"
    },
    {
        "icd": "S86",
        "rs": "Povreda mišića i tetive potkolenice",
        "la": "Laesio traumatica musculi et tendinis cruris"
    },
    {
        "icd": "S860",
        "rs": "Povreda Ahilove tetive",
        "la": "Laesio traumatica tendinis Achillis"
    },
    {
        "icd": "S861",
        "rs": "Povreda drugog mišića i tetive (drugih mišića i tetiva) u zadnjem predelu potkolenice",
        "la": "Laesio traumatica musculi et tendinis (musculorum et tendinum) alterius regionis cruris, posterioris"
    },
    {
        "icd": "S862",
        "rs": "Povreda mišića i tetive (mišića i tetiva) u prednjem predelu potkolenice",
        "la": "Laesio traumatica musculi et tendinis (musculorum et tendinum) regionis cruris, anterioris"
    },
    {
        "icd": "S863",
        "rs": "Povreda mišića i njegove tetive (mišića i tetiva) u lisnjačnom predelu potkolenice",
        "la": "Laesio traumatica musculi peronaei (et tendinis eius) (musculorum et tendinum) regionis cruris"
    },
    {
        "icd": "S867",
        "rs": "Povreda više mišića i tetiva potkolenice",
        "la": "Laesio traumatica musculorum et tendinum cruris multiplicium"
    },
    {
        "icd": "S868",
        "rs": "Povreda neoznačenih mišića i tetiva potkolenice",
        "la": "Laesio traumatica musculorum et tendinum cruris non specificatae"
    },
    {
        "icd": "S869",
        "rs": "Povreda neoznačenog mišića i tetive potkolenice",
        "la": "Laesio traumatica musculi et tendinis cruris non specificatae"
    },
    {
        "icd": "S87",
        "rs": "Povreda uzrokovana zdrobljavanjem – kraš kolena i potkolenice",
        "la": "Conquassatio genus et conquassatio cruris"
    },
    {
        "icd": "S870",
        "rs": "Povreda uzrokovana zdrobljavanjem kolena",
        "la": "Conquassatio genus"
    },
    {
        "icd": "S878",
        "rs": "Povreda uzrokovana zdrobljavanjem drugih neoznačenih delova potkolenice",
        "la": "Conquassatio partium cruris aliarum, non specificatarum"
    },
    {
        "icd": "S88",
        "rs": "Traumatska amputacija potkolenice",
        "la": "Amputatio traumatica cruris"
    },
    {
        "icd": "S880",
        "rs": "Traumatska amputacija u nivou kolena",
        "la": "Amputatio traumatica in altitudine genus"
    },
    {
        "icd": "S881",
        "rs": "Traumatska amputacija u nivou između kolena i gležnja",
        "la": "Amputatio traumatica inter genum et tarsum"
    },
    {
        "icd": "S889",
        "rs": "Traumatska amputacija potkolenice, neoznačenog nivoa",
        "la": "Amputatio traumatica cruris, regione non specificata"
    },
    {
        "icd": "S89",
        "rs": "Druge neoznačene povrede potkolenice",
        "la": "Laesiones traumatica cruris aliae, non specificatae"
    },
    {
        "icd": "S897",
        "rs": "Višestruke povrede potkolenice",
        "la": "Laesiones traumaticae cruris multiplices"
    },
    {
        "icd": "S898",
        "rs": "Druge označene povrede potkolenice",
        "la": "Laesiones traumaticae cruris aliae, specificatae"
    },
    {
        "icd": "S899",
        "rs": "Povreda potkolenice, neoznačena",
        "la": "Laesio traumatica cruris, non specificata"
    },
    {
        "icd": "S90",
        "rs": "Površinska povreda u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica regionis malleoli et pedis, superficialis"
    },
    {
        "icd": "S900",
        "rs": "Nagnječenje u predelu skočnog zgloba",
        "la": "Contusio regionis malleoli"
    },
    {
        "icd": "S901",
        "rs": "Nagnječenje prsta (prstiju) bez ozlede nokta",
        "la": "Contusio digiti (digitorum) pedis sine laesione unguis"
    },
    {
        "icd": "S902",
        "rs": "Nagnječenje prsta (prstiju) stopala sa ozledom nokta",
        "la": "Contusio digiti (digitorum) pedis cum laesione uguis"
    },
    {
        "icd": "S903",
        "rs": "Nagnječenje drugih neoznačenih delova stopala",
        "la": "Contusio partium pedis aliarum, non specificatarum"
    },
    {
        "icd": "S907",
        "rs": "Višestruka površinska povreda u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica regionis malleoli et pedis multiplex superficialis"
    },
    {
        "icd": "S908",
        "rs": "Druge površinske povrede u predelu skočnog zgloba i stopala",
        "la": "Laesiones traumaticae regionis malleoli et pedis superficialis aliae"
    },
    {
        "icd": "S909",
        "rs": "Površinska povreda u predelu skočnog zgloba i stopala, neoznačena",
        "la": "Laesio traumatica regionis malleoli et pedis, superficialis, non specificata"
    },
    {
        "icd": "S91",
        "rs": "Otvorena rana u predelu skočnog zgloba i stopala",
        "la": "Vulnus apertum regionis malleoli et pedis"
    },
    {
        "icd": "S910",
        "rs": "Otvorena rana u predelu skočnog zgloba",
        "la": "Vulnus apertum regionis malleoli"
    },
    {
        "icd": "S911",
        "rs": "Otvorena rana prsta (prstiju)stopala bez ozlede nokta",
        "la": "Vulnus apertum digiti (digitorum) pedis sine laesione unguis"
    },
    {
        "icd": "S912",
        "rs": "Otvorena rana prsta (prstiju) stopala sa ozledom nokta",
        "la": "Vulnus apertum digiti (digitorum) pedis cum laesione unguis"
    },
    {
        "icd": "S913",
        "rs": "Otvorena rana drugih delova stopala",
        "la": "Vulnus apertum partium pedis aliarum"
    },
    {
        "icd": "S917",
        "rs": "Višestruka otvorena rana u predelu skočnog zgloba i stopala",
        "la": "Vulnus apertum regionis malleoli et pedis multiplex"
    },
    {
        "icd": "S92",
        "rs": "Prelom stopala - izuzev predela skočnog zgloba",
        "la": "Fractura pedis (excl.regio malleoli)"
    },
    {
        "icd": "S920",
        "rs": "Prelom petne kosti",
        "la": "Fractura calcanei"
    },
    {
        "icd": "S921",
        "rs": "Prelom skočne kosti",
        "la": "Fractura tali"
    },
    {
        "icd": "S922",
        "rs": "Prelom neke druge kosti (drugih kostiju) nožja",
        "la": "Fractura ossis (ossium) tarsi alterius"
    },
    {
        "icd": "S923",
        "rs": "Prelom kosti donožja",
        "la": "Fractura ossis metatarsi"
    },
    {
        "icd": "S924",
        "rs": "Prelom palca stopala",
        "la": "Fractura hallucis"
    },
    {
        "icd": "S925",
        "rs": "Prelom nekog drugog prsta stopala",
        "la": "Fractura digiti pedis alterius"
    },
    {
        "icd": "S927",
        "rs": "Višestruki prelom stopala",
        "la": "Fractura pedis multiplex"
    },
    {
        "icd": "S929",
        "rs": "Prelom stopala, neoznačen",
        "la": "Fractura pedis, non specificata"
    },
    {
        "icd": "S93",
        "rs": "Iščašenje, uganuće i istegnuće zglobova i veza u predelu skočnog zgloba i stopala",
        "la": "Luxatio, distorsio et distensio articulorum et ligamentorum regionis malleoli et pedis"
    },
    {
        "icd": "S930",
        "rs": "Iščašenje skočnog zgloba",
        "la": "Luxatio articuli talocruralis"
    },
    {
        "icd": "S931",
        "rs": "Iščašenje prsta (prstiju) stopala",
        "la": "Luxatio digiti (digitorum) pedis"
    },
    {
        "icd": "S932",
        "rs": "Ruptura veze u predelu skočnog zgloba i stopala",
        "la": "Ruptura ligamentorum regionis malleoli et pedis"
    },
    {
        "icd": "S933",
        "rs": "Iščašenje drugih neoznačenih delova stopala",
        "la": "Luxatio partium pedis aliarum, non specificatarum"
    },
    {
        "icd": "S934",
        "rs": "Uganuće i istegnuće skočnog zgloba",
        "la": "Distorsio et distensio articuli talocruralis"
    },
    {
        "icd": "S935",
        "rs": "Uganuće i istegnuće prsta (prstiju) stopala",
        "la": "Distorsio et distensio digiti (digitorum) pedis"
    },
    {
        "icd": "S936",
        "rs": "Uganuće i istegnuće drugih neoznačenih delova stopala",
        "la": "Distorsio et distensio partium pedis aliarum, non specificatarum"
    },
    {
        "icd": "S94",
        "rs": "Povreda živaca u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica nervorum regionis malleoli et pedis"
    },
    {
        "icd": "S940",
        "rs": "Povreda spoljašnjeg živca tabana",
        "la": "Laesio traumatica nervi plantaris lateralis"
    },
    {
        "icd": "S941",
        "rs": "Povreda unutrašnjeg živca tabana",
        "la": "Laesio traumatica nervi plantaris medialis"
    },
    {
        "icd": "S942",
        "rs": "Povreda dubokog živca lisnjače u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica nervi peronaei profundi regionis malleoli et pedis"
    },
    {
        "icd": "S943",
        "rs": "Povreda kožnog senzitivnog živca u predelu skočnog zgloba (i stopala)",
        "la": "Laesio traumatica nervi cutanei sensitivi regionis malleoli (et pedis)"
    },
    {
        "icd": "S947",
        "rs": "Povreda više živaca u predelu skočnog zgloba (i stopala)",
        "la": "Laesio traumatica nervorum regionis malleoli (et pedis) multiplicium"
    },
    {
        "icd": "S948",
        "rs": "Povreda nekog drugog živca u predelu skočnog zgloba (i stopala)",
        "la": "Laesio traumatica nervi regionis malleoli (et pedis) alterius"
    },
    {
        "icd": "S949",
        "rs": "Povreda neoznačenog živca u predelu skočnog zgloba (i stopala)",
        "la": "Laesio traumatica nervi regionis malleoli (et pedis) non specificati"
    },
    {
        "icd": "S95",
        "rs": "Povreda krvnih sudova u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica vasorum regionis malleoli et pedis"
    },
    {
        "icd": "S950",
        "rs": "Povreda leđne arterije stopala",
        "la": "Laesio traumatica arteriae dorsalis pedis"
    },
    {
        "icd": "S951",
        "rs": "Povreda arterije tabana",
        "la": "Laesio traumatica arteriae plantaris pedis"
    },
    {
        "icd": "S952",
        "rs": "Povreda leđne vene stopala",
        "la": "Laesio traumatica venae dorsalis pedis"
    },
    {
        "icd": "S957",
        "rs": "Povreda više krvnih sudova u predelu skočnog zgloba (i stopala)",
        "la": "Laesio traumatica vasorum regionis malleoli (et pedis) multiplicium"
    },
    {
        "icd": "S958",
        "rs": "Povreda drugih krvnih sudova u predelu skočnog zgloba (i stopala)",
        "la": "Laesio traumatica vasorum regionis malleoli (et pedis) aliorum"
    },
    {
        "icd": "S959",
        "rs": "Povreda neoznačenog krvnog suda u predelu skočnog zgloba (i stopala)",
        "la": "Laesio traumatica vasis regionis malleoli (et pedis) non specificati"
    },
    {
        "icd": "S96",
        "rs": "Povreda mišića i tetive u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica musculi et tendinis regionis malleoli et pedis"
    },
    {
        "icd": "S960",
        "rs": "Povreda mišića dugog savijača palca stopala i njegove tetive u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica musculi et (tendinis eius) flexoris hallucis pedis longi regionis malleoli et pedis"
    },
    {
        "icd": "S961",
        "rs": "Povreda mišića dugog opružača palca stopala i njegove tetive u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica musculi (et tendinis eius) extensoris hallucis pedis longi regionis mall. et pedis"
    },
    {
        "icd": "S962",
        "rs": "Povreda unutrašnjeg mišića i njegove tetive u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica musculi (et tendinis aius) interni regionis malleoli et pedis"
    },
    {
        "icd": "S967",
        "rs": "Povreda više mišića i njihovih tetiva u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica musculorum (et tendinum eorum) regionis malleoli et pedis multiplicium"
    },
    {
        "icd": "S968",
        "rs": "Povreda drugih mišića i njihovih tetiva u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica musculorum (et tendinum eorum) regionis malleoli (et pedis) aliorum"
    },
    {
        "icd": "S969",
        "rs": "Povreda neoznačenog mišića i njegove tetive u predelu skočnog zgloba i stopala",
        "la": "Laesio traumatica musculi (et tendinis eius) regionis malleoli et pedis, non specificati"
    },
    {
        "icd": "S97",
        "rs": "Povreda uzrokovana zdrobljavanjem – kraš u predelu skočnog zgloba i stopala",
        "la": "Conquassatio regionis malleoli et pedis"
    },
    {
        "icd": "S970",
        "rs": "Povreda uzrokovana zdrobljavanjem u predelu skočnog zgloba",
        "la": "Conquassatio regionis malleoli"
    },
    {
        "icd": "S971",
        "rs": "Povreda uzrokovana zdrobljavanjem prsta (prstiju) stopala",
        "la": "Conquassatio digiti (digitorum) pedis"
    },
    {
        "icd": "S978",
        "rs": "Povreda uzrokovana zdrobljavanjem drugih delova u predelu skočnog zgloba i stopala",
        "la": "Conquassatio partium regionis malleoli et pedis aliarum"
    },
    {
        "icd": "S98",
        "rs": "Traumatska amputacija skočnog zgloba i stopala",
        "la": "Amputatio traumatica tarsi et pedis"
    },
    {
        "icd": "S980",
        "rs": "Traumatska amputacija stopala u nivou skočnog zgloba",
        "la": "Amputatio traumatica in regione tarsi et pedis"
    },
    {
        "icd": "S981",
        "rs": "Traumatska amputacija jednog prsta stopala",
        "la": "Amputatio traumatica digiti pedis singularis"
    },
    {
        "icd": "S982",
        "rs": "Traumatska amputacija dva ili više prstiju stopala",
        "la": "Amputatio traumatica duorum vel plurium digitorum pedis"
    },
    {
        "icd": "S983",
        "rs": "Traumatska amputacija drugih delova stopala",
        "la": "Amputatio traumatica partium pedis aliarum"
    },
    {
        "icd": "S984",
        "rs": "Traumatska amputacija stopala, neoznačenog nivoa",
        "la": "Amputatio traumatica pedis, regionis non specificatae"
    },
    {
        "icd": "S99",
        "rs": "Druge neoznačene povrede u predelu skočnog zgloba i stopala",
        "la": "Laesiones traumaticae regionis malleoli et pedis aliae non specificatae"
    },
    {
        "icd": "S997",
        "rs": "Višestruke povrede u predelu skočnog zgloba i stopala",
        "la": "Laesiones traumaticae regionis malleoli et pedis multiplices"
    },
    {
        "icd": "S998",
        "rs": "Druge označene povrede u predelu skočnog zgloba i stopala",
        "la": "Laesiones traumaticae regionis malleoli et pedis aliae, specificatae"
    },
    {
        "icd": "S999",
        "rs": "Povreda u predelu skočnog zgloba i stopala, neoznačena",
        "la": "Laesio traumatica regionis malleoli et pedis, non specificata"
    },
    {
        "icd": "T00",
        "rs": "Površinske povrede u više predela tela",
        "la": "Laesiones traumaticae regionum corporis multiplicium superficiales"
    },
    {
        "icd": "T000",
        "rs": "Površinske povrede glave i vrata",
        "la": "Laesiones tramuaticae capitis et colli superficiales"
    },
    {
        "icd": "T001",
        "rs": "Površinske povrede grudnog koša, trbuha, slabinskog predela (i karlice)",
        "la": "Laesiones traumaticae thoracis, abdominis, regionis lumbalis (et pelvis) superficiales"
    },
    {
        "icd": "T002",
        "rs": "Površinska povreda u više predela ruke (-u)",
        "la": "Laesio traumatica extremitatis (-um) superioris (-um) regionum multiplicium, superficialis"
    },
    {
        "icd": "T003",
        "rs": "Površinska povreda u više predela noge (-u)",
        "la": "Laesio traumatica extremitatis (-um) inferioris (-um) regionum multiplicium, superficialis"
    },
    {
        "icd": "T006",
        "rs": "Višestruke površinske povrede ruke (-u) i noge (-u)",
        "la": "Laesio traumatica extremitatis (-um)) superioris (-um) et inferioris (-um) superficialis multiplex"
    },
    {
        "icd": "T008",
        "rs": "Površinska udružena povreda u drugim predelima tela",
        "la": "Laesio traumatica regionum corporis aliarum superficialis combinata"
    },
    {
        "icd": "T009",
        "rs": "Višestruke površinske povrede, neoznačene",
        "la": "Laesiones traumaticae superficiales multiplices, non specificatae"
    },
    {
        "icd": "T01",
        "rs": "Otvorena rana u više predela tela",
        "la": "Vulnus apertum regionum corporis multiplicium"
    },
    {
        "icd": "T010",
        "rs": "Otvorena rana glave (i vrata)",
        "la": "Vulnus apertum capitis (et colli)"
    },
    {
        "icd": "T011",
        "rs": "Otvorena rana grudnog koša, trbuha, slabinskog predela i karlice",
        "la": "Vulnus apertum thoracis, abdominis, regionis lumbalis et pelvis"
    },
    {
        "icd": "T012",
        "rs": "Otvorena rana u više predela ruke",
        "la": "Vulnus apertum regionum extremitatis superioris multiplicium"
    },
    {
        "icd": "T013",
        "rs": "Otvorena rana u više predela noge",
        "la": "Vulnus apertum regionum extremitatis inferioris multiplicium"
    },
    {
        "icd": "T016",
        "rs": "Otvorena rana u više predela ruke i noge",
        "la": "Vulnus apertum regionum extremitatis superioris et extremitatis inferioris multiplicium"
    },
    {
        "icd": "T018",
        "rs": "Otvorena udružena rana u drugim predelima tela",
        "la": "Vulnus apertum regionum corporis aliarum combinatum"
    },
    {
        "icd": "T019",
        "rs": "Višestruka otvorena rana, neoznačena",
        "la": "Vulnus apertum corporis multiplex, non specificatum"
    },
    {
        "icd": "T02",
        "rs": "Prelomi u više predela tela",
        "la": "Fracturae regionum corporis multiplicium"
    },
    {
        "icd": "T020",
        "rs": "Prelomi glave i vrata",
        "la": "Fracturae capitis et colli"
    },
    {
        "icd": "T021",
        "rs": "Prelomi grudnog koša, slabinskog predela i karlice",
        "la": "Fracturae thoracis, regionis lumbalis et pelvis"
    },
    {
        "icd": "T022",
        "rs": "Prelomi više predela jedne ruke",
        "la": "Fractura regionum extremitatis superioris unae multiplicium"
    },
    {
        "icd": "T023",
        "rs": "Prelomi više predela jedne noge",
        "la": "Fractura regionum extremitatis inferioris unae multiplicium"
    },
    {
        "icd": "T024",
        "rs": "Prelom obe ruke",
        "la": "Fractura extremitatis superioris bilateralis"
    },
    {
        "icd": "T025",
        "rs": "Prelom obe noge",
        "la": "Fractura extremitatis inferioris, bilateralis"
    },
    {
        "icd": "T026",
        "rs": "Prelomi u više predela ruke (-u) i noge (-u)",
        "la": "Fracturae regionum extremitatis superioris (-um) et inferioris (-um) multiplicium"
    },
    {
        "icd": "T027",
        "rs": "Prelomi grudnog koša, slabinskog predela, karlice i ekstremiteta",
        "la": "Fracturae thoracis, regionis lumbalis, pelvis et extremitatis (-um)"
    },
    {
        "icd": "T028",
        "rs": "Udruženi prelomi u drugim predelima tela",
        "la": "Fracturae regionum corporis aliarum combinatae"
    },
    {
        "icd": "T029",
        "rs": "Višestruki prelom, neoznačen",
        "la": "Fractura multipliex, non specificata"
    },
    {
        "icd": "T03",
        "rs": "Iščašenje, uganuće i istegnuće u više delova tela",
        "la": "Luxatio, distorsio et distensio regionum corporis multiplicium"
    },
    {
        "icd": "T030",
        "rs": "Iščašenje, uganuće i istegnuće glave i vrata",
        "la": "Luxatio, distorsio et distensio capitis et colli"
    },
    {
        "icd": "T031",
        "rs": "Iščašenje, uganuće i istegnuće grudnog koša, slabinskog predela i karlice",
        "la": "Luxatio, distorsio et distensio thoracis, regionis lumbalis et pelvis"
    },
    {
        "icd": "T032",
        "rs": "Iščašenje, uganuće i istegnuće više predela ruke (-u)",
        "la": "Luxatio, distorsio et distensio extremitatis (-um) superioris (-um) multiplicium"
    },
    {
        "icd": "T033",
        "rs": "Iščašenje, uganuće i istegnuće u više predela noge (-u)",
        "la": "Luxatio, distorsio et distensio regionum extremitatis (-um) inferioris (-um) multiplicium"
    },
    {
        "icd": "T034",
        "rs": "Iščašenje, uganuće i istegnuće ruku (-u) i nogu (-u)",
        "la": "Luxatio, distorsio et distensio extremitatis (-um) superioris (-um) et inferioris (-um)"
    },
    {
        "icd": "T038",
        "rs": "Udruženo iščašenje, uganuće i istegnuće u drugim predelima tela",
        "la": "Luxatio, distorsio et distensio regionum corporis aliarum combinata"
    },
    {
        "icd": "T039",
        "rs": "Višestruko iščašenje, uganuće i istegnuće, neoznačeno",
        "la": "Luxatio, distrosio et distensio multiplex, non specificata"
    },
    {
        "icd": "T04",
        "rs": "Povrede uzrokovane zdrobljavanjem – kraš u više predela tela",
        "la": "Conquassatio regionum corporis multiplicium"
    },
    {
        "icd": "T040",
        "rs": "Povrede uzrokovane zdrobljavanjem glave i vrata",
        "la": "Conquassatio capitis et colli"
    },
    {
        "icd": "T041",
        "rs": "Povreda uzrokovana zdrobljavanjem grudnog koša, trbuha, slabinskog predela i karlice",
        "la": "Conquassatio thoracis, abdominis, regionis lumbalis et pelvis"
    },
    {
        "icd": "T042",
        "rs": "Povrede uzrokovane zdrobljavanjem u više predela ruke (-u)",
        "la": "Conquassatio regionum extremitatis (-um) superioris (-um) multiplicium"
    },
    {
        "icd": "T043",
        "rs": "Povreda uzrokovana zdrobljavanjem u više predela noge (-u)",
        "la": "Conquassatio regionum extremitatis (-um) inferioris (-um) multiplicium"
    },
    {
        "icd": "T044",
        "rs": "Višestruka povreda uzrokovana zdrobljavanjem ruke (u) i noge (-u)",
        "la": "Conquassatio extremitatis (-um) superioris (-um) et inferioris(-um) multiplex"
    },
    {
        "icd": "T047",
        "rs": "Povreda uzrokovana zdrobljavanjem grudnog koša, trbuha, slabinskog predela, karlice i uda (-ova)",
        "la": "Conquassatio thoracis, abdominis, regionis lumbalis, pelvis et extremitatis (-um)"
    },
    {
        "icd": "T048",
        "rs": "Udružena povreda uzrokovana zdrobljavanjem u drugim predelima tela",
        "la": "Conquassatio regionum corporis aliarum, combinata"
    },
    {
        "icd": "T049",
        "rs": "Višestruka povreda uzrokovana zdrobljavljavanjem, neoznačena",
        "la": "Conquassatio multiplex, non specificata"
    },
    {
        "icd": "T05",
        "rs": "Traumatska amputacija više predela tela",
        "la": "Amputatio traumatica regionum corporis multiplicium"
    },
    {
        "icd": "T050",
        "rs": "Traumatska amputacija obe šake",
        "la": "Amputatio traumatica manus, bilateralis"
    },
    {
        "icd": "T051",
        "rs": "Traumatska amputacija jedne šake i druge ruke [bilo koji nivo, izuzev šake]",
        "la": "Amputatio traumatica manus unius et membri superioris alterius [in qualibet regione manu exclusa]"
    },
    {
        "icd": "T052",
        "rs": "Traumatska amputacija obe ruke [bilo koji nivo]",
        "la": "Amputatio traumatica extremitatis superioris bilateralis [in qualibet regione]"
    },
    {
        "icd": "T053",
        "rs": "Traumatska amputacija oba stopala",
        "la": "Amputatio traumatica pedis bilateralis"
    },
    {
        "icd": "T054",
        "rs": "Traumatska amputacija jednog stopala i druge noge [bilo koji nivo, osim stopala]",
        "la": "Amputatio traumatica pedis et membri inferioris alterius [in qualibet regione, excepto pede]"
    },
    {
        "icd": "T055",
        "rs": "Traumatska amputacija obe noge [bilo koji nivo]",
        "la": "Amputatio traumatica extremitatis inferioris regionis indefinitae bilateralis [in qualibet regione]"
    },
    {
        "icd": "T056",
        "rs": "Traumatska amputacija gornjih i donjih udova, bilo koja kombinacija [bilo koji nivo]",
        "la": "Amputatio traumatica extremitatum superiorum et inferiorum, combinata [in qualibet regione]"
    },
    {
        "icd": "T058",
        "rs": "Traumatske amputacije koje uključuju druge kombinacije delova tela",
        "la": "Amputationes traumaticae combinatae regionum corporis aliarum"
    },
    {
        "icd": "T059",
        "rs": "Višestruke traumatske amputacije, neoznačene",
        "la": "Amputationes multiplices traumaticae, non specificatae"
    },
    {
        "icd": "T06",
        "rs": "Druge povreda više predela tela, neklasifikovane na drugom mestu",
        "la": "Laesiones traumaticae regionum corporis multiplicium aliae, non alibi classificatae"
    },
    {
        "icd": "T060",
        "rs": "Povrede mozga i lobanjskih živaca sa povredama živaca i kičmene moždine u predelu vrata",
        "la": "Laesio traumatica cerebri et nervorum cranialium cum traumatis nervorum et medullae spinalis reginis colli"
    },
    {
        "icd": "T061",
        "rs": "Povreda živaca i kičmene moždine u više drugih predela tela",
        "la": "Laesio traumatica nervorum et medullae spinalis regionum corporis multiplicium aliarum"
    },
    {
        "icd": "T062",
        "rs": "Povreda živaca u više predela tela",
        "la": "Laesio traumatica nervorum regionum corporis multiplicium"
    },
    {
        "icd": "T063",
        "rs": "Povrede krvnih sudova u više predela tela",
        "la": "Laesiones traumaticae vasorum regionum corporis multiplicum"
    },
    {
        "icd": "T064",
        "rs": "Povrede mišića i tetiva u više predela tela",
        "la": "Laesiones traumaticae musculi et tendinis regionum corporis multiplicium"
    },
    {
        "icd": "T065",
        "rs": "Povreda unutargrudnih, unutartrbušnih i karličnih organa",
        "la": "Laesio traumatica organorum intrathoracalium, intraabdominalium et pelvicorum"
    },
    {
        "icd": "T068",
        "rs": "Druge označene povrede više predela tela",
        "la": "Laesio traumatica regionum corporis multiplicium alia, specificata"
    },
    {
        "icd": "T07",
        "rs": "Višestruke neoznače povrede",
        "la": "Laesiones traumataicae mutiplices, non specificatae"
    },
    {
        "icd": "T08",
        "rs": "Prelom kičme, neoznačen",
        "la": "Fractura columnae vertebralis regionis non specificata"
    },
    {
        "icd": "T09",
        "rs": "Druge povrede kičme i trupa, neoznačene",
        "la": "Laesiones traumaticae columnae vertebralis et trunci regionis non specificatae, aliae"
    },
    {
        "icd": "T090",
        "rs": "Površinska povreda trupa u neoznačenom predelu",
        "la": "Laesio traumatica trunci regionis superficialis, non specificatae"
    },
    {
        "icd": "T091",
        "rs": "Otvorena povreda trupa u neoznačenom predelu",
        "la": "Vulnus apertum trunci regionis non specificatae"
    },
    {
        "icd": "T092",
        "rs": "Iščašenje, uganuće i istegnuće neoznačenog zgloba i veze trupa",
        "la": "Luxatio, distorsio et distensio articuli et ligamenti trunci, non specificati"
    },
    {
        "icd": "T093",
        "rs": "Povreda u neoznačenom predelu kičmene moždine",
        "la": "Laesio traumatica medullae spinalis, regionis non specificatae"
    },
    {
        "icd": "T094",
        "rs": "Povreda neoznačenog živca, korena kimenog živca i živcanog spleta trupa",
        "la": "Laesio traumatica nervi, radicis spinalis et plexus nervorum trunci non specificati"
    },
    {
        "icd": "T095",
        "rs": "Povreda neoznačenog mišića i tetive trupa",
        "la": "Laesio traumatica musculi et tendinis trunci non specificatae"
    },
    {
        "icd": "T096",
        "rs": "Traumatska amputacija trupa, neoznačenog nivoa",
        "la": "Amputatio traumatica trunci, regionis non specificatae"
    },
    {
        "icd": "T098",
        "rs": "Druge označene povrede u neoznačenom predelu trupa",
        "la": "Laesiones traumaticae trunci regionis non specificatae aliae, specificatae"
    },
    {
        "icd": "T099",
        "rs": "Neoznačena povreda u neoznačenom predelu trupa",
        "la": "Laesio traumatica trunci non specificata, regionis non specificatae"
    },
    {
        "icd": "T10",
        "rs": "Prelom u neoznačenom predelu ruke",
        "la": "Fractura extremitatis superioris regionis non specificatae"
    },
    {
        "icd": "T11",
        "rs": "Druge povrede u neoznačenom predelu ruke",
        "la": "Laesiones traumaticae extremitatis superioris regionis non specificatae aliae"
    },
    {
        "icd": "T110",
        "rs": "Površinska povreda u neoznačenom predelu ruke",
        "la": "Laesio traumatica extremitatis superioris regionis superficialis non specificatae"
    },
    {
        "icd": "T111",
        "rs": "Otvorena povreda u neoznačenom predelu ruke",
        "la": "Vulnus apertum extremitatis superioris regionis non specificatae"
    },
    {
        "icd": "T112",
        "rs": "Iščašenje, uganuće i istegnuće u neoznačenom predelu neoznačenog zgloba i veze ruke",
        "la": "Luxatio, distorsio et distensio articuli et ligamenti extremitatis superioris regionis non specficata"
    },
    {
        "icd": "T113",
        "rs": "Povreda u neoznačenom predelu živca ruke",
        "la": "Laesio traumatica nervi extremitatis superioris regionis non specificatae"
    },
    {
        "icd": "T114",
        "rs": "Povreda u neoznačenom predelu krvnog suda ruke",
        "la": "Laesio traumatica vasis extremitatis superioris regionis non specificatae"
    },
    {
        "icd": "T115",
        "rs": "Povreda u neoznačenom predelu mišića i tetive ruke",
        "la": "Laesio traumatica musculi et tendinis extremitatis superioris regionis non specificatae"
    },
    {
        "icd": "T116",
        "rs": "Traumatska amputacija ruke, neoznačenog nivoa",
        "la": "Amputatio traumatica extremitatis superioris, regionis non specificatae"
    },
    {
        "icd": "T118",
        "rs": "Druge označene povrede u neoznačenom predelu ruke",
        "la": "Laesiones traumaticae extremitatis superioris regionis non specificatae, aliae"
    },
    {
        "icd": "T119",
        "rs": "Neoznačena povreda u neoznačenom predelu ruke",
        "la": "Laesio traumatica non specificata extremitatis superioris regionis non specificatae"
    },
    {
        "icd": "T12",
        "rs": "Prelom u neoznačenom predelu noge",
        "la": "Fractura extremitatis inferioris regionis non specificatae"
    },
    {
        "icd": "T13",
        "rs": "Druge povrede u neoznačenom predelu noge",
        "la": "Laesiones traumaticae extremitatis iferioris regionis non specificatae, aliae"
    },
    {
        "icd": "T130",
        "rs": "Površinska povreda u neoznačenom predelu noge",
        "la": "Laesio traumatica extramitatis inferioris regionis non specificatae, superficialis"
    },
    {
        "icd": "T131",
        "rs": "Otvorena povreda u neoznačenom predelu noge",
        "la": "Vulnus apertum extremitatis inferioris regionis non specificatae"
    },
    {
        "icd": "T132",
        "rs": "Iščašenje, uganuće i istegnuće u neoznačenom predelu zgloba i veze noge",
        "la": "Luxatio, distrosio et distensio articuli et ligamenti extremitatis inferioris regionis non specificatae"
    },
    {
        "icd": "T133",
        "rs": "Povreda u neoznačenom predelu živca noge",
        "la": "Laesio traumatica nervi extremitatis inferioris regionis non specificatae"
    },
    {
        "icd": "T134",
        "rs": "Povreda u neoznačenom predelu krvnog suda noge",
        "la": "Laesio traumatica vasis extremitatis inferioris regionis non specificatae"
    },
    {
        "icd": "T135",
        "rs": "Povreda u neoznačenom predelu mišića i tetive noge",
        "la": "Laesio traumatica musculi et tendinis extremitatis inferioris regionis non specificatae"
    },
    {
        "icd": "T136",
        "rs": "Traumatska amputacija noge, neoznačenog nivoa",
        "la": "Amputatio traumatica extremitatis inferioris, regionis non specificatae"
    },
    {
        "icd": "T138",
        "rs": "Ostale označene povrede u neoznačenom predelu noge",
        "la": "Laesiones traumaticae extremitatis inferioris regionis non specificatae aliae, specificatae"
    },
    {
        "icd": "T139",
        "rs": "Neoznačena povreda u neoznačenom predelu noge",
        "la": "Laesio traumatica extremitatis inferioris non specificata, regionis non specificatae"
    },
    {
        "icd": "T14",
        "rs": "Povreda tela u neoznačenom predelu",
        "la": "Laesio traumatica corporis regionis non specificatae"
    },
    {
        "icd": "T140",
        "rs": "Površinska povreda tela u neoznačenom predelu",
        "la": "Laesio traumatica corporis regionis non specificatae, superficialis"
    },
    {
        "icd": "T141",
        "rs": "Otvorena rana tela, neoznačenog predela",
        "la": "Vulnus apertum corporis regionis non specificatae"
    },
    {
        "icd": "T142",
        "rs": "Prelom tela u neoznačenom predelu",
        "la": "Fractura corporis regionis non specificatae"
    },
    {
        "icd": "T143",
        "rs": "Iščašenje, uganuće i istegnuće u neoznačenom predelu tela",
        "la": "Luxatio, distorsio et distensio regionis corporis non specificatae"
    },
    {
        "icd": "T144",
        "rs": "Povreda živca (-aca) u neoznačenom predelu tela",
        "la": "Laesio traumatica nervi (-orum) regionis corporis non specificatae"
    },
    {
        "icd": "T145",
        "rs": "Povreda krvnog suda (-ova) u neoznačenom predelu tela",
        "la": "Laesio traumatica vasis (-orum) regionis corporis non specificatae"
    },
    {
        "icd": "T146",
        "rs": "Povreda tetive i mišića u neoznačenom predelu tela",
        "la": "Laesio traumatica tendinis (-um) et musculi (-orum) regionis corporis non specificatae"
    },
    {
        "icd": "T147",
        "rs": "Povreda uzrokovana zdrobljavanjem i traumatska amputacija u neoznačenom predelu tela",
        "la": "Conquassatio et amputatio traumatica regionis corporis non specificatae"
    },
    {
        "icd": "T148",
        "rs": "Ostale povrede u neoznačenom predelu tela",
        "la": "Laesiones traumaticae regionis corporis non specificatae, aliae"
    },
    {
        "icd": "T149",
        "rs": "Povreda, neoznačena",
        "la": "Laesio traumatica, non specificata"
    },
    {
        "icd": "T15",
        "rs": "Strano telo u spoljašnjem delu oka",
        "la": "Corpus alienum partis oculi externae"
    },
    {
        "icd": "T150",
        "rs": "Strano telo u ronjači",
        "la": "Corpus alienum corneae"
    },
    {
        "icd": "T151",
        "rs": "Strano telo u vežnjači",
        "la": "Corpus alienum conjunctivae"
    },
    {
        "icd": "T158",
        "rs": "Strano telo u drugom i više predela spoljašnjeg dela oka",
        "la": "Corpus alienum partis alterius et partium oculi externi multiplicium"
    },
    {
        "icd": "T159",
        "rs": "Strano telo u neoznačenom predelu spoljašnjeg dela oka",
        "la": "Corpus alienum partis oculi externi non specificatae"
    },
    {
        "icd": "T16",
        "rs": "Strano telo u uvu",
        "la": "Corpus alienum auris"
    },
    {
        "icd": "T17",
        "rs": "Strano telo u sistemu za disanje",
        "la": "Corpus alienum tractus respiratorii"
    },
    {
        "icd": "T170",
        "rs": "Strano telo u sinusu nosa",
        "la": "Corpus alienum sinus nasalis"
    },
    {
        "icd": "T171",
        "rs": "Strano telo u nosu",
        "la": "Corpus alienum nasi"
    },
    {
        "icd": "T172",
        "rs": "Strano telo u ždrelu",
        "la": "Corpus alienum pharyngis"
    },
    {
        "icd": "T173",
        "rs": "Strano telo u grkljanu",
        "la": "Corpus alienum laryngis"
    },
    {
        "icd": "T174",
        "rs": "Strano telo u dušniku",
        "la": "Corpus alienum tracheae"
    },
    {
        "icd": "T175",
        "rs": "Strano telo u bronhiji",
        "la": "Corpus alienum bronchi"
    },
    {
        "icd": "T178",
        "rs": "Strano telo u drugom i više delova sistema za disanje",
        "la": "Corpus alienum partis alterius et partium tractus respiratorii multiplicium"
    },
    {
        "icd": "T179",
        "rs": "Strano telo u neoznačenom delu sistema za disanje",
        "la": "Corpus alienum tractus respiratorii, pars non specificata"
    },
    {
        "icd": "T18",
        "rs": "Strano telo u sistemu za varenje",
        "la": "Corpus alienum tractus digestivi"
    },
    {
        "icd": "T180",
        "rs": "Strano telo u ustima",
        "la": "Corpus alienum oris"
    },
    {
        "icd": "T181",
        "rs": "Strano telo u jednjaku",
        "la": "Corpus alienum oesophagi"
    },
    {
        "icd": "T182",
        "rs": "Strano telo u želucu",
        "la": "Corpus alienum ventriculi"
    },
    {
        "icd": "T183",
        "rs": "Strano telo u tankom crevu",
        "la": "Corpus alienum intestini tenuis"
    },
    {
        "icd": "T184",
        "rs": "Strano telo u debelom crevu",
        "la": "Corpus alienum coli"
    },
    {
        "icd": "T185",
        "rs": "Strano telu u anusu i rektumu",
        "la": "Corpus alienum ani et recti"
    },
    {
        "icd": "T188",
        "rs": "Strano telo u drugom i više delova sistema za varenje",
        "la": "Corpus alienum partis alterius et partium tractus disgestivii multiplicium"
    },
    {
        "icd": "T189",
        "rs": "Strano telo u neoznačenom delu sistema za varenje",
        "la": "Corpus alienum tractus digestivi, pars non specificata"
    },
    {
        "icd": "T19",
        "rs": "Strano telo u mokraćno-polnom sistemu",
        "la": "Corpus alienum tractus urogenitalis"
    },
    {
        "icd": "T190",
        "rs": "Strano telo u mokraćovodu bešike",
        "la": "Corpus alienum urethrae"
    },
    {
        "icd": "T191",
        "rs": "Strano telo u mokraćnoj bešici",
        "la": "Corpus alienum vesicae urinariae"
    },
    {
        "icd": "T192",
        "rs": "Strano telo u stidnici i vagini",
        "la": "Corpus alienum vulvae et vaginae"
    },
    {
        "icd": "T193",
        "rs": "Strano telo u neoznačenom delu materice",
        "la": "Corpus alienum uteri, pars non specificata"
    },
    {
        "icd": "T198",
        "rs": "Strano telo u drugom i više delova mokraćno-polnog sistema",
        "la": "Corpus alienum partis alterius et partium tractus urogenitalis multiplicium"
    },
    {
        "icd": "T199",
        "rs": "Strano telo u neoznačenom delu mokraćno-polnog sistema",
        "la": "Corpus alienum tractus urogenitalis, pars no specificata"
    },
    {
        "icd": "T20",
        "rs": "Opekotina i razjed glave i vrata",
        "la": "Combustio et corrosio capitis et colli"
    },
    {
        "icd": "T200",
        "rs": "Opekotina glave i vrata, neoznačenog stepena",
        "la": "Combustio capitis et colli, gradus non specificati"
    },
    {
        "icd": "T201",
        "rs": "Opekotina glave i vrata prvog stepena",
        "la": "Combustio capitis et colli, gradus primi"
    },
    {
        "icd": "T202",
        "rs": "Opekotina glave i vrata drugog stepena",
        "la": "Combustio capitis et colli, gradus secundi"
    },
    {
        "icd": "T203",
        "rs": "Opekotina glave i vrata trećeg stepena",
        "la": "Combustio capitis et colli, gradus tertii"
    },
    {
        "icd": "T204",
        "rs": "Razjed glave i vrata neoznačenog stepena",
        "la": "Corrosio capitis et colli, gradus non specificati"
    },
    {
        "icd": "T205",
        "rs": "Razjed glave i vrata prvog stepena",
        "la": "Corrosio capitis et colli, gradus primi"
    },
    {
        "icd": "T206",
        "rs": "Razjed glave i vrata drugog stepena",
        "la": "Corrosio capitis et colli, gradus secundi"
    },
    {
        "icd": "T207",
        "rs": "Razjed glave i vrata trećeg stepena",
        "la": "Corrosio capitis et colli, gradus tertii"
    },
    {
        "icd": "T21",
        "rs": "Opekotina i razjed trupa",
        "la": "Combustio et corrosio trunci"
    },
    {
        "icd": "T210",
        "rs": "Opekotina trupa neoznaenog stepena",
        "la": "Combustio trunci, gradus non specificati"
    },
    {
        "icd": "T211",
        "rs": "Opekotina trupa prvog stepena",
        "la": "Combustio trunci, gradus primi"
    },
    {
        "icd": "T212",
        "rs": "Opekotina trupa drugog stepena",
        "la": "Combustio trunci, gradus secundi"
    },
    {
        "icd": "T213",
        "rs": "Opekotina trupa trećeg stepena",
        "la": "Combustio trunci, gradus tertii"
    },
    {
        "icd": "T214",
        "rs": "Razjed trupa neoznačenog stepena",
        "la": "Corrosio trunci, gradus non specificati"
    },
    {
        "icd": "T215",
        "rs": "Razjed trupa prvog stepena",
        "la": "Corrosio trunci, gradus primi"
    },
    {
        "icd": "T216",
        "rs": "Razjed trupa drugog stepena",
        "la": "Corrosio trunci, gradus secundi"
    },
    {
        "icd": "T217",
        "rs": "Razjed trupa trećeg stepena",
        "la": "Corrosio trunci, gradus tertii"
    },
    {
        "icd": "T22",
        "rs": "Opekotine i razjed ruke izuzev predela ručja i šake",
        "la": "Combustio et corrosio extremitatis superioris (excl. carpus et manus)"
    },
    {
        "icd": "T220",
        "rs": "Opekotine ruke, izuzev ručja i šake, neoznačenog stepena",
        "la": "Combustio extremitatis superioris, gradus non specificati (excl. carpus et manus)"
    },
    {
        "icd": "T221",
        "rs": "Opekotine ruke, izuzev ručja i šake, prvog stepena",
        "la": "Combustio extremitatis superioris, gradus primi (excl. carpus et manus)"
    },
    {
        "icd": "T222",
        "rs": "Opekotine ruke, izuzev ručja i šake, drugog stepena",
        "la": "Combustio extremitatis superioris, gradus secundi (excl.carpus et manus)"
    },
    {
        "icd": "T223",
        "rs": "Opekotine ruke, izuzev ručja i šake, trećeg stepena",
        "la": "Combustio extremitatis superioris, gradus tertii (excl.carpus et manus)"
    },
    {
        "icd": "T224",
        "rs": "Razjed ruke, izuzev ručja i šake, neoznačenog stepena",
        "la": "Corrosio extremitatis superioris, gradus non specificati (excl.carpus et manus)"
    },
    {
        "icd": "T225",
        "rs": "Razjed ruke, izuzev ručja i šake, prvog stepena",
        "la": "Corrosio extremitatis superioris, gradus primi (excl.carpus et manus)"
    },
    {
        "icd": "T226",
        "rs": "Razjed ruke, izuzev ručja i šake, drugog stepena",
        "la": "Corrosio extremitatis superioris, gradus secundi (excl.carpus et manus)"
    },
    {
        "icd": "T227",
        "rs": "Razjed ruke, izuzev ručja i šake, trešeg stepena",
        "la": "Corrosio extremitatis superioris, gradus tertii (excl.carpus et manus)"
    },
    {
        "icd": "T23",
        "rs": "Opekotina i razjed ručja i šake",
        "la": "Combustio et corrosio carpi et manus"
    },
    {
        "icd": "T230",
        "rs": "Opekotine ručja i šake neoznačenog stepena",
        "la": "Combustio carpi et manus, gradus non specificati"
    },
    {
        "icd": "T231",
        "rs": "Opekotina ručja i šake prvog stepena",
        "la": "Combustio carpi et manus, gradus primi"
    },
    {
        "icd": "T232",
        "rs": "Opekotina ručja i šake drugog stepena",
        "la": "Combustio carpi et manus, gradus secundi"
    },
    {
        "icd": "T233",
        "rs": "Opekotina ručja i šake trećeg stepena",
        "la": "Combustio carpi et manus, gradus tertii"
    },
    {
        "icd": "T234",
        "rs": "Razjed ručja i šake neoznačenog stepena",
        "la": "Corrosio carpi et manus, gradus non specificati"
    },
    {
        "icd": "T235",
        "rs": "Razjed ručja i šake prvog stepena",
        "la": "Corrosio carpi et manus, gradus primi"
    },
    {
        "icd": "T236",
        "rs": "Razjed ručja i šake drugog stepena",
        "la": "Corrosio carpi et manus, gradus secundi"
    },
    {
        "icd": "T237",
        "rs": "Razjed ručja i šake trećeg stepena",
        "la": "Corrosio carpi et manus, gradus tertii"
    },
    {
        "icd": "T24",
        "rs": "Opekotina i razjed kuka i noge, izuzev predela skočnog zgloba i stopala",
        "la": "Combustio et corrosio coxae et extremitatis inferioris (excl.regio malleoli et pes)"
    },
    {
        "icd": "T240",
        "rs": "Opekotina kuka i noge neoznačenog stepena, izuzev povreda skočnog zgloba i stopala",
        "la": "Combustio coxae et extremitatis inferioris, gradus non specificati (excl.regio malleoli et pes)"
    },
    {
        "icd": "T241",
        "rs": "Opekotina kuka i noge prvog stepena, izuzev predela skočnog zgloba i stopala",
        "la": "Combustio coxae extremitatis inferioris, gradus primi (excl.regio malleoli et pes)"
    },
    {
        "icd": "T242",
        "rs": "Opekotina kuka i noge drugog stepena, izuzev predela skočnog zgloba i stopala",
        "la": "Combustio coxae et extremitatis inferioris, gradus secundi (excl.regio malleoli et pes)"
    },
    {
        "icd": "T243",
        "rs": "Opekotina kuka i noge trećeg stepena, izuzev predela skočnog zgloba i stopala",
        "la": "Combustio coxae et extremitatis inferioris, gradus tertii (excl.regio malleoli et pes)"
    },
    {
        "icd": "T244",
        "rs": "Razjed kuka i noge, neoznačenog stepena, izuzev predela skočnog zgloba i stopala",
        "la": "Corrosio coxae et extremitatis inferioris gradus non specificati (excl.regio malleoli et pes)"
    },
    {
        "icd": "T245",
        "rs": "Razjed kuka i noge prvog stepena, izuzev predela skočnog zgloba i stopala",
        "la": "Corrosio coxae et extremitatis inferioris, gradus primi (excl. regio malleoli et pes)"
    },
    {
        "icd": "T246",
        "rs": "Razjed kuka i noge drugog stepena, izuzev predela skočnog zgloba i stopala",
        "la": "Corrosio coxae et extremitatis inferioris, gradus secundi (excl.regio malleoli et pes)"
    },
    {
        "icd": "T247",
        "rs": "Razjed kuka i noge trećeg stepena, izuzev povreda skočnog zgloba i stopala",
        "la": "Corrosio coxae et extremitatis inferioris, gradus tertii (excl.regio malleoli et pes)"
    },
    {
        "icd": "T25",
        "rs": "Opekotina i razjed u predelu skočnog zgloba i stopala",
        "la": "Combustio et corrosio regionis malleoli et pedis"
    },
    {
        "icd": "T250",
        "rs": "Opekotina u predelu skočnog zgloba i stopala neoznačenog stepena",
        "la": "Combustio regionis malleoli et pedis, gradus non specificati"
    },
    {
        "icd": "T251",
        "rs": "Opekotina u predelu skočnog zgloba i stopala prvog stepena",
        "la": "Combustio regionis malleoli et pedis gradus primi"
    },
    {
        "icd": "T252",
        "rs": "Opekotina u predelu skočnog zgloba i stopala drugog stepena",
        "la": "Combustio regionis malleoli et pedis, gradus secundi"
    },
    {
        "icd": "T253",
        "rs": "Opekotina u predelu skočnog zgloba i stopala trećeg stepena",
        "la": "Combustio regionis malleoli et pedis, gradus tertii"
    },
    {
        "icd": "T254",
        "rs": "Razjed u predelu skočnog zgloba i stopala neoznačenog stepena",
        "la": "Corrosio regionis malleoli et pedis, gradus non specificati"
    },
    {
        "icd": "T255",
        "rs": "Razjed u predelu skočnog zgloba i stopala prvog stepena",
        "la": "Corrosio regionis malleoli et pedis, gradus primi"
    },
    {
        "icd": "T256",
        "rs": "Razjed u predelu skočnog zgloba i stopala drugog stepena",
        "la": "Corrosio regionis malleoli et pedis, gradus secundi"
    },
    {
        "icd": "T257",
        "rs": "Razjed u predelu skočnog zgloba i stopala trećeg stepena",
        "la": "Corrosio regionis malleoli et pedis, gradus tertii"
    },
    {
        "icd": "T26",
        "rs": "Opekotina i razjed oka i predela duplje oka",
        "la": "Combustio et corrosio oculi et regionis orbitalis"
    },
    {
        "icd": "T260",
        "rs": "Opekotina kapka oka i predela oko oka",
        "la": "Combustio palpebrae et regionis orbitalis"
    },
    {
        "icd": "T261",
        "rs": "Opekotina rožnjace i vežnjace",
        "la": "Combustio corneae et conjunctivae"
    },
    {
        "icd": "T262",
        "rs": "Opekotina sa rupturom i razgradnjom jabučice oka",
        "la": "Combustio cum ruptura et destructione bulbi oculi"
    },
    {
        "icd": "T263",
        "rs": "Opekotina drugih delova oka i pripojaka oka",
        "la": "Combustio partium oculi et adnexorum aliarum"
    },
    {
        "icd": "T264",
        "rs": "Opekotina neoznačenog dela oka i pripojaka oka",
        "la": "Combustio oculi et adnexorum, pars non specificata"
    },
    {
        "icd": "T265",
        "rs": "Razjed kapka oka i predela oko oka",
        "la": "Corrosio palpebrae et regionis periocularis"
    },
    {
        "icd": "T266",
        "rs": "Razjed rožnjače i vežnjače",
        "la": "Corrosio cornaee et conjunctivae"
    },
    {
        "icd": "T267",
        "rs": "Razjed sa rupturom i razgradnjom očne jabučice",
        "la": "Corrosio cum ruptura et destructione bulbi oculi"
    },
    {
        "icd": "T268",
        "rs": "Razjed drugih delova oka i pripojaka oka",
        "la": "Corrosio partium oculi et adnexorum aliarum"
    },
    {
        "icd": "T269",
        "rs": "Razjed neoznačenog dela oka i pripojaka oka",
        "la": "Corrosio oculi et adnexorum, pars non specificata"
    },
    {
        "icd": "T27",
        "rs": "Opekotina i razjed sistema za disanje",
        "la": "Combustio et corrosio tractus respiratorii"
    },
    {
        "icd": "T270",
        "rs": "Opekotina grkljana i dušnika",
        "la": "Combustio laryngis et tracheae"
    },
    {
        "icd": "T271",
        "rs": "Opekotina grkljana, dušnika i pluća",
        "la": "Combustio laryngis, tracheae et pulmonis"
    },
    {
        "icd": "T272",
        "rs": "Opekotina drugih delova disajnog sistema",
        "la": "Combustio partium tractus respiratorii aliarum"
    },
    {
        "icd": "T273",
        "rs": "Opekotina neoznačenog dela sistema za disanje",
        "la": "Combustio tractus respiratorii, pars non specificata"
    },
    {
        "icd": "T274",
        "rs": "Razjed grkljana dušnika",
        "la": "Corrosio laryngis et tracheae"
    },
    {
        "icd": "T275",
        "rs": "Razjed grkljana, dušnika i pluća",
        "la": "Corrosio laryngis, tracheae et pulmonis"
    },
    {
        "icd": "T276",
        "rs": "Razjed drugih delova sistema za disanje",
        "la": "Corrosio partium tractus respiratorii aliarum"
    },
    {
        "icd": "T277",
        "rs": "Razjed neoznačenog dela sistema za disanje",
        "la": "Corrosio tractus respiratorii, pars non specificata"
    },
    {
        "icd": "T28",
        "rs": "Opekotina i razjed drugih unutrašnjih organa",
        "la": "Combustio et corrosio organorum internorum aliorum"
    },
    {
        "icd": "T280",
        "rs": "Opekotina usta i ždrela",
        "la": "Combustio cavi oris et pharyngis"
    },
    {
        "icd": "T281",
        "rs": "Opekotina jednjaka",
        "la": "Combustio oesophagi"
    },
    {
        "icd": "T282",
        "rs": "Opekotina drugih delova sistema za varenje",
        "la": "Combustio partium tractus alimentarii aliarum"
    },
    {
        "icd": "T283",
        "rs": "Opekotina unutrašnjih mokraćno-polnih organa",
        "la": "Combustio organorum urogenitalium internorum"
    },
    {
        "icd": "T284",
        "rs": "Opekotina drugih neoznačenih unutrašnjih organa",
        "la": "Combustio organorum internorum aliorum, non specificatorum"
    },
    {
        "icd": "T285",
        "rs": "Razjed usta i ždrela",
        "la": "Corrosio cavi oris et cavi pharyngis"
    },
    {
        "icd": "T286",
        "rs": "Razjed jednjaka",
        "la": "Corrosio oesophagi"
    },
    {
        "icd": "T287",
        "rs": "Razjed drugih delova sistema za varenje",
        "la": "Corrosio partium tractus alimentarii aliarum"
    },
    {
        "icd": "T288",
        "rs": "Razjed unutrašnjih mokraćno-polnih organa",
        "la": "Corrosio organorum urogenitalium internorum"
    },
    {
        "icd": "T289",
        "rs": "Razjed drugih neoznačenih unutrašnjih organa",
        "la": "Corrosio organorum internorum aliorum, non specificatorum"
    },
    {
        "icd": "T29",
        "rs": "Opekotine i razjedi u više predela tela",
        "la": "Combustio et corrosio regionum corporis multiplicium"
    },
    {
        "icd": "T290",
        "rs": "Opekotine u više predela naoznačenog stepena",
        "la": "Combustio regionum multiplicium, gradus non specificati"
    },
    {
        "icd": "T291",
        "rs": "Opekotine u više predela prvog stepena",
        "la": "Combustio regionum multiplicium, gradus primi"
    },
    {
        "icd": "T292",
        "rs": "Opekotine u više predela drugog stepena",
        "la": "Combustio regionum multiplicium, gradus secundi"
    },
    {
        "icd": "T293",
        "rs": "Opekotine u više predela trećeg stepena",
        "la": "Combustio regionum multiplicium, gradus tertii"
    },
    {
        "icd": "T294",
        "rs": "Razjedi u više predela neoznačenog stepena",
        "la": "Corrosio regionum multiplicium, gradus non specificati"
    },
    {
        "icd": "T295",
        "rs": "Razjed više predela, samo do prvog stepena",
        "la": "Corrosio regionum multiplicium, ad gradum primum majoris non citati"
    },
    {
        "icd": "T296",
        "rs": "Razjedi više predela, samo do drugog stepena",
        "la": "Corrosio regionum multiplicium, ad gradum secundum majoris non citati"
    },
    {
        "icd": "T297",
        "rs": "Razjedi više predela samo do trećeg stepena",
        "la": "Corrosio regionum multiplicium, ad gradum tertium majoris non citati"
    },
    {
        "icd": "T30",
        "rs": "Opekotina i razjed tela u neoznačenom predelu",
        "la": "Combustio et corrosio corporis, regionis non specificatae"
    },
    {
        "icd": "T300",
        "rs": "Opekotina u neoznačenom predelu tela, neoznačenog stepena",
        "la": "Combustio regionis corporis non specificatae, gradus non specificati"
    },
    {
        "icd": "T301",
        "rs": "Opekotina u neoznačenom predelu tela, prvog stepena",
        "la": "Combustio regionis corporis non specificatae, gradus primi"
    },
    {
        "icd": "T302",
        "rs": "Opekotina u neoznačenom predelu tela, drugog stepena",
        "la": "Combustio regionis corporis non specificatae, gradus secundi"
    },
    {
        "icd": "T303",
        "rs": "Opekotina u neoznačenom predelu tela, trećeg stepena",
        "la": "Combustio regionis corporis non specificatae, gradus tertii"
    },
    {
        "icd": "T304",
        "rs": "Razjed u neoznačenom predelu tela, neoznačenog stepena",
        "la": "Corrosio regionis corporis non specificatae, gradus non specificati"
    },
    {
        "icd": "T305",
        "rs": "Razjed u neoznačenom predelu tela, prvog stepena",
        "la": "Corrosio regionis corporis non specificatae, gradus primi"
    },
    {
        "icd": "T306",
        "rs": "Razjed u neoznačenom predelu tela, drugog stepena",
        "la": "Corrosio regionis corporis non specificatae, gradus secundi"
    },
    {
        "icd": "T307",
        "rs": "Razjed u neoznačenom predelu tela, trećeg stepena",
        "la": "Corrosio regionis corporis non specificatae, gradus tertii"
    },
    {
        "icd": "T31",
        "rs": "Opekotine svrstane prema obimu zahvaćene telesne površine",
        "la": "Combustiones secundum amplitudinem superficiei corporis comprehensae classificatae"
    },
    {
        "icd": "T310",
        "rs": "Opekotine koje zahvataju manje od 10% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes minus quam 10%"
    },
    {
        "icd": "T311",
        "rs": "Opekotine koje zahvataju 10-19% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 10-19%"
    },
    {
        "icd": "T312",
        "rs": "Opekotine koje zahvataju 20-29% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 20-29%"
    },
    {
        "icd": "T313",
        "rs": "Opekotine koje zahvataju 30-39% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 30-39%"
    },
    {
        "icd": "T314",
        "rs": "Opekotine koje zahvataju 40-49% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 40-49%"
    },
    {
        "icd": "T315",
        "rs": "Opekotine koje zahvataju 50-59% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 50-59%"
    },
    {
        "icd": "T316",
        "rs": "Opekotine koje zahvataju 60-69% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 60-69%"
    },
    {
        "icd": "T317",
        "rs": "Opekotine koje zahvataju 70-79% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 70-79%"
    },
    {
        "icd": "T318",
        "rs": "Opekotine koje zahvataju 80-89% telesne površine",
        "la": "Combustiones superficiei corporis comprehendentes 80-89%"
    },
    {
        "icd": "T319",
        "rs": "Opekotine koje zahvataju 90% ili više telesne površine",
        "la": "Combustiones sive plus superficiei corporis comprehendentes 90%"
    },
    {
        "icd": "T32",
        "rs": "Razjedi svrstani prema obimu zahvaćene telesne površine",
        "la": "Corrosiones secundum amplitudinem superficiei corporis comprehensae classificatae"
    },
    {
        "icd": "T320",
        "rs": "Razjedi koji zahvataju manje od 10% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes minus quam 10%"
    },
    {
        "icd": "T321",
        "rs": "Razjedi koji zahvataju 10-19% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 10-19%"
    },
    {
        "icd": "T322",
        "rs": "Razjedi koji zahvataju 20-29% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 20-29%"
    },
    {
        "icd": "T323",
        "rs": "Razjedi koji zahvataju 30-39% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 30-39%"
    },
    {
        "icd": "T324",
        "rs": "Razjedi koji zahvataju 40-49% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 40-49%"
    },
    {
        "icd": "T325",
        "rs": "Razjedi koji zahvataju 50-59% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 50-59%"
    },
    {
        "icd": "T326",
        "rs": "Razjedi koji zahvataju 60-69% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 60-69%"
    },
    {
        "icd": "T327",
        "rs": "Razjedi koji zahvataju 70-79% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 70-79%"
    },
    {
        "icd": "T328",
        "rs": "Razjedi koji zahvataju 80-89% telesne površine",
        "la": "Corrosiones superficiei corporis comprehendentes 80-89%"
    },
    {
        "icd": "T329",
        "rs": "Razjedi koji zahvataju 90% ili više telesne površine",
        "la": "Corrosiones sive plus superficiei corporis comprehendentes 90%"
    },
    {
        "icd": "T33",
        "rs": "Površinska promrzlina",
        "la": "Congelatio superficialis"
    },
    {
        "icd": "T330",
        "rs": "Površinska promrzlina glave",
        "la": "Congelatio capitis, superficialis"
    },
    {
        "icd": "T331",
        "rs": "Površinska promrzlina vrata",
        "la": "Congelatio colii, superficialis"
    },
    {
        "icd": "T332",
        "rs": "Površinska promrzlina grudnog koša",
        "la": "Congelatio thoracis, superficialis"
    },
    {
        "icd": "T333",
        "rs": "Povrinska promrzlina trbušnog zida, slabinskog predela i karlice",
        "la": "Congelatio abdominis regionis lumbalis et pelvis, superficialis"
    },
    {
        "icd": "T334",
        "rs": "Površinska promrzlina ruke",
        "la": "Congelatio extremitatis superioris, superficialis"
    },
    {
        "icd": "T335",
        "rs": "Površinska promrzlina ručja i šake",
        "la": "Congelatio carpi et manus, superficialis"
    },
    {
        "icd": "T336",
        "rs": "Površinska promrzlina kuka i butine",
        "la": "Cangelatio coxae et femoris, superficialis"
    },
    {
        "icd": "T337",
        "rs": "Površinska promrzlina kolena i potkolenice",
        "la": "Congelatio genus et cruris, superficialis"
    },
    {
        "icd": "T338",
        "rs": "Površinska promrzlina u predelu skočnog zgloba i stopala",
        "la": "Congelatio regionis malleoli et pedis, superficialis"
    },
    {
        "icd": "T339",
        "rs": "Površinska promrzlina drugih i neoznačenih mesta",
        "la": "Congelatio locorum aliorum et non specificatorum superficialis"
    },
    {
        "icd": "T34",
        "rs": "Promrzlina sa nekrozom tkiva",
        "la": "Congelatio cum necrosi textus"
    },
    {
        "icd": "T340",
        "rs": "Promrzlina glave sa nekrozom tkiva",
        "la": "Congelatio capitis cum necrosi textus"
    },
    {
        "icd": "T341",
        "rs": "Promrzlina vrata sa nekrozom tkiva",
        "la": "Congelatio colii cum necrosi textus"
    },
    {
        "icd": "T342",
        "rs": "Promrzlina grudnog koša sa nekrozom tkiva",
        "la": "Congelatio thoracis cum necrosi textus"
    },
    {
        "icd": "T343",
        "rs": "Promrzlina trbuha, slabinskog predela i karlice, sa nekrozom tkiva",
        "la": "Congelatio abdominis, regionis lumbalis et pelvis cum necrosi textus"
    },
    {
        "icd": "T344",
        "rs": "Promrzlina ruke sa nekrozom tkiva ruke",
        "la": "Congelatio extremitatis superioris cum necrosi textus"
    },
    {
        "icd": "T345",
        "rs": "Promrzlina ručja i šake sa nekrozom tkiva",
        "la": "Congelatio carpi et manus cum necrosi textus"
    },
    {
        "icd": "T346",
        "rs": "Promrzlina kuka i butine sa nekrozom tkiva",
        "la": "Congelatio coxae et femoris cum necrosi textus"
    },
    {
        "icd": "T347",
        "rs": "Promrzlina kolena i potkolenice sa nekrozom tkiva",
        "la": "Congelatio genus et cruris cum necrosi textus"
    },
    {
        "icd": "T348",
        "rs": "Promrzlina u predelu skočnog zgloba i stopala sa nekrozom tkiva",
        "la": "Congelatio regionis malleoli et pedis cum necrosi textus"
    },
    {
        "icd": "T349",
        "rs": "Promrzlina drugih i neoznačenih mesta sa nekrozom tkiva",
        "la": "Congelatio locorum aliorum et non specificatorum cum necrosi textus"
    },
    {
        "icd": "T35",
        "rs": "Promrzline više delova tela i neoznačene promrzline",
        "la": "Congelatio regionum corporis multiplicium et congelatio non specificata"
    },
    {
        "icd": "T350",
        "rs": "Površinska promrzlina više delova tela",
        "la": "Congelatio regionum corporis multiplicium superficialis"
    },
    {
        "icd": "T351",
        "rs": "Promrzlina sa nekrozom tkiva više delova tela",
        "la": "Congelatio regionum corporis multiplicium cum necrosi textus"
    },
    {
        "icd": "T352",
        "rs": "Neoznačena promrzlina glave i vrata",
        "la": "Congelatio non specificata capitis et colli"
    },
    {
        "icd": "T353",
        "rs": "Neoznačena promrzlina grudnog koša, trbuha, slabinskog predela i karlice",
        "la": "Congelatio non specificata thoracis, abdominis, regionis lumbalis et pelvis"
    },
    {
        "icd": "T354",
        "rs": "Neoznačena promrzlina gornjih ekstremiteta",
        "la": "Congelatio non specificata extremitatis superioris"
    },
    {
        "icd": "T355",
        "rs": "Neoznačena promrzlina donjih ekstremiteta",
        "la": "Congelatio non specificata extremitatis inferioris"
    },
    {
        "icd": "T356",
        "rs": "Neoznačena promrzlina više delova tela",
        "la": "Congelatio non specificata regionum corporis multiplicium"
    },
    {
        "icd": "T357",
        "rs": "Neoznačena promrzlina neoznačenog mesta",
        "la": "Congelatio non specificata loco non specificato"
    },
    {
        "icd": "T36",
        "rs": "Trovanje sistemskim antbioticima",
        "la": "Intoxicatio antibioticis systemicis"
    },
    {
        "icd": "T360",
        "rs": "Penicilini",
        "la": "Penicillina"
    },
    {
        "icd": "T361",
        "rs": "Cefalosporini i beta-laktamski antibiotici",
        "la": "Cephalosporina et beta-lactam-antibiotica"
    },
    {
        "icd": "T362",
        "rs": "Hloramfenikolska grupa",
        "la": "Chloramphenicol-classis"
    },
    {
        "icd": "T363",
        "rs": "Macrolidi",
        "la": "Macrolida"
    },
    {
        "icd": "T364",
        "rs": "Tetraciklini",
        "la": "Tetracyclina"
    },
    {
        "icd": "T365",
        "rs": "Aminoglikozidi",
        "la": "Aminoglycosida"
    },
    {
        "icd": "T366",
        "rs": "Rifamicini",
        "la": "Rifamycina"
    },
    {
        "icd": "T367",
        "rs": "Fungicidni antibiotici primenjeni sistemski",
        "la": "Antibiotica antifungalia systemica applikata"
    },
    {
        "icd": "T368",
        "rs": "Drugi sistemski antibiotici",
        "la": "Antibiotica systemica alia"
    },
    {
        "icd": "T369",
        "rs": "Neoznačeni sistemski antibiotici",
        "la": "Antibiotica systemica, non specificata"
    },
    {
        "icd": "T37",
        "rs": "Trovanje drugim sistemskim antiinfektivnim i antiparazitarnim lekovima",
        "la": "Intoxicatio medicamentis antiinfectivis et antiparasitariis systemicis aliis"
    },
    {
        "icd": "T370",
        "rs": "Sulfonamidi",
        "la": "Sulfonamida"
    },
    {
        "icd": "T371",
        "rs": "Lekovi protiv gljivica i bakterija",
        "la": "Medicamenta antimycobacterialia"
    },
    {
        "icd": "T372",
        "rs": "Antimalarici i lekovi za odstranjenje drugih protozoa krvi",
        "la": "Medicamenta antimalarica et remedia, ad eliminationem protozoorum sanquineorum aliorum"
    },
    {
        "icd": "T373",
        "rs": "Drugi lekovi protiv protozoa",
        "la": "Medicamenta antiprotozoica alia"
    },
    {
        "icd": "T374",
        "rs": "Lekovi protiv crevnih glista",
        "la": "Antihelminthica"
    },
    {
        "icd": "T375",
        "rs": "Lekovi protiv virusa",
        "la": "Medicamenta antiviralia"
    },
    {
        "icd": "T378",
        "rs": "Drugi označeni sistemski antiinfektivni i antiparazitarni lekovi",
        "la": "Medicamenta antiinfectiva et antiparasitaria systemica alia specificata"
    },
    {
        "icd": "T379",
        "rs": "Neoznačeni sistemski antiinfektivni i antiparazitarni lekovi",
        "la": "Medicamenta antiinfectiva et antiparasitaria systemica, non specificata"
    },
    {
        "icd": "T38",
        "rs": "Trovanje hormonima i njihovim sintetičkim zamenama i antagonistima, neklasifikovano na drugom mestu",
        "la": "Intoxicatio hormonis, substitutis syntheticis eorum et antagonistis, non alibi classificatis"
    },
    {
        "icd": "T380",
        "rs": "Glikokortikoidi i sintetički analogni preparati",
        "la": "Glycocorticoida et praeparatus synthetici analogici"
    },
    {
        "icd": "T381",
        "rs": "Hormoni štitaste žlezde i zamene",
        "la": "Hormona glandulae thyreoideae et substitutiones"
    },
    {
        "icd": "T382",
        "rs": "Antitireoidni lekovi",
        "la": "Medicamenta antithyreoidea"
    },
    {
        "icd": "T383",
        "rs": "Insulin i hipoglikemijski lekovi (antidijabetici) za oralnu upotrebu",
        "la": "Insulinum et medicamenta hypoglycaemica(antidiabetica ad usum oralem)"
    },
    {
        "icd": "T384",
        "rs": "Kontraceptivi za oralnu upotrebu",
        "la": "Contraceptiva ad usum oralem"
    },
    {
        "icd": "T385",
        "rs": "Ostali estrogeni i progestrogeni",
        "la": "Oestrogenia et progestrogenia alia"
    },
    {
        "icd": "T386",
        "rs": "Antigonadotropini, antiestrogeni, antiandrogeni",
        "la": "Intoxicatio per remedia antigonadotrophina, antioestrogenia, antiandrogenia"
    },
    {
        "icd": "T387",
        "rs": "Androgeni i istovrsni anabolici",
        "la": "Remedia androgenia et anabolica identica"
    },
    {
        "icd": "T388",
        "rs": "Drugi nespecifični hormoni i njihove veštačke zamene",
        "la": "Hormona alia, non specificata et substitutiones synthethicae eorum"
    },
    {
        "icd": "T389",
        "rs": "Drugi neoznačeni antagonisti hormona",
        "la": "Antagonistae hormonorum alii, non specificati"
    },
    {
        "icd": "T39",
        "rs": "Trovanje neopijatskim analgeticima, antipireticima i antireumaticima",
        "la": "Intoxicatio analgeticis non opiaticis, antipireticis et antirheumaticis"
    },
    {
        "icd": "T390",
        "rs": "Salicilati",
        "la": "Salicylata"
    },
    {
        "icd": "T391",
        "rs": "Derivati 4-aminofenola",
        "la": "Derivata 4-aminophenoli"
    },
    {
        "icd": "T392",
        "rs": "Derivati pirazolona",
        "la": "Derivata pyrazoloni"
    },
    {
        "icd": "T393",
        "rs": "Ostali nesteroidni antiinflamatorni lekovi [NSAID]",
        "la": "Medicamenta antiinflamatoria non steroida alia"
    },
    {
        "icd": "T394",
        "rs": "Antireumatici, neklasifikovani na drugom mestu",
        "la": "Antirheumatica, non alibi classificata"
    },
    {
        "icd": "T398",
        "rs": "Drugi neopijatski analgetici i antipiretici, neklasifikovani na drugom mestu",
        "la": "Analgetica et antipyretica non opiatica alia, non alibi classificata"
    },
    {
        "icd": "T399",
        "rs": "Neoznačeni neopijatski analgetici, antipiretici i antireumatici",
        "la": "Analgetica antipyretica et antirheumatica non opiatica, non specificata"
    },
    {
        "icd": "T40",
        "rs": "Trovanje narkoticima i psihodislepticima [halucinogenima]",
        "la": "Intoxicatio narcoticis et psychodyslepticis [hallucinogenibus]"
    },
    {
        "icd": "T400",
        "rs": "Opijum",
        "la": "Opium"
    },
    {
        "icd": "T401",
        "rs": "Heroin",
        "la": "Heroinum"
    },
    {
        "icd": "T402",
        "rs": "Drugi opijati",
        "la": "Opiata alia"
    },
    {
        "icd": "T403",
        "rs": "Metadon",
        "la": "Methadonum"
    },
    {
        "icd": "T404",
        "rs": "Drugi sintetički narkotici",
        "la": "Narcotica synthetica alia"
    },
    {
        "icd": "T405",
        "rs": "Kokain",
        "la": "Cocainum"
    },
    {
        "icd": "T406",
        "rs": "Drugi neoznačeni narkotici",
        "la": "Narcotica alia, non specificata"
    },
    {
        "icd": "T407",
        "rs": "Kanabis (derivati)",
        "la": "Cannabis (derivata)"
    },
    {
        "icd": "T408",
        "rs": "Lizergična kiselina [LSD]",
        "la": "Lysergidum [LSD]"
    },
    {
        "icd": "T409",
        "rs": "Drugi i neoznačeni psihodisleptici [halucinogeni]",
        "la": "Psychodisleptica [hallucinogenia] alia et non specificata"
    },
    {
        "icd": "T41",
        "rs": "Trovanje anesteticima i gasovima upotrebljenim u svrhu lečenja",
        "la": "Intoxicatio anaestheticis et vaporibus(gas) therapeuticis"
    },
    {
        "icd": "T410",
        "rs": "Inhalacioni anestetici",
        "la": "Anaesthetica inhalata"
    },
    {
        "icd": "T411",
        "rs": "Intravenski anestetici",
        "la": "Anaesthetica intravenosa"
    },
    {
        "icd": "T412",
        "rs": "Drugi i neoznačeni opšti anestetici",
        "la": "Anaesthetica generalia alia et non specificata"
    },
    {
        "icd": "T413",
        "rs": "Lokalni anestetici",
        "la": "Anaesthetica localia"
    },
    {
        "icd": "T414",
        "rs": "Anestetik, neoznačen",
        "la": "Anaesthetica, non specificata"
    },
    {
        "icd": "T415",
        "rs": "Gasovi upotrebljeni u svrhu lečenja",
        "la": "Vapores (gas) therapeutici"
    },
    {
        "icd": "T42",
        "rs": "Trovanje antiepilepticima, sedativno-hipnotičkim i antiparkinsonskim lekovima",
        "la": "Intoxicatio medicamentis antiepilepticis, sedativohypnoticis et antiparkinsonicis"
    },
    {
        "icd": "T420",
        "rs": "Derivati hidantoina",
        "la": "Derivata hydantoini"
    },
    {
        "icd": "T421",
        "rs": "Iminostilbeni",
        "la": "Iminostilbena"
    },
    {
        "icd": "T422",
        "rs": "Sukcinimidi i oksazolidinedioni",
        "la": "Succinimida et oxazolidinediona"
    },
    {
        "icd": "T423",
        "rs": "Barbiturati",
        "la": "Barbiturata"
    },
    {
        "icd": "T424",
        "rs": "Benzadiazepini",
        "la": "Benzodiazopina"
    },
    {
        "icd": "T425",
        "rs": "Mešavine antiepileptika, koje nisu klasifikovane na drugom mestu",
        "la": "Mixturae antiepilepticae, non alibi classificatae"
    },
    {
        "icd": "T426",
        "rs": "Drugi antiepileptici i sedativno-hipnotički lekovi",
        "la": "Medicamenta antiepileptica et sedativo-hypnotica alia"
    },
    {
        "icd": "T427",
        "rs": "Antiepileptici i sedativno-hipnotički lekovi, neoznačeni",
        "la": "Medicamenta antiepileptica et sedativo-hypnotica, non specificata"
    },
    {
        "icd": "T428",
        "rs": "Antiparkinsonici i drugi centralni depresori mišićnog tonusa",
        "la": "Medicamenta antiparkinsonica et depressores toni muscularis centrales alii"
    },
    {
        "icd": "T43",
        "rs": "Trovanje psihotropnim lekovima, neklasifikovano na drugom mestu",
        "la": "Intoxicatio medicamentis psychotropicis, non alibi classificatis"
    },
    {
        "icd": "T430",
        "rs": "Triciklični i tetraciklični antidepresivi",
        "la": "Antidepressiva tricyclica et tetracylica"
    },
    {
        "icd": "T431",
        "rs": "Antidepresivi inhibitori monoaminooksidaze",
        "la": "Antidepressiva inhibitores monoamino-oxydasis"
    },
    {
        "icd": "T432",
        "rs": "Drugi i neoznačeni antidepresivi",
        "la": "Antidepressiva alia et non specificata"
    },
    {
        "icd": "T433",
        "rs": "Fenotiazinski antipsihotici i neuroleptici",
        "la": "Antipsyhotica et neuroleptica phenothiazinica"
    },
    {
        "icd": "T434",
        "rs": "Butirofenonski i tioksantenski neuroleptici",
        "la": "Neuroleptica butyrophenonica et thioxanthenica"
    },
    {
        "icd": "T435",
        "rs": "Drugi i neoznačeni antipsihotici i neuroleptici",
        "la": "Antipsychotica et neuroleptica alia et non specificata"
    },
    {
        "icd": "T436",
        "rs": "Psihostimulansi sa mogućnošću zloupotrebe",
        "la": "Psychostimulantia cum possibilitate abusus"
    },
    {
        "icd": "T438",
        "rs": "Drugi psihotropni lekovi, neklasifikovani na drugom mestu",
        "la": "Medicamenta psychotropica alia, non alibi classificata"
    },
    {
        "icd": "T439",
        "rs": "Psihotropni lekovi, neoznačeni",
        "la": "Medicamenta psychotropica, non specificata"
    },
    {
        "icd": "T44",
        "rs": "Trovanje lekovima koji prvenstveno deluju na autonomni nervni sistem",
        "la": "Intoxicatio medicamentis ad systema nervosum autonomicum primum afficientibus"
    },
    {
        "icd": "T440",
        "rs": "Sredstva sa antiholinesteraznim delovanjem",
        "la": "Instrumenta anticholinesterasica"
    },
    {
        "icd": "T441",
        "rs": "Drugi parasimpatomimetici [holinergici]",
        "la": "Parasympathomimetica alia [cholinergica)]"
    },
    {
        "icd": "T442",
        "rs": "Ganglijski blokatori, neklasifikovani na drugom mestu",
        "la": "Medicamenta-inhibitores ganglionici, non alibi classificata"
    },
    {
        "icd": "T443",
        "rs": "Drugi parasimpatikolitici [antiholinergici i antimuskarinici] i spazmolitici, neklasifikovani na drugom mestu",
        "la": "Parasympathicolytica [anticholinergica et antimuscarinica] et spasmolitica alia, non alibi classificata"
    },
    {
        "icd": "T444",
        "rs": "Pretežni agonisti alfa-adrenoreceptora, neklasifikovani na drugom mestu",
        "la": "Agonistae praedominanter alfa-adrenoreceptorum, non alibi classificatae"
    },
    {
        "icd": "T445",
        "rs": "Pretežni agonisti beta-adrenoreceptora, neklasifikovani na drugom mestu",
        "la": "Agonistae praedominanter beta-adrenoreceptorum, non alibi classificatae"
    },
    {
        "icd": "T446",
        "rs": "Antagonisti alfa-adrenoreceptora, neklasifikovani na drugom mestu",
        "la": "Antagonistae alfa-adrenoreceptorum, non alibi classificatae"
    },
    {
        "icd": "T447",
        "rs": "Antagonisti beta-adrenoreceptora, neklasifikovani na drugom mestu",
        "la": "Antagonistae beta-adrenoreceptorum, non alibi classificatae"
    },
    {
        "icd": "T448",
        "rs": "Aktivatori i blokatori adrenergičkih neurona sa centralnim delovanjem, neklasifikovani na drugom mestu",
        "la": "Actores et inhibitores neuronorum adrenergicorum centrales, non alibi classificatae"
    },
    {
        "icd": "T449",
        "rs": "Drugi neoznačeni lekovi koji prvenstveno deluju na autonomni nervni sistem",
        "la": "Remedia systema ad nervosum autonomicum primum afficientia alia, non specificata"
    },
    {
        "icd": "T45",
        "rs": "Trovanje prvenstveno sistemskim i hematološkim sredstvima, neklasifikovano na drugom mestu",
        "la": "Intoxicatio primum instrumentis systemicis et haematologicis, non alibi classificatis"
    },
    {
        "icd": "T450",
        "rs": "Antialergijski i antiemetički lekovi",
        "la": "Remedia antiallergica et antiemetica"
    },
    {
        "icd": "T451",
        "rs": "Antineoplastički i imunosupresivni lekovi",
        "la": "Remedia antineoplastica et immunosupressiva"
    },
    {
        "icd": "T452",
        "rs": "Vitamini, neklasifikovani na drugom mestu",
        "la": "Vitamina, non alibi classificata"
    },
    {
        "icd": "T453",
        "rs": "Enzimi, neklasifikovani na drugom mestu",
        "la": "Enzymae, non alibi classificatae"
    },
    {
        "icd": "T454",
        "rs": "Gvožđe i njegova jedinjenja",
        "la": "Ferrum et composita ferri"
    },
    {
        "icd": "T455",
        "rs": "Antikoagulansi",
        "la": "Anticoagulantia"
    },
    {
        "icd": "T456",
        "rs": "Lekovi koji deluju na fibrinolizu",
        "la": "Remedia ad fibrinolysim afficientia"
    },
    {
        "icd": "T457",
        "rs": "Antagonisti antikoagulanasa, vitamin K i drugi koagulansi",
        "la": "Antagonistae anticoagulantium, vitaminum K et coagulantia alia"
    },
    {
        "icd": "T458",
        "rs": "Druga prvenstveno sistemska i hematološka sredstva",
        "la": "Instrumenta primum systemica et haematologici alia"
    },
    {
        "icd": "T459",
        "rs": "Prvenstveno sistemska i hematološka sredstva, neoznačena",
        "la": "Instrumenta primum systemica et haematologica, non specificata"
    },
    {
        "icd": "T46",
        "rs": "Trovanje sredstvima koja prvenstveno utiču na sistem krvotoka",
        "la": "Intoxicatio instrumentis ad systema cardiovasculare primum afficientibus"
    },
    {
        "icd": "T460",
        "rs": "Kardiotonički glikozidi i lekovi sličnog dejstva",
        "la": "Glycosida cardiostimulantia et remedia actionis simillis"
    },
    {
        "icd": "T461",
        "rs": "Blokatori kalcijumskih kanala",
        "la": "Inhibitores calcium-canalis"
    },
    {
        "icd": "T462",
        "rs": "Drugi antidisritmički lekovi, neklasifikovani na drugom mestu",
        "la": "Remedia antidysrhythmica alia, non alibi classificata"
    },
    {
        "icd": "T463",
        "rs": "Koronarni vazodilatatori, neklasifikovani na drugom mestu",
        "la": "Vasodilatatores coronarii, non alibi clasificati"
    },
    {
        "icd": "T464",
        "rs": "Inhibitori angiotenzin-konvertujućeg enzima",
        "la": "Inhibitores enzymes convertoris anglotensini"
    },
    {
        "icd": "T465",
        "rs": "Drugi antihipertenzivni lekovi, neklasifikovani na drugom mestu",
        "la": "Remedia antihypertensiva alia, non alibi classificata"
    },
    {
        "icd": "T466",
        "rs": "Antihiperlipidemički i antiarteriosklerotički lekovi",
        "la": "Remedia antihyperlipidaemica et antiarteriosclerotica"
    },
    {
        "icd": "T467",
        "rs": "Periferni vazodilatatori",
        "la": "Vasodilatatores peripherici"
    },
    {
        "icd": "T468",
        "rs": "Antivarikozni lekovi, uključujući i sklerozantna sredstva",
        "la": "Remedia antivaricosa et agentes sclerosantes"
    },
    {
        "icd": "T469",
        "rs": "Druga i neoznačena sredstva koja prvenstveno deluju na sistem krvotoka",
        "la": "Instrumenta ad systema cardiovasculare primum afficientia alia et non specificata"
    },
    {
        "icd": "T47",
        "rs": "Trovanje sredstvima koji prvenstveno deluju na želudačno-crevni sistem",
        "la": "Intoxicatio instrumentis ad systema gastrointestinale primum afficientibus"
    },
    {
        "icd": "T470",
        "rs": "Antagonisti histaminskih H2-receptora",
        "la": "Antagonistae H2-receptoris histamini"
    },
    {
        "icd": "T471",
        "rs": "Drugi antacidi i lekovi protiv lučenja kiseline želuca",
        "la": "Remedia antacida et anti secretionem gastricam alia"
    },
    {
        "icd": "T472",
        "rs": "Stimulatorni laksativi",
        "la": "Laxativa stimulantia"
    },
    {
        "icd": "T473",
        "rs": "Soli za čišćenje i osmotski laksativi",
        "la": "Laxativa salina et osmotica"
    },
    {
        "icd": "T474",
        "rs": "Drugi laksativi",
        "la": "Laxativa alia"
    },
    {
        "icd": "T475",
        "rs": "Digestivi",
        "la": "Digestiva"
    },
    {
        "icd": "T476",
        "rs": "Lekovi protiv proliva",
        "la": "Remedia antidiarrhoeica"
    },
    {
        "icd": "T477",
        "rs": "Emetici",
        "la": "Emetica"
    },
    {
        "icd": "T478",
        "rs": "Druga sredstva koja prvenstveno deluju na želudačno-crevni sistem",
        "la": "Instrumenta ad systema gastrointestinale primum afficientia alia"
    },
    {
        "icd": "T479",
        "rs": "Sredstva koja prvenstveno deluju na želudačno-crevni sistem, neoznačena",
        "la": "Instrumenta systema ad gastrointestinale primum afficientia, non specificata"
    },
    {
        "icd": "T48",
        "rs": "Trovanje sredstvima koja prvenstveno deluju na glatke mišiće i mišiće skeleta i sistem za disanje",
        "la": "Intoxicatio instrumentis ad textum muscularem non striatum et striatum (sceletalem) et systema respiratorium primum afficientibus"
    },
    {
        "icd": "T480",
        "rs": "Oksitocički lekovi",
        "la": "Remedia oxytocica"
    },
    {
        "icd": "T481",
        "rs": "Relaksansi mišića skeleta [nervno-mišićni blokatori]",
        "la": "Relaxantia textus muscularis sceletalis [inhibitores neuromusculares]"
    },
    {
        "icd": "T482",
        "rs": "Druga i neoznačena sredstva koja prventstveno deluju na mišiće",
        "la": "Instrumenta musculos primum afficientia alia et non specificata"
    },
    {
        "icd": "T483",
        "rs": "Lekovi protiv kašlja",
        "la": "Antitussiva (remedia)"
    },
    {
        "icd": "T484",
        "rs": "Lekovi za iskašljavanje",
        "la": "Expectorantia (remedia)"
    },
    {
        "icd": "T485",
        "rs": "Lekovi protiv prehlade",
        "la": "Remedia contra frigus commune"
    },
    {
        "icd": "T486",
        "rs": "Antiastmatici, neklasifikovani na drugom mestu",
        "la": "Antiasthmatica, non alibi classificata"
    },
    {
        "icd": "T487",
        "rs": "Druga i neoznačena sredstva koja prvenstveno deluju na sistem za disanje",
        "la": "Instrumenta systema ad respiratorium primum afficientia alia et non specificata"
    },
    {
        "icd": "T49",
        "rs": "Trovanje lokalnim sredstvima koja deluju na kožu i sluznicu i trovanje oftalmološkim, otorinolaringološkim i stomatološkim lekovima",
        "la": "Intoxicatio instrumentis localibus ad cutem et tunicam mucosam primum afficientibus et intoxicatio remediis ophthalmologicis, otorhinolaryngologicis et dentalibus"
    },
    {
        "icd": "T490",
        "rs": "Lokalni antigljivični, antiinfektivni i antiinflamatorni lekovi, neklasifikovani na drugom mestu",
        "la": "Remedia antifungalia, antiinfectiva et antiinflammatoria localia, non alibi classificata"
    },
    {
        "icd": "T491",
        "rs": "Sredstva protiv svraba",
        "la": "Instrumenta antipruriginosa"
    },
    {
        "icd": "T492",
        "rs": "Lokalna adstrigentna sredstva i deterdženti za lokalnu upotrebu",
        "la": "Instrumenta adstrigentia et detergentes locales"
    },
    {
        "icd": "T493",
        "rs": "Sredstva za omekšavanje, ublažavanje i zaštitu",
        "la": "Instrumenta emollientia, demulcentia et protegenita"
    },
    {
        "icd": "T494",
        "rs": "Keratolitici, keratoplastici i drugi lekovi i preparati za lečenje kose",
        "la": "Keratolitica, keratoplastica et remedia et praeparatus ad crinem alii"
    },
    {
        "icd": "T495",
        "rs": "Lekovi i preparati za lečenje očiju",
        "la": "Remedia et praeparatus ophthalmologici"
    },
    {
        "icd": "T496",
        "rs": "Lekovi i preparati za lečenje uva, nosa i grla",
        "la": "Remedia et praeparatus otorhinolaryngologici antiinfectivi"
    },
    {
        "icd": "T497",
        "rs": "Lekovi za lečenje zuba, za lokalnu primenu",
        "la": "Remedia dentalia, ad usum localem"
    },
    {
        "icd": "T498",
        "rs": "Druga sredstva za lokalnu primenu",
        "la": "Instrumenta localia alia"
    },
    {
        "icd": "T499",
        "rs": "Sredstvo za lokalnu primenu, neoznačeno",
        "la": "Instrumentum locale, non specificatum"
    },
    {
        "icd": "T50",
        "rs": "Trovanje diureticima i drugim neoznačenim lekovima, preparatima i biološkim supstancama",
        "la": "Intoxicatio diureticis et remediis aliis non specificatis, medicamentis et substantiis biologicis"
    },
    {
        "icd": "T500",
        "rs": "Mineralokortikoidi i njihovi antagonisti",
        "la": "Mineralocorticoidae et antagonistae eorum"
    },
    {
        "icd": "T501",
        "rs": "Diuretici koji deluju na petlju bubrega [Henleovu]",
        "la": "Remedia diuretica ad ansam nephriticam afficientia"
    },
    {
        "icd": "T502",
        "rs": "Inhibitori karboanhidraze, benzotiadiazidi i drugi diuretici",
        "la": "Inhibitores carbo-anhydrasis, benzothiadiasida et remedia diuretica alia"
    },
    {
        "icd": "T503",
        "rs": "Elektrolitička, kalorijska i sredstva za održavanje ravnoteže vode",
        "la": "Instrumenta electrolytica, calorica et aquae-aequilibrica"
    },
    {
        "icd": "T504",
        "rs": "Lekovi koji deluju na metabolizam mokraćne kiseline",
        "la": "Remedia ad metabolismum acidi urici afticientia"
    },
    {
        "icd": "T505",
        "rs": "Sredstva za smanjenje apetita",
        "la": "Deprimentia appetitus"
    },
    {
        "icd": "T506",
        "rs": "Antidoti i helati, neklasifikovani na drugom mestu",
        "la": "Antidota et instrumenta chelatica, non alibi classificata"
    },
    {
        "icd": "T507",
        "rs": "Analeptici i antagonisti receptora opijata",
        "la": "Analeptica et antagonistae receptorum opiaticorum"
    },
    {
        "icd": "T508",
        "rs": "Dijagnostička sredstva",
        "la": "Agentes diagnostici"
    },
    {
        "icd": "T509",
        "rs": "Drugi i neoznačeni lekovi, preparati i biološke supstance",
        "la": "Remedia, medicamenta et substantiae biologicae alia, non specificata"
    },
    {
        "icd": "T51",
        "rs": "Toksičko dejstvo alkohola",
        "la": "Effectus alcoholis toxicus"
    },
    {
        "icd": "T510",
        "rs": "Etanol",
        "la": "Aethanolum"
    },
    {
        "icd": "T511",
        "rs": "Metanol",
        "la": "Methanolum"
    },
    {
        "icd": "T512",
        "rs": "2-propanol",
        "la": "2-propanolum"
    },
    {
        "icd": "T513",
        "rs": "Ulje u alkoholu",
        "la": "Oleum in spiritu"
    },
    {
        "icd": "T518",
        "rs": "Drugi alkoholi",
        "la": "Spiritus alii"
    },
    {
        "icd": "T519",
        "rs": "Alkohol, neoznačen",
        "la": "Spiritus, non specificatus"
    },
    {
        "icd": "T52",
        "rs": "Toksičko dejstvo organskih rastvarača",
        "la": "Effectus solventium organicorum toxici"
    },
    {
        "icd": "T520",
        "rs": "Proizvodi petroleuma",
        "la": "Producta petrolei"
    },
    {
        "icd": "T521",
        "rs": "Benzen",
        "la": "Benzenum"
    },
    {
        "icd": "T522",
        "rs": "Rastvarači istovetni sa benzenom",
        "la": "Homologa cum benzeno"
    },
    {
        "icd": "T523",
        "rs": "Glikoli",
        "la": "Glycolia"
    },
    {
        "icd": "T524",
        "rs": "Ketoni",
        "la": "Ketona"
    },
    {
        "icd": "T528",
        "rs": "Drugi organski rastarači",
        "la": "Solventes organici alii"
    },
    {
        "icd": "T529",
        "rs": "Organski rastvarači, neoznačeni",
        "la": "Solventi organici, non specificati"
    },
    {
        "icd": "T53",
        "rs": "Toksičko dejstvo halogenih derivata alifatičnih i aromatičnih ugljovodonika",
        "la": "Effectus derivatorum hydrocarbonum aliphaticorum et aromaticorum toxici halogenium"
    },
    {
        "icd": "T530",
        "rs": "Ugljen-tetrahlorid",
        "la": "Carbo- tetrachloridum"
    },
    {
        "icd": "T531",
        "rs": "Hloroform",
        "la": "Chloroformium"
    },
    {
        "icd": "T532",
        "rs": "Trihloroetilen",
        "la": "Trichloroethylenum"
    },
    {
        "icd": "T533",
        "rs": "Tetrahloroetilen",
        "la": "Tetrachloroethylenum"
    },
    {
        "icd": "T534",
        "rs": "Dihlormetan",
        "la": "Dichloromethanum"
    },
    {
        "icd": "T535",
        "rs": "Hlorfluorougljenici",
        "la": "Chlorofluorocarbones"
    },
    {
        "icd": "T536",
        "rs": "Drugi halogeni derivati alifatičnih ugljovodonika",
        "la": "Derivata hydrocarbonum aliphaticorum halogenia alia"
    },
    {
        "icd": "T537",
        "rs": "Drugi halogeni derivati aromatičnih ugljovodonika",
        "la": "Derivata hidrocarbonum aromaticorum halogenia alia"
    },
    {
        "icd": "T539",
        "rs": "Halogeni derivati alifatičnih i aromatičnih ugljovodonika, neoznačeni",
        "la": "Derivata hydrocarbonum aliphaticorum et aromaticorum halogenia, non specificata"
    },
    {
        "icd": "T54",
        "rs": "Toksičko dejstvo korozivnih supstanci",
        "la": "Effectus substantiarum corrosivarum toxicus"
    },
    {
        "icd": "T540",
        "rs": "Fenol i njegovi homolozi",
        "la": "Phenolum et eius homologa"
    },
    {
        "icd": "T541",
        "rs": "Druge korozivne organske mešavine",
        "la": "Mixturae organicae corosivae aliae"
    },
    {
        "icd": "T542",
        "rs": "Korozivne kiseline i kiselinama slične supstance",
        "la": "Acida corrosiva et substantiae acidis similes"
    },
    {
        "icd": "T543",
        "rs": "Nagrizajuće baze i bazama slične supstance",
        "la": "Alkalia corrosiva et substantiae alkaliis similes"
    },
    {
        "icd": "T549",
        "rs": "Nagrizajuća supstanca, neoznačena",
        "la": "Substantia corrosiva, non specificata"
    },
    {
        "icd": "T55",
        "rs": "Toksičko dejstvo sapuna i deterdženata",
        "la": "Effectus saponum et detergentium toxicus"
    },
    {
        "icd": "T56",
        "rs": "Toksičko dejstvo metala",
        "la": "Effectus metallorum toxicus"
    },
    {
        "icd": "T560",
        "rs": "Olovo i njegova jedinjenja",
        "la": "Plumbum et composita plumbi"
    },
    {
        "icd": "T561",
        "rs": "Živa i njena jedinjenja",
        "la": "Hydrargyrum et composita hydrargyri"
    },
    {
        "icd": "T562",
        "rs": "Hrom i njegova jedinjenja",
        "la": "Chromium et composita chromi"
    },
    {
        "icd": "T563",
        "rs": "Kadmijum i njegova jedinjenja",
        "la": "Cadmium et composita cadmii"
    },
    {
        "icd": "T564",
        "rs": "Bakar i njegova jedinjenja",
        "la": "Cuprum et composita cupri"
    },
    {
        "icd": "T565",
        "rs": "Cink i njegova jedinjenja",
        "la": "Zincum et composita zinci"
    },
    {
        "icd": "T566",
        "rs": "Kalaj i njegova jedinjenja",
        "la": "Stannum et composita stanni"
    },
    {
        "icd": "T567",
        "rs": "Berilijum i njegova jedinjenja",
        "la": "Beryllium et composita berylli"
    },
    {
        "icd": "T568",
        "rs": "Drugi metali",
        "la": "Metalla alia"
    },
    {
        "icd": "T569",
        "rs": "Metal, neoznačen",
        "la": "Metallum, non specificatum"
    },
    {
        "icd": "T57",
        "rs": "Toksičko dejstvo drugih neorganskih supstanci",
        "la": "Effectus substantiarum anorganicarum aliarum toxicus"
    },
    {
        "icd": "T570",
        "rs": "Arsen i njegova jedinjenja",
        "la": "Arsenum et composita arseni"
    },
    {
        "icd": "T571",
        "rs": "Fosfor i njegova jedinjenja",
        "la": "Phosphorum et composita phosphori"
    },
    {
        "icd": "T572",
        "rs": "Mangan i njegova jedinjenja",
        "la": "Manganum et composita mangani"
    },
    {
        "icd": "T573",
        "rs": "Cijanovodonik",
        "la": "Hydrogenium cyanidum"
    },
    {
        "icd": "T578",
        "rs": "Druge označene neorganske supstance",
        "la": "Substantiae anorganicae aliae, specificatae"
    },
    {
        "icd": "T579",
        "rs": "Neorganska supstanca, neoznačena",
        "la": "Substantia anorganica, non specificata"
    },
    {
        "icd": "T58",
        "rs": "Toksičko dejstvo ugljen-monoksida",
        "la": "Effectus carbomonoxidi toxicus"
    },
    {
        "icd": "T59",
        "rs": "Toksičko dejstvo drugih gasova, dimova i para",
        "la": "Effectus vaporum (gas), fumorum et exhalationum aliarum toxicus"
    },
    {
        "icd": "T590",
        "rs": "Azotni oksidi",
        "la": "Oxyda nitrogenii"
    },
    {
        "icd": "T591",
        "rs": "Sumpor-dioksid",
        "la": "Dioxidum sulphuricum"
    },
    {
        "icd": "T592",
        "rs": "Formaldehid",
        "la": "Formaldehydum"
    },
    {
        "icd": "T593",
        "rs": "Suzavac",
        "la": "Vapor (gas) lacrimoferens"
    },
    {
        "icd": "T594",
        "rs": "Hlorni gas",
        "la": "Vapor (gas) chloricus"
    },
    {
        "icd": "T595",
        "rs": "Fluorni gas i vodonik-fluorid",
        "la": "Vapor (gas) fluorinus et hydrogenium-fluoridum"
    },
    {
        "icd": "T596",
        "rs": "Vodonik-sulfid",
        "la": "Hydrogenium- sulfidum"
    },
    {
        "icd": "T597",
        "rs": "Ugljendioksid",
        "la": "Arbodioxidum"
    },
    {
        "icd": "T598",
        "rs": "Drugi označeni gasovi, dimovi i pare",
        "la": "Vapores (gas), fumi et exhalationes aliae, specificatae"
    },
    {
        "icd": "T599",
        "rs": "Gasovi, dimovi i pare, neoznačeni",
        "la": "Vapores (gas), fumi et exhalationes, non specificatae"
    },
    {
        "icd": "T60",
        "rs": "Toksičko dejstvo pesticida",
        "la": "Effectus pesticidorum toxicus"
    },
    {
        "icd": "T600",
        "rs": "Organofosfatni i karbamatni insekticidi",
        "la": "Organophosphata et insecticida carbamata"
    },
    {
        "icd": "T601",
        "rs": "Halogenizovani insekticidi",
        "la": "Insecticida halogenisata (mercurialia)"
    },
    {
        "icd": "T602",
        "rs": "Drugi insekticidi",
        "la": "Insecticida alia"
    },
    {
        "icd": "T603",
        "rs": "Herbicidi i fungicidi",
        "la": "Herbicida et fungicida"
    },
    {
        "icd": "T604",
        "rs": "Rodenticidi",
        "la": "Rodenticida"
    },
    {
        "icd": "T608",
        "rs": "Drugi pesticidi",
        "la": "Pesticida alia"
    },
    {
        "icd": "T609",
        "rs": "Pesticid, neoznačen",
        "la": "Pesticidum, non specificatum"
    },
    {
        "icd": "T61",
        "rs": "Toksičko dejstvo štetnih supstanci unetih kao hrana iz mora",
        "la": "Effectus substantiarum nocivarum, ut alimentum maritimum ingestarum toxicus"
    },
    {
        "icd": "T610",
        "rs": "Trovanje ribom Ciguatera",
        "la": "Intoxicatio pisce Ciguatera"
    },
    {
        "icd": "T611",
        "rs": "Trovanje ribom roda skuše",
        "la": "Intoxicatio pisce scombroidi"
    },
    {
        "icd": "T612",
        "rs": "Trovanje drugom ribom i školjkom",
        "la": "Intoxicatio pisce alio et concha"
    },
    {
        "icd": "T618",
        "rs": "Otrovno dejstvo druge morske hrane",
        "la": "Effectus allmenti maritimi alterius toxicus"
    },
    {
        "icd": "T619",
        "rs": "Otrovno dejstvo neoznačene morske hrane",
        "la": "Effectus allmenti maritimi non specificati toxicus"
    },
    {
        "icd": "T62",
        "rs": "Otrovno dejstvo drugih štetnih supstanci unetih kao hrana",
        "la": "Effectus substantiarum nocivarum aliarum, ut alimentum ingestarum toxicus"
    },
    {
        "icd": "T620",
        "rs": "Gljive unete kao hrana",
        "la": "Fungi ingesti"
    },
    {
        "icd": "T621",
        "rs": "Bobice unete kao hrana",
        "la": "Baccae ingestae"
    },
    {
        "icd": "T622",
        "rs": "Druge biljke (delovi biljke) unete kao hrana",
        "la": "Plantae ingestae aliae"
    },
    {
        "icd": "T628",
        "rs": "Druge označene štetne supstance unete kao hrana",
        "la": "Substantiae nocivae, ut alimentum ingestae, aliae, specificatae"
    },
    {
        "icd": "T629",
        "rs": "Druga štetna supstanca uneta kao hrana, neoznačena",
        "la": "Substantia nociva, ut alimentum ingesta, alia, non specificata"
    },
    {
        "icd": "T63",
        "rs": "Otrovno dejstvo kontakta sa otrovnim životinjama",
        "la": "Effectus contactus cum animalibus venenosis toxicus"
    },
    {
        "icd": "T630",
        "rs": "Ujed otrovne zmije",
        "la": "Morsus serpentis venenosi"
    },
    {
        "icd": "T631",
        "rs": "Ujed otrovnih gmizavaca",
        "la": "Morsus reptilium venenosorum"
    },
    {
        "icd": "T632",
        "rs": "Ubod otrovne škorpije",
        "la": "Morsus scorpionis venenosi"
    },
    {
        "icd": "T633",
        "rs": "Ujed otrovnog pauka",
        "la": "Morsus arachnae venenosae"
    },
    {
        "icd": "T634",
        "rs": "Ujed drugih zglavkara",
        "la": "Morsus arthropodarum aliorum"
    },
    {
        "icd": "T635",
        "rs": "Otrovno dejstvo dodira sa ribom",
        "la": "Effectus contactus cum pisce toxicus"
    },
    {
        "icd": "T636",
        "rs": "Otrovno dejstvo dodira sa drugim morskim životinjama",
        "la": "Effectus contactus cum animalibus maritimis aliis toxicus"
    },
    {
        "icd": "T638",
        "rs": "Otrovno dejstvo kontakta sa drugim otrovnim životinjama",
        "la": "Effectus contactus cum animalibus venenosis aliis toxicus"
    },
    {
        "icd": "T639",
        "rs": "Otrovno dejstvo kontakta sa neoznačenim otrovnim životinjama",
        "la": "Effectus contactus cum animalibus venenosis non specificatis toxicus"
    },
    {
        "icd": "T64",
        "rs": "Otrovno dejstvo aflatotoksina i drugih mikotoksinskih zagađivača hrane",
        "la": "Effectus aflatoxini et contaminatorum alimenti mycotoxicorum aliorum toxicus"
    },
    {
        "icd": "T65",
        "rs": "Otrovno dejstvo drugih i neoznačenih supstanci",
        "la": "Effectus substantiarum aliarum et non specificatarum toxicus"
    },
    {
        "icd": "T650",
        "rs": "Cijanidi",
        "la": "Cyanida"
    },
    {
        "icd": "T651",
        "rs": "Strihnin i njegove soli",
        "la": "Strychninum et sales strichnini"
    },
    {
        "icd": "T652",
        "rs": "Duvan i nikotin",
        "la": "Tabaccum et nicotinum"
    },
    {
        "icd": "T653",
        "rs": "Nitro- i aminoderivati benzena i njegovi homolozi",
        "la": "Nitroderivata et aminoderivata benzeni et homologa benzeni"
    },
    {
        "icd": "T654",
        "rs": "Ugljen-disulfid",
        "la": "Carbo- disulfidum"
    },
    {
        "icd": "T655",
        "rs": "Nitroglicerin i druge nitrokiseline i estri",
        "la": "Nitroglycerinum, acida nitrica alia et estri"
    },
    {
        "icd": "T656",
        "rs": "Farbe i boje, neklasifikovane na drugom mestu",
        "la": "Colores et pigmenta, non alibi classificata"
    },
    {
        "icd": "T658",
        "rs": "Otrovno dejstvo drugih označenih supstanci",
        "la": "Effectus substantiarum aliarum specificatarum toxicus"
    },
    {
        "icd": "T659",
        "rs": "Otrovno dejstvo neoznačenih supstanci",
        "la": "Effectus substantiarum non specificatarum toxicus"
    },
    {
        "icd": "T66",
        "rs": "Neoznačena dejstva zračenja",
        "la": "Effectus radiationis, non specificatus"
    },
    {
        "icd": "T67",
        "rs": "Dejstva toplote i svetlosti",
        "la": "Effectus caloris et lucis"
    },
    {
        "icd": "T670",
        "rs": "Toplotni udar i sunčanica",
        "la": "Ictus e calore et sole (insolatio)"
    },
    {
        "icd": "T671",
        "rs": "Gubitak svesti uzrokovan toplotom",
        "la": "Syncopa e calore"
    },
    {
        "icd": "T672",
        "rs": "Grčevi uzrokovani toplotom",
        "la": "Convulsiones e calore"
    },
    {
        "icd": "T673",
        "rs": "Toplotno iscrpljenje uzrokovano gubitkom tečnosti",
        "la": "Exhaustio e calore anhydrotica"
    },
    {
        "icd": "T674",
        "rs": "Iscrpljenost zbog gubitka soli uzrokovananog toplotom",
        "la": "Exhaustio e calore propter deficientiam salis"
    },
    {
        "icd": "T675",
        "rs": "Iscrpljenost uzrokovana toplotom, neoznačena",
        "la": "Exhaustio e calore, non specificata"
    },
    {
        "icd": "T676",
        "rs": "Prolazni zamor uzrokovan toplotom",
        "la": "Fatigatio e calore transitoria"
    },
    {
        "icd": "T677",
        "rs": "Edem uzrokovan toplotom",
        "la": "Oedema e calore"
    },
    {
        "icd": "T678",
        "rs": "Ostala dejstva toplote i svetla",
        "la": "Effectus caloris et lucis alii"
    },
    {
        "icd": "T679",
        "rs": "Dejstva toplote i svetla, neoznačena",
        "la": "Effectus caloris et lucis, non specificati"
    },
    {
        "icd": "T68",
        "rs": "Rashlađenje tela",
        "la": "Hypothermia"
    },
    {
        "icd": "T69",
        "rs": "Druga dejstva snižene temperature",
        "la": "Effectus temperaturae reductae alii"
    },
    {
        "icd": "T690",
        "rs": "Utapanje šake i stopala",
        "la": "Immersio manus et pedis"
    },
    {
        "icd": "T691",
        "rs": "Promrzline",
        "la": "Perniones"
    },
    {
        "icd": "T698",
        "rs": "Druga označena dejstva sniženja temperature",
        "la": "Effectus reductionis temperaturae alii, specificati"
    },
    {
        "icd": "T699",
        "rs": "Dejstva sniženja temperature, neoznačena",
        "la": "Effectus reductionis temperaturae, non specificatus"
    },
    {
        "icd": "T70",
        "rs": "Dejstva povišenog pritiska vazduha i vode",
        "la": "Effectus pressionis atmosphaericae et aquae"
    },
    {
        "icd": "T700",
        "rs": "Barotrauma uva",
        "la": "Barotrauma auris"
    },
    {
        "icd": "T701",
        "rs": "Barotrauma sinusa",
        "la": "Barotrauma sinus"
    },
    {
        "icd": "T702",
        "rs": "Druga neoznačena dejstva boravka na visini",
        "la": "Effectus altitudinis magnae alii, non specificati"
    },
    {
        "icd": "T703",
        "rs": "Kesonska bolest",
        "la": "Morbus capsicus"
    },
    {
        "icd": "T704",
        "rs": "Dejstva visokog pritiska u tečnostima",
        "la": "Effectus hypercompressionis in fluidis"
    },
    {
        "icd": "T708",
        "rs": "Druga dejstva povišenog pritiska vazduha i vode",
        "la": "Effectus pressionis atmosphaericae et aquae alii"
    },
    {
        "icd": "T709",
        "rs": "Dejstvo povišenog pritiska vazduha i vode, neoznačeno",
        "la": "Effectus pressionis atmosphaericae et aquae, non specificatus"
    },
    {
        "icd": "T71",
        "rs": "Gušenje",
        "la": "Asphyxia"
    },
    {
        "icd": "T73",
        "rs": "Dejstva drugih lišavanja",
        "la": "Effectus deprivationum aliarum"
    },
    {
        "icd": "T730",
        "rs": "Gladovanje",
        "la": "Inanitio"
    },
    {
        "icd": "T731",
        "rs": "Dejstvo žeđi (bolesno žeđanje)",
        "la": "Effectus sitis (dipsesis)"
    },
    {
        "icd": "T732",
        "rs": "Iscrpljenost uzrokovana drugim izlaganjima",
        "la": "Exhaustio propter expositionem"
    },
    {
        "icd": "T733",
        "rs": "Iscrpljenost uzrokovana prekomernim vežbanjem",
        "la": "Exhaustio propter exercitationem nimiam"
    },
    {
        "icd": "T738",
        "rs": "Druga dejstva lišavanja",
        "la": "Effectus deprivationis alii"
    },
    {
        "icd": "T739",
        "rs": "Dejstva lišavanja, neoznačena",
        "la": "Effectus deprivationis, non specificati"
    },
    {
        "icd": "T74",
        "rs": "Sindrom lošeg postupanja",
        "la": "Syndroma maltractationis"
    },
    {
        "icd": "T740",
        "rs": "Zapostavljenost ili odbačenost",
        "la": "Neglegentia sive derelictio"
    },
    {
        "icd": "T741",
        "rs": "Fizička zloupotreba",
        "la": "Abusus physicus"
    },
    {
        "icd": "T742",
        "rs": "Seksualna zloupotreba",
        "la": "Abusus sexualis"
    },
    {
        "icd": "T743",
        "rs": "Psihološka zloupotreba",
        "la": "Abusus psychologicus"
    },
    {
        "icd": "T748",
        "rs": "Drugi sindromi lošeg postupanja",
        "la": "Syndromata maltractationis alia"
    },
    {
        "icd": "T749",
        "rs": "Sindrom lošeg postupanja, neoznačen",
        "la": "Syndroma maltractationis, non specificatum"
    },
    {
        "icd": "T75",
        "rs": "Dejstva drugih spoljnih uzroka",
        "la": "Effectus causarum externarum aliarum"
    },
    {
        "icd": "T750",
        "rs": "Dejstvo munje",
        "la": "Effectus fulguris"
    },
    {
        "icd": "T751",
        "rs": "Uranjanje i utapanje bez smrtnog ishoda",
        "la": "Immersio et submersio non letalis"
    },
    {
        "icd": "T752",
        "rs": "Dejstvo vibracija",
        "la": "Effectus vibrationum"
    },
    {
        "icd": "T753",
        "rs": "Bolest kretanja",
        "la": "Kinetosis"
    },
    {
        "icd": "T754",
        "rs": "Posledice dejstva električne struje",
        "la": "Effectus cursus electrici"
    },
    {
        "icd": "T758",
        "rs": "Druga označena dejstva spoljašnjih uzroka",
        "la": "Effectus causarum externarum alii, specificati"
    },
    {
        "icd": "T78",
        "rs": "Nepovoljna dejstva, neklasifikovana na drugom mestu",
        "la": "Effectus adversi, non alibi classificati"
    },
    {
        "icd": "T780",
        "rs": "Anafilaktički šok uzrokovan nepovoljnom reakcijom na hranu",
        "la": "Afflictus (shock) anaphylacticus propter reactionem in alimentum adversam"
    },
    {
        "icd": "T781",
        "rs": "Druge nepovoljne reakcije na hranu, neklasifikovane na drugom mestu",
        "la": "Reactiones ad alimentum adversae aliae, non alibi classificatae"
    },
    {
        "icd": "T782",
        "rs": "Anafilaktički šok, neoznačen",
        "la": "Afflictus (shock) anaphylacticus, non specificatus"
    },
    {
        "icd": "T783",
        "rs": "Angioneurotski edem",
        "la": "Oedema angioneuroticum"
    },
    {
        "icd": "T784",
        "rs": "Alergija, neoznačena",
        "la": "Allergia, non specificata"
    },
    {
        "icd": "T788",
        "rs": "Druga nepovoljna dejstva, neklasifikovana na drugom mestu",
        "la": "Effectus adversi alii, non alibi classificati"
    },
    {
        "icd": "T789",
        "rs": "Nepovoljno dejstvo, neoznačeno",
        "la": "Effectus adversus, non specificatus"
    },
    {
        "icd": "T79",
        "rs": "Rane komplikacije posle povrede, neklasifikovane na drugom mestu",
        "la": "Complicationes traumatis praecoces, non alibi classificatae"
    },
    {
        "icd": "T790",
        "rs": "Vazdušna embolija uzrokovana povredom",
        "la": "Embolia aeria traumatica"
    },
    {
        "icd": "T791",
        "rs": "Masna embolija uzrokovana povredom",
        "la": "Embolia adiposa traumatica"
    },
    {
        "icd": "T792",
        "rs": "Sekundarno i ponavljano krvarenje uzrokovano povredom",
        "la": "Haemorrhagia traumatica secundaria et recidiva"
    },
    {
        "icd": "T793",
        "rs": "Infekcija rane posle povrede, neklasifikovana na drugom mestu",
        "la": "Infectio vulneris post trauma, non alibi classificata"
    },
    {
        "icd": "T794",
        "rs": "Šok uzrokovan povredom",
        "la": "Afflictus (shock) traumaticus"
    },
    {
        "icd": "T795",
        "rs": "Izostajanje lučenja mokraće uzrokovano povredom",
        "la": "Anuria posttraumatica"
    },
    {
        "icd": "T796",
        "rs": "Ishemija mišića uzrokovana povredom",
        "la": "Ischaemia musculorum traumatica"
    },
    {
        "icd": "T797",
        "rs": "Potkožni emfizem uzrokovan povredom",
        "la": "Emphysema subcutaneum traumaticum"
    },
    {
        "icd": "T798",
        "rs": "Druga rana komplikacija povrede",
        "la": "Complicatio traumatis praecox alia"
    },
    {
        "icd": "T799",
        "rs": "Rana komplikaccija povrede, neoznačena",
        "la": "Complicatio traumatis praecox, non specificata"
    },
    {
        "icd": "T80",
        "rs": "Komplikacije zbog infuzije, transfuzije i injekcije",
        "la": "Complicationes ex infusione, transfusione et injectione"
    },
    {
        "icd": "T800",
        "rs": "Vazdušna embolija zbog infuzije, transfuzije i injekcije",
        "la": "Embolia aeria ex infusione, transfusione et injectione"
    },
    {
        "icd": "T801",
        "rs": "Komplikacije na krvnim sudovima zbog infuzije, transfuzije i injekcije",
        "la": "Complicationes ex infusione, transfusione et injectione vasculares"
    },
    {
        "icd": "T802",
        "rs": "Infekcije zbog infuzije, transfuzije i injekcije",
        "la": "Infectio ex infusione, tranfusione et injectione"
    },
    {
        "icd": "T803",
        "rs": "Reakcija na neodgovarajuću krvnu grupu ABO",
        "la": "Reactio in incompatibilitatem ABO"
    },
    {
        "icd": "T804",
        "rs": "Reakcija na neodgovarajući Rh-faktor",
        "la": "Reactio in incompatibilitatem Rh"
    },
    {
        "icd": "T805",
        "rs": "Anafilaktički šok uzrokovan serumom",
        "la": "Afflictus (shock) anaphylacticus propter serum"
    },
    {
        "icd": "T806",
        "rs": "Druge reakcije na serum",
        "la": "Reactiones in serum aliae"
    },
    {
        "icd": "T808",
        "rs": "Druge komplikacije zbog infuzije, transfuzije i injekcije",
        "la": "Complicationes ex infusione, transfusione et injectione aliae"
    },
    {
        "icd": "T809",
        "rs": "Neoznačena komplikacija zbog infuzije, transfuzije i injekcije",
        "la": "Complcatio ex infusione, transfusione et injectione, non specificata"
    },
    {
        "icd": "T81",
        "rs": "Komplikacije medicinskih intervencija, neklasifikovane na drugom mestu",
        "la": "Complicationes procedurales, non alibi classificates"
    },
    {
        "icd": "T810",
        "rs": "Krvarenje i krvni podliv kao komplikacije medicinskog postupka, neklasifikovani na drugom mestu",
        "la": "Hamorrhagia et haematoma ut complicationes procedurae medicinalis, non alibi classificatae"
    },
    {
        "icd": "T811",
        "rs": "Šok za vreme ili posle medicinske intervencije, neklasifikovan na drugom mestu",
        "la": "Afflictus (shock) per sive post proceduram, non alibi classificatus"
    },
    {
        "icd": "T812",
        "rs": "Slučajni ubod i razderotina za vreme medicinske intervencije, neklasifikovana na drugom mestu",
        "la": "Punctura et laceratio accidentalis inter proceduram, non alibi classificata"
    },
    {
        "icd": "T813",
        "rs": "Poremećaj operativne rane, neklasifikovan na drugom mestu",
        "la": "Disruptio vulneris operativum non alibi classificata"
    },
    {
        "icd": "T814",
        "rs": "Infekcija nakon medicinske intervencije, neklasifikovana na drugom mestu",
        "la": "Infectio post proceduram, non alibi classificata"
    },
    {
        "icd": "T815",
        "rs": "Strano telo u operativnoj rani",
        "la": "Corpus alienum vulneris post operationem"
    },
    {
        "icd": "T816",
        "rs": "Akutna reakcija na stranu supstancu slučajno ostavljenu za vreme medicinske intervencije",
        "la": "Reactio ad substantiam alienam per proceduram casu relictam acuta"
    },
    {
        "icd": "T817",
        "rs": "Vaskularne komplikacije posle medicinske intervencije, neklasifikovane na drugom mestu",
        "la": "Complicationes vasculares post proceduram, non alibi classificatae"
    },
    {
        "icd": "T818",
        "rs": "Druge komplikacije medicinskih intervencija, neklasifikovane na drugom mestu",
        "la": "Complicationes post proceduram aliae, non alibi classificatae"
    },
    {
        "icd": "T819",
        "rs": "Neoznačena komplikacija medicinske intervencije",
        "la": "Complicatio procedurae, non specificata"
    },
    {
        "icd": "T82",
        "rs": "Komplikacije zbog ugradnje protetskih aparata, implantata i transplantata u srce i krvne sudove",
        "la": "Complicationes propter apparatos protheticos cardiacos et vasculares implantata et transplantata"
    },
    {
        "icd": "T820",
        "rs": "Mehanička komplikacija zbog protetskog srčanog zaliska",
        "la": "Complicatio valvulam cordis propter protheticam, mechanica"
    },
    {
        "icd": "T821",
        "rs": "Mehanička komplikacija zbog srčanog elektronskog uređaja",
        "la": "Complicatio propter apparatum cardiacum electronicum, mechanica"
    },
    {
        "icd": "T822",
        "rs": "Mehanička komplikacija zbog koronarnnog arterijskog bajpasa (zaobilaženja) i kalemova zalistaka srca",
        "la": "Complicatio propter circuitum (bypass) arteriarum coronariarum et transplantata valvularum, mechanica"
    },
    {
        "icd": "T823",
        "rs": "Mehaničke komplikacije zbog drugih kalemova krvnih sudova",
        "la": "Complicationes propter transplantata vascularis alia, mechanicae"
    },
    {
        "icd": "T824",
        "rs": "Mehanička komplikacija zbog katetera za dijalizu krvotoka",
        "la": "Complicatio propter catheterem ad dialysim vascularem, mechanica"
    },
    {
        "icd": "T825",
        "rs": "Mehanička komlikacija zbog drugih aparata i implantata u srcu i krvnim sudovima",
        "la": "Complicatio propter apparatus cardiacos et vasculares implantata-mechanica alios"
    },
    {
        "icd": "T826",
        "rs": "Infekcija i zapaljenjenska reakcija na protezu zaliska srca",
        "la": "Infectio et reactio inflammatoria ad prothesim valvulae cardiacae"
    },
    {
        "icd": "T827",
        "rs": "Infekcija i zapaljenjska reakcija na druge kardijalne i vaskularne aparate, implantate i kalemove",
        "la": "Infectio et reactio inflamatoria ad apparatus, cardicos et vasculares alios, implantata et transplantata."
    },
    {
        "icd": "T828",
        "rs": "Druge označene komplikacije zbog ugradnje protetskih aparata, implantata i kalemova u srce i krvne sudove",
        "la": "Complicationes propter apparatus protheticos, implantata et transplantata cardiaca et vascula. aliae"
    },
    {
        "icd": "T829",
        "rs": "Neoznačena komplikacija zbog ugradnje protetskog aparata, implantata i kalema u srce i krvne sudove",
        "la": "Complicatio propter apparatum protheticum, cardiacum et vascularem et transplantatum, non specificata"
    },
    {
        "icd": "T83",
        "rs": "Komplikacije zbog mokraćno-polnih protetskih aparata, implantata i kalemova",
        "la": "Complicationes propter apparatus protheticos urogenitales, implantata et transplantata"
    },
    {
        "icd": "T830",
        "rs": "Mehanička komplikacija zbog (stalnog) mokraćnog katerera",
        "la": "Complicatio propter catheterem urinarium (persistentem), mechanica"
    },
    {
        "icd": "T831",
        "rs": "Mehanička komplikacija zbog drugih mokraćnih aparata i implantata",
        "la": "Complicatio propter apparatus urinarios alios et implantata, mechanica"
    },
    {
        "icd": "T832",
        "rs": "Mehanička komplikacija zbog transplantacije mokraćnog organa",
        "la": "Complicatio propter transplantationem organi urinarii, mechanica"
    },
    {
        "icd": "T833",
        "rs": "Mehanička komplikacija zbog aparata ugrađenog u matericu",
        "la": "Complicatio propter apparatum contraceptivum intrauterinum, mechanica"
    },
    {
        "icd": "T834",
        "rs": "Mehanička komplikacija zbog drugih protetskih aparata, implantata i kalemova u polnom sistemu",
        "la": "Complicatio propter apparatus protheticos alios implantata et transplantata in systemate genitali mechanica"
    },
    {
        "icd": "T835",
        "rs": "Infekcija i zapaljenjska reakcija na protetski aparat, implantat i kalem u mokraćnom sistemu",
        "la": "Infectio et reactio inflammatoria ad apparatum protheticum, implantatum et transplantatum in systemate urinario"
    },
    {
        "icd": "T836",
        "rs": "Infekcija i zapaljenjska reakcija na protetski aparat, implantat i kalem u polnom sistemu",
        "la": "Infectio et reactio inflammatoria ad apparatum protheticum, implantatum et transplantatum in systemate genitali"
    },
    {
        "icd": "T838",
        "rs": "Druge komplikacije zbog mokraćno polnih protetskih aparata, implantata i kalemova",
        "la": "Complicationes propter apparatus protheticos urogenitales, implantata et transplantata aliae"
    },
    {
        "icd": "T839",
        "rs": "Neoznačena komplikacija zbog mokraćno-polnog protetskog aparata, implantata i kalema",
        "la": "Complicatio propter apparatum protheticum urogenitalem, implantata et tranplantata, non specificata"
    },
    {
        "icd": "T84",
        "rs": "Komplikacije zbog položaja unutrašnjih ortopedskih protetskih aparata, implantata i kalemova",
        "la": "Complicationes propter collocationem apparatum protheticorum, orthopedicorum internorum, implantatorum et transplantatorum"
    },
    {
        "icd": "T840",
        "rs": "Mehanicka komplikacija zbog unutrašnje proteze zgloba",
        "la": "Complicatio propter prothesim articuli internam, mechanica"
    },
    {
        "icd": "T841",
        "rs": "Mehanička komplikacija zbog aparata za unutrašnju fiksaciju kostiju uda",
        "la": "Complicatio propter apparatum ad fixationem ossium extremitatis internam, mechanica"
    },
    {
        "icd": "T842",
        "rs": "Mehanička komplikacija zbog aparata za unutrašnju fiksaciju drugih kostiju",
        "la": "Complicatio propter apparatum ad fixationem ossium aliorum internam, mechanica"
    },
    {
        "icd": "T843",
        "rs": "Mehanička komplikacija zbog drugih aparata za kosti, implantata i kalemova",
        "la": "Complicatio propter apparatus ossium alios, implantata et transplantata, mechanica"
    },
    {
        "icd": "T844",
        "rs": "Mehanička komplikacija zbog drugih unutrašnjih ortopedskih aparata, implantata i kalemova",
        "la": "Complicatio propter apparatus orthopedicos internos alios implantata et transplantata, mechanica"
    },
    {
        "icd": "T845",
        "rs": "Infekcija i zapaljenjska reakcija na unutrašnju protezu zgloba",
        "la": "Infectio et reactio inflammatoria in prothesim articularem internam"
    },
    {
        "icd": "T846",
        "rs": "Infekcija i zapaljenjska reakcija na aparat za unutrašnju fiksaciju (neodređenog mesta)",
        "la": "Infectio et reactio inflammatoria ad apparatum ad fixationem internam (loci indefiniti)"
    },
    {
        "icd": "T847",
        "rs": "Infekcija i zapaljenjska reakcija na druge unutrašnje ortopedsko-protetske aparate, implantate i kalemove",
        "la": "Infectio et reactio inflammatoria ad apparatus protheticos orthopedicos internos alios, implantata et transplantata"
    },
    {
        "icd": "T848",
        "rs": "Druge komplikacije zbog unutrašnjih ortopedskih protetskih aparata, implantata i kalemova",
        "la": "Complicationes propter apparatus protheticos orthopaedicos internos, implantata et transplantata orthopedica aliae"
    },
    {
        "icd": "T849",
        "rs": "Neoznačena komplikacija zbog unutrašnjeg ortopedskog protetskog aparata, implantata i kalema",
        "la": "Complicatio propter apparatum protheticum orthopedicum internum, implantata et transplantata, non specificata"
    },
    {
        "icd": "T85",
        "rs": "Komplikacije zbog drugih unutrašnjih protetskih aparata, implantata i kalemova",
        "la": "Complicationes propter apparatus protheticos internos allios, implantata et transplantata"
    },
    {
        "icd": "T850",
        "rs": "Mehanička komplikacija zbog komornog unutarlobanjskog komunikacionog kanala",
        "la": "Complicatio propter canalem (communicantem) ventricularem intracranialem, mechanica"
    },
    {
        "icd": "T851",
        "rs": "Mehanička komplikacija zbog implantiranog elektronskog stimulatora nervnog sistema",
        "la": "Complicatio propter stimulatorem systematis nervosi electronicum implantatum, mechanica"
    },
    {
        "icd": "T852",
        "rs": "Mehanička komplikacija zbog unutaročnog sočiva",
        "la": "Complicatio propter lentem intraocularem, mechanica"
    },
    {
        "icd": "T853",
        "rs": "Mehanička komplikacija zbog drugih očnih protetskih aparata",
        "la": "Complicatio propter apparatus protheticos oculorum allos, implantata et transplantata, mechanica"
    },
    {
        "icd": "T854",
        "rs": "Mehanička komplikacija zbog proteze i implantata dojke",
        "la": "Complicatio propter prothesim et implantata mammae mechanica"
    },
    {
        "icd": "T855",
        "rs": "Mehanička komplikacija zbog želudačno-crevnih protetskih aparata, implantata i kalemova",
        "la": "Complicatio propter apparatus protheticos gastrointestinales, implantata et transplantata. mechanica"
    },
    {
        "icd": "T856",
        "rs": "Mehanička komplikacija zbog drugih označenih unutrašnjih protetskih aparata, implantata i kalemova",
        "la": "Complicatio propter apparatus protheticos internos specificatos implantata et transplantata, mechanica"
    },
    {
        "icd": "T857",
        "rs": "Infekcija i zapaljenjska reakcija na ostale unutrašnje protetske aparate, implantate i kalemove",
        "la": "Infectio et reactio inflammatoria ad apparatus protheticos internos alios, implantata et transplantata"
    },
    {
        "icd": "T858",
        "rs": "Druge komplikacije zbog unutrašnjih protetskih aparata, implantata i kalemova, neklasifikovane na drugom mestu",
        "la": "Complicationes propter apparatus protheticos internos, implantata et transplantata aliae, non alibi classificatae"
    },
    {
        "icd": "T859",
        "rs": "Neoznačena komplikacija zbog unutrašnjeg protetskog aparata, implantata i kalema",
        "la": "Complicatio propter apparatum protheticum internum, implantata et transplantata, non specificata"
    },
    {
        "icd": "T86",
        "rs": "Otkazivanje i odbacivanje transplantiranih organa i tkiva",
        "la": "Insufficientia et rejectio organorum et textuum transplantatorum"
    },
    {
        "icd": "T860",
        "rs": "Odbacivanje transplantirane koštane srži",
        "la": "Rejectio medullae ossium transplantatae"
    },
    {
        "icd": "T861",
        "rs": "Otkazivanje i odbacivanje transplantiranog bubrega",
        "la": "Insufficientia et rejectio renis transplantati"
    },
    {
        "icd": "T862",
        "rs": "Otkazivanje i odbacivanje transplantiranog srca",
        "la": "Insufficientia et rejectio cordis transplantati"
    },
    {
        "icd": "T863",
        "rs": "Otkazivanje i odbacivanje transplantata srce - pluća",
        "la": "Insufficientia et rejectio transplantati cardio-pulmonalis"
    },
    {
        "icd": "T864",
        "rs": "Neuspeh i odbacivanje transplantata jetre",
        "la": "Insufficientia et rejectio transplantati hepatis"
    },
    {
        "icd": "T868",
        "rs": "Neuspeh i odbacivanje drugih transplantiranih organa i tkiva",
        "la": "Insufficientia et rejectio organorum et textuum transplantatorum aliorum"
    },
    {
        "icd": "T869",
        "rs": "Otkaz i odbacivanje neoznačenog transplantiranog organa i tkiva",
        "la": "Insufficientia et rejectio organi et textus transplantati non specificati"
    },
    {
        "icd": "T87",
        "rs": "Komplikacije svojstvene ponovnom spajanju i amputaciji",
        "la": "Complicationes propter reunionem et amputationem"
    },
    {
        "icd": "T870",
        "rs": "Komplikacije ponovno spojenog dela ruke",
        "la": "Complicationes partis extremitatis superioris reunitae"
    },
    {
        "icd": "T871",
        "rs": "Komplikacije ponovno spojenog dela noge",
        "la": "Complicationes partis extremitatis inferioris reunitae"
    },
    {
        "icd": "T872",
        "rs": "Komplikacije drugog ponovo spojenog dela tela",
        "la": "Complicationes partis corporis reunitae, alterius"
    },
    {
        "icd": "T873",
        "rs": "Neurom amputiranog patrljka",
        "la": "Neuroma trunculi amputati"
    },
    {
        "icd": "T874",
        "rs": "Infekcija amputiranog patrljka",
        "la": "Infectio trunculi amputati"
    },
    {
        "icd": "T875",
        "rs": "Nekroza amputiranog patrljka",
        "la": "Necrosis trunculi amputati"
    },
    {
        "icd": "T876",
        "rs": "Druge neoznačene komplikacije amputiranog patrljka",
        "la": "Complicationes trunculi amputati aliae, non specificatae"
    },
    {
        "icd": "T88",
        "rs": "Druge komplikacije hirurške i medicinske nege",
        "la": "Complicationes curae chirurgicae et medicalis aliae"
    },
    {
        "icd": "T880",
        "rs": "Infekcija posle imunizacije",
        "la": "Infectio post immunisationem"
    },
    {
        "icd": "T881",
        "rs": "Druge komplikacije posle imunizacije, neklasifikovane na drugom mestu",
        "la": "Complicationes post immunisationem aliae, non alibi classificatae"
    },
    {
        "icd": "T882",
        "rs": "Šok uzrokovan anestezijom",
        "la": "Afflictus (shock) propter anaesthesiam"
    },
    {
        "icd": "T883",
        "rs": "Teška hipertermija uzrokovana anestezijom",
        "la": "Hyperthermia maligna propter anaesthesiam"
    },
    {
        "icd": "T884",
        "rs": "Neuspela ili teška intubacija",
        "la": "Intubatio inefficax sive gravis"
    },
    {
        "icd": "T885",
        "rs": "Druge komplikacije uzrokovane anestezijom",
        "la": "Complicationes propter anaesthesiam, aliae"
    },
    {
        "icd": "T886",
        "rs": "Anafilaktički šok uzrokovan nepovoljnim dejstvom pravilno primenjenog leka ili preparata",
        "la": "Afflictus (shock) anaphylacticus propter effectum remedii sive medicamenti correcti exacte applicati adversum"
    },
    {
        "icd": "T887",
        "rs": "Neoznačeno nepovoljno dejstvo leka ili preparata",
        "la": "Effectus remedii sive medicamenti adversus, non specificatus"
    },
    {
        "icd": "T888",
        "rs": "Druge označene komplikacije hirurške i medicinske nege, neklasifikovane na drugom mestu",
        "la": "Complicationes curae chirurgicae et medicalis aliae, specificatae, non alibi classificatae"
    },
    {
        "icd": "T889",
        "rs": "Komplikacija hirurške i medicinske nege, neoznačena",
        "la": "Complicatio curae chirurgicae et medicalis, non specificata"
    },
    {
        "icd": "T90",
        "rs": "Posledice povreda glave",
        "la": "Sequelae laesionum traumaticarum capitis"
    },
    {
        "icd": "T900",
        "rs": "Posledice površinske povrede glave",
        "la": "Sequelae laesionis traumaticae capitis, superficiales"
    },
    {
        "icd": "T901",
        "rs": "Posledice otvorene rane glave",
        "la": "Sequelae vulneris aperti capitis"
    },
    {
        "icd": "T902",
        "rs": "Posledice preloma kostiju lobanje i lica",
        "la": "Sequelae fracturae ossium cranii et faciei"
    },
    {
        "icd": "T903",
        "rs": "Posledice povrede lobanjskih živaca",
        "la": "Sequelae laesionis traumaticae nervorum cranii"
    },
    {
        "icd": "T904",
        "rs": "Posledice povrede oka i duplje oka",
        "la": "Sequelae laesionis traumaticae oculi et orbitae"
    },
    {
        "icd": "T905",
        "rs": "Posledice povrede unutar lobanje",
        "la": "Sequelae laesionis traumaticae intracranialis"
    },
    {
        "icd": "T908",
        "rs": "Posledice drugih označenih povreda glave",
        "la": "Sequelae laesionum traumaticarum capitis aliarum specificatarum"
    },
    {
        "icd": "T909",
        "rs": "Posledice neoznačene povrede glave",
        "la": "Sequelae laesionis traumaticae capitis, non specificatae"
    },
    {
        "icd": "T91",
        "rs": "Posledice povreda vrata i trupa",
        "la": "Sequelae leasionum traumaticarum colli et trunci"
    },
    {
        "icd": "T910",
        "rs": "Posledice površinske povrede i otvorene povrede vrata i trupa",
        "la": "Sequelae laesionis traumaticae superficialis et vulneris aperti colli et trunci"
    },
    {
        "icd": "T911",
        "rs": "Posledice preloma kičme",
        "la": "Sequelae fracturae columnae vertebralis"
    },
    {
        "icd": "T912",
        "rs": "Posledice drugih preloma grudnog koša i karlice",
        "la": "Sequelae fracturarum thoracis et pelvis aliarum"
    },
    {
        "icd": "T913",
        "rs": "Posledice povrede kičmene moždine",
        "la": "Sequelae laesionis traumaticae medullae spinalis"
    },
    {
        "icd": "T914",
        "rs": "Posledice povrede organa grudnog koša",
        "la": "Sequelae laesionis traumaticae organorum thoracis"
    },
    {
        "icd": "T915",
        "rs": "Posledice povrede organa trbuha i karlice",
        "la": "Sequelae laesionis traumaticae organorum abdominis et pelvis"
    },
    {
        "icd": "T918",
        "rs": "Posledice drugih označenih povreda vrata i trupa",
        "la": "Sequelae laesionum traumaticarum colli et trunci aliarum specificatarum"
    },
    {
        "icd": "T919",
        "rs": "Posledice neoznačene povrede vrata i trupa",
        "la": "Sequelae laesionis traumaticae colli et trunci, non specificatae"
    },
    {
        "icd": "T92",
        "rs": "Posledice povreda ruke",
        "la": "Sequelae laesionum traumaticarum extremitatis superioris"
    },
    {
        "icd": "T920",
        "rs": "Posledice otvorene rane ruke",
        "la": "Sequelae vulneris aperti extremitatis superioris"
    },
    {
        "icd": "T921",
        "rs": "Posledice preloma ruke",
        "la": "Sequelae fracturae extremitatis superioris"
    },
    {
        "icd": "T922",
        "rs": "Posledice preloma ručja i šake",
        "la": "Sequelae fracturae carpi et manus"
    },
    {
        "icd": "T923",
        "rs": "Posledice iščašenja, uganuća i istegnuća ruke",
        "la": "Sequelae luxationis, distorsionis et distensionis extremitatis superioris"
    },
    {
        "icd": "T924",
        "rs": "Posledice povrede živca ruke",
        "la": "Sequelae laesionis traumaticae nervi extremitatis superioris"
    },
    {
        "icd": "T925",
        "rs": "Posledice povrede mišića i tetive ruke",
        "la": "Sequelae laesionis traumaticae musculi et tendinis extremitatis superioris"
    },
    {
        "icd": "T926",
        "rs": "Posledice zdrobljavanja i traumatske amputacije ruke",
        "la": "Sequelae conquassationis et amputationis extremitatis superioris"
    },
    {
        "icd": "T928",
        "rs": "Posledice drugih označenih povreda ruke",
        "la": "Sequelae laesionum traumaticarum extremitatis superioris aliarum specificatarum"
    },
    {
        "icd": "T929",
        "rs": "Posledice neoznačene povrede ruke",
        "la": "Sequelae laesionis traumaticae extremitatis superioris non specificatae"
    },
    {
        "icd": "T93",
        "rs": "Posledice povreda nogu",
        "la": "Sequelae laesionum traumaticarum extremitatis inferioris"
    },
    {
        "icd": "T930",
        "rs": "Posledice otvorene rane noge",
        "la": "Sequelae vulneris aperti extremitatis inferioris"
    },
    {
        "icd": "T931",
        "rs": "Posledice preloma butnjače",
        "la": "Sequelae fracturae femoris"
    },
    {
        "icd": "T932",
        "rs": "Posledice drugih preloma noge",
        "la": "Sequelae fracturarum extremitatis inferioris aliarum"
    },
    {
        "icd": "T933",
        "rs": "Posledice iščašenja, uganuća i istegnuća noge",
        "la": "Sequelae luxationis, distorsionis et distensionis extremitatis inferioris"
    },
    {
        "icd": "T934",
        "rs": "Posledice povrede živca noge",
        "la": "Sequelae laesionis traumaticae nervi extremitatis inferioris"
    },
    {
        "icd": "T935",
        "rs": "Posledice povrede mišića i tetive noge",
        "la": "Sequelae laesionis traumaticae musculi et tendinis extremitatis inferioris"
    },
    {
        "icd": "T936",
        "rs": "Posledice zdrobljavanja i traumatske amputacije noge",
        "la": "Sequelae laesionis traumaticae conquassatione et amputatione extremitatis inferioris"
    },
    {
        "icd": "T938",
        "rs": "Posledice drugih označenih povreda noge",
        "la": "Sequelae laesionum traumaticarum extremitatis inferioris aliarum specificatarum"
    },
    {
        "icd": "T939",
        "rs": "Posledice neoznačene povrede noge",
        "la": "Sequelae laesionis traumaticae extremitatis inferioris non specificatae"
    },
    {
        "icd": "T94",
        "rs": "Posledice povreda u više neoznačenih predela tela",
        "la": "Sequelae laesionum traumaticarum regionum corporis multiplicum, non specificatarum"
    },
    {
        "icd": "T940",
        "rs": "Posledice povreda više predela tela",
        "la": "Sequelae laesionum traumaticarum regionum corporis multiplicium"
    },
    {
        "icd": "T941",
        "rs": "Posledice povreda neoznačenih predela tela",
        "la": "Sequelae laesionum traumaticarum regionum corporis non specificatarum"
    },
    {
        "icd": "T95",
        "rs": "Posledice opekotina, razjeda i promrzline",
        "la": "Sequelae combustionis, corrosionis et congelationis"
    },
    {
        "icd": "T950",
        "rs": "Posledice opekotine, razjeda i promrzline glave i vrata",
        "la": "Sequelae combustionis, corrosionis et congelationis capitis et colli"
    },
    {
        "icd": "T951",
        "rs": "Posledice opekotine, razjeda i promrzline trupa",
        "la": "Sequelae combustionis, corrosionis et congelationis trunci"
    },
    {
        "icd": "T952",
        "rs": "Posledice opekotine, razjeda i promrzline ruke",
        "la": "Sequelae combustionis, corrosionis et congelationis extremitatis superioris"
    },
    {
        "icd": "T953",
        "rs": "Polsedice opekotine, razjeda i promrzline noge",
        "la": "Sequelae combustionis, corrosionis et congelationis extremitatis inferioris"
    },
    {
        "icd": "T954",
        "rs": "Posledice opekotine i razjeda klasifikovane samo na osnovu obima zahvaćene površine tela",
        "la": "Sequelae combustionis et corrosionis secundum amplitudinem superficiei corporis comprehensae classificatae"
    },
    {
        "icd": "T958",
        "rs": "Posledice druge označene opekotine, razjeda i promrzline",
        "la": "Sequelae combustionis, corrosionis et congelationis alterius specificatae"
    },
    {
        "icd": "T959",
        "rs": "Posledice neoznačene opekotine, razjeda i promrzline",
        "la": "Sequelae combustionis, corrosionis et congelationis non specificatae"
    },
    {
        "icd": "T96",
        "rs": "Posledice trovanja lekovima, preparatima i biološkim supstancama",
        "la": "Sequelae intoxicationis remediis medicamentis et substantiis biologicis"
    },
    {
        "icd": "T97",
        "rs": "Posledice toksičkih dejstava uglavnom nemedicinskih supstanci",
        "la": "Sequelae effectuum toxicorum substantiarum, capitulatim, non medicinalibus"
    },
    {
        "icd": "T98",
        "rs": "Posledice drugih i neoznačenih dejstava spoljašnjih faktora",
        "la": "Sequelae effectuum agentium externorum, aliorum et non specificatorum"
    },
    {
        "icd": "T980",
        "rs": "Posledice dejstva stranog tela koje je ušlo u telo kroz prirodni otvor",
        "la": "Sequelae effectus corporis alieni per orificium naturale inserti"
    },
    {
        "icd": "T981",
        "rs": "Posledice drugih i neoznačenih dejstava spoljnih faktora",
        "la": "Sequelae effectuum agentium externorum, aliorum et non specificatorum"
    },
    {
        "icd": "T982",
        "rs": "Posledice izvesnih ranih komplikacija povrede",
        "la": "Sequelae complicationum traumatis certarum praecocium"
    },
    {
        "icd": "T983",
        "rs": "Posledice komplikacija zbog hirurške i medicinske nege, neklasifikovane na drugom mestu",
        "la": "Sequelae complicationum curae chirurgicae et medicinalis, non alibi classificatae"
    }
];

export default icd;