import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatient, setPatientCurrentProtocol, setPatientProtocolCurrentPage, setPatientProtocolCurrentQuestion } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import Convert from './Convert';
import Footer from '../appbar/Footer';

function Patients(props)
{
    const [patientsList, setPatientsList] = useState([]);

    const [deletePatient, setDeletePatient] = useState({});
    const [confirmDeletePatientDialog, setConfirmDeletePatientDialog] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const [searchText, setSearchText] = useState("");
    const [lastPatientDate, setLastPatientDate] = useState("2100-01-01");
    const [_lastProtocolIndex, setLastProtocolIndex] = useState(9999);

    useEffect(() =>
    {
        getDataAsync('/patients_info/lastProtocolIndex', setLastProtocolIndex);
        getPatients();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const getPatients = (appendToEnd = false) =>
    {
        if(appendToEnd)
            getListOrderByChildEndBeforeLimit('/patients', (result) => drawPatients(result, appendToEnd), 'createdDate', lastPatientDate);
        else
            getListOrderByChildEndBeforeLimit('/patients', (result) => drawPatients(result, appendToEnd), 'createdDate', "2100-01-01");
    }

    const drawPatients = (patients, appendToEnd = false) =>
    {
        let list = [];
        let tempLastDate = lastPatientDate;
        for (var i = 0; i < patients.length; i++)
        {
            let patient = patients[i];
            if (dayjs(patient.createdDate).isBefore(dayjs(tempLastDate)))
                tempLastDate = patient.createdDate;

            list.push(
                <TableRow
                    key={patient.protocol_number}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        <Button onClick={() => onPatientClick(patient)} key={"button_" + patient.index}>{patient.protocol_number + " : " + patient.name + " (" + dayjs(patient.birthdate).format('YYYY') + ")"}</Button>
                    </TableCell>
                    <TableCell align="right">
                        {user.role == "admin" && (
                            <IconButton aria-label="delete" onClick={() => onPatientDeleteClick(patient)}>
                                <DeleteIcon />
                            </IconButton>)}
                    </TableCell>
                </TableRow>);
        }

        setLastPatientDate(tempLastDate);
        if (appendToEnd)
            setPatientsList(patientsList.concat(list));
        else
            setPatientsList(list);
    };

    const onPatientClick = (patient) =>
    {
        dispatch(setPatient(patient));
        dispatch(setPatientCurrentProtocol({}));
        dispatch(setPatientProtocolCurrentPage(-1));
        dispatch(setPatientProtocolCurrentQuestion(-1));
        navigate('/patient');
    }

    const onPatientDeleteClick = (patient) =>
    {
        setDeletePatient(patient);
        setConfirmDeletePatientDialog(true);
    }

    const onPatientDeleteConfirm = (isConfirmed) =>
    {
        if (isConfirmed)
        {
            getPatientsProtocols((protocols) =>
            {
                for (var i = 0; i < protocols.length; i++)
                {
                    if(protocols[i].pages !== undefined)
                        for (var j = 0; j < protocols[i].pages.length; j++)
                        {
                            deleteData('/patients_protocols_pages/' + protocols[i].pages[j].id);
                        }
                    deleteData('/patients_protocols/' + protocols[i].id);
                }
                deleteData('/patients/' + deletePatient.id, () =>
                {
                    getPatients();
                });
                console.log("DELETED PATIENT: " + deletePatient.name);
            },
                deletePatient.id
            );
        }

        setConfirmDeletePatientDialog(false);
    }

    const onSearchButtonClick = (event) =>
    {
        if (searchText !== "")
        {
            getSearchPatients(searchText, drawPatients);
        }
        else
        {
            getPatients();
        }
    }

    const onAddPatient = (event) =>
    {
        let newPatient = {
            id: uuidv4(),
            email: "@gmail.com",
            address: "",
            age: "",
            birthdate: dayjs().format(),
            createdDate: dayjs().format(),
            city: "Beograd",
            gender: "",
            language: "en",
            name: "Prezime i ime pacijenta",
            phone: "+381 6",
            protocol_number: 1 + _lastProtocolIndex + "/" + dayjs().format("YY"),
            zip: "11000"
        };

        updateData('/patients_info', { lastProtocolIndex: _lastProtocolIndex + 1 });
        writeData('/patients/' + newPatient.id, newPatient, () => onPatientClick(newPatient));
    }

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            <ConfirmationDialog isOpen={confirmDeletePatientDialog} callback={onPatientDeleteConfirm} />
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    {/*<Convert />*/}
                    <Typography align="center" variant="h5">
                        Pacijenti
                    </Typography>
                    <Paper className="padding20">
                        <Stack direction="row" spacing={3}>
                        <TextField
                                fullWidth
                                placeholder="Traži pacijenta po PREZIMENU"
                                defaultValue={searchText}
                                onChange={(event) => setSearchText(event.target.value.toUpperCase())}
                                onKeyUp={(event) =>
                                {
                                    if (event.key === 'Enter')
                                        onSearchButtonClick(null);
                                }}
                            />
                            <Button variant="outline" size="small" onClick={onSearchButtonClick}>Traži</Button>
                            {user.role == "admin" && (<Button variant="contained" color="secondary" size="small" onClick={onAddPatient}>Novi Pacijent</Button>)}
                        </Stack>
                    </Paper>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Protokol : Prezime i Ime (Godina rođenja)</TableCell>
                                    <TableCell align="right">Akcije</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {patientsList}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) =>
                        {
                            getPatients(true);
                        }}
                    >Učitaj još pacijenata</Button>
                </Stack>
            </Container>
            <Spacer height={40} />
            <Footer />
        </div>
    );
}

export default withAuthentication(Patients);
