import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate, useLocation  } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { getUser } from '../user/UserSlice';
import { useSelector } from 'react-redux';
import { BottomNavigation, BottomNavigationAction, ButtonGroup, Paper, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import FaceIcon from '@mui/icons-material/Face';
import TaskIcon from '@mui/icons-material/Task';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BarChartIcon from '@mui/icons-material/BarChart';
import { getPatient, getPatientCurrentProtocol } from '../patient/PatientSlice';

function AppBarNavigation()
{
    const navigate = useNavigate();
    const user = useSelector(getUser);
    const patient = useSelector(getPatient);
    const protocol = useSelector(getPatientCurrentProtocol);

    const pages = [{ name: 'Pacijenti', path: '/', icon: "GroupsIcon" }];
    if (patient.id != "")
        pages.push({ name: patient.name, path: '/patient', icon: "FaceIcon" });
    if (protocol.id != undefined)
        pages.push({ name: 'Poseta', path: '/patient_protocol', icon: "TaskIcon" });
    
    if (user.role == "admin")
    {
        pages.push({ name: 'Admin', path: '/protocols', icon: "AdminPanelSettingsIcon" });
        pages.push({ name: 'Stats', path: '/statistics', icon: "BarChartIcon" });
    }

    const location = useLocation();

    const getIcon = name =>
    {
        switch (name)
        {
            case "GroupsIcon": return <GroupsIcon />;
            case "FaceIcon": return <FaceIcon />;
            case "TaskIcon": return <TaskIcon />;
            case "AdminPanelSettingsIcon": return <AdminPanelSettingsIcon />;
            case "BarChartIcon": return <BarChartIcon />;
            default: return <TaskIcon />
        }
    }

    return (
        <Stack direction="row" spacing={3}>
            <ToggleButtonGroup
                color="bela"
                value={location.pathname}
                exclusive
                onChange={(event, newValue) =>
                {
                    navigate(newValue);
                }}
            >
                {pages.length && pages.map(page => (
                    <ToggleButton key={page.name} sx={{ color: '#c28585' }} value={page.path}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            {getIcon(page.icon)}
                            {page.name}
                        </Stack>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Stack>
    );
}
export default AppBarNavigation;