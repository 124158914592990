import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, TextField, Select, MenuItem, Autocomplete } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData } from '../../app/firebase';
import moment from 'moment';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatientCurrentProtocol, setPatient, getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentPage } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import { cloneDeep } from 'lodash';
import PatientProtocolNavigation from './PatientProtocolNavigation';
import PatientProtocolDiagnosis from './PatientProtocolDiagnosis';
import Spacer from '../common/Spacer';
import Footer from '../appbar/Footer';
import ProtocolTypeLabelMap from '../common/ProtocolTypeLabelMap';
import { DateTimeField } from '@mui/x-date-pickers';
import * as dayjs from 'dayjs';
import PatientPaymentOptions from './PatientPaymentOptions';
import icd from './ICD';

function PatientProtocolFinish(props)
{
    const user = useSelector(getUser);
    const protocol = useSelector(getPatientCurrentProtocol);
    const protocol_page = useSelector(getPatientCurrentPage);

    const [localProtocol, setLocalProtocol] = useState(protocol);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    var listenerId = "";

    useEffect(() =>
    {
        
        // cleanup function
        return () =>
        {
            //stopListening(listenerId);
        };
    }, []);

    const [pageIndex, setPageIndex] = useState(protocol_page);
    const onPageChange = (newPage) =>
    {
        dispatch(setPatientProtocolCurrentPage(newPage));
        setPageIndex(newPage);
    }

    const updateProtocol = async (property, value) =>
    {
        let updateObj = {};
        updateObj[property] = value;
        updateData('/patients_protocols/' + localProtocol.id, updateObj, () => console.log('Protocol updated'));

        let newProtocol = cloneDeep(protocol);
        newProtocol[property] = value;
        dispatch(setPatientCurrentProtocol(newProtocol));
    };

    return (
        <Stack spacing={3}>
            <PatientProtocolNavigation onPageChange={onPageChange} showSelect />
            <Paper className="padding20">
                <Stack direction="row" spacing={3} alignContent="center" justifyContent="space-between" alignItems="center">
                    <TextField
                        style={{ maxWidth: 180 }}
                        disabled
                        variant="outlined"
                        label="Tip posete"
                        defaultValue={ProtocolTypeLabelMap[localProtocol.type]}
                    />
                    <TextField
                        style={{ maxWidth: 65 }}
                        disabled
                        variant="outlined"
                        label="Serija"
                        defaultValue={localProtocol.protocol_number}
                    />
                    <TextField
                        style={{ maxWidth: 85 }}
                        disabled
                        variant="outlined"
                        label="Dolazak"
                        defaultValue={localProtocol.protocol_round}
                    />
                    <DateTimeField
                        disabled
                        label="Datum"
                        disableFuture={true}
                        formatDensity="spacious"
                        format="DD/MM/YYYY HH:mm"
                        value={dayjs(localProtocol.date) || dayjs()}
                    />
                    <TextField
                        style={{ maxWidth: 150 }}
                        variant="outlined"
                        label="Plaćeno (RSD)"
                        defaultValue={localProtocol.paid_price}
                        onBlur={event => updateProtocol('paid_price', event.target.value)}
                    />
                    <PatientPaymentOptions defaultValue={localProtocol.payment_type} updateProtocol={updateProtocol} showLabel />
                </Stack>
            </Paper>
            {localProtocol.type === 'exam' && (
                <Paper className="padding20">
                    <Typography variant="h5">Dijagnoza (MKB)</Typography>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={icd}
                        getOptionLabel={(option) => option.icd + " - " + option.rs}
                        defaultValue={localProtocol.diagnosis_icd}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                        onChange={(e, newValue) =>
                        {
                            updateProtocol('diagnosis_icd', newValue);
                        }}
                    />
                </Paper>
            )}
            {localProtocol.type === 'exam' && (
                <Paper className="padding20">
                    <Typography variant="h5">Dijagnoza</Typography>
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        defaultValue={localProtocol.diagnosis}
                        onBlur={event => updateProtocol('diagnosis', event.target.value)}
                    />
                </Paper>
            )}
            <Paper className="padding20">
                <Typography variant="h5">{localProtocol.type == "exam" ? 'Zaključak' : 'Napomene'}</Typography>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    defaultValue={localProtocol.conclusion}
                    onBlur={event => updateProtocol('conclusion', event.target.value)}
                />
            </Paper>
            {localProtocol.type === 'exam' && (
                <Paper className="padding20">
                    <Typography variant="h5">Terapija</Typography>
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        defaultValue={localProtocol.therapy}
                        onBlur={event => updateProtocol('therapy', event.target.value)}
                    />
                </Paper>
            )}
            <Paper className="padding20">
                <Typography variant="h5">Sledeći korak</Typography>
                <Spacer />
                <Stack direction="row" spacing={3}>
                    {user.role == "admin" && (<Button variant="contained" onClick={() => navigate('/patient_protocol_finish_print')} color="secondary">Odštampaj izveštaj</Button>)}
                    <Button variant="contained" onClick={() => navigate('/patient')}>Završi posetu</Button>
                </Stack>
            </Paper>
            <Typography align="center" variant="h4">
                IZVEŠTAJ POSETE
            </Typography>
            <Paper className="padding20">
                <PatientProtocolDiagnosis />
            </Paper>
            <Spacer height={40} />
            <PatientProtocolNavigation onPageChange={onPageChange} showSelect />
            <Spacer height={40} />
        </Stack>
    );
}

export default withAuthentication(PatientProtocolFinish);
