import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './features/login/Login';
import Signup from './features/login/Signup';
import Patient from './features/patient/Patient';
import Patients from './features/patient/Patients';
import { theme } from './app/styles';
import PatientProtocolFinishPrint from './features/patient/PatientProtocolFinishPrint';
import AdminProtocols from './features/administration/AdminProtocols';
import AdminProtocol from './features/administration/AdminProtocol';
import PatientProtocol from './features/patient/PatientProtocol';
import Statistics from './features/statistics/Statistics';


function App()
{
  return (
    <div style={{ minHeight: "100vh", backgroundColor: theme.palette.background.default}}>
        <Router>
            <Routes>
                <Route path="/" element={<Patients />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/patient" element={<Patient />} />
                <Route path="/patient_protocol" element={<PatientProtocol />} />
                <Route path="/patient_protocol_finish_print" element={<PatientProtocolFinishPrint />} />
                <Route path="/protocols" element={<AdminProtocols />} />
                <Route path="/protocol" element={<AdminProtocol />} />
                <Route path="/statistics" element={<Statistics />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
