import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientCurrentProtocol } from './PatientSlice';
import * as dayjs from 'dayjs';
import { Box, Button, Grid, Typography } from '@mui/material';

export default function PatientProtocolMedicalRecords()
{
    const domain = "https://physiopro.nika.rs/uploads/";
    const protocol = useSelector(getPatientCurrentProtocol);
    const dispatch = useDispatch();

    const getRecords = () =>
    {
        let records = [];
        if (protocol.medicalRecords == undefined) return records;


        for (var i = 0; i < protocol.medicalRecords.length; i++)
        {
            let record = protocol.medicalRecords[i];
            if (record.files == undefined) continue;

            for (var j = 0; j < record.files.length; j++)
            {
                let item = { date: record.date, filename: record.files[j] };

                let ext = item.filename.split('.').pop();
                let imageComponent =
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: 248, p: 2, border: '5px dashed grey' }}>
                        <Typography variant="h3">{ext}</Typography>
                    </Grid>;

                if (ext == "jpg" || ext == "jpeg" || ext == "gif" || ext == "bmp" || ext == "png")
                    imageComponent = <img
                        src={domain + protocol.id + "/" + item.filename}
                        style={{ height: 250 }}
                        alt={item.filename}
                        loading="lazy"
                    />;

                let component = <a key={item.date + item.filename} href={domain + protocol.id + "/" + item.filename} target="_blank">
                    <ImageListItem>
                        {imageComponent}
                        <ImageListItemBar
                            subtitle={item.filename}
                            title={<span>{dayjs(item.date).format('YYYY-MM-DD')}</span>}
                            position="bottom"
                        />
                    </ImageListItem></a>;

                records.push(component);
            }
        }

        return records;
    }

    return (
        <ImageList cols={4} sx={{  }}>
            {getRecords()}
        </ImageList>
    );
}