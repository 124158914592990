import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const initialState = {
    id: "",
    email: "",
    address: "",
    age: "",
    birthdate: "",
    city: "",
    gender: "",
    index: "",
    language: "",
    name: "",
    phone: "",
    protocol_number: "",
    zip: "",
    current_protocol: {},
    current_page: -1,
    current_question: -1,
    protocols: {}
};

export const PatientSlice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
        setPatient: (state, action) =>
        {
            Object.assign(state, action.payload);
        },
        setPatientProtocols: (state, action) =>
        {
            Object.assign(state.protocols, action.payload);
        },
        setPatientCurrentProtocol: (state, action) =>
        {
            state.current_protocol = cloneDeep(action.payload);
        },
        setPatientProtocolCurrentPage: (state, action) =>
        {
            state.current_page = action.payload;
        },
        setPatientProtocolCurrentQuestion: (state, action) =>
        {
            state.current_question = action.payload;
        },

    },
});

export const getPatient = (state) => state.patient;
export const getPatientCurrentProtocol = (state) => state.patient.current_protocol;
export const getPatientCurrentPage = (state) => state.patient.current_page;
export const getPatientCurrentQuestion = (state) => state.patient.current_question;

export const { setPatient, setPatientProtocols, setPatientProtocolCurrentPage, setPatientCurrentProtocol, setPatientProtocolCurrentQuestion } = PatientSlice.actions;
export default PatientSlice.reducer;
