import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const initialState = {
    current_protocol: {},
    current_page: 0,
    current_question: -1
};

export const ProtocolSlice = createSlice({
    name: 'protocol',
    initialState,
    reducers: {
        setCurrentProtocol: (state, action) =>
        {
            state.current_protocol = cloneDeep(action.payload);
        },
        setProtocolCurrentPage: (state, action) =>
        {
            state.current_page = action.payload;
        },
        setProtocolCurrentQuestion: (state, action) =>
        {
            state.current_question = action.payload;
        },

    },
});

export const getCurrentProtocol = (state) => state.protocol.current_protocol;
export const getProtocolCurrentPage = (state) => state.protocol.current_page;
export const getProtocolCurrentQuestion = (state) => state.protocol.current_question;

export const { setProtocolCurrentPage, setCurrentProtocol, setProtocolCurrentQuestion } = ProtocolSlice.actions;
export default ProtocolSlice.reducer;
