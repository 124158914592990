import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, FormControl, InputLabel, Select, MenuItem, Box, Collapse, TextField } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { deleteData, getDataAsync, getPatientsProtocols, updateData, writeData } from '../../app/firebase';
import moment from 'moment';
import Convert from './Convert';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatientCurrentProtocol, setPatient, setPatientProtocolCurrentPage, setPatientProtocolCurrentQuestion } from './PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import ProtocolTypeLabelMap from '../common/ProtocolTypeLabelMap';
import * as dayjs from 'dayjs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PatientPaymentOptions from './PatientPaymentOptions';

function PatientProtocols(props)
{
    const patient = useSelector(getPatient);
    const user = useSelector(getUser);

    const [protocolsList, setProtocolsList] = useState([]);
    const [deleteProtocol, setDeleteProtocol] = useState({});
    const [confirmDeleteProtocolDialog, setConfirmDeleteProtocolDialog] = useState(false);

    const [newProtocolType, setNewProtocolType] = useState('exam');
    const [visitsCount, setVisitsCount] = useState({});

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() =>
    {
        getPatientsProtocols(onPatientsProtocols, patient.id);
        // cleanup function
        return () =>
        {
            
        };
    }, []);

    const onPatientsProtocols = (protocols) =>
    {
        // find the highest protocol_round per protocol type
        let tempVisits = {};
        let protocolsTree = {};
        for (var i = 0; i < protocols.length; i++)
        {
            let protocol = protocols[i];
            if (protocol.protocol_round == 1)
            {
                protocol.subProtocols = [];
                protocol.lastRound = 1;
                protocolsTree[protocol.type + protocol.protocol_number] = protocol;
            }
        }

        for (var i = 0; i < protocols.length; i++)
        {
            let protocol = protocols[i];

            //add protocols as subprotocols to the main protocol
            if (protocol.protocol_round > 1)
            {
                protocolsTree[protocol.type + protocol.protocol_number].subProtocols.push(protocol);
                if (protocol.protocol_round > protocolsTree[protocol.type + protocol.protocol_number].lastRound)
                    protocolsTree[protocol.type + protocol.protocol_number].lastRound = protocol.protocol_round;
            }

            if (tempVisits[protocol.type + protocol.protocol_number] == undefined)
            {
                tempVisits[protocol.type + protocol.protocol_number] = 0;
            }
            tempVisits[protocol.type + protocol.protocol_number] += 1;
        }

        let newProtocols = [];
        Object.keys(protocolsTree).map(key =>
        {
            newProtocols.push(protocolsTree[key]);
        });

        setVisitsCount(tempVisits);
        setProtocolsList(newProtocols);
    };

    const onProtocolClick = (protocol) =>
    {
        dispatch(setPatientCurrentProtocol(protocol));
        dispatch(setPatientProtocolCurrentPage(-1));
        dispatch(setPatientProtocolCurrentQuestion(-1));
        navigate('/patient_protocol');
    }

    const onProtocolDeleteClick = (protocol) =>
    {
        setDeleteProtocol(protocol);
        setConfirmDeleteProtocolDialog(true);
    }

    const onPatientDeleteConfirm = (isConfirmed) =>
    {
        if (isConfirmed)
        {
            if (deleteProtocol.pages !== undefined)
                for (var j = 0; j < deleteProtocol.pages.length; j++)
                {
                    deleteData('/patients_protocols_pages/' + deleteProtocol.pages[j].id);
                }
            deleteData('/patients_protocols/' + deleteProtocol.id, () => getPatientsProtocols(onPatientsProtocols, patient.id));
        }

        setConfirmDeleteProtocolDialog(false);
    }

    let newProtocolData = {};

    const onStartNewProtocol = () =>
    {
        // find the latest protocol of requested type
        let newProtocolNumber = 0;
        protocolsList.forEach(protocol =>
        {
            if (protocol.type == newProtocolType &&
                protocol.protocol_number > newProtocolNumber)
                newProtocolNumber = protocol.protocol_number;
        });

        let conclusion = '​Uvidom u medicinsku dokumentaciju, anamnezu i funkcionalni status, trebalo bi da se u prvoj terapijskoj fazi sprovedu analgetske, relaksirajuće i revitalizirajuće fizikalne terapije, kao uvod u kineziterapiju (sistemsko rešenje).';
        let therapy = 'POTREBNO JE:\n•\tFth., Kth.; \n•\tmirovanje dok traju terapije';

        if (newProtocolType != "exam")
        {
            conclusion = '';
            therapy = '';
        }

        // set new protocol and save it
        newProtocolData = {
            date: moment().valueOf(),
            id: uuidv4(),
            patient: patient.id,
            protocol_number: (newProtocolNumber + 1),
            protocol_round: 1,
            type: newProtocolType,
            conclusion: conclusion,
            therapy: therapy
        }
        writeData('/patients_protocols/' + newProtocolData.id, newProtocolData, onNewProtocolAdded);
    }

    const onNewProtocolAdded = () =>
    {
        dispatch(setPatientCurrentProtocol(newProtocolData));
        dispatch(setPatientProtocolCurrentPage(-1));
        dispatch(setPatientProtocolCurrentQuestion(-1));
        navigate('/patient_protocol');
    }

    let oldPages = [];

    const onContinueProtocol = (protocol) =>
    {
        newProtocolData = cloneDeep(protocol);
        oldPages = newProtocolData.pages == undefined ? [] : newProtocolData.pages;
        oldPages.forEach(page =>
        {
            let newPageId = uuidv4();
            duplicatePage(page.id, newPageId);
            page.id = newPageId;
        });
        newProtocolData.id = uuidv4();
        newProtocolData.pages = oldPages;
        newProtocolData.date = moment().valueOf();
        newProtocolData.protocol_round = newProtocolData.lastRound + 1;
        newProtocolData.paid_price = 0;

        writeData('/patients_protocols/' + newProtocolData.id, newProtocolData, onNewProtocolAdded);
    }

    const duplicatePage = (oldPageId, newPageId) =>
    {
        getDataAsync('/patients_protocols_pages/' + oldPageId, (value) => onDuplicatePageLoad(value, newPageId));
    }

    const onDuplicatePageLoad = (page, newPageId) =>
    {
        let newPage = cloneDeep(page);
        writeData('/patients_protocols_pages/' + newPageId, newPage);
    }

    const onProtocolPropertyUpdate = (protocol, property, value) =>
    {
        let updateObj = {};
        updateObj[property] = value;
        updateData('/patients_protocols/' + protocol.id, updateObj, () => getPatientsProtocols(onPatientsProtocols, patient.id));
    }

    return (
        <Stack spacing={3}>
            {user.role == "admin" && 
                <Paper className="padding20">
                <Stack direction="row" spacing={3} alignItems="center">
                    <Typography variant="h6">Započni novu posetu: </Typography>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="protocol-select-label">Tip posete</InputLabel>
                        <Select
                            labelId="protocol-select-label"
                            label="Tip posete"
                            value={newProtocolType}
                            onChange={(e) => setNewProtocolType(e.target.value)}>
                            {Object.keys(ProtocolTypeLabelMap).map(key =>
                            {
                                return <MenuItem key={key} value={key}>{ProtocolTypeLabelMap[key]}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={(e) => onStartNewProtocol()}>Pokreni</Button>
                </Stack>
            </Paper>}
            <ConfirmationDialog isOpen={confirmDeleteProtocolDialog} callback={onPatientDeleteConfirm} />
            <Paper className="padding10">
                <Typography variant="h5">Prethodne posete</Typography>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">Akcije</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {protocolsList && protocolsList.map(protocol =>
                        (
                            <Row
                                key={protocol.id}
                                protocol={protocol}
                                onProtocolClick={onProtocolClick}
                                onContinueProtocol={onContinueProtocol}
                                onProtocolDeleteClick={onProtocolDeleteClick}
                                onProtocolPropertyUpdate={onProtocolPropertyUpdate}
                                user={user}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Stack>
    );
}

function Row(props)
{
    const { protocol, onProtocolClick, onContinueProtocol, onProtocolDeleteClick, user, onProtocolPropertyUpdate } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow
                key={protocol.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell width={50}>
                    {protocol.subProtocols.length > 0 && (<IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>)}
                </TableCell>
                <TableCell component="th" scope="row">
                    <Button onClick={() => onProtocolClick(protocol)} key={"button_" + protocol.id}>{ProtocolTypeLabelMap[protocol.type] + " " + protocol.protocol_number}</Button>
                </TableCell>
                <TableCell align="right">
                    {(protocol.type == "therapy" || protocol.type == "kinesiotherapy") && protocol.protocol_round == 1 &&
                        <Button variant="text" color="secondary" onClick={(e) => onContinueProtocol(protocol)}>Nastavi</Button>}

                    {user.role == "admin" && (
                        <IconButton aria-label="delete" onClick={() => onProtocolDeleteClick(protocol)}>
                            <DeleteIcon />
                        </IconButton>)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#fff4e9" }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Pregled dolazaka
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Plaćeno</TableCell>
                                        <TableCell>Način plaćanja</TableCell>
                                        <TableCell>Datum</TableCell>
                                        <TableCell>Dolazak</TableCell>
                                        <TableCell align="right">Akcije</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {protocol.subProtocols.map((protocol) => (
                                        <TableRow
                                            key={protocol.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" width={50}>
                                                <TextField
                                                    variant="standard"
                                                    defaultValue={protocol.paid_price}
                                                    onBlur={event => onProtocolPropertyUpdate(protocol, 'paid_price', event.target.value)} />
                                            </TableCell>
                                            <TableCell component="th" scope="row" width={110}>
                                                <PatientPaymentOptions variant="standard" defaultValue={protocol.payment_type} updateProtocol={event => onProtocolPropertyUpdate(protocol, 'payment_type', event.target.value)} />
                                            </TableCell>
                                            <TableCell component="th" scope="row" width={50}>{dayjs(protocol.date).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <Button onClick={() => onProtocolClick(protocol)} key={"button_" + protocol.id}>{ProtocolTypeLabelMap[protocol.type] + " " + protocol.protocol_number + " - " + protocol.protocol_round}</Button>
                                            </TableCell>
                                            <TableCell align="right">
                                                {user.role == "admin" && (
                                                    <IconButton aria-label="delete" onClick={() => onProtocolDeleteClick(protocol)}>
                                                        <DeleteIcon />
                                                    </IconButton>)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow
                                        key={protocol.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" width={50}>
                                            <TextField
                                                variant="standard"
                                                defaultValue={protocol.paid_price}
                                                onBlur={event => onProtocolPropertyUpdate(protocol, 'paid_price', event.target.value)} />
                                        </TableCell>
                                        <TableCell component="th" scope="row" width={110}>
                                            <PatientPaymentOptions variant="standard" defaultValue={protocol.payment_type} updateProtocol={event => onProtocolPropertyUpdate(protocol, 'payment_type', event.target.value)} />
                                        </TableCell>
                                        <TableCell component="th" scope="row" width={50}>{dayjs(protocol.date).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell component="th" scope="row">
                                            <Button onClick={() => onProtocolClick(protocol)} key={"button_" + protocol.id}>{ProtocolTypeLabelMap[protocol.type] + " " + protocol.protocol_number + " - " + protocol.protocol_round}</Button>
                                        </TableCell>
                                        <TableCell align="right">
                                            {user.role == "admin" && (
                                                <IconButton aria-label="delete" onClick={() => onProtocolDeleteClick(protocol)}>
                                                    <DeleteIcon />
                                                </IconButton>)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default withAuthentication(PatientProtocols);
