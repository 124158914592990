import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getDataAsync, getListOrderByChildEndBeforeLimit, updateData, writeData } from '../../app/firebase';
import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import ProtocolTypeLabelMap from '../common/ProtocolTypeLabelMap';

export default function PageCopyDialog(props)
{
    const dispatch = useDispatch();

    const [protocolsList, setProtocolsList] = React.useState({});
    const [typeFilter, setTypeFilter] = React.useState("exam");
    const [selectedProtocol, setSelectedProtocol] = React.useState("");

    React.useEffect(() =>
    {
        getProtocols();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const getProtocols = () =>
    {
        getListOrderByChildEndBeforeLimit('/protocols', setProtocols, 'name', 'z', 9999, false);
    }

    const setProtocols = (protocols) =>
    {
        let newProtocols = {};
        for (var i = 0; i < protocols.length; i++)
        {
            newProtocols[protocols[i].id] = protocols[i];
        }
        setProtocolsList(newProtocols);
    };

    const onCopyConfirmClick = event =>
    {
        if (selectedProtocol !== "")
        {
            console.log('/protocols/' + protocolsList[selectedProtocol].id);
            getDataAsync('/protocols/' + protocolsList[selectedProtocol].id, (protocol) => onProtocolLoaded(protocol));
        }
    }

    const onProtocolLoaded = (protocol) =>
    {
        let newProtocol = cloneDeep(protocol);
        if (protocol.pages == undefined) newProtocol.pages = [];

        let newPage = cloneDeep(props.selectedQuestion);
        newPage.id = uuidv4();
        newPage.index = newProtocol.pages.length;

        newProtocol.pages.push(newPage);

        getDataAsync('/protocols_pages/' + props.selectedQuestion.id, (page) =>
        {
            let newPageData = cloneDeep(page);
            writeData('/protocols_pages/' + newPage.id, newPageData, () =>
            {
                writeData('/protocols/' + newProtocol.id, newProtocol, handleConfirm);
            });
        });
    }

    const handleCancel = () =>
    {
        props.callback(false);
    }

    const handleConfirm = () =>
    {
        props.callback(true);
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title">
                {"Kopiraj stranu?"}
            </DialogTitle>
            <DialogContent>
                <Stack>
                    <FormControl sx={{ m: 1, minWidth: 450 }}>
                        <InputLabel id="type-select-label">Izaberi tip posete</InputLabel>
                        <Select labelId="type-select-label" label="Izaberi tip posete" value={typeFilter} onChange={event =>
                        {
                            setTypeFilter(event.target.value);

                            // find the first protocol with the filter
                            const keys = Object.keys(protocolsList);
                            let foundProtocol = "";
                            for (var i = 0; i < keys.length; i++)
                            {
                                if (protocolsList[keys[i]].type == event.target.value)
                                {
                                    foundProtocol = protocolsList[keys[i]].id;
                                    break;
                                }
                            }
                            
                            setSelectedProtocol(foundProtocol);
                        }
                        }>
                            {Object.keys(ProtocolTypeLabelMap).map(key =>
                            {
                                return <MenuItem key={key} value={key}>{ProtocolTypeLabelMap[key]}</MenuItem>;
                            })}

                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="protokol-select-label">Izaberi protokol</InputLabel>
                        <Select labelId="protokol-select-label" label="Izaberi protokol" value={selectedProtocol} onChange={event =>
                        {
                            setSelectedProtocol(event.target.value);
                        }
                        }>
                            {Object.keys(protocolsList).map(key =>
                            {
                                let protocol = protocolsList[key];
                                if (protocol.type !== typeFilter) return null;
                                return <MenuItem key={protocol.id} value={protocol.id}>{protocol.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Odustani</Button>
                <Button onClick={onCopyConfirmClick}>
                    KOPIRAJ
                </Button>
            </DialogActions>
        </Dialog>
    );
}