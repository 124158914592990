import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';

export default function ShortAnswerView(props) {
  const patientAnswer = props.patientAnswer === undefined ? '' : props.patientAnswer;
  return (
    <TextField
      variant="outlined"
      fullWidth
          onBlur={event => props.answer({ index: props.index, answer: event.target.value })}
          defaultValue={patientAnswer}
    />
  );
}

export function ShortAnswerDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '')
      return <Grid container direction="row" justify="flex-start" alignItems="center" className="dontBreak">
          <Paper style={{ padding: 15 }}>
          <Typography variant="body1">
        <b>{props.question}</b>
      </Typography>
      <Box/>
      <Typography variant="body2" display="inline">
        {props.patientAnswer}
              </Typography>
              </Paper>
    </Grid>;
  else return null;
}
