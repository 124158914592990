import { Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

export default function VideoAdmin(props)
{
    return (
        <div>
            <TextField
                variant="outlined"
                label="Youtube URL:"
                fullWidth
                defaultValue={props.videoURL}
                onBlur={event => props.setProperty('videoURL', event.target.value)}
            />
            <Stack direction="row">
                <FormControlLabel
                    control={<Checkbox checked={props.height == true} color="primary" onChange={event => props.setProperty('height', event.target.checked)} />}
                    label="Large Size"
                />
                <FormControlLabel
                    control={<Checkbox checked={props.controls == true} color="primary" onChange={event => props.setProperty('controls', event.target.checked)} />}
                    label="Controls"
                />
                <FormControlLabel
                    control={<Checkbox checked={props.autoplay == true} color="primary" onChange={event => props.setProperty('autoplay', event.target.checked)} />}
                    label="Autoplay"
                />
                <FormControlLabel
                    control={<Checkbox checked={props.mute == true} color="primary" onChange={event => props.setProperty('mute', event.target.checked)} />}
                    label="Mute"
                />
                <FormControlLabel
                    control={<Checkbox checked={props.loop == true} color="primary" onChange={event => props.setProperty('loop', event.target.checked)} />}
                    label="Loop"
                />
            </Stack>
        </div>);
}
