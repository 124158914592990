import { getPatientCurrentProtocol, getPatientCurrentPage, setPatientProtocolCurrentPage, setPatientCurrentProtocol } from './PatientSlice';
import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, Grid, FormControl, InputLabel, Select, TextField, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { deleteData, getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData, writeData } from '../../app/firebase';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HeightIcon from '@mui/icons-material/Height';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Spacer from '../common/Spacer';
import { getUser } from '../user/UserSlice';

function PatientProtocolPagesAdmin(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const protocol = useSelector(getPatientCurrentProtocol);
    
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [deletePageID, setDeletePageID] = useState(0);

    useEffect(() =>
    {
        // cleanup function
        return () =>
        {
        };
    }, [protocol]);

    const onPatientDeleteConfirm = (isConfirmed) =>
    {
        if (isConfirmed)
        {
            const newProtocol = cloneDeep(protocol);
            newProtocol.pages.splice(deletePageID, 1);
            for (let index = 0; index < newProtocol.pages.length; index++)
            {
                newProtocol.pages[index].index = index;
            }

            updateData('/patients_protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
            {
                console.log('Page deleted');
                deleteData('/patients_protocols_pages/' + protocol.pages[deletePageID].id, () => { dispatch(setPatientCurrentProtocol(newProtocol)); dispatch(setPatientProtocolCurrentPage(-1)); });
            });
        }

        setIsDeleteOpen(false);
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex)
        {
            const newProtocol = cloneDeep(protocol);
            const item = newProtocol.pages.splice(fromIndex, 1)[0];
            newProtocol.pages.splice(toIndex, 0, item);
            for (let index = 0; index < newProtocol.pages.length; index++)
            {
                newProtocol.pages[index].index = index;
            }

            
            updateData('/patients_protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
            {
                console.log('Page order changed');
                dispatch(setPatientCurrentProtocol(newProtocol));
            });
        },
        nodeSelector: '.protocolPagesSelector',
        handleSelector: '.protocolPagesHandle',
    };

    return (
        <Stack
            spacing={5}
        >
            <ConfirmationDialog isOpen={isDeleteOpen} callback={onPatientDeleteConfirm} />
            <ReactDragListView {...dragProps} >
                {protocol.pages && protocol.pages.length && protocol.pages.map(element => (
                    <div key={element.index + element.name} className="protocolPagesSelector">
                        <Stack direction="row" spacing={3}>
                            <Typography sx={{ flexGrow: 1 }}>#{element.index+1} - {element.name}</Typography>
                            <Button className="protocolPagesHandle">
                                <HeightIcon />
                            </Button>
                            <Button
                                onClick={() =>
                                {
                                    setDeletePageID(element.index);
                                    setIsDeleteOpen(true);
                                }}
                            >
                                <DeleteOutlineIcon />
                            </Button>
                        </Stack>
                        <Divider />
                    </div>
                ))}
            </ReactDragListView>
        </Stack>
    );
}

export default PatientProtocolPagesAdmin;
