import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { getPatient, setPatient, setPatientCurrentProtocol, setPatientProtocolCurrentPage } from '../patient/PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import Footer from '../appbar/Footer';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';

function ChartTotalsPerType(props)
{
    const [data, setData] = useState([]);

    useEffect(() =>
    {
        drawData(props.data);
        // cleanup function
        return () =>
        {
        };
    }, [props]);

    const drawData = dataRaw =>
    {
        console.log("Received patients. DRAW PATIENTS");
        let list = {};
        for (var i = 0; i < dataRaw.length; i++)
        {
            let protocol = dataRaw[i];
            let date = dayjs(protocol.date);

            if (list[date.format('YYYY-MM')] == undefined)
                list[date.format('YYYY-MM')] = {};

            if (list[date.format('YYYY-MM')][protocol.type] == undefined)
                list[date.format('YYYY-MM')][protocol.type] = 0;

            list[date.format('YYYY-MM')][protocol.type] += 1;
        }

        let chartData = [];
        Object.keys(list).map(key =>
            chartData.push({ date: key, exam: list[key]["exam"], therapy: list[key]["therapy"], kinesiotherapy: list[key]["kinesiotherapy"] })
        );

        chartData.sort((a, b) =>
        {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (dateA > dateB)
            {
                return 1;
            } else if (dateA < dateB)
            {
                return -1;
            }
            return 0;
        });

        setData(chartData);
    };

    const monthTickFormatter = (tick) =>
    {
        const date = new Date(tick);
        return date.getMonth() + 1;
    };

    return (
            <Paper className="padding20">
                <LineChart
                    width={1100}
                    height={500}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line dataKey="exam" stroke="#ff0000" type="monotone" />
                    <Line dataKey="therapy" stroke="#0000ff" type="monotone" />
                <Line dataKey="kinesiotherapy" stroke="#08a708" type="monotone" />
                </LineChart>
            </Paper>
    );
}

export default ChartTotalsPerType;
