import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getDataAsync, getListOrderByChildEndBeforeLimit, updateData, writeData } from '../../app/firebase';
import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import ProtocolTypeLabelMap from '../common/ProtocolTypeLabelMap';
import { getCurrentProtocol } from './ProtocolSlice';

export default function ElementCopyDialog(props)
{
    const dispatch = useDispatch();
    const protocol = useSelector(getCurrentProtocol);

    const [protocolsList, setProtocolsList] = React.useState({});
    const [typeFilter, setTypeFilter] = React.useState("exam");
    const [selectedProtocol, setSelectedProtocol] = React.useState("");
    const [selectedProgram, setSelectedProgram] = React.useState("");

    React.useEffect(() =>
    {
        getProtocols();
        // cleanup function
        return () =>
        {
        };
    }, [protocol]);

    const getProtocols = () =>
    {
        getListOrderByChildEndBeforeLimit('/protocols', setProtocols, 'name', 'z', 9999, false);
    }

    const setProtocols = (protocols) =>
    {
        let newProtocols = {};        
        for (var i = 0; i < protocols.length; i++)
        {
            newProtocols[protocols[i].id] = protocols[i];
        }
        setProtocolsList(newProtocols);

        setSelectedProtocol(props.protocol.id);
        setSelectedProgram(props.pageIndex);
    };

    const onCopyConfirmClick = event =>
    {
        if (selectedProgram !== "")
        {
            console.log('/protocols_pages/' + protocolsList[selectedProtocol].pages[selectedProgram].id);
            getDataAsync('/protocols_pages/' + protocolsList[selectedProtocol].pages[selectedProgram].id, (page) => onProtocolPageLoaded(page, protocolsList[selectedProtocol].pages[selectedProgram].id));
        }
    }

    const onProtocolPageLoaded = (page, id) =>
    {
        let newQuestion = cloneDeep(props.selectedQuestion);
        newQuestion.index = page.length;

        let newPage = cloneDeep(page);
        newPage.push(newQuestion);

        writeData('/protocols_pages/' + id, newPage, () =>
        {
            handleConfirm();
        });
    }

    const handleCancel = () =>
    {
        props.callback(false);
    }

    const handleConfirm = () =>
    {
        props.callback(true);
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title">
                {"Kopiraj element?"}
            </DialogTitle>
            <DialogContent>
                <Stack>
                    <FormControl sx={{ m: 1, minWidth: 450 }}>
                        <InputLabel id="type-select-label">Izaberi tip posete</InputLabel>
                        <Select labelId="type-select-label" label="Izaberi tip posete" value={typeFilter} onChange={event =>
                        {
                            setTypeFilter(event.target.value);

                            // find the first protocol with the filter
                            const keys = Object.keys(protocolsList);
                            let foundProtocol = "";
                            for (var i = 0; i < keys.length; i++)
                            {
                                if (protocolsList[keys[i]].type == event.target.value)
                                {
                                    foundProtocol = protocolsList[keys[i]].id;
                                    break;
                                }
                            }
                            
                            setSelectedProtocol(foundProtocol);
                            setSelectedProgram(0);
                        }
                        }>
                            {Object.keys(ProtocolTypeLabelMap).map(key =>
                            {
                                return <MenuItem key={key} value={key}>{ProtocolTypeLabelMap[key]}</MenuItem>;
                            })}

                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="protokol-select-label">Izaberi protokol</InputLabel>
                        <Select labelId="protokol-select-label" label="Izaberi protokol" value={selectedProtocol} onChange={event =>
                        {
                            setSelectedProtocol(event.target.value);
                            setSelectedProgram(protocolsList[event.target.value].pages[0].index);
                        }
                        }>
                            {Object.keys(protocolsList).map(key =>
                            {
                                let protocol = protocolsList[key];
                                if (protocol.type !== typeFilter) return null;
                                return <MenuItem key={protocol.id} value={protocol.id}>{protocol.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="program-select-label">Izaberi program</InputLabel>
                        <Select labelId="program-select-label" label="Izaberi program" value={selectedProgram} onChange={event => setSelectedProgram(event.target.value)}>
                            {protocolsList[selectedProtocol] && protocolsList[selectedProtocol].pages != undefined && protocolsList[selectedProtocol].pages.map(page =>
                            {
                                return <MenuItem key={page.id} value={page.index}>{page.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Odustani</Button>
                <Button onClick={onCopyConfirmClick}>
                    KOPIRAJ
                </Button>
            </DialogActions>
        </Dialog>
    );
}