const ProtocolTypeLabelMap =
{
    all: "Sve",
    exam: "Pregled",
    therapy: "Fizikalna terapija",
    kinesiotherapy: "Kineziterapija",
    grouptherapy: "Grupne vežbe",
    instruments: "Instrumenti",
    somatoped: "Somatoped",
    logoped: "Logoped",
    psiholog: "Psiholog",
    endokrinolog: "Endokrinolog",
    template: "Template"
};

export default ProtocolTypeLabelMap;