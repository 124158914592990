import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@mui/material';

export default function DateTimeView(props) {
  const today = new Date();
  const patientAnswer = props.patientAnswer === undefined ? today.valueOf() : props.patientAnswer;
  return (
    <DatePicker
      margin="normal"
      format="DD/MM/yyyy"
      value={patientAnswer}
      onChange={date => props.answer({ index: props.index, answer: date.valueOf() })}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  );
}

export function DateDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '') {
    const today = new Date(props.patientAnswer);
      return <Grid container direction="row" justify="flex-start" alignItems="center">
      <Typography variant="body1">
        <b>{props.question}</b>
      </Typography>
      <Box/>
      <Typography variant="body2" display="inline">
        {moment(today).format('DD/MM/yyyy')}
      </Typography>
    </Grid>;
  } else return null;
}
