import { Button, Container, Divider, Pagination, Paper, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getData, writeData, getListOrderByChildEndBeforeLimit } from '../../app/firebase';
import moment from 'moment';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useDispatch, useSelector } from 'react-redux';
import { getPatient, setPatient } from './PatientSlice';
import PatientForm from './PatientForm';
import PatientProtocols from './PatientProtocols';
import Spacer from '../common/Spacer';
import Footer from '../appbar/Footer';

function Patient(props)
{
    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            <Container maxWidth="lg">
                <Paper className="padding20">
                    <PatientForm />
                </Paper>
                <Spacer />
                <PatientProtocols />
            </Container>
            <Footer />
        </div>
    );
}

export default withAuthentication(Patient);
