import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Typography, Box, Grid, FormControl, InputLabel, Select, TextField, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { deleteData, getList, getListOrderByChild, getPatientProtocolPages, getPatientsProtocols, startListeningChildrenChanged, stopListening, updateData, writeData } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import * as Components from '../components/Components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HeightIcon from '@mui/icons-material/Height';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Spacer from '../common/Spacer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getCurrentProtocol, getProtocolCurrentPage, getProtocolCurrentQuestion, setCurrentProtocol, setProtocolCurrentPage, setProtocolCurrentQuestion } from './ProtocolSlice';
import PageCopyDialog from './PageCopyDialog';

function AdminProtocolPages(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const protocol = useSelector(getCurrentProtocol);
    const protocol_page = useSelector(getProtocolCurrentPage);
    let page = null;
    if (protocol.pages !== undefined)
    {
        page = protocol.pages[protocol_page];
        console.log("current pageId: " + page.id);
    }
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [deletePageID, setDeletePageID] = useState(0);
    const [isCopyOpen, setIsCopyOpen] = useState(false);
    const [copyQuestion, setCopyQuestion] = useState({});

    useEffect(() =>
    {
        console.log("USE EFFECT");
        // cleanup function
        return () =>
        {
            console.log("clear AdminProtocolPages");
        };
    }, [protocol_page, protocol]);

    const selectPage = (pageIndex) =>
    {
        console.log("selectPage: " + pageIndex);
        dispatch(setProtocolCurrentPage(pageIndex));
    }

    const onSetProperty = (property, data) =>
    {
        const newProtocol = cloneDeep(protocol);
        newProtocol.pages[protocol_page][property] = data;

        let valueObj = {};
        valueObj[property] = data;

        updateData('/protocols/' + protocol.id + '/pages/' + protocol_page, valueObj, () => dispatch(setCurrentProtocol(newProtocol)));
    }

    const onPatientDeleteConfirm = (isConfirmed) =>
    {
        console.log("isConfirmed: " + isConfirmed);

        if (isConfirmed)
        {
            const newProtocol = cloneDeep(protocol);
            newProtocol.pages.splice(deletePageID, 1);
            for (let index = 0; index < newProtocol.pages.length; index++)
            {
                newProtocol.pages[index].index = index;
            }
            
            updateData('/protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
            {
                console.log('Page deleted');
                deleteData('/protocols_pages/' + protocol.pages[deletePageID].id, () => { dispatch(setCurrentProtocol(newProtocol)); dispatch(setProtocolCurrentPage(0)); });
            });
        }

        setIsDeleteOpen(false);
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex)
        {
            const newProtocol = cloneDeep(protocol);
            const item = newProtocol.pages.splice(fromIndex, 1)[0];
            newProtocol.pages.splice(toIndex, 0, item);
            for (let index = 0; index < newProtocol.pages.length; index++)
            {
                newProtocol.pages[index].index = index;
            }

            updateData('/protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
            {
                console.log('Page order changed');
                dispatch(setCurrentProtocol(newProtocol));
                dispatch(setProtocolCurrentPage(toIndex));
            });
        },
        nodeSelector: '.protocolPagesSelector',
        handleSelector: '.protocolPagesHandle',
    };

    const onAddNewPageClick = event =>
    {
        const newProtocol = cloneDeep(protocol);
        if (newProtocol.pages == undefined)
            newProtocol.pages = [];

        const newPage = { id: uuidv4(), name: "New Page", index: newProtocol.pages.length };
        newProtocol.pages.push(newPage);

        updateData('/protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
        {
            console.log('Page added');
            writeData('/protocols_pages/' + newPage.id,
                [{
                    index: newPage.index,
                    question: "New Question",
                    type: "shortanswer",
                    answers: [{ index: 0, answer: '' }]
                }],
                () => dispatch(setCurrentProtocol(newProtocol)));
        });
    }

    const onElementCopyConfirm = (isOpen) =>
    {
        setIsCopyOpen(false);
    }

    return (
        <div>
            <ConfirmationDialog isOpen={isDeleteOpen} callback={onPatientDeleteConfirm} />
            <PageCopyDialog isOpen={isCopyOpen} callback={onElementCopyConfirm} selectedQuestion={copyQuestion} />
            <ReactDragListView {...dragProps} >
                {protocol.pages && protocol.pages.length && protocol.pages.map(element => (
                    <div key={element.index + element.name} className="protocolPagesSelector">
                            {page.index != element.index && (
                                <Button fullWidth className="leftButton"
                                    onClick={event => selectPage(element.index)} ><span className="leftButton">{element.name}</span></Button>
                            )}
                            {page.index == element.index && (
                                <Stack direction="row" spacing={3}>
                                    <TextField
                                        variant="outlined"
                                        defaultValue={element.name}
                                        onBlur={event => onSetProperty('name', event.target.value)}
                                        fullWidth
                                    />
                                    <Button className="protocolPagesHandle">
                                        <HeightIcon />
                                    </Button>
                                    <Button
                                        onClick={() =>
                                        {
                                            setCopyQuestion(element);
                                            setIsCopyOpen(true);
                                        }}
                                    >
                                        <ContentCopyIcon />
                                    </Button>
                                    <Button
                                        onClick={() =>
                                        {
                                            setDeletePageID(element.index);
                                            setIsDeleteOpen(true);
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </Button>
                                </Stack>
                        )}
                    </div>
                ))}
            </ReactDragListView>
            <Button color="secondary" variant="contained" onClick={onAddNewPageClick}>Dodaj novu stranu</Button>
        </div>
    );
}

export default withAuthentication(AdminProtocolPages);