import { Button } from '@mui/material';
import React from 'react';

export default function UploadButton(props)
{
    return (
        <div>
            <input id="contained-button-file" style={{display:"none"}} multiple type="file" onChange={event => props.onChange(event.target.files)} />
            <label htmlFor="contained-button-file">
                <Button variant="contained" color="secondary" component="span">
                    Dodaj datoteke
                </Button>
            </label>
        </div>
    );
}
