import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button, Grid, Typography, Box, Stack, Select, MenuItem, InputLabel, FormControl, Divider } from "@mui/material";
import { getDatabase, ref, set } from "firebase/database";
import { getPatient, setPatient } from "./PatientSlice"; // import the slice you defined
import { startListening, stopListening, updateData, writeData } from "../../app/firebase";
import { DatePicker, DateTimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Spacer from "../common/Spacer";

function PatientForm(props)
{
    const patient = useSelector(getPatient); // get the patient data from Redux
    const dispatch = useDispatch();
    const [localPatient, setLocalPatient] = useState(patient); // local state for the form

    useEffect(() =>
    {
        var listenerId = startListening('/patients/' + patient.id, onPatientValue);
        // cleanup function
        return () =>
        {
            stopListening(listenerId);
        };
    }, []);

    const onPatientValue = (newPatient) =>
    {
        dispatch(setPatient(newPatient));
    }

    // Update the patient info in Firebase and in the Redux store
    const updatePatient = async (property, value) =>
    {
        let updateObj = {};
        updateObj[property] = value;
        updateData('/patients/' + patient.id, updateObj, () => console.log('Patient updated'));
    };

    return (
        <form>
            <Typography variant="h4">Podaci o pacijentu:</Typography>
            <Spacer />
            <Stack spacing={3}>
            <Divider>Osnovne informacije</Divider>
                <Stack direction="row" spacing={3}>
                    <TextField
                        fullWidth
                        label="Prezime i ime pacijenta"
                        value={localPatient.name || ""}
                        onBlur={(e) => updatePatient("name", e.target.value)}
                        onChange={(e) =>
                            setLocalPatient({ ...localPatient, name: e.target.value })
                        }
                    />
                    <FormControl sx={{ m: 1, maxWidth: 125 }}>
                        <TextField
                            label="Broj protokola"
                            value={localPatient.protocol_number || ""}
                            disabled
                        />
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={3}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <DateTimeField
                            label="Datum rođenja"
                            disableFuture={true}
                            formatDensity="spacious"
                            format="DD/MM/YYYY"
                            value={dayjs(localPatient.birthdate) || dayjs()}
                            onBlur={(e) => { updatePatient("birthdate", dayjs(e.target.value, "DD/MM/YYYY").valueOf()) }}
                            onChange={date => { setLocalPatient({ ...localPatient, birthdate: date.valueOf() }) }}

                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 110 }}>
                        <InputLabel id="gender-select-label">Pol</InputLabel>
                        <Select
                            labelId="gender-select-label"
                            label="Pol"
                            value={localPatient.gender}
                            onBlur={(e) => updatePatient("gender", e.target.value)}
                            onChange={(e) =>
                            { setLocalPatient({ ...localPatient, gender: e.target.value }); }}
                            >
                                <MenuItem value="male">Muški</MenuItem>
                                <MenuItem value="female">Ženski</MenuItem>
                                <MenuItem value="other">Neodređen</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 125 }}>
                    <InputLabel id="age-select-label">Starosno doba</InputLabel>
                    <Select
                            labelId="age-select-label"
                            label="Starosno doba"
                            value={localPatient.age}
                            onBlur={(e) => updatePatient("age", e.target.value)}
                            onChange={(e) =>
                            { setLocalPatient({ ...localPatient, age: e.target.value }); }}
                    >
                        <MenuItem value="Adult">Punoletno</MenuItem>
                        <MenuItem value="Child">Maloletno</MenuItem>
                        <MenuItem value="Baby">Beba</MenuItem>
                        </Select>
                    </FormControl>
                    
                </Stack>
                <Divider>Kontakt informacije</Divider>
                <Stack direction="row" spacing={3}>
                    <TextField
                        fullWidth
                        label="Ulica i broj"
                        value={localPatient.address || ""}
                        onBlur={(e) => updatePatient("address", e.target.value)}
                        onChange={(e) =>
                            setLocalPatient({ ...localPatient, address: e.target.value })
                        }
                    />
                    <TextField
                        label="Grad"
                        value={localPatient.city || ""}
                        onBlur={(e) => updatePatient("city", e.target.value)}
                        onChange={(e) =>
                            setLocalPatient({ ...localPatient, city: e.target.value })
                        }
                    />
                    <FormControl sx={{ m: 1, maxWidth: 125 }}>
                    <TextField
                        label="Poštanski broj"
                        value={localPatient.zip || ""}
                        onBlur={(e) => updatePatient("zip", e.target.value)}
                        onChange={(e) =>
                            setLocalPatient({ ...localPatient, zip: e.target.value })
                        }
                        />
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={3}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={localPatient.email || ""}
                        onBlur={(e) => updatePatient("email", e.target.value)}
                        onChange={(e) =>
                            setLocalPatient({ ...localPatient, email: e.target.value })
                        }
                    />
                    <TextField
                        fullWidth
                        label="Telefon"
                        value={localPatient.phone || ""}
                        onBlur={(e) => updatePatient("phone", e.target.value)}
                        onChange={(e) =>
                            setLocalPatient({ ...localPatient, phone: e.target.value })
                        }
                        />
                </Stack>
            </Stack>
        </form>
    );
}

export default PatientForm;
