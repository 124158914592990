import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button, Grid, Typography, Box, Stack, Select, MenuItem, InputLabel, FormControl, Divider } from "@mui/material";
import { getDatabase, ref, set } from "firebase/database";
import { getPatient, setPatient } from "./PatientSlice"; // import the slice you defined
import { startListening, stopListening, updateData, writeData } from "../../app/firebase";
import { DatePicker, DateTimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import GenderMap from "../common/GenderMap";

function PatientFormPrint(props)
{
    const patient = useSelector(getPatient); // get the patient data from Redux
    const [localPatient, setLocalPatient] = useState(patient); // local state for the form

    return (
        <form>
            <Stack spacing={3}>
                <Stack direction="row" spacing={3}>
                    <TextField
                        fullWidth
                        label="Prezime i ime"
                        variant="standard"
                        value={localPatient.name || ""}
                        
                    />
                    <FormControl sx={{ m: 1, maxWidth: 105 }}>
                        <TextField
                            label="Protokol"
                            variant="standard"
                            value={localPatient.protocol_number || ""}
                        
                        />
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={3}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <DateTimeField
                            label="Datum rođenja"
                            disableFuture={true}
                            formatDensity="spacious"
                            variant="standard"
                            format="DD/MM/YYYY"
                            value={dayjs(localPatient.birthdate) || dayjs()}
                        
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 110 }}>
                        <TextField
                            label="Pol"
                            value={GenderMap[localPatient.gender] || ""}
                            variant="standard"
                        />
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={3}>
                    <TextField
                        fullWidth
                        label="Ulica i broj"
                        variant="standard"
                        value={localPatient.address || ""}
                        
                    />
                    <TextField
                        label="Mesto"
                        variant="standard"
                        value={localPatient.city || ""}
                        
                    />
                    <FormControl sx={{ m: 1, maxWidth: 105 }}>
                    <TextField
                        label="Poštanski broj"
                        value={localPatient.zip || ""}
                        variant="standard"
                        />
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={3}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={localPatient.email || ""}
                        variant="standard"
                    />
                    <TextField
                        fullWidth
                        label="Telefon"
                        value={localPatient.phone || ""}
                        variant="standard"
                        />
                </Stack>
            </Stack>
        </form>
    );
}

export default PatientFormPrint;
